import { Button, withStyles } from "@material-ui/core";
import format from "date-fns/format";
import { debounce, throttle, isNil } from "lodash";
import { useCallback, useEffect, useRef } from "react";

export const createObjectBasedOnFirst = (firstObj: any, otherObj: any) => {
    if (otherObj === undefined) {
        return firstObj;
    }
    let obj: any = {};
    Object.keys(firstObj).forEach(key => {
        if (otherObj.hasOwnProperty(key)) {
            obj[key] = otherObj[key];
        } else {
            obj[key] = firstObj[key];
        }
    });
    return obj;
}

// Format int 123 => string $1.23
export const formatCurrencyAsDollars = (value: number | string): string => {
  if (typeof value === 'number') value = value.toString();
  if (value.length <= 2) value = value.padStart(3, '0');
  const dollar = value.slice(0, value.length - 2);
  const cents = value.slice(-2);
  return `$${dollar}.${cents}`;
}

// Format string $1.23 => int 123
export const formatDollarsAsCurrency = (value: string): number => {
  return parseInt(value.replaceAll(/\$|,|./g, ''));
}

export const formatAsPhoneNumber = (value: string) => {
    value = value.replace(/[^0-9]/g, '').substring(0, 10);
    let number = '';
    if (value.length > 0 && value.length <= 3) {
        number = `(${value.substring(0, value.length)}`;
    } else if (value.length > 3 && value.length < 7) {
        number = `(${value.substring(0, 3)}) ${value.substring(3, value.length)}`;
    } else if (value.length > 6) {
        number = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, value.length)}`;
    }
    return number
}

export const formatAsPercent = (value: string | number, toPercent: boolean) => {
    value = value.toString();
    value = value.replace(/[^0-9.]/g, '');
    if (toPercent && value.trim().length > 0) {
        return value + '%';
    } else {
        return value;
    }
}

export const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatTime = (time: { hour: number; minute: number | undefined; }): string => {
    // h (1, 2, ..., 11, 12)
    // mm (00, 01, ..., 59)
    // aaaaa (a, p)
    let _time = '';
    try {
        _time = format(new Date().setHours(time.hour, time.minute, 0, 0), 'h:mm aaaaa') + 'm'
    } catch (err) { }
    return _time;
}

export const formatTimeString = (date: Date, hour: number, minute: number): string => {
    // HH (00, 01, 02, ..., 23)
    // mm (00, 01, ..., 59)
    let _time = '';
    if (date !== undefined && hour !== undefined && minute !== undefined) {
        try {
            _time = format(date.setHours(hour, minute, 0, 0), 'HHmm')
        } catch (err) {
            console.error(err);
            return '';
        }
    }
    return _time;
}

/**
 * Taken from
 * https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
 * @param cb 
 * @param delay 
 */
export function useThrottle(cb: any, delay: number | undefined) {
    const options = { leading: true, trailing: false }; // add custom lodash options
    const cbRef = useRef(cb);
    // use mutable ref to make useCallback/throttle not depend on `cb` dep
    useEffect(() => { cbRef.current = cb; });
    return useCallback(
        throttle((...args) => cbRef.current(...args), delay, options),
        [delay]
    );
}

/**
 * Taken from
 * https://stackoverflow.com/a/2188651/6917847
 * @param timeString 
 */
export function parseTime(timeString: string) {
    // eslint-disable-next-line eqeqeq
    if (timeString == '') return null;

    var time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
    if (time == null) return null;

    var hours = parseInt(time[1], 10);
    // eslint-disable-next-line eqeqeq
    if (hours == 12 && !time[4]) {
        hours = 0;
    }
    else {
        hours += (hours < 12 && time[4]) ? 12 : 0;
    }
    var d = new Date();
    d.setHours(hours);
    d.setMinutes(parseInt(time[3], 10) || 0);
    d.setSeconds(0, 0);
    return d;
}
export function useDebounce(cb: any, delay: number | undefined) {
    const options = {
        leading: false,
        trailing: true
    };
    const isMounted = useIsMounted();
    useEffect(() => {
        inputsRef.current = { cb, delay };
    });
    const inputsRef = useRef(cb); // mutable ref like with useThrottle
    useEffect(() => { inputsRef.current = { cb, delay }; }); //also track cur. delay
    return useCallback(
        debounce((...args) => {
            // Debounce is an async callback. Cancel it, if in the meanwhile
            // (1) component has been unmounted (see isMounted in snippet)
            // (2) delay has changed
            if (inputsRef.current.delay === delay && isMounted())
                inputsRef.current.cb(...args);
        }, delay, options
        ),
        [delay, debounce]
    );
}
function useIsMounted() {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    return () => isMountedRef.current;
}


export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const partnerBase = {
    fullName: '',
    email: '',
    number: '',
    address: '',
    profileImage: {
        imageId: null,
        url: null,
        urlHd: null,
        urlSd: null,
        urlThumbnail: null,
    },
    signUpState: {
        page: 1,
        activePage: 1,
        doneSignUp: false,
    },
    revenuePercent: 0.2,
};

export const locationBase = {
    locationId: null,
    name: '',
    address: '',
    latlng: {
        lat: null,
        lng: null,
    },
    number: '',
    taxRate: '',
    hours: null,
    experienceIds: [],
    materials: {
        hasInstoreMaterials: false,
    },
    manager: {
        name: '',
        managerImage: {
            imageId: null,
            url: null,
            urlHd: null,
            urlSd: null,
            urlThumbnail: null,
        }
    },
    payoutMethod: {}
}

export const experienceBase: any = {
    experienceId: null,
    title: '', // min 3 max 50
    why: '', // min 0 max 500
    price: 0,
    discountedPrice: 0,
    priorityValue: 1,
    order: 1,
    mutualsCut: 0,
    staffInstructions: '',
    type: {
        key: 0,
        value: 'Food',
    },
    hideDiscountedPrice: false,
    numRedeems: null,
    websiteUrl: '',
    quantity: { value: 0, label: 'Unlimited' },
    occurance: [],
    seasons: [],
    includedItems: [''],
    addOns: [
        { title: '', price: 0 }
    ],
    images: [],
    categories: [],
    approved: false,
    live: false,
    hours: null,
    limitPerUser: null,
    redemptionDetails: '', // min 0 max 250
}

export const BackButton = withStyles({
    root: {
        width: '132px',
        padding: '12.5px 0',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '5px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
        },
    },
})(Button);

export const boxShadow =
    "0px 9px 31px rgba(18, 74, 12, 0.24), 0px 2.01027px 6.92426px rgba(18, 74, 12, 0.143066), 0px 0.598509px 2.06153px rgba(18, 74, 12, 0.0969343)";

export const NextButton = withStyles({
    root: {
        width: '132px',
        padding: '12.5px 0',
        backgroundColor: 'rgba(0,255,148,.5)',
        borderColor: 'rgba(0,255,148,.5)',
        borderRadius: '5px',
        boxShadow: boxShadow,
        '&:hover': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
        '&:active': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
    },
})(Button);

export const DenyOutlineButton = withStyles({
    root: {
        width: '100%',
        height: '50px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #FE2A2A',
        borderRadius: '5px',
        padding: '6px 8px',
        boxShadow: boxShadow,
        color: '#FE2A2A',
        textTransform: 'uppercase',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 700,
        letterSpacing: '0.05em',
    },
})(Button);

export const CancelOutlineButton = withStyles({
    root: {
        width: '100%',
        height: '50px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #161515',
        borderRadius: '5px',
        padding: '6px 8px',
        boxShadow: boxShadow,
        color: '#161515',
        textTransform: 'uppercase',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 700,
        letterSpacing: '0.05em',
    },
})(Button);

// export const CancelOutlineButton = withStyles({
//     root: {
//         width: '100%',
//         height: '50px',
//         backgroundColor: '#FFFFFF',
//         border: '1px solid #FE2A2A',
//         borderRadius: '5px',
//         padding: '6px 8px',
//         boxShadow: boxShadow,
//         color: '#FE2A2A',
//         textTransform: 'uppercase',
//         fontSize: '14px',
//         lineHeight: '17px',
//         fontWeight: 700,
//         letterSpacing: '0.05em',
//     },
// })(Button);

// export const SaveOutlineButton = withStyles({
//     root: {
//         width: '100%',
//         height: '50px',
//         backgroundColor: '#FFFFFF',
//         border: '1px solid #29ABE3',
//         borderRadius: '5px',
//         padding: '6px 8px',
//         boxShadow: boxShadow,
//         color: '#29ABE3',
//         textTransform: 'uppercase',
//         fontSize: '14px',
//         lineHeight: '17px',
//         fontWeight: 700,
//         letterSpacing: '0.05em',
//     },
// })(Button);

export const CopyButton = withStyles({
    root: {
        minWidth: '115px',
        padding: '13px 26px',
        backgroundColor: '#00FF94',
        borderColor: '#00FF94',
        borderRadius: '5px',
        boxShadow: boxShadow,
        '&:hover': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
        '&:active': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
    },
})(Button);

export const PaymentButton = withStyles({
    root: {
        padding: '13px 26px',
        backgroundColor: '#00FF94',
        borderColor: '#00FF94',
        borderRadius: '5px',
        boxShadow: boxShadow,
        '&:hover': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
        '&:active': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
    },
})(Button);

export const ExperiencesButton = withStyles({
    root: {
        backgroundColor: '#2ABC12',
        borderColor: '#2ABC12',
        borderRadius: '5px',
        padding: '6px 8px',
        boxShadow: boxShadow,
        '&:hover': {
            backgroundColor: '#2ABC12',
            borderColor: '#2ABC12',
        },
        '&:active': {
            backgroundColor: '#2ABC12',
            borderColor: '#2ABC12',
        },
    },
})(Button);

export const ExperiencesSubmitButton = withStyles({
    root: {
        height: '50px',
        backgroundColor: '#00FF94',
        borderColor: '#00FF94',
        borderRadius: '5px',
        padding: '6px 8px',
        boxShadow: boxShadow,
        '&:hover': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
        '&:active': {
            backgroundColor: '#00FF94',
            borderColor: '#00FF94',
        },
    },
})(Button);

export const ExperiencesSaveButton = withStyles({
    root: {
        height: '50px',
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
        borderRadius: '5px',
        padding: '6px 8px',
        boxShadow: boxShadow,
        '&:hover': {
            backgroundColor: '#FFFFFF',
            borderColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
            borderColor: '#FFFFFF',
        },
    },
})(Button);

export const notNumbersRegex = /[^0-9]+/gi;

export function convertExperienceToEditedExperience(exp: any) {
    if (!isNil(exp.editedExperience)) {
        Object.keys(exp.editedExperience).forEach((key) => {
        if (key !== "live" && key !== "approved") {
            exp[key] = exp.editedExperience[key];
        }
        });
    }
    return;
};