import {
  Box,
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    label: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 700,
      color: "#5C5C5C",
    },
    formInput: {
      paddingBottom: "1rem",
    },
    ownerField: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 400,
      color: "#5C5C5C",
    },
  });

function GenericFormInput(props: any) {
  const {
    classes,
    formField,
    infoError,
    editMode,
    object,
    handleInputChange,
    onBlur,
    detectFormErrors,
    key,
    disabled = false,
    width,
  } = props;
  return (
    <div>
      <InputLabel
        htmlFor={props.formField.name}
        className={classes.label}
        error={infoError[formField.name]}
      >
        {formField.label}
      </InputLabel>
      {editMode ? (
        <FormControl
          error={infoError[formField.name]}
          required
          fullWidth
          className={classes.formInput}
          style={width && { width: width }}
        >
          <Input
            key={formField.name}
            id={formField.name}
            onBlurCapture={(evt) => onBlur(formField.name)}
            onChange={(evt) =>
              handleInputChange(formField.name, evt.target.value)
            }
            value={object[formField.name]}
            name={formField.name}
            multiline={formField.multiline}
            onBlur={() => detectFormErrors(formField.name)}
            disabled={disabled}
            style={{
              color: infoError[formField.name] ? "#FE2A2A" : "unset",
              borderBottom: infoError[formField.name]
                ? "unset"
                : "1px solid rgba(0, 0, 0, 0.42)",
            }}
            className={classes.ownerField}
          />
          {infoError[formField.name] && (
            <FormHelperText id={`partner${formField.name}${key}`}>
              {formField.errorMsg}
            </FormHelperText>
          )}
        </FormControl>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          paddingBottom={"1rem"}
          paddingTop={"1rem"}
        >
          <Typography className={clsx(classes.ownerField)}>
            {object[formField.name]}&nbsp;
          </Typography>
        </Box>
      )}
    </div>
  );
}
export default withStyles(styles)(GenericFormInput);
