import React, { useEffect, useState } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { getAdminId, getPartnerId } from "../services/auth";
import {
  convertExperienceToEditedExperience,
  createObjectBasedOnFirst,
  locationBase,
  partnerBase,
} from "../services/util";
import {
  ACCOUNT,
  ADMINTOOLS,
  BUSINESSINFO,
  EMPLOYEETRAINING,
  EXPERIENCES,
  PAYOUTMETHOD,
  TRANSACTIONS,
} from "../constants/routes";
import DashboardPage from "./DashboardPage";
import { isNil, isEqual, unionBy } from "lodash";
import getData from "../services/getData";

const styles = createStyles({});

// TODO: maybe all of this should live in something like Redux?
function Wrapper(props: any) {
  const { user, locationId, setLocationId } = props;
  const [partnerId, setPartnerId] = useState("");
  const [adminId, setAdminId] = useState("");
  const [token, setToken] = useState<string>("");
  const [partner, setPartner] = useState<any>(
    createObjectBasedOnFirst(partnerBase, {})
  );
  const [isSignUp, setIsSignUp] = useState(true);

  const [locations, setLocations] = useState<any>([]);
  const [allExperiences, setAllExperiences] = useState<any>([]);
  const [experiences, setExperiences] = useState<any>([]);
  const [payoutMethods, setPayoutMethods] = useState<any>({});
  const [cards, setCards] = useState<any>([]);
  const [location, setLocation] = useState<any>(createObjectBasedOnFirst(locationBase, !isNil(locations) && locations.length > 0 ? locations[0] : {}));
  const [prevLocation, setPrevLocation] = useState<any>();

  const updateLocation = async (_location: any) => {
    if (_location.hasOwnProperty("locationId")) {
      setLocation(createObjectBasedOnFirst(locationBase, _location));
    }
  };

  // Fetch locations to get the most updated locations info
  useEffect(() => {
    if (!isEqual(location, prevLocation)) {
      getLocations(location.locationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, location]);

  const updatePayoutMethods = async (locationId: string) => {
    await getLocations(locationId);
    await getPayoutMethods("stripe", {}).then((_otherPaymentMethods) => {
      getPayoutMethods("check", _otherPaymentMethods).then(
        (_paymentMethods) => {}
      );
    });
  };

  useEffect(() => {
    if (locationId !== undefined && locationId !== "") {
      updatePayoutMethods(locationId);
      setLocationId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, setLocationId]);

  const setupPartnerId = async () => {
    let token;
    let partnerId = "";
    token = await user.getIdToken(true);
    partnerId = await getPartnerId(token);
    if (partnerId === "") {
      partnerId = user.uid;
    }
    if (partnerId !== null) {
      setToken(token);
      setPartnerId(partnerId);
    }
  };

  // Listen for token changes, setup timer to update every 10 minutes
  // Listen and timer are causing reload of token on start, which causes token/partnerId to be update
  // which triggers API calls reloading the app data
  useEffect(() => {
    async function setupPartnerId() {
      let token;
      let partnerId = "";
      token = await user.getIdToken(true);
      partnerId = await getPartnerId(token);
      if (partnerId === "") {
        partnerId = user.uid;
      }
      if (partnerId !== null) {
        setToken(token);
        setPartnerId(partnerId);
      }
    }

    async function setupAdminId() {
      let token;
      let adminId = "";
      token = await user.getIdToken(true);
      adminId = await getAdminId(token);
      if (adminId !== null) {
        setToken(token);
        setAdminId(adminId);
      }
    }

    // const refreshToken = setInterval(() => {
    //   setupPartnerId();
    // }, 10 * 60 * 1000);
    setupPartnerId();
    setupAdminId();
    // const unsubOnIdTokenChanged = firebase
    //   .auth()
    //   .onIdTokenChanged(async (user) => {
    //     if (user !== null) {
    //       const token: string = await user.getIdToken();
    //       let partnerId = await getPartnerId(token);
    //       if (partnerId !== null) {
    //         setToken(token);
    //         setPartnerId(partnerId);
    //       }
    //     } else {
    //       // Logout is detected clear our timer and onIdTokenChanged subscription
    //       clearInterval(refreshToken);
    //       unsubOnIdTokenChanged();
    //     }
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getData(
          `partners/${partnerId}`,
          "GET",
          token,
          {}
        );
        const _partner = createObjectBasedOnFirst(partnerBase, response);
        setPartner(_partner);
        setIsSignUp(!_partner.signUpState.doneSignUp);
        if (!_partner.signUpState.doneSignUp) {
          if (_partner.signUpState.activePage === 1) {
            props.history.push(`/home${ACCOUNT}`);
          } else if (_partner.signUpState.activePage === 2) {
            props.history.push(`/home${BUSINESSINFO}`);
          } else if (_partner.signUpState.activePage === 3) {
            props.history.push(`/home${EXPERIENCES}`);
          } else if (_partner.signUpState.activePage === 4) {
            props.history.push(`/home${PAYOUTMETHOD}`);
          } else if (_partner.signUpState.activePage === 5) {
            props.history.push(`/home${TRANSACTIONS}`);
          } else if (_partner.signUpState.activePage === 6) {
            props.history.push(`/home${EMPLOYEETRAINING}`);
          }
        } else {
          props.history.push(`/home${BUSINESSINFO}`);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (
      !isNil(partnerId) &&
      partnerId.length > 0 &&
      token.length > 0 &&
      partnerId !== user.uid
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, partnerId]);

  useEffect(() => {
    if (partner !== undefined) {
      setIsSignUp(!partner.signUpState.doneSignUp);
      if (!partner.signUpState.doneSignUp) {
        if (partner.signUpState.activePage === 1) {
          props.history.push(`/home${ACCOUNT}`);
        } else if (partner.signUpState.activePage === 2) {
          props.history.push(`/home${BUSINESSINFO}`);
        } else if (partner.signUpState.activePage === 3) {
          props.history.push(`/home${EXPERIENCES}`);
        } else if (partner.signUpState.activePage === 4) {
          props.history.push(`/home${PAYOUTMETHOD}`);
        } else if (partner.signUpState.activePage === 5) {
          props.history.push(`/home${TRANSACTIONS}`);
        } else if (partner.signUpState.activePage === 6) {
          props.history.push(`/home${EMPLOYEETRAINING}`);
        }
      }
    }
  }, [partner, props.history]);

  async function getLocations(locationId: string) {
    if (partnerId !== user.uid) {
      setPrevLocation(location);
      
      try {
        const locations = await getData("locations", "GET", token, {});
        if (locations.length > 0) {
          setLocations(locations);

          if (user.shouldReload !== undefined) {
            locationId = user.shouldReload;
          }
          if (locationId === "") {
            setLocation(createObjectBasedOnFirst(locationBase, locations[0]));
          } else {
            const _location = locations.filter(
              (item: { locationId: any }) => item.locationId === locationId
            );
            if (_location.length === 1) {
              setLocation(
                createObjectBasedOnFirst(locationBase, _location.pop())
              );
            }
          }
        } else {
          setLocations(undefined);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getExperiences() {
    if (partnerId !== user.uid) {
      if (!isNil(location)) {
        await getLocations(location.locationId);
      }
      try {
        let _experiences = await getData("experiences", "GET", token, {});
        if (_experiences.length > 0) {
          _experiences = _experiences.map((experience: any) => {
            convertExperienceToEditedExperience(experience);
            return experience;
          });
          setAllExperiences(_experiences);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getPaymentMethods() {
    if (partnerId !== user.uid) {
      try {
        const paymentMethods = await getData(
          "payment-methods",
          "GET",
          token,
          {}
        );
        if (paymentMethods.length > 0) {
          let _cards = [];
          for (let payoutMethod of paymentMethods) {
            _cards.push({
              key: payoutMethod.id,
              brand: payoutMethod.card.brand.toLowerCase(),
              last4: payoutMethod.card.last4,
            });
          }
          setCards(_cards);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (allExperiences !== undefined && location !== undefined) {
      const _experiences = allExperiences.filter((exp: any) =>
        location.experienceIds.includes(exp.experienceId)
      );
      if (JSON.stringify(experiences) !== JSON.stringify(_experiences)) {
        setExperiences(_experiences);
      }
    }
  }, [allExperiences, location, experiences]);

  async function getPayoutMethods(type: string, _otherPaymentMethods: any) {
    if (token !== undefined && (await getPartnerId(token)) !== "") {
      try {
        const response = await getData(
          `payout-methods?type=${type}`,
          "GET",
          token,
          {}
        );
        const stripeAccounts = unionBy(
          payoutMethods.stripeAccounts,
          response.stripeAccounts,
          _otherPaymentMethods.stripeAccounts,
          "id"
        );
        const lobAccounts = unionBy(
          payoutMethods.lobAccounts,
          response.lobAccounts,
          _otherPaymentMethods.lobAccounts,
          "id"
        );
        const _paymentMethods = { stripeAccounts, lobAccounts };
        setPayoutMethods(_paymentMethods);
        return _paymentMethods;
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (
      token !== undefined &&
      token.length > 1 &&
      !isNil(partnerId) &&
      partnerId !== "" &&
      partnerId.length > 0
    ) {
      getLocations("");
      getExperiences();
      getPayoutMethods("stripe", {}).then((_otherPaymentMethods) => {
        getPayoutMethods("check", _otherPaymentMethods);
      });
      getPaymentMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, partnerId]);

  const selectFirstLocation = () => {
    if (
      locations !== undefined &&
      locations.length > 0 &&
      (location === undefined || location.locationId === null)
    ) {
      setLocation(createObjectBasedOnFirst(locationBase, locations[0]));
    }
  };

  return (
    <div>
      {partner !== undefined && (
        <DashboardPage
          key={"dashboardPage"}
          partner={partner}
          setPartner={setPartner}
          isSignUp={isSignUp}
          token={token}
          setToken={setToken}
          partnerId={partnerId}
          adminId={adminId}
          setPartnerId={setPartnerId}
          user={user}
          locations={locations}
          experiences={experiences}
          payoutMethods={payoutMethods}
          getLocations={getLocations}
          updatePayoutMethods={updatePayoutMethods}
          updatePaymentMethods={getPaymentMethods}
          selectedLocation={location}
          setLocation={setLocation}
          selectFirstLocation={selectFirstLocation}
          cards={cards}
          getPaymentMethods={getPaymentMethods}
          updateLocation={updateLocation}
          setupPartnerId={setupPartnerId}
          getExperiences={getExperiences}
        ></DashboardPage>
      )}
    </div>
  );
}

export default withStyles(styles)(withRouter(Wrapper));
