import {
  Box,
  Card,
  createStyles,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  withTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: '10px'
      }
    },
    dataTitle: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
    },
    headerText: {
      [theme.breakpoints.up("md")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      fontWeight: 900,
      lineHeight: "36px",
    },
    locationText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "21.6px",
      color: "#5C5C5C",
    },
    headerDivider: {
      marginTop: theme.spacing(2),
      backgroundColor: "#D6D6D6",
    },
    table: {},
    tableHead: {
      width: "100%",
      backgroundColor: "#F2F2F2",
    },
    tableHeaderText: {
      color: "#5C5C5C",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      minWidth: "200px",
    },
    tableCell: {
      fontSize: "20px",
      lineHeight: "24px",
      color: "#0B0A0A",
    },
    headerValue: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      color: "#0B0A0A",
      width: "fit-content",
    },
    label: {
      color: "#5C5C5C",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "18px",
      width: "fit-content",
    },
    salesTax: {
      fontWeight: 700,
      fontSize: "17px",
      lineHeight: "20.4px",
      color: "#9A9A9A",
    },
    card: {
      [theme.breakpoints.up("md")]: {
        boxShadow: 'none'
      },
      [theme.breakpoints.down("sm")]: {
        boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802",
      },
    },
  });

function TransactionsPage(props: any) {
  const { classes, transactions, metrics, isSignUp, selectedLocation } = props; // currentUser, partnerId,

  const [days, setDays] = useState(new Map());

  function createData(transaction: any) {
    return {
      purchaseId: transaction.purchaseId,
      time: new Date(transaction.used.createdAt)
        .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
        .replace(/^0+/, ""),
      title: transaction.experience.title,
      salesTax: transaction.salesTax,
      spent: transaction.paymentAmount,
    };
  }

  useEffect(() => {
    if (selectedLocation.locationId !== null) {
      const _days = new Map();
      transactions.forEach((transaction: any) => {
        const options: Intl.DateTimeFormatOptions = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        const date = new Date(transaction.used.createdAt).toLocaleDateString(
          [],
          options
        );
        const row = createData(transaction);
        if (_days.has(date)) {
          const rows = _days.get(date);
          rows.push(row);
          _days.set(date, rows);
        } else {
          const rows = [];
          rows.push(row);
          _days.set(date, rows);
        }
      });
      setDays(_days);
    }
  }, [transactions, selectedLocation]);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box display="flex" flexDirection="column">
      <Card className={classes.card}>
        <Box display="flex" flexDirection="column" p={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" pb={2}>
              <Box display="flex" flexDirection="column" alignItems="baseline">
                <Typography className={classes.headerText}>
                  {isSignUp ? "Example Transactions" : "Transactions"}
                </Typography>
                <Typography className={classes.locationText}>
                  {selectedLocation.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!xs && !sm && (<Divider className={classes.headerDivider}></Divider>)}

          {selectedLocation.locationId !== null && (
            <div className={classes.root}>
              <Grid
                container
                spacing={5}
                style={{ justifyContent: sm ? "center" : "unset" }}
              >
                <Grid item className={classes.dataTitle}>
                  <Typography className={classes.label}>Sales</Typography>
                  <Typography className={classes.headerValue}>
                    {Number(metrics.sales).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item className={classes.dataTitle}>
                  <Typography className={classes.label}>Revenue</Typography>
                  <Typography className={classes.headerValue}>
                    {"$" +
                      (metrics.revenue / 100).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                  </Typography>
                </Grid>
                <Grid item className={classes.dataTitle}>
                  <Typography className={classes.label}>
                    Sales tax collected
                </Typography>
                  <Typography className={classes.headerValue}>
                    {"$" +
                      (metrics.salesTax / 100).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                  </Typography>
                </Grid>
                <Grid item className={classes.dataTitle}>
                  <Typography className={classes.label}>Clicks</Typography>
                  <Typography className={classes.headerValue}>
                    {Number(metrics.clicks).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item className={classes.dataTitle}>
                  <Typography className={classes.label}>
                    Marketing spend
                </Typography>
                  <Typography className={classes.headerValue}>
                    {"$" +
                      (metrics.marketingSpend / 100).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                  </Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </div>
          )}

          {[...days].map(([key, value], index: number) => {
            return (
              <Box
                key={key}
                display="flex"
                flexDirection="column"
                paddingTop={theme.spacing(0.25)}
              >
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell align="left">
                          <Typography
                            className={classes.tableHeaderText}
                            style={{ width: "15vw" }}
                          >
                            {sm ? key.split(",")[1].trim() : key}
                          </Typography>
                        </TableCell>
                        {!sm && <TableCell align="right"></TableCell>}
                        <TableCell align="right">
                          <Typography className={classes.tableHeaderText}>
                            Sales Tax
                        </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className={classes.tableHeaderText}>
                            Price
                        </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {value.map((row: any) => (
                        <TableRow key={row.purchaseId}>
                          <TableCell
                            className={classes.tableCell}
                            component="th"
                            scope="row"
                          >
                            {row.time}
                          </TableCell>
                          {!sm && (
                            <TableCell className={classes.tableCell}>
                              {row.title}
                            </TableCell>
                          )}
                          <TableCell className={classes.salesTax} align="right">
                            {"$" + (row.salesTax / 100).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.tableCell} align="right">
                            {"$" + (row.spent / 100).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            );
          })}
        </Box>
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(TransactionsPage));
