import { createStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Grid,
  Link,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import getData from "src/services/getData";
import { auth } from "firebase";
import { getAdminId, getPartnerId, isUserAdmin } from "src/services/auth";
import { delay } from "src/services/util";
import { Spinner } from "react-activity";
import { useState } from "react";

const styles = createStyles({
  fullWidth: {
    width: "100%",
  },
  toolbar: {
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  partnerName: {
    transform: "translateX(1%)",
    fontWeight: 300,
    fontSize: "13px",
    color: "#000000",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: "center",
  },
  link: {
    color: "#29ABE3",
    fontSize: "13px",
    fontStyle: "italic",
    textDecorationLine: "underline",
  },
  spinner: {
    alignSelf: "center",
  },
});

function HeaderBanner(props: any) {
  const { classes, partner, setPartnerId, setToken } = props;
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);

  const signoutOfPartner = async () => {
    setLoading(true);
    try {
      await getData(
        `partners/customClaim`,
        "PATCH",
        props.currentUser,
        {},
        {},
        true
      );
      let token = await auth().currentUser?.getIdToken(true);
      let tokenPartnerId = await getPartnerId(token);
      let tokenAdminId = await getAdminId(token);
      let attempts: number = 0;
      while (attempts < 5) {
        token = await auth().currentUser?.getIdToken(true);
        tokenPartnerId = await getPartnerId(token);
        if (tokenPartnerId === tokenAdminId) break;
        attempts++;
        await delay(1000);
      }
      if (token && tokenPartnerId === tokenAdminId) {
        setToken(token);
        setPartnerId(tokenPartnerId);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  async function checkIfUserIsAdmin() {
    const token = await auth().currentUser?.getIdToken(true);
    setUserIsAdmin(await isUserAdmin(token));
  }

  checkIfUserIsAdmin();
  return userIsAdmin ? (
    <>
      <AppBar style={{ zIndex: theme.zIndex.drawer + 1, position: "relative" }}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Grid container id="grid" alignItems="center" justifyContent="center">
            <Box display="flex" flexDirection="column">
              <Typography className={classes.partnerName}>
                You are viewing as {partner.fullName ?? partner.email}
              </Typography>

              {loading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Spinner color="#000000" size={15} />
                </Box>
              ) : (
                <Typography className={classes.partnerName}>
                  <Link
                    component="button"
                    className={classes.link}
                    onClick={() => signoutOfPartner()}
                  >
                    Back to Admin
                  </Link>
                </Typography>
              )}
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  ) : (
    <></>
  );
}

export default withStyles(styles)(withRouter(HeaderBanner));
