import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { MenuList, MenuItem, ListItemIcon, ListItemText, Box } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { SignOutIcon } from '../Icons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import firebase from "firebase/app";

const styles = (theme: Theme) =>
    createStyles({
        item: {
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
        },
        itemCategory: {
            backgroundColor: '#1E1029',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        mainHeader: {
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'flex-start',
            padding: '0 1rem',
            backgroundColor: '#1E1029'
        },
        header: {
            fontSize: 24,
            color: theme.palette.common.white,
        },
        itemActiveItem: {
            backgroundColor: '#3C2052',
        },
        itemActiveItemText: {
            color: '#00FF94',
        },
        itemPrimary: {
            fontSize: '18px',
            lineHeight: '22px',
            color: '#DECDEB',
        },
        itemIcon: {
            minWidth: 'auto',
            marginRight: theme.spacing(2),
        },
        divider: {
            marginTop: theme.spacing(2),
            width: '80%',
            opacity: '0.5',
            backgroundColor: '#DECDEB',
            marginRight: '5%',
        },
        businessName: {
            width: '100%',
        },
        businessNameText: {
            fontSize: '18px',
            lineHeight: '22px',
            color: '#5C5C5C',
        },
        menuItem: {
            paddingTop: "0",
            paddingBottom: "0",
        },
        drawerPadding: {
            paddingTop: "56px"
        },
    });

const mutualTextStyle = {
    fontWeight: 900,
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '-0.377px',
}

const experiencesTextStyle = {
    fontWeight: 300,
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '-0.377px',
    color: '#00FF94',
}

const menuListStyle = {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: '100%',
    backgroundColor: '#1E1029'
}

const dividerContainerStyle = {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
}

function Navigator(props: any) {

    const { theme, classes, staticContext, locations, selectedLocation, changeLocation,
        categories, setCategories, isSignUp, partner, setActivePage, selectFirstLocation, isUsingPartner, handleDrawerToggle, ...other } = props;


    const MIN = 0;
    const MAX = 6;
    const normalise = (value: number) => Number(((value - MIN) * 100 / (MAX - MIN) / 100).toFixed(2));
    const [progress, setProgress] = useState(normalise(partner === undefined ? 1 : partner.signUpState.page));

    useEffect(() => {
        setProgress(normalise(partner === undefined ? 1 : partner.signUpState.page));
    }, [partner]);

    const signOut = async () => {
        await firebase.auth().signOut();
    };

    const truncate = (str: string, n: number) => {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    };

    let locationNames = [];
    let locationLabel;

    if (locations !== undefined && locations.length > 0) {
        locationNames = locations.map((location: { locationId: string; name: string; }) => {
            return { value: location.locationId, label: truncate(location.name, 20) }
        });
        locationNames.push({ value: null, label: 'Add New Location' });
        if (selectedLocation !== undefined && selectedLocation.name !== undefined && selectedLocation.locationId !== null) {
            locationLabel = { value: selectedLocation.locationId, label: truncate(selectedLocation.name, 20) };
        } else if (selectedLocation !== undefined && selectedLocation.locationId === null) {
            locationLabel = locationNames[locationNames.length - 1];
        } else {
            locationLabel = locationNames[0];
        }
    }

    return (
        <Drawer variant="permanent" {...other}>
            <Box display='flex' flexDirection='column' className={isUsingPartner ? classes.drawerPadding : null}>
                <div className={clsx(classes.mainHeader, classes.header, classes.itemCategory)}>
                    <h1 style={mutualTextStyle}>Tonite</h1>
                </div>
                {isSignUp && <ProgressBar width={175} percent={progress} status={`${progress * 100}% Complete`}></ProgressBar>}
            </Box>

            <MenuList disablePadding style={menuListStyle} >
                <div>
                    <MenuItem classes={{ root: classes.menuItem }} className={classes.itemCategory}
                        style={{ overflow: 'unset', display: (isSignUp ? 'none' : 'block'), padding: theme.spacing(1) }} >
                        <Dropdown className={classes.businessName} options={locationNames}
                            value={locationLabel} placeholder='Location Name'
                            onChange={changeLocation} />
                    </MenuItem>
                    {categories.map((category: any) => (
                        <div key={category.id}>
                            {category.children.map((nav: any, index: number) => {
                                return <MenuItem
                                    component={Link}
                                    onClick={() => { if (isSignUp) { setActivePage(nav.index); } else if (selectedLocation.locationId === null) { selectFirstLocation() } else { handleDrawerToggle() } }}
                                    to={'/home/' + nav.id.toLowerCase()}
                                    key={nav.id}
                                    button
                                    className={clsx(classes.item,
                                        props.location.pathname.includes(nav.id.toLowerCase())
                                        && classes.itemActiveItem)}
                                    disabled={isSignUp && index + 1 > partner.signUpState.page}
                                >
                                    <ListItemIcon className={classes.itemIcon}>
                                        {props.location.pathname.includes(nav.id.toLowerCase()) ? nav.iconActive : nav.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.itemPrimary,
                                        }}
                                    >
                                        <div className={clsx(classes.inactiveItemText, props.location.pathname.includes(nav.id.toLowerCase())
                                            && classes.itemActiveItemText)}>{nav.id}</div>
                                    </ListItemText>
                                </MenuItem>
                            })}
                            {categories[categories.length - 1].id !== category.id &&
                                <div style={dividerContainerStyle}><Divider className={classes.divider} /></div>}
                        </div>
                    ))}
                </div>
                <div>
                    <MenuItem className={clsx(classes.item, classes.itemCategory)}
                        onClick={() => signOut()}>
                        <ListItemIcon className={classes.itemIcon}>
                            <SignOutIcon fill='#DECDEB' />
                        </ListItemIcon>
                        <ListItemText
                            classes={{
                                primary: classes.itemPrimary,
                            }}
                        >
                            Sign Out
                        </ListItemText>
                    </MenuItem>
                </div>
            </MenuList>
        </Drawer >
    );
}

export default withStyles(styles)(withRouter(Navigator));
