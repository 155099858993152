import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import "./App.scss";
import LoginPage from "./pages/LoginPage";
import * as ROUTES from "./constants/routes";
import { ProtectedRoute } from "./components/ProtectedRoute";
import firebase, { User } from "firebase/app";
import "firebase/auth";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import { NotFound } from "./pages/NotFound";
import { devFirebaseConfig, firebaseConfig } from "./services/firebase";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Wrapper from "./pages/Wrapper";
import TermsOfService from "./pages/TermsOfService";
import { Flavors } from "./config/flavor.enums";
import Config from "./config";
import { ConfigKey } from "./config/config.enums";

function App() {
  const [user, setUser] = useState<User | null>();
  const [initFirebase, setInitFirebase] = useState(false);
  const [locationId, setLocationId] = useState("");

  useEffect(() => {
    if (!firebase.apps.length) {
      if (Config.getValue(ConfigKey.FLAVOR) === Flavors.dev) {
        firebase.initializeApp(devFirebaseConfig);
      }
      else {
        firebase.initializeApp(firebaseConfig);
      }
      setUser(firebase.auth().currentUser);
      setInitFirebase(true);
    }
  }, []);

  useEffect(() => {
    const getAuthStatus = () => {
      firebase.auth().onIdTokenChanged((user) => {
        setUser(user);
      })
    };
    getAuthStatus();
  }, []);

  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#22112F",
      },
      secondary: {
        main: "#00FF94",
      },
      info: {
        main: "#0C0B0B",
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
    typography: {
      fontFamily: ["Lato", "Helvetica", "Arial", "sans-serif"].join(","),
      button: {
        textTransform: "none",
      },
    },
    overrides: {
      MuiInput: {
        underline: {
          "&&&&:hover:before": {
            borderBottom: "0",
          },
        },
      },
    },
  });

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const locationId = localStorage.getItem("reload");
      if (locationId !== null && locationId !== "") {
        localStorage.removeItem("reload");
        setLocationId(locationId);
      }
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        {!initFirebase && (
          <Route
            exact
            path={ROUTES.LOGIN}
            render={(props) => <LoginPage {...props} auth={user} />}
          />
        )}
        {initFirebase && (
          <Switch>
            <Route
              exact
              path={ROUTES.LOGIN}
              render={(props) => <LoginPage {...props} auth={user} />}
            />
            ;
            <Route
              exact
              path={ROUTES.TERMS}
              component={TermsOfService}
            />
            ;
            <ProtectedRoute
              path={ROUTES.DASHBOARD}
              component={Wrapper}
              {...{ auth: user }}
              locationId={locationId}
              setLocationId={setLocationId}
            />
            ;
            <Route path="*" component={NotFound} />;
          </Switch>
        )}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
