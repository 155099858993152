import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, IconButton, Popover, Typography } from "@material-ui/core";
import {
  ExperiencesSubmitButton,
  CancelOutlineButton,
  DenyOutlineButton,
} from "../../services/util";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { TrashIcon, CardIcon } from "../Icons";
import { isNil } from "lodash";

enum BoostTypes {
  monthly = 0,
  fixedBudget = 1,
  free = 2,
}

export const CheckoutForm = (props: any) => {
  const {
    theme,
    classes,
    canSubmit,
    saveCard,
    cards,
    selectedCard,
    setSelectedCard,
    removeCard,
    cardComplete,
    setCardComplete,
    submitBoost,
    liveBoost,
    pendingBoosts,
    formType,
    setOpen,
    approveBoost,
    rejectBoost,
    manageFreeBoost,
    boostIsLive,
    isAdmin,
    boostType,
  } = props;

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [unableToDelete, setUnableToDelete] = useState("");

  useEffect(() => {
    if (!isNil(selectedCard) && liveBoost) {
      const _cards = cards.filter((item: any) => {
        return item.key === selectedCard.key;
      });
      setPaymentMethods(_cards);
    } else {
      setPaymentMethods(cards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, selectedCard]);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (cardComplete) {
      if (stripe !== null && elements !== null) {
        const card = elements.getElement(CardElement);
        if (card !== null) {
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
          });
          if (paymentMethod !== undefined) {
            await saveCard(paymentMethod.id);
            submitBoost(paymentMethod.id);
          }
        }
      }
    } else {
      submitBoost(selectedCard.key);
    }
  };

  const submitFreeBoost = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, approve: boolean) => {
    event.preventDefault();
    manageFreeBoost(approve);
  };

  const submitApproveBoost = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (cardComplete) {
      if (stripe !== null && elements !== null) {
        const card = elements.getElement(CardElement);
        if (card !== null) {
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
          });
          if (paymentMethod !== undefined) {
            await saveCard(paymentMethod.id);
            approveBoost(paymentMethod.id);
          }
        }
      }
    } else {
      approveBoost(selectedCard.key);
    }
  };

  const submitRejectBoost = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (cardComplete) {
      if (stripe !== null && elements !== null) {
        const card = elements.getElement(CardElement);
        if (card !== null) {
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
          });
          if (paymentMethod !== undefined) {
            await saveCard(paymentMethod.id);
            rejectBoost(paymentMethod.id);
          }
        }
      }
    } else {
      rejectBoost(selectedCard.key);
    }
  };

  const toggleSelected = (card: any, e: any) => {
    if (unableToDelete.length > 0) {
      setUnableToDelete("");
    }
    if (!liveBoost) {
      if (e.target.tagName !== "svg") {
        // Ignore if more dots are click
        if (selectedCard === undefined || selectedCard.key !== card.key) {
          setSelectedCard(card);
        } else {
          setSelectedCard(undefined);
        }
      }
    }
  };

  const focusOnCardElement = () => {
    if (unableToDelete.length > 0) {
      setUnableToDelete("");
    }
    setSelectedCard(undefined);
  };

  const cardElementChange = (event: any) => {
    setCardComplete(event.complete);
  };

  const deleteCard = (id: string) => {
    let cardInLiveUse = false;
    const boost = pendingBoosts.find((item: any) => {
      return item.paymentMethodId === id;
    });
    if (!isNil(boost)) {
      cardInLiveUse = true;
    }
    if (!cardInLiveUse) {
      removeCard(id);
    } else {
      setUnableToDelete(id);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {boostType.value !== BoostTypes.free && (<Box paddingY={theme.spacing(0.25)}>
        <Typography className={classes.cardTitle}>
          {liveBoost ? "Credit/Debit Card" : "Add Credit/Debit Card"}
        </Typography>
      </Box>)}
      {!isNil(unableToDelete) && unableToDelete !== "" && boostType.value !== BoostTypes.free && (
        <Typography className={classes.deleteError}>
          You can't delete a payment method that is attached to an active boost
        </Typography>
      )}
      {boostType.value !== BoostTypes.free && (<Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        marginBottom={theme.spacing(0.1)}
      >
        {paymentMethods.map((card: any) => (
          <Box
            id={card.key}
            key={card.key}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.cardContainer}
            width={0.475}
            marginBottom={theme.spacing(0.25)}
            paddingY={theme.spacing(0.25)}
            paddingLeft={theme.spacing(0.25)}
            paddingRight={theme.spacing(0.1)}
            style={{
              border:
                unableToDelete === card.key
                  ? "1px solid #F92B2A"
                  : selectedCard !== undefined && selectedCard.key === card.key
                    ? "1px solid rgba(186, 186, 186, 0.6)"
                    : "1px solid transparent",
              opacity:
                selectedCard !== undefined && selectedCard.key === card.key
                  ? "1"
                  : "0.5",
            }}
            onClick={(e) => toggleSelected(card, e)}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <CardIcon brand={card.brand} width={28} />
              <Typography
                className={classes.cardInfo}
              >{`Ending in ${card.last4}`}</Typography>
            </Box>
            <PopupState variant="popover" popupId="card-more-popover">
              {(popupState) => (
                <div>
                  <IconButton
                    {...bindTrigger(popupState)}
                    style={{ padding: "0" }}
                    className={classes.ignoreToggle}
                  >
                    <MoreVertIcon
                      htmlColor={
                        unableToDelete === card.key ? "#F92B2A" : "#2ABC12"
                      }
                    />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box width={"200px"} paddingY={"10px"} margin={0}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        paddingY={"4px"}
                        className={classes.hoverButton}
                        onClick={() => {
                          deleteCard(card.key);
                        }}
                        style={{
                          fontWeight: 700,
                          fontSize: "18px",
                          lineHeight: "21.6px",
                          width: "100%",
                          margin: "5px 0",
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          flex={1}
                          marginX={theme.spacing(0.1)}
                        >
                          <TrashIcon fill={"#5C5C5C"} />
                        </Box>
                        <Box display="flex" flexDirection="row" flex={4}>
                          <Typography
                            style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              lineHeight: "21.6px",
                              color: "#5C5C5C",
                            }}
                          >
                            Delete
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Popover>
                </div>
              )}
            </PopupState>
          </Box>
        ))}
      </Box>)}
      {(!liveBoost || isAdmin) && (
        <form>
          {boostType.value !== BoostTypes.free && (<CardElement
            onFocus={() => {
              focusOnCardElement();
            }}
            onChange={(event) => {
              cardElementChange(event);
            }}
          />)}
          <Box
            display="flex"
            flexDirection="column"
            paddingTop={theme.spacing(0.25)}
            paddingBottom={theme.spacing(0.1)}
          >
            {formType === "submit-approval" ? (
              <ExperiencesSubmitButton
                variant="contained"
                color="secondary"
                onClick={(event) => {
                  handleSubmit(event);
                }}
                disabled={
                  !canSubmit || (!cardComplete && selectedCard === undefined)
                }
                type="submit"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography className={classes.buttonTextMain}>
                    Submit for Approval
                  </Typography>
                </Box>
              </ExperiencesSubmitButton>
            ) : null}
            {formType === "edit" && boostType.value !== BoostTypes.free ? (
              <ExperiencesSubmitButton
                variant="contained"
                color="secondary"
                onClick={(event) => {
                  handleSubmit(event);
                }}
                disabled={
                  !canSubmit ||
                  (!cardComplete && selectedCard === undefined) ||
                  boostIsLive
                }
                type="submit"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography className={classes.buttonTextMain}>
                    Save Changes
                  </Typography>
                </Box>
              </ExperiencesSubmitButton>
            ) : null}
            {formType === "approve-reject" || boostType.value === BoostTypes.free ? (
              <>
                <ExperiencesSubmitButton
                  variant="contained"
                  color="secondary"
                  onClick={(event) => {
                    boostType.value === BoostTypes.free ? submitFreeBoost(event, !liveBoost) : submitApproveBoost(event);
                  }}
                  disabled={
                    (!canSubmit || (!cardComplete && selectedCard === undefined)) && boostType.value !== BoostTypes.free
                  }
                  type="submit"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography className={classes.buttonTextMain}>
                      {liveBoost && boostType.value === BoostTypes.free ? 'Remove Free Boost' : 'Approve'}
                    </Typography>
                  </Box>
                </ExperiencesSubmitButton>
                <Box padding={theme.spacing(0.1)}></Box>
                <Box display="flex" flexDirection="row">
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    paddingRight={theme.spacing(0.1)}
                  >
                    <CancelOutlineButton
                      variant="outlined"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </CancelOutlineButton>
                  </Box>
                  {boostType.value !== BoostTypes.free && (<Box
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    paddingLeft={theme.spacing(0.1)}
                  >
                    <DenyOutlineButton
                      variant="outlined"
                      disabled={!stripe}
                      type="submit"
                      onClick={submitRejectBoost}
                    >
                      Deny
                    </DenyOutlineButton>
                  </Box>)}
                </Box>
              </>
            ) : null}
          </Box>
        </form>
      )}
    </Box>
  );
};
