import { Box, Card, CardContent, createStyles, Divider, FormControl, Input, Theme, Typography, useMediaQuery, withStyles, withTheme } from "@material-ui/core";
import React, { useState } from "react";
import { CopyButton } from "../services/util";

const styles = (theme: Theme) =>
    createStyles({
        card: {
            minWidth: '350px',
            maxWidth: '500px',
            width: '50%',
            minHeight: '300px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            [theme.breakpoints.down("sm")]: {
                width: '95%',
                minWidth: '215px',
            },
        },
        headerText: {
            [theme.breakpoints.up("md")]: {
                fontSize: "30px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "24px",
            },
            fontWeight: 900,
            lineHeight: '36px',
        },
        locationText: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '21.6px',
            color: '#5C5C5C',
        },
        headerDivider: {
            marginTop: theme.spacing(2),
            backgroundColor: '#D6D6D6',
        },
        h2Text: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '24px',
            color: '#5C5C5C',
        },
        subHeader: {
            paddingBottom: theme.spacing(2),
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '18px',
            color: '#5C5C5C',
        },
        inputField: {
            width: '100%',
        },
        copyButtonText: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '16.8px',
            color: '#050505',
        },
        employeeCard: {
            [theme.breakpoints.up("md")]: {
                boxShadow: 'none'
            },
            [theme.breakpoints.down("sm")]: {
                boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802",
            },
        }
    });

function EmployeePage(props: any) {

    const { theme, classes, selectedLocation } = props; // currentUser, partnerId

    const text = 'Hi everyone!\n\nWe just partnered with Tonite (an app that makes it simple ' +
        'to discover cool dates at a discount)\n\nYou can also checkout the app at: mutual.app';
    const [training, setTraining] = useState(text);
    const [copied, setCopied] = useState(false);

    const handleChange = (event: any) => {
        setTraining(event.target.value);
        if (copied) {
            setCopied(false);
        }
    };

    const copyText = () => {
        navigator.clipboard.writeText(training).then(() => {
            setCopied(true);
        }, (err) => {
            console.error('Async: Could not copy text: ', err);
        });
    }

    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box display='flex' flexDirection='column'>
            <Card className={classes.employeeCard}>
                <Box m={2}>
                    <Box display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                            <Box display='flex' flexDirection='column'>
                                <Box display='flex' flexDirection='column' alignItems='baseline'>
                                    <Typography className={classes.headerText}>Employee Training</Typography>
                                    <Typography className={classes.locationText}>{selectedLocation.name}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {!xs && !sm && (<Divider className={classes.headerDivider}></Divider>)}
                    </Box>

                    <Box display='flex' flexDirection='column'>
                        <Typography className={classes.h2Text}>Employee training text</Typography>
                        <Typography className={classes.subHeader}>Copy this text and send it to your whole staff so transactions are a breeze</Typography>
                        <Card className={classes.card}>
                            <CardContent>
                                <FormControl className={classes.inputField} >
                                    <Input id="training" aria-describedby="Employee training information"
                                        value={training}
                                        disableUnderline multiline onChange={handleChange} />
                                </FormControl>
                            </CardContent>
                        </Card>
                        <Box display='flex' flexDirection='row' alignItems='center' paddingTop={theme.spacing(.25)}>
                            <CopyButton onClick={copyText}>
                                <Typography className={classes.copyButtonText}>{copied ? 'Copied!' : 'Copy Text'}</Typography>
                            </CopyButton>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
}

export default withTheme(withStyles(styles)(EmployeePage));
