import React, { useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import InfoHeader from "../components/InfoHeader/InfoHeader";
import {
  createObjectBasedOnFirst,
  formatAsPercent,
  formatAsPhoneNumber,
  formatTimeString,
  locationBase,
  partnerBase,
} from "../services/util";
import GenericFormInput from "../components/GenericFormInput/GenericFormInput";
import LocationSearch from "../components/LocationSearch/LocationSearch";
import StoreHours from "../components/StoreHours/StoreHours";
import EditableAvatar from "../components/EditableAvatar/EditableAvatar";
import Config from "../config";
import { ConfigKey } from "../config/config.enums";
import { isNil, throttle, isEqual } from "lodash";
import getData from "../services/getData";

interface PlaceType {
  name: string;
  address: string;
  location: LatLng;
}

interface LatLng {
  lat: number;
  lng: number;
}

const avatarSize = 6.5;
const styles = (theme: Theme) =>
  createStyles({
    location: {
      width: "100%",
      // maxWidth: "650px",
      display: "flex",
      flex: 1,
      boxShadow: "unset",
    },
    root: {
      width: "100%",
      display: "flex",
      flex: 1,
      boxShadow: "unset",
    },
    title: {
      fontSize: "24px",
      lineHeight: "29px",
    },
    pos: {
      marginBottom: 12,
    },
    large: {
      width: `${avatarSize}rem`,
      height: `${avatarSize}rem`,
      position: "unset",
    },
    divider: {
      width: "100%",
      marginLeft: "0.5%",
      backgroundColor: "#BABABA",
    },
    editButton: {
      fontStyle: "italic",
      fontSize: "14px",
      lineHeight: "16.8px",
      color: "#2ABC12",
      fontWeight: 400,
    },
    saveButton: {
      color: "#13A3D5",
    },
    stack: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    stackItem: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexFlow: "column",
      justifyContent: "flex-end",
    },
    stackForeground: {
      marginLeft: "-100%",
      width: `${avatarSize}rem`,
      height: `${avatarSize}rem`,
      clipPath: `circle(${avatarSize / 2}rem at center)`,
    },
    editBannerText: {
      color: "#FFFFFF",
      textAlign: "center",
    },
    editBanner: {
      backgroundColor: "#100F0F",
      opacity: 0.75,
      bottom: 0,
    },
    label: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 700,
      color: "#5C5C5C",
    },
    formInput: {
      paddingBottom: "1rem",
    },
    "MuiInput-input": {
      padding: 0,
    },
    ownerField: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 400,
      color: "#5C5C5C",
    },
    copyOwnerField: {
      color: "#13A3D5",
    },
    spacing: {
      paddingTop: "1rem",
    },
    spacingLarge: {
      paddingRight: "1rem",
    },
    removeTopBorderRadius: {
      borderRadius: "0px 0px 8px 8px",
    },
    removeBottomBorderRadius: {
      borderRadius: "8px 8px 0px 0px",
    },
  });

function BusinessInfoPage(props: any) {
  const {
    classes,
    selectedLocation,
    setLocation,
    updateLocationManager,
    signUpState,
    locationInfoError,
    setLocationInfoError,
    locationSelected,
    setLocationSelected,
    updateLocation,
  } = props;

  const week = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const [managerInfoError, setManagerInfoError] = useState<any>({
    name: false,
  });
  const [editManagerInfo, setEditManagerInfo] = useState(locationSelected);
  const [uploadingImage, setUploadingImage] = useState(false);
  let latLng: any;

  const [periods, setPeriods] = useState(
    selectedLocation === undefined || selectedLocation.hours === null
      ? undefined
      : selectedLocation.hours
  );
  const [locationOld] = useState<any>(
    createObjectBasedOnFirst(locationBase, selectedLocation)
  );

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));

  const getPlaces = React.useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          let url = `places?businessName=${request.input}`;
          if (latLng !== undefined) {
            url += `&lat=${latLng.lat}&lng=${latLng.lng}`;
          }
          const response = await getData(url, "GET", props.currentUser, {});
          callback(response);
        },
        1000
      ),
    [props.currentUser, latLng]
  );

  useEffect(() => {
    let active = true;
    if (isNil(selectedLocation) || selectedLocation.address === "") {
      return undefined;
    }
    getPlaces({ input: selectedLocation.address }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        if (results && results.length > 0) {
          newOptions = [...newOptions, ...results];
        }
        selectedLocation.latlng = {
          lat: newOptions[0]?.location?.lat,
          lng: newOptions[0]?.location?.lng,
        };
        if (newOptions.length > 0) {
          locationInfoError.address = false;
        } else {
          locationInfoError.address = true;
        }
        setLocationInfoError(locationInfoError);
        setManagerInfoError("address");
      }
    });
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlaces, selectedLocation, setLocationInfoError]);

  const setLocationInfo = async () => {
    const isUpdate =
      selectedLocation.hasOwnProperty("locationId") &&
      selectedLocation.locationId !== null;
    if (
      locationSelected &&
      !isEqual(selectedLocation, locationOld)
    ) {
      updateLocation(isUpdate);
    } else {
      setLocationSelected(!locationSelected);
    }
  };

  const saveManagerInfo = () => {
    setEditManagerInfo(!editManagerInfo);
    if (editManagerInfo) {
      selectedLocation.manager = {
        name: selectedLocation.manager.name,
      };
      if (
        selectedLocation.manager.managerImage !== undefined &&
        selectedLocation.manager.managerImage.imageId !== null
      ) {
        selectedLocation.manager.managerImage = {
          ...selectedLocation.manager.managerImage,
        };
      }
      updateLocation(true);
    }
  };

  const uploadManagerImage = async (files: any) => {
    if (files.length > 0) {
      setUploadingImage(true);
      saveManagerInfo();
      let form = new FormData();
      form.append("photo", files[0]);
      const apiProtocol = Config.getValue(ConfigKey.API_PROTOCOL);
      const apiRoot = Config.getValue(ConfigKey.API_ROOT);
      const apiRoute = Config.getValue(ConfigKey.API_ROUTE);
      const res = await fetch(
        `${apiProtocol}://${apiRoot}${apiRoute}/locations/${selectedLocation.locationId}/profile-image`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            authtoken: props.currentUser,
          }),
          body: form,
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        updateLocationManager({
          ...selectedLocation,
          manager: response.manager,
        });
      }
      setUploadingImage(false);
    }
  };

  const detectFormErrors = (name: string) => {
    const value = selectedLocation.manager[name];
    const errorState = { name: false };
    if (name === "name" && value !== undefined && value.length === 0) {
      errorState[name] = true;
    }
    setManagerInfoError(errorState);
  };

  const handleInputChangeManager = (e: {
    target: { name: string; value: string };
  }) => {
    let { name, value } = e.target;
    setLocation({
      ...selectedLocation,
      manager: { ...selectedLocation.manager, [name]: value },
    });
  };

  const handleInputChange = (name: string, value: any) => {
    if (name === "number") {
      value = formatAsPhoneNumber(value);
    }
    if (name === "taxRate") {
      value = formatAsPercent(value, false);
    }
    setLocation({ ...selectedLocation, [name]: value });
  };

  const handleBlur = async (name: string) => {
    if (name === "taxRate") {
      let value = selectedLocation.taxRate;
      value = formatAsPercent(value, true);
      setLocation({ ...selectedLocation, [name]: value });
    }
  };

  const updateLocationFields = (auto: any | null) => {
    if (!isNil(auto)) {
      setLocationSelected(true);
      setLocation({
        ...selectedLocation,
        name: !isNil(auto.name) ? auto.name : "",
        address: auto.address,
        number: !isNil(auto.number) ? auto.number : "",
        hours: !isNil(auto.opening_hours) ? auto.opening_hours : null,
        latlng: { ...auto.location },
      });
      if (!isNil(auto.opening_hours) && !isNil(auto.opening_hours.periods)) {
        setPeriods(auto.opening_hours.periods);
      }
    }
  };

  const updateHours = (openHours: any) => {
    if (
      selectedLocation["hours"] !== undefined &&
      openHours !== undefined &&
      openHours !== null
    ) {
      const oldHours = JSON.parse(JSON.stringify(selectedLocation["hours"]));
      const hours: {
        open: { day: number; time: string };
        close: { day: number; time: string };
      }[] = [];
      const date = new Date();
      Object.entries(openHours).forEach(([dayOfWeek, dayInfo]) => {
        if (dayInfo !== undefined) {
          const day: any = dayInfo;
          const dayIndex: number = week.indexOf(dayOfWeek);
          if (day.available) {
            if (day.hours !== undefined) {
              day.hours.forEach(
                (period: {
                  open: { hour: number; minute: number };
                  close: { hour: number; minute: number };
                }) => {
                  hours.push({
                    open: {
                      day: dayIndex,
                      time: formatTimeString(
                        date,
                        period.open.hour,
                        period.open.minute
                      ),
                    },
                    close: {
                      day: dayIndex,
                      time: formatTimeString(
                        date,
                        period.close.hour,
                        period.close.minute
                      ),
                    },
                  });
                }
              );
            }
          }
        }
      });
      hours.sort((a, b) =>
        a.open.day > b.open.day ? 1 : b.open.day > a.open.day ? -1 : 0
      );
      selectedLocation["hours"] = hours;
      setLocation(selectedLocation);
    }
  };

  if (selectedLocation === undefined) {
    return <Box></Box>;
  }

  const formFields = [
    {
      label: "Manager Name:",
      name: "name",
      type: "text",
      errorMsg: "Enter the managers full name",
      multiline: false,
      copy: false,
    },
  ];

  const formFieldsLocation = [
    {
      label: "Location Name:",
      name: "name",
      type: "text",
      errorMsg: "Enter the location's name",
      multiline: false,
      copy: false,
    },
    {
      label: "Phone Number:",
      name: "number",
      type: "text",
      errorMsg: "Please enter a complete phone number",
      multiline: false,
      copy: false,
    },
    {
      label: "Address:",
      name: "address",
      type: "text",
      errorMsg: "The address is not valid",
      multiline: false,
      copy: false,
    },
    {
      label: "Sales Tax:",
      name: "taxRate",
      type: "number",
      errorMsg: "Add your sales tax",
      multiline: false,
      copy: false,
      width: "100px",
    },
  ];

  const locationInfoFields = formFieldsLocation.map((formField, i) => {
    return (
      <div key={i}>
        <GenericFormInput
          infoError={locationInfoError}
          formField={formField}
          editMode={locationSelected}
          object={selectedLocation}
          handleInputChange={handleInputChange}
          onBlur={handleBlur}
          width={formField.width}
          detectFormErrors={() => {}}
          key={i}
        ></GenericFormInput>
      </div>
    );
  });
  const managerInfoFormFields = formFields.map((formField, i) => {
    return (
      <div key={i}>
        <InputLabel
          htmlFor={formField.name}
          className={classes.label}
          error={managerInfoError[formField.name]}
        >
          {formField.label}
        </InputLabel>
        {editManagerInfo ? (
          <FormControl
            error={managerInfoError[formField.name]}
            required
            fullWidth
            className={classes.formInput}
          >
            <Input
              key={formField.name}
              id={formField.name}
              onChange={handleInputChangeManager}
              value={selectedLocation.manager[formField.name]}
              name={formField.name}
              multiline={formField.multiline}
              style={{
                color: managerInfoError[formField.name] ? "#FE2A2A" : "unset",
                borderBottom: managerInfoError[formField.name]
                  ? "unset"
                  : "1px solid rgba(0, 0, 0, 0.42)",
              }}
              onBlur={() => detectFormErrors(formField.name)}
              className={classes.ownerField}
            />
            {managerInfoError[formField.name] && (
              <FormHelperText id={`partner${formField.name}${i}`}>
                {formField.errorMsg}
              </FormHelperText>
            )}
          </FormControl>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            paddingBottom={"1rem"}
            paddingTop={"1rem"}
          >
            <Typography
              className={clsx(
                classes.ownerField,
                formField.copy ? classes.copyOwnerField : ""
              )}
            >
              {selectedLocation.manager[formField.name]}&nbsp;
            </Typography>
          </Box>
        )}
      </div>
    );
  });

  return (
    <Box
      display="flex"
      flexDirection={sm || md ? "column" : "row"}
      alignItems={sm ? "center" : "start"}
    >
      <Card
        className={
          !xs && !sm
            ? classes.location
            : `${classes.location} ${classes.removeBottomBorderRadius}`
        }
      >
        <CardContent style={{ width: "100%" }}>
          <InfoHeader
            saveInfo={setLocationInfo}
            editInfo={locationSelected}
            infoError={locationInfoError}
            header={"Location Information"}
            showActions={
              !(signUpState.activePage === 2 && signUpState.page === 2)
            }
          ></InfoHeader>
          <LocationSearch
            updateLocationFields={updateLocationFields}
            currentUser={props.currentUser}
            hasLocationId={
              !(
                selectedLocation !== undefined &&
                selectedLocation.locationId === null
              )
            }
          ></LocationSearch>
          <Box display="flex" flexDirection="column" paddingTop={"1rem"}>
            <form key="locationFields">{locationInfoFields}</form>
          </Box>
          <StoreHours
            title={"Store Hours"}
            locationSelected={locationSelected}
            periods={periods}
            updateHours={updateHours}
          ></StoreHours>
        </CardContent>
      </Card>
      <>
        <Card
          className={
            md
              ? classes.location
              : !xs && !sm
              ? classes.root
              : `${classes.location} ${classes.removeTopBorderRadius}`
          }
        >
          <CardContent style={{ width: "100%" }}>
            <InfoHeader
              saveInfo={saveManagerInfo}
              editInfo={editManagerInfo}
              infoError={managerInfoError}
              header={"Manager Information"}
              showActions={
                !(signUpState.activePage === 2 && signUpState.page === 2)
              }
            ></InfoHeader>
            <EditableAvatar
              uploadImage={uploadManagerImage}
              url={selectedLocation.manager?.managerImage?.url}
              uploadingImage={uploadingImage}
            ></EditableAvatar>
            <Box display="flex" flexDirection="column" paddingTop={"1rem"}>
              <form>{managerInfoFormFields}</form>
            </Box>
          </CardContent>
        </Card>
      </>
    </Box>
  );
}

export default withStyles(styles)(BusinessInfoPage);
