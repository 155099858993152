import {
  Box,
  Card,
  createStyles,
  Dialog,
  Theme,
  Typography,
  withStyles,
  withTheme,
  FormControl,
  useMediaQuery,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  useTheme,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import getData from "src/services/getData";
import { Spinner } from "react-activity";
import Dropdown from "react-dropdown";
import SwitchPartnerDialog from "src/components/AdminTools/SwitchPartnerDialog";
import Chip from "../StyledChip/StyledChip";
import _ from "lodash";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    profileImage: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      objectFit: "cover",
      background: "#E6E6E6",
    },
    inputField: {
      paddingBottom: "15px",
      paddingTop: "5px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
      },
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    tableCell: {
      border: "none",
      // width: "180px"
    },
    incompleteSignUpChipStyle: {
      outlineColor: "#FCF55F",
      fontWeight: 500,
      fontSize: 13,
      margin: "5px",
    },
    incompleteExperiences: {
      backgroundColor: "#6495ED",
      fontWeight: 500,
      fontSize: 13,
      margin: "5px",
    },
    deactivatingExperiences: {
      backgroundColor: "#DE3163",
      fontWeight: 500,
      fontSize: 13,
      margin: "5px",
    },
  });

function PartnersList(props: any) {
  const { theme, value, index, classes, changePartner, initialPendingBoosts } =
    props;

  let experienceTypes: any = [];

  const [partners, setPartners] = useState<any>([]);
  const [filteredPartners, setFilteredPartners] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredPartners.length - page * rowsPerPage);
  // action items
  const [partnerIdsWithPendingBoost, setPartnerIdsWithPendingBoost] =
    useState<any>([]);
  const [
    partnerIdsWithInvalidPaymentMethods,
    setPartnerIdsWithInvalidPaymentMethods,
  ] = useState<any>([]);
  const [partnerIdsWithIncompleteSignUps, setPartnerIdsWithIncompleteSignUps] =
    useState<any>([]);
  const [
    partnerIdsWithIncompleteExperiences,
    setPartnerIdsWithIncompleteExperiences,
  ] = useState<any>([]);
  const [
    partnerIdsWithDeactivatingExperiences,
    setPartnerIdsWithDeactivatingExperiences,
  ] = useState<any>([]);
  const [
    partnerIdsWithIncompleteTraining,
    setPartnerIdsWithIncompleteTraining,
  ] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableAreas, setAvailableAreas] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState<any>({ ...experienceTypes });
  const [actionItemFilter, setActionItemFilter] = useState<string>();

  const actionItemDropdownTypes = [
    {
      label: "All Partners",
      value: "",
    },
    {
      label: "Pending Boosts",
      value: "pending_boosts",
    },
    {
      label: "Invalid Payment Method",
      value: "invalid_payment_method",
    },
    {
      label: "Incomplete Experience",
      value: "incomplete_experience",
    },
    {
      label: "Incomplete Sign Up",
      value: "incomplete_signup",
    },
    {
      label: "Deactivating Experiences",
      value: "deactivating_experiences",
    },
  ];

  useEffect(() => {
    fetchAvailableAreas();
    getAttentionActionItems();
    getApprovalActionItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSelectedPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea, availableAreas]);

  async function fetchAvailableAreas() {
    try {
      const response = await getData(
        "areas",
        "GET",
        props.currentUser,
        {},
        {},
        true
      );
      const areas = response.map((area: any) => {
        return { value: area.areaId, label: area.key, county: area.county };
      });
      setAvailableAreas([
        { value: null, label: "No filter", county: null },
        ...areas,
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  async function getSelectedPartners() {
    if (!_.isNil(selectedArea)) {
      setIsLoading(true);
      const response = await getData(
        selectedArea?.value ? `partners/${selectedArea?.value}` : "partners",
        "GET",
        props.currentUser,
        {},
        undefined,
        true
      );
      setPartners(response);
    }
    setIsLoading(false);
  }

  async function getApprovalActionItems() {
    setIsLoading(true);
    try {
      const response = await getData(
        "action-items",
        "GET",
        props.currentUser,
        {},
        {
          type: "approval",
        },
        true
      );
      setPartnerIdsWithPendingBoost(
        getUniquePartnerIdsFromList(response.pendingBoosts)
      );
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    setIsLoading(false);
  }

  async function getAttentionActionItems() {
    setIsLoading(true);
    try {
      const response = await getData(
        "action-items",
        "GET",
        props.currentUser,
        {},
        {
          type: "attention",
        },
        true
      );
      setPartnerIdsWithDeactivatingExperiences(
        getUniquePartnerIdsFromList(response.deactivatingExperiences)
      );
      setPartnerIdsWithIncompleteExperiences(
        getUniquePartnerIdsFromList(response.incompleteExperiences)
      );
      setPartnerIdsWithIncompleteSignUps(
        getUniquePartnerIdsFromList(response.incompletePartnerSignUps)
      );
      setPartnerIdsWithInvalidPaymentMethods(
        getUniquePartnerIdsFromList(response.invalidPaymentMethodPartners)
      );
      setPartnerIdsWithIncompleteTraining(
        getUniquePartnerIdsFromList(response.incompleteTraining)
      );
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    setIsLoading(false);
  }

  function getUniquePartnerIdsFromList(list: any[]) {
    let partnerIds = new Set();
    list.forEach((object: any) => {
      partnerIds.add(object.partnerId);
    });
    return [...partnerIds];
  }

  useEffect(() => {
    switch (actionItemFilter) {
      case "incomplete_signup":
        setFilteredPartners(
          partners.filter((partner: any) =>
            partnerIdsWithIncompleteSignUps.includes(partner.partnerId)
          )
        );
        break;
      case "incomplete_experience":
        setFilteredPartners(
          partners.filter((partner: any) =>
            partnerIdsWithIncompleteExperiences.includes(partner.partnerId)
          )
        );
        break;
      case "pending_boosts":
        setFilteredPartners(
          partners.filter((partner: any) =>
            partnerIdsWithPendingBoost.includes(partner.partnerId)
          )
        );
        break;
      case "invalid_payment_method":
        setFilteredPartners(
          partners.filter((partner: any) =>
            partnerIdsWithInvalidPaymentMethods.includes(partner.partnerId)
          )
        );
        break;
      case "deactivating_experiences":
        setFilteredPartners(
          partners.filter((partner: any) =>
            partnerIdsWithDeactivatingExperiences.includes(partner.partnerId)
          )
        );
        break;
      case "incomplete_training":
        setFilteredPartners(
          partners.filter((partner: any) =>
            partnerIdsWithIncompleteTraining.includes(partner.partnerId)
          )
        );
        break;
      default:
        setFilteredPartners(partners);
        break;
    }
  }, [
    partners,
    actionItemFilter,
    partnerIdsWithIncompleteSignUps,
    partnerIdsWithIncompleteExperiences,
    partnerIdsWithPendingBoost,
    partnerIdsWithInvalidPaymentMethods,
    partnerIdsWithDeactivatingExperiences,
    partnerIdsWithIncompleteTraining,
  ]);

  function updateSelectedArea(selectedArea: any) {
    setPartners([]);
    const fullAreaObject = availableAreas.find((area: any) => {
      return area.value === selectedArea.value;
    });
    setSelectedArea(fullAreaObject);
  }

  function handleActionItemChange(option: any) {
    setActionItemFilter(option.value);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ paddingBottom: "5%" }}>
      {value === index && (
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Box
            display="flex"
            flexDirection="row"
            style={{ padding: "10px 0px" }}
          >
            <Box style={{ marginRight: "10px" }}>
              <Dropdown
                controlClassName={classes.dropdownSelector}
                placeholderClassName={classes.dropDownOption}
                menuClassName={classes.dropdown}
                className={classes.dropDownOption}
                options={availableAreas}
                value={selectedArea.label}
                onChange={updateSelectedArea}
              />
            </Box>
            <Box style={{ marginLeft: "10px" }}>
              <Dropdown
                controlClassName={classes.dropdownSelector}
                placeholderClassName={classes.dropDownOption}
                menuClassName={classes.dropdown}
                className={classes.dropDownOption}
                options={actionItemDropdownTypes}
                value={actionItemFilter}
                onChange={handleActionItemChange}
              />
            </Box>
          </Box>
          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              m={5}
              style={{ padding: "10px 15px" }}
            >
              <Spinner />
            </Box>
          )}
          <TableContainer component={Paper}>
            <Table
              style={{ borderCollapse: "collapse" }}
              aria-label="custom pagination table"
            >
              <TableBody>
                {!_.isNil(partners) && partners.length > 0 ? (
                  (rowsPerPage > 0
                    ? filteredPartners.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredPartners
                  ).map((partner: any, i: number) => {
                    return (
                      <TableRow>
                        <PartnerListItem
                          key={i}
                          index={index}
                          classes={classes}
                          partner={partner}
                          changePartner={(id: string) => changePartner(id)}
                          theme={theme}
                          isPendingBoost={partnerIdsWithPendingBoost.includes(
                            partner.partnerId
                          )}
                          isInvalidPayment={partnerIdsWithInvalidPaymentMethods.includes(
                            partner.partnerId
                          )}
                          isIncompleteSignup={partnerIdsWithIncompleteSignUps.includes(
                            partner.partnerId
                          )}
                          isIncompleteTraining={partnerIdsWithIncompleteTraining.includes(
                            partner.partnerId
                          )}
                          isIncompleteExperience={partnerIdsWithIncompleteExperiences.includes(
                            partner.partnerId
                          )}
                          isDeactivatingExperience={partnerIdsWithDeactivatingExperiences.includes(
                            partner.partnerId
                          )}
                        />
                      </TableRow>
                    );
                  })
                ) : (
                  <Box display="flex" justifyContent="center">
                    {!isLoading && (
                      <Typography>
                        There are no partners in this area
                      </Typography>
                    )}
                  </Box>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      15,
                      25,
                      50,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={filteredPartners.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
}

function PartnerListItem(props: any) {
  const {
    theme,
    index,
    partner,
    changePartner,
    classes,
    isPendingBoost,
    isInvalidPayment,
    isIncompleteSignup,
    isIncompleteExperience,
    isIncompleteTraining,
    isDeactivatingExperience,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [locationName, setLocationName] = useState<string>("No location name");

  const clickedPartner = () => {
    setOpen(true);
  };

  useEffect(() => {
    let _locationName = "No location name";
    if (!_.isEmpty(partner.businessName)) {
      _locationName = partner.businessName;
    }
    if (
      !_.isEmpty(partner.locations) &&
      !_.isEmpty(partner.locations[0].name)
    ) {
      _locationName = partner.locations[0].name;
    }
    setLocationName(_locationName);
  }, [partner]);

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        key={index}
        onClick={() => {
          clickedPartner();
        }}
      >
        <Card
          onClick={() => {
            clickedPartner();
          }}
          style={{
            borderRadius: "0px",
            border: "1px solid #E6E6E6",
            cursor: "pointer",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ padding: "0px 15px", borderRadius: "0px" }}
          >
            <TableCell style={{ width: "50px", borderBottom: "none" }}>
              <Box style={{ fontWeight: "bold" }}>
                {partner.profileImage?.url && (
                  <img
                    src={partner.profileImage.url}
                    className={classes.profileImage}
                    alt=""
                  />
                )}
                {!partner.profileImage?.url && (
                  <div className={classes.profileImage} />
                )}
              </Box>
            </TableCell>
            <TableCell
              style={{ width: !sm ? "35%" : "150px", borderBottom: "none" }}
            >
              <Typography
                style={{
                  width: "100%%",
                  margin: "0px 10px",
                  fontWeight: "bold",
                }}
              >
                {partner.fullName ?? "No name available"}
              </Typography>
            </TableCell>
            <TableCell
              style={{ width: !sm ? "35%" : "150px", borderBottom: "none" }}
            >
              <Typography style={{ width: "100%", margin: "0px 10px" }}>
                {locationName}
              </Typography>
            </TableCell>
            <TableCell
              style={{ width: !sm ? "25%" : "150px", borderBottom: "none" }}
            >
              <Typography style={{ width: "100%", margin: "0px 10px" }}>
                {partner.number ?? "No phone"}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                width: !sm ? "35%" : "200px",
                borderBottom: "none",
                overflowWrap: "anywhere",
              }}
            >
              <Typography style={{ width: "100%" }}>
                {partner.email ?? "No email"}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                width: !sm ? "35%" : "400px",
                overflowWrap: "anywhere",
                borderBottom: "none",
              }}
            >
              {isPendingBoost && (
                <Chip
                  label="Pending Boosts"
                  color="#3C2052"
                  variant="outlined"
                />
              )}
              {isInvalidPayment && (
                <Chip
                  label="Invalid Payment"
                  color="#FF0000"
                  variant="outlined"
                />
              )}

              {isIncompleteSignup && (
                <Chip
                  label="Incomplete Sign up"
                  color="#FACA0F"
                  variant="outlined"
                />
              )}
              {isIncompleteExperience && (
                <Chip
                  label="Incomplete Experience"
                  color="#6495ED"
                  variant="outlined"
                />
              )}
              {isDeactivatingExperience && (
                <Chip
                  label="Deactivating Experience"
                  color="#DE3163"
                  variant="outlined"
                />
              )}
            </TableCell>
          </Box>
        </Card>
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <SwitchPartnerDialog
          open={open}
          setOpen={setOpen}
          partner={partner}
          changePartner={(id: string) => changePartner(id)}
          theme={theme}
        />
      </Dialog>
    </>
  );
}

export default withTheme(withStyles(styles)(PartnersList));
