import React from "react";
import { Card, CardContent, Typography } from '@material-ui/core';

export function NotFound(props: any) {
  return (
    <Card>
      <CardContent>
        <Typography>
          404 NOT FOUND!
          </Typography>
      </CardContent>
    </Card>
  );
}

