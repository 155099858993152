export enum ConfigKey {
	STRIPE_KEY = "STRIPE_KEY",
	STRIPE_CLIENT_ID = "STRIPE_CLIENT_ID",
	API_PROTOCOL = "API_PROTOCOL",
	API_ROOT = "API_ROOT",
	API_ROUTE = "API_ROUTE",
	API_ADMIN_ROUTE = "API_ADMIN_ROUTE",
	S3_URL = "S3_URL",
	APP_HOSTED_URL = "APP_HOSTED_URL",
	FLAVOR = "FLAVOR"
}