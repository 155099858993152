import {
	Box,
	Card,
	CardContent,
	createStyles,
	IconButton,
	Theme,
	Typography,
	withStyles,
	withTheme,
	FormControl,
	Button,
} from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";
import { CheckoutForm } from "../CheckoutForm/CheckoutForm";
import CurrencyInput from "../CurrencyInput";
import DateSelectorWithSwitch from "../DateSelectorWithSwitch/DateSelectorWithSwitch";
import { addMonths } from "date-fns";
import { isNil } from "lodash";
import getData from "../../services/getData";
import CloseIcon from "@material-ui/icons/Close";
import { OptionType } from "src/constants/types";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			maxWidth: "600px",
			minHeight: "95%",
		},
		title: {
			fontWeight: 700,
			fontSize: "24px",
			lineHeight: "28.8px",
			color: "#000000",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(2),
		},
		label: {
			fontWeight: 700,
			fontSize: "14px",
			lineHeight: "16.8px",
			color: "#5C5C5C",
			paddingBottom: theme.spacing(1),
		},
		subtitle: {
			fontWeight: 400,
			fontSize: "14px",
			lineHeight: "16.8px",
			color: "#5C5C5C",
		},
		dropdownSelector: {
			border: "1px solid rgba(186, 186, 186, 0.6)",
			boxSizing: "border-box",
			borderRadius: "10px",
		},
		dropdown: {
			border: "1px solid rgba(186, 186, 186, 0.6)",
			boxSizing: "border-box",
			borderRadius: "10px",
			padding: `0px ${theme.spacing(1)}px`,
			width: "100%",
		},
		dropDownOption: {
			fontWeight: "bold",
			fontSize: "16px",
			lineHeight: "19px",
			color: "#000000",
			width: "100%",
		},
		impressionBox: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "130px",
			height: "40px",
			background: "#2ABC12",
			border: "1px solid rgba(186, 186, 186, 0.4)",
			borderRadius: "10px",
			color: "#FFFFFF",
			fontWeight: 700,
			fontSize: "16px",
			lineHeight: "19.2px",
			marginLeft: theme.spacing(1),
		},
		cardTitle: {
			fontWeight: 700,
			fontSize: "18px",
			lineHeight: "21.6px",
			color: "#5C5C5C",
		},
		cardIcon: {
			width: "26px",
			height: "17px",
			borderRadius: "2px",
		},
		cardContainer: {
			boxShadow:
				"0px 9px 31px rgba(74, 81, 73, 0.24), 0px 2.01027px 6.92426px rgba(68, 73, 67, 0.143066), 0px 0.598509px 2.06153px rgba(77, 83, 76, 0.0969343)",
			borderRadius: "5px",
		},
		cardInfo: {
			fontWeight: 700,
			fontSize: "14px",
			lineHeight: "16.8px",
			color: "#5C5C5C",
			paddingLeft: theme.spacing(1),
		},
		hoverButton: {
			backgroundColor: "rgba(255,255,255,1)",
			"&:hover": {
				backgroundColor: "rgba(0,255,148,0.4)",
			},
		},
		deleteError: {
			fontWeight: 400,
			fontSize: "13px",
			lineHeight: "16px",
			color: "#FE2A2A",
			fontStyle: "italic",
			paddingBottom: theme.spacing(1),
		},
	});

function BoostForm(props: any) {
	const plans = [
		{
			budget: "$250",
			price: 25000,
			name: "Jog Pace",
			included: [""],
			index: 0,
		},
		{
			budget: "$1000",
			price: 100000,
			name: "Rocket Pace",
			included: [""],
			index: 1,
		},
		{
			budget: "Custom",
			price: 50000,
			name: "Create Your Own",
			included: [""],
			index: 2,
		},
	];

	const [selected, setSelected] = useState(2);
	const [costPerImpression, setCostPerImpression] = useState(0.0);
	const formatNumberUs = new Intl.NumberFormat("en-US");

	const {
		theme,
		classes,
		// toggleBoostOpen,
		cards,
		updatePaymentMethods,
		stripe,
		experienceId,
		selectedBoost,
		updateBoosts,
		liveBoosts,
		selectedExperienceBoost,
		setSelectedExperienceBoost,
		reloadExperiences,
		setOpen,
		isAdmin,
		formType,
		getExperiencesSeekingApproval,
		boostIsLive,
    } = props;

	const immediately: OptionType[] = [{ value: '0', label: "Immediately" }];
    const indefinitely: OptionType[] = [{ value: '0', label: "Indefinitely" }];
    const monthlyBoost: OptionType = { value: '0', label: "Monthly Budget"};
 	const fixedBoost: OptionType = { value: '1', label: "Fixed Budget" };
 	const freeBoost: OptionType= { value: '2', label: "Free Boost" };
 	const boostTypes: OptionType[] = [ monthlyBoost, fixedBoost ];
 	if (isAdmin) {
 		boostTypes.push(freeBoost);
 	}
	const [boostType, setBoostType] = useState<any>(boostTypes[0]);
	const [boost, setBoost] = useState<any>({
		startDate: new Date(),
		endDate: addMonths(new Date(), 6),
		budget: 30000,
		repeatMonthly: true,
		costPerImpression: 0.1,
		boostOrderId: undefined,
	});
	const [dateMinMax] = useState<any>({
		startDate: new Date(),
		endDate: addMonths(new Date(), 6),
	});
	const [impressions, setImpressions] = useState(
		formatNumberUs.format(boost.budget * 100 * costPerImpression)
	);
	const [dateRange, setDateRange] = useState<any>({
		startDate: { available: true },
		endDate: { available: true },
	});

	const [canSubmit, setCanSubmit] = useState(false);
	const [selectedCard, setSelectedCard] = useState<any>();
	const [cardComplete, setCardComplete] = useState(false);
	const [editCostPerImpression, setEditCostPerImpression] = useState(false);

	useEffect(() => {
		if (!!selectedBoost) {
			if (!!selectedBoost.freeBoost) {
				setBoostType(boostTypes[2]);
			} else if (!isNil(selectedBoost.repeatMonthly) && !selectedBoost.repeatMonthly) {
				setBoostType(boostTypes[1]);
			}
			const isIndefinitely = selectedBoost.endDate === null;
			setDateRange({
				startDate: { available: false },
				endDate: { available: isIndefinitely },
			});
			setBoost({
				startDate:
					new Date() < selectedBoost?.startDate
						? selectedBoost?.startDate
						: new Date(),
				endDate: !isIndefinitely
					? selectedBoost.endDate
					: addMonths(new Date(), 6),
				budget: selectedBoost.budget,
				repeatMonthly: selectedBoost.repeatMonthly,
				costPerImpression: selectedBoost?.costPerImpression,
				boostOrderId: selectedBoost?.boostOrderId,
			});
			let foundPlan = false;
			if (isIndefinitely && selectedBoost?.repeatMonthly) {
				for (const plan of plans) {
					if (selectedBoost?.budget * 100 === plan.price && plan.index !== 2) {
						setSelected(plan.index);
						foundPlan = true;
					}
				}
			}
			if (foundPlan === false) {
				setSelected(plans.length - 1);
			}
			setCanSubmit(selectedBoost?.budget > 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBoost]);

	useEffect(() => {
		getImpressionCost();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setImpressions(
			formatNumberUs.format(boost.budget * 100 * costPerImpression)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [costPerImpression]);

	useEffect(() => {
		if (isNil(selectedBoost)) {
			if (cards.length > 0) {
				setSelectedCard(cards[0]);
			}
		} else {
			const paymentMethod = cards.find((card: any) => {
				return card.key === selectedBoost.paymentMethodId;
			});
			if (!isNil(paymentMethod)) {
				setSelectedCard(paymentMethod);
			}
		}
		if (boost.budget > 0) {
			setCanSubmit(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards, selectedBoost]);

	const updateBoostType = (type: Option) => {
		setBoost({ ...boost, repeatMonthly: type.value === '0' });
		setBoostType(type);
	};

	const updateBudget = (value: number, index: number) => {
		const _impressions = value * 100 * costPerImpression;
		setImpressions(formatNumberUs.format(_impressions));
		setBoost({ ...boost, budget: value });
		setCanSubmit(value > 0);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		dateRange[event.target.name].available = event.target.checked;
		setDateRange({ ...dateRange });
	};

	const setDateChange = (name: string, event: any) => {
		setBoost({ ...boost, [name]: event });
	};

	const saveCard = async (token: string) => {
		try {
			await getData("payment-methods", "POST", props.currentUser, {
				stripeToken: token,
			});
			updatePaymentMethods();
		} catch (error) {
			console.error(error);
		}
	};

	const removeCard = async (cardId: string) => {
		try {
			await getData(
				`payment-methods/${cardId}`,
				"DELETE",
				props.currentUser,
				{}
			);
			updatePaymentMethods();
			updateBoosts();
		} catch (error) {
			console.error(error);
		}
	};

	const submitForApproval = async () => {
		try {
			await getData(`boosts/${experienceId}`, "POST", props.currentUser, {
				...boost,
				experienceId: experienceId,
			});
			updateBoosts();
		} catch (error) {
			console.error(error);
		}
		reloadExperiences();
	};

	const submitBoost = async (paymentMethodId: string) => {
		if (isNil(selectedBoost) || isNil(selectedBoost.boostOrderId)) {
			const boostRequest = {
				experienceId: experienceId,
				startDate: dateRange.startDate.available
					? new Date().toISOString()
					: boost.startDate.toISOString(),
				endDate: dateRange.endDate.available
					? null
					: boost.endDate.toISOString(),
				budget: boost.budget,
				repeatMonthly: boost.repeatMonthly,
				costPerImpression: costPerImpression,
				paymentMethodId: paymentMethodId,
			};
			try {
				await getData(
					`boosts/${experienceId}`,
					"POST",
					props.currentUser,
					boostRequest
				);
				updateBoosts();
			} catch (error) {
				console.error(error);
			}
		} else {
			await updateBoost(paymentMethodId);
		}

		reloadExperiences();
		getExperiencesSeekingApproval();
		setSelectedExperienceBoost(undefined);
		setOpen(false);
	};

	const approveBoost = async (paymentMethodId: string) => {
		try {
			await getData(
				`action-items?action_type=boost&id=${selectedBoost.boostOrderId}`,
				"POST",
				props.currentUser,
				{ approve: true },
				undefined,
				true
			);
			reloadExperiences();
			getExperiencesSeekingApproval();
			updateBoosts();
		} catch (error) {
			console.error(error);
		}
		setOpen(false);
	};

	const manageFreeBoost = async (approve: boolean) => {
		try {
			await getData(
				`action-items?action_type=free_boost&id=${experienceId}`,
				"POST",
				props.currentUser,
				{
					approve: approve,
					startDate: dateRange.startDate.available
						? new Date().toISOString()
						: boost.startDate.toISOString(),
					endDate: dateRange.endDate.available
						? null
						: boost.endDate.toISOString(),
				},
				undefined,
				true
			);
			reloadExperiences();
			getExperiencesSeekingApproval();
			updateBoosts();
		} catch (error) {
			console.error(error);
		}
		setOpen(false);
	};

	const rejectBoost = async (paymentMethodId: string) => {
		try {
			await getData(
				`action-items?action_type=boost&id=${selectedExperienceBoost.boostOrderId}`,
				"POST",
				props.currentUser,
				{ approve: false },
				undefined,
				true
			);
			reloadExperiences();
			getExperiencesSeekingApproval();
			updateBoosts();
		} catch (error) {
			console.error(error);
		}
		setOpen(false);
	};

	const updateBoost = async (paymentMethodId: string) => {
		const boostRequest = {
			boostOrderId: selectedBoost.boostOrderId,
			experienceId: experienceId,
			startDate: dateRange.startDate.available
				? new Date().toISOString()
				: boost.startDate.toISOString(),
			endDate: dateRange.endDate.available ? null : boost.endDate.toISOString(),
			budget: boost.budget,
			repeatMonthly: boost.repeatMonthly,
			costPerImpression: costPerImpression,
			paymentMethodId: paymentMethodId,
		};
		try {
			await getData(
				`boosts/${selectedBoost.boostOrderId}`,
				"PATCH",
				props.currentUser,
				boostRequest
			);
			reloadExperiences();
			updateBoosts();
		} catch (error) {
			console.error(error);
		}
	};

	async function getImpressionCost() {
		try {
			const response = await getData(
				`boosts/impression-cost/${experienceId}`,
				"GET",
				props.currentUser,
				{}
			);
			if (!isNil(response.cost)) {
				setCostPerImpression(response.cost);
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function editBoostImpressionCost() {
		try {
			await getData(
				`boosts/${boost.boostOrderId}`,
				"PATCH",
				props.currentUser,
				{ ...selectedBoost, costPerImpression: boost.costPerImpression },
				undefined,
				true
			);
		} catch (error) {
			console.error(error);
		}
	}

	const updateCostPerImpression = (value: any) => {
		setBoost({ ...selectedBoost, costPerImpression: value });
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height={1}
		>
			<div style={{ position: "absolute", top: "0.5em", right: "1em" }}>
				<IconButton
					color="inherit"
					onClick={() => setOpen(false)}
					style={{ padding: 0 }}
				>
					<CloseIcon style={{ height: "1em", width: "1em" }} />
				</IconButton>
			</div>
			<Card className={classes.root} elevation={0}>
				<CardContent>
					<Box display="flex" flexDirection="column" marginX={"5%"}>
						<Typography className={classes.title}>
							{selectedBoost !== undefined
								? "Your Boost"
								: "Boost Your Experience"}
						</Typography>
						<Typography className={classes.subtitle}>
							Get seen by more users and get more purchases
						</Typography>
					</Box>

					<Box display="flex" flexDirection="column" marginX={"5%"}>
						{selected === 2 && (
							<Box
								display="flex"
								flexDirection="column"
								paddingTop={theme.spacing(0.2)}
								paddingBottom={theme.spacing(0.05)}
							>
								{isAdmin &&
									!isNil(selectedBoost) &&
									formType === "edit" &&
									selectedBoost.approved !== true && (
										<>
											<Typography className={classes.label}>
												Cost Per Impression
											</Typography>
											<FormControl className={classes.inputField}>
												<Box
													display="flex"
													flexDirection="row"
													justifyContent="center"
													pb={theme.spacing(0.1)}
												>
													<CurrencyInput
														aria-describedby="Cost per impression"
														onValueChange={updateCostPerImpression}
														value={Math.trunc(boost.costPerImpression)}
														index={0}
														disabled={!editCostPerImpression}
														placeholder={"Price"}
														id="price"
														max={99999999}
														classes={classes}
													/>
													{!editCostPerImpression && (
														<Button
															onClick={() => setEditCostPerImpression(true)}
														>
															<Typography>Edit</Typography>
														</Button>
													)}
													{editCostPerImpression && (
														<Button
															onClick={() => {
																setEditCostPerImpression(false);
																editBoostImpressionCost();
															}}
														>
															<Typography style={{ color: "#00FF94" }}>
																Save
															</Typography>
														</Button>
													)}
												</Box>
											</FormControl>
										</>
									)}
								<Typography className={classes.label}>Boost type</Typography>
								<Box display="flex" flexDirection="row">
									<Box
										display="flex"
										flexDirection="row"
										paddingRight={theme.spacing(0.1)}
										paddingBottom={boostType.value === 2 ? theme.spacing(0.1) : 0}
										flex={1}
									>
										<Dropdown
											controlClassName={classes.dropdownSelector}
											placeholderClassName={classes.dropDownOption}
											menuClassName={classes.dropdown}
											className={classes.dropDownOption}
											options={boostTypes}
											value={boostType}
											disabled={boostIsLive || formType !== "edit"}
											onChange={updateBoostType}
										/>
									</Box>
									{boostType.value !== 2 &&
										(<Box
											display="flex"
											flexDirection="row"
											paddingLeft={theme.spacing(0.1)}
											flex={1}
										>
											<CurrencyInput
												aria-describedby="Budget per time frame"
												onValueChange={updateBudget}
												value={boost.budget}
												index={0}
												disabled={boostIsLive || formType !== "edit"}
												placeholder={"Budget"}
												id="budget"
												max={99999999}
												classes={classes}
											/>
										</Box>)}
								</Box>
							</Box>
						)}

						{boostType.value !== 2 && (<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="flex-end"
							paddingY={theme.spacing(0.2)}
						>
							<Typography className={classes.subtitle}>
								Estimated increased impressions:
							</Typography>
							<div className={classes.impressionBox}>{impressions}</div>
						</Box>)}

						<Box display="flex" flexDirection="row">
							<DateSelectorWithSwitch
								date={dateRange.startDate}
								dateMinMax={dateMinMax}
								handleChange={handleChange}
								dropdownOptions={immediately}
								selectedDate={boost.startDate}
								handleDateChange={setDateChange}
								name="startDate"
								label="Start Date"
								disabled={boostIsLive || formType !== "edit"}
							></DateSelectorWithSwitch>
							<DateSelectorWithSwitch
								date={dateRange.endDate}
								dateMinMax={dateMinMax}
								handleChange={handleChange}
								dropdownOptions={indefinitely}
								selectedDate={boost.endDate}
								handleDateChange={setDateChange}
								name="endDate"
								label="End Date"
								disabled={boostIsLive || formType !== "edit"}
							></DateSelectorWithSwitch>
						</Box>

						<Elements stripe={stripe} key={stripe}>
							<CheckoutForm
								theme={theme}
								classes={classes}
								canSubmit={canSubmit}
								saveCard={saveCard}
								removeCard={removeCard}
								cards={cards}
								selectedCard={selectedCard}
								setSelectedCard={setSelectedCard}
								cardComplete={cardComplete}
								submitBoost={submitBoost}
								setCardComplete={setCardComplete}
								liveBoost={selectedBoost !== undefined}
								liveBoosts={liveBoosts}
								formType={formType}
								setOpen={setOpen}
								approveBoost={approveBoost}
								rejectBoost={rejectBoost}
								manageFreeBoost={manageFreeBoost}
								boostIsLive={boostIsLive}
								isAdmin={isAdmin}
								boostType={boostType}
							></CheckoutForm>
						</Elements>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}

export default withTheme(withStyles(styles)(BoostForm));
