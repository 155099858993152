import {
  Box,
  createStyles,
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { useEffect } from "react";
import { useState } from "react";
import getData from "src/services/getData";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { VerticalOption } from "../VerticalOption/VerticalOption";
import { EditIcon } from "../Icons";
import Dropdown from "react-dropdown";
import { isNil } from "lodash";
import RefundDialog from "./RefundDialog";
import ExtendExpirationDialog from "./ExtendExpirationDialog";

enum ActionType {
  refund,
  extendExpiration,
}

const styles = (theme: Theme) =>
  createStyles({
    label: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#5C5C5C",
      paddingBottom: theme.spacing(1),
    },
    inputField: {
      width: "100%",
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      paddingLeft: "10px",
    },
    table: {
      marginTop: "10px",
    },
    tableHead: {
      backgroundColor: "#F2F2F2",
      borderRadius: "10px",
    },
    tableHeadCell: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#5C5C5C",
    },
    tableBodyCell: {
      fontSize: "21px",
      fontWeight: 400,
      color: "#0B0A0A",
    },
    verticalIcon: {
      color: "#0B0A0A",
    },
    dropdownStyle: {
      paddingTop: "5px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
      },
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    heading: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      color: 'rgba(92, 92, 92, 1)',
      paddingBottom: '5px',
    },
    title: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '16px',
      color: 'rgba(92, 92, 92, 1)',
    }
  });

function TransactionsList(props: any) {
  const { classes } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [allTransactions, setAllTransactions] = useState<any>([]);
  const [transactions, setTransactions] = useState<any>([]);
  const [availableAreas, setAvailableAreas] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState<any>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>();
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState<ActionType>();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPartners();
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (!isNil(selectedTransaction)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedTransaction]);

  useEffect(() => {
    fetchAvailableAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openTransaction = async (popupState: any, transaction: any, action: ActionType) => {
    popupState.close();
    setOpen(true);
    setSelectedTransaction(transaction);
    setActionType(action);
  };

  async function getPartners() {
    if (searchText.length > 0) {
      try {
        const response = await getData(
          "purchases",
          "GET",
          props.currentUser,
          {},
          { search_term: searchText },
          true
        );
        setAllTransactions(response);
        filterTransactions(response, selectedArea);
      } catch (error) {
        console.error("Error in getPartners function:", error);
      }
    }
  }

  function filterTransactions(allTrans: any, area: any) {
    if (isNil(area.value)) {
      setTransactions(allTrans);
    } else {
      const filteredTransactions = allTrans.filter(
        (transaction: any) => transaction.experience.areaId === area.value
      );
      setTransactions(filteredTransactions);
    }
  }

  async function onSearchTextChange(event: any) {
    const searchText: string = event.target.value;
    setSearchText(searchText);
  }

  async function fetchAvailableAreas() {
    try {
      const response = await getData(
        "areas",
        "GET",
        props.currentUser,
        {},
        {},
        true
      );
      const areas = response.map((area: any) => {
        return { value: area.areaId, label: area.key, county: area.county };
      });
      setAvailableAreas([
        { value: null, label: "All", county: null },
        ...areas,
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  function updateSelectedArea(selectedArea: any) {
    const fullAreaObject = availableAreas.find((area: any) => {
      return area.value === selectedArea.value;
    });
    setSelectedArea(fullAreaObject);
    filterTransactions(allTransactions, selectedArea);
  }

  function createDataCell(transaction: any) {
    const purchase = transaction.purchases[0];
    const experience = purchase.experience;
    const date: Date = new Date(purchase.createdAt);
    const refundable = !purchase.used && !purchase.refund;
    return (
      <>
        <TableCell className={classes.tableBodyCell}>
          {experience.location.name}
        </TableCell>
        <TableCell align="right" className={classes.tableBodyCell}>
          {purchase.easyIdentifier}
        </TableCell>
        <TableCell align="right" className={classes.tableBodyCell}>
          {"$" + (purchase.paymentAmount / 100).toFixed(2)}
        </TableCell>
        <TableCell align="right" className={classes.tableBodyCell}>
          {experience.title}
        </TableCell>
        <TableCell align="right" className={classes.tableBodyCell}>
          {format(date, "MM/dd/yyyy")}
        </TableCell>
        <TableCell align="right" className={classes.tableBodyCell}>
          {format(date, "h:mm a")}
        </TableCell>
        <TableCell align="right" className={classes.tableBodyCell}>
          {purchase.used ? 'Used' : refundable ? "Refund" : "Refunded"}
        </TableCell>
        <TableCell>
          {refundable && (
            <PopupState variant="popover" popupId="exp-more-popover">
              {(popupState) => (
                <div>
                  <IconButton {...bindTrigger(popupState)}>
                    <MoreVertIcon className={classes.verticalIcon} />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {refundable && (
                      <Box width={"200px"} paddingY={"10px"} margin={0}>
                        <VerticalOption
                          classes={classes}
                          icon={<EditIcon fill={"#5C5C5C"} />}
                          onClick={() => {
                            openTransaction(popupState, transaction, ActionType.refund);
                          }}
                          label="Refund"
                        />
                        <VerticalOption
                          classes={classes}
                          icon={<EditIcon fill={"#5C5C5C"} />}
                          onClick={() => {
                            openTransaction(popupState, transaction, ActionType.extendExpiration);
                          }}
                          label="Extend Expiration"
                        />
                      </Box>
                    )}
                  </Popover>
                </div>
              )}
            </PopupState>
          )}
        </TableCell>
      </>
    );
  }

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Box display="flex" flexDirection="column">
        <InputLabel className={classes.label}></InputLabel>
        <Box pl={mobile ? 2 : 0} pr={mobile ? 2 : 0} pt={mobile ? 1 : 0}>
          <FormControl className={classes.inputField}>
            <Input
              id="searchTransactions"
              aria-describedby="Description of the experience"
              autoComplete="off"
              placeholder={"Search by easy identifier or full purchaseId"}
              onChange={(event: any) => onSearchTextChange(event)}
            ></Input>
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row" style={{ padding: "10px 0px" }}>
          <FormControl className={classes.dropdownStyle}>
            <Dropdown
              controlClassName={classes.dropdownSelector}
              placeholderClassName={classes.dropDownOption}
              menuClassName={classes.dropdown}
              className={classes.dropDownOption}
              options={availableAreas}
              value={selectedArea.label}
              onChange={updateSelectedArea}
            />
          </FormControl>
        </Box>
        {/* the fetch http request can possibly return an error object instead of a list */}
        {Array.isArray(transactions) || searchText.length === 0 ? (
          <TableContainer>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>
                    Customer
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadCell}>
                    Identifier
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadCell}>
                    Amount
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadCell}>
                    Description
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadCell}>
                    Date
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadCell}>
                    Time
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadCell}>
                    Status
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableHeadCell}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchText.length > 0 &&
                  transactions.map((transaction: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        {createDataCell(transaction)}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          searchText.length > 0 && (
            <Typography className={classes.headerText}>
              Server didn't respond
            </Typography>
          )
        )}
      </Box>
      {!isNil(selectedTransaction) && actionType === ActionType.refund && (
        <Dialog
          open={open}
          onClose={() => {
            setSelectedTransaction(undefined);
          }}
        >
          <RefundDialog
            currentUser={props.currentUser}
            purchase={selectedTransaction.purchases[0]}
            setOpen={setOpen}
            getPartners={getPartners}
          />
        </Dialog>
      )}
      {!isNil(selectedTransaction) && actionType === ActionType.extendExpiration && (
        <Dialog
          open={open}
          onClose={() => {
            setSelectedTransaction(undefined);
          }}
        >
          <ExtendExpirationDialog
            currentUser={props.currentUser}
            purchase={selectedTransaction.purchases[0]}
            setOpen={setOpen}
            getPartners={getPartners}
            classes={classes}
            theme={theme}
          />
        </Dialog>
      )}
    </div>
  );
}

export default withStyles(styles)(TransactionsList);
