import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Location from "../../assets/locationHeader.png";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { ExperiencesButton } from "../../services/util";
import AddIcon from "@material-ui/icons/Add";
import MobileSetupHeader from "../MobileSetupHeader";
import clsx from 'clsx';

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
      // position: 'absolute',
      verticalAlign: 'center'
    },
    itemCategory: {
      backgroundColor: 'transparent',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    mainHeader: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'center',
      padding: '0 1rem',
    },
    header: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
    toolbar: {
      minHeight: "unset",
    },
    locationImg: {
      width: "100%",
      height: "100px",
      objectFit: "cover",
      zoom: "155%",
    },
    backgroundLocationImg: {
      width: "101%",
      height: "100px",
      backgroundImage: "url(" + Location + ")",
      backgroundSize: "cover",
      transform: "translateX(-1%)",
    },
    locationName: {
      transform: "translateX(1%)",
      fontWeight: 900,
      fontSize: "40px",
      lineHeight: "50px",
      letterSpacing: "-0.377px",
      color: "#FFFFFF",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "75%",
    },
    buttonText: {
      paddingLeft: "4px",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "16.8px",
      letterSpacing: "5%",
      textTransform: "uppercase",
      color: "#FFFFFF",
    },
    fullWidth: {
      width: "100%",
    },
    headerItem: {
      flexBasis: "33%",
      maxWidth: "33%",
    },
    alignLeft: {
      textAlign: "left"
    },
    alignCenter: {
      textAlign: "center"
    },
    headerContainer: {
      width: '100vw',
      display: 'flex'
    },
    menuButtonStyle: {
      height: '65px'
    }
  });

const mutualTextStyle = {
  fontWeight: 900,
  fontSize: '26px',
  lineHeight: '31px',
  letterSpacing: '-0.377px',
}

const experiencesTextStyle = {
  fontWeight: 300,
  fontSize: '26px',
  lineHeight: '31px',
  letterSpacing: '-0.377px',
  color: '#00FF94',
}

function Header(props: any) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    classes,
    onDrawerToggle,
    selectedLocation,
    setupNewLocation,
    isSignUp,
    partner,
    locations,
    changeLocation,
    categories,
    setCategories,
    setActivePage,
    selectFirstLocation,
  } = props;
  const pathName = props.location.pathname.substring(
    props.location.pathname.lastIndexOf("/") + 1
  );

  return (
    <>
      {sm && !isSignUp && (
        <Box pb={sm ? 6 : 0}>
          <AppBar position={sm ? "absolute" : "sticky"} elevation={0}>
            <Toolbar>
              <Grid container id='grid' spacing={1} alignItems="center">
                <Hidden mdUp>
                  <div className={classes.headerContainer}>
                    <div className={`${classes.headerItem} ${classes.alignLeft}`}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onDrawerToggle}
                        className={classes.menuButtonStyle}
                      >
                        <MenuIcon />
                      </IconButton>
                    </div>
                    <div className={`${clsx(classes.mainHeader, classes.header, classes.itemCategory)} ${classes.headerItem} ${classes.alignCenter}`}>
                      <h1 style={mutualTextStyle}>Tonite</h1>
                    </div>
                    <div className={classes.headerItem}></div>
                  </div>
                </Hidden>
                <Grid item xs />
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      )}
      {sm && isSignUp && (
        <MobileSetupHeader currentActivePage={partner.signUpState.activePage}
          locations={locations}
          selectedLocation={selectedLocation}
          changeLocation={changeLocation}
          categories={categories}
          setCategories={setCategories}
          isSignUp={isSignUp}
          partner={partner}
          setActivePage={setActivePage}
          theme={theme}
          selectFirstLocation={selectFirstLocation} />
      )}

      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar disableGutters className={classes.toolbar}>
          <Grid container alignItems="center">
            <Grid item sm className={classes.fullWidth}>
              {!sm && pathName === "location info" && (
                <Box maxHeight="100px" overflow="hidden" width={"100%"}>
                  {selectedLocation !== undefined && (
                    <Box
                      className={classes.backgroundLocationImg}
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      paddingBottom={"28px"}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingX={"2.5%"}
                      >
                        <Typography className={classes.locationName}>
                          {selectedLocation.name}
                        </Typography>
                        {!isSignUp && (
                          <ExperiencesButton
                            variant="contained"
                            color="primary"
                            onClick={setupNewLocation}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              <AddIcon htmlColor="#FFFFFF" />
                              <Typography className={classes.buttonText}>
                                New Location
                              </Typography>
                            </Box>
                          </ExperiencesButton>
                        )}
                      </Box>
                    </Box>
                  )}
                  {selectedLocation === undefined && (
                    <Box>
                      <img
                        src={Location}
                        alt="Location background header"
                        className={classes.locationImg}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default withStyles(styles)(withRouter(Header));
