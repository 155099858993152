import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import getData from "src/services/getData";
import { useState } from "react";
import { boxShadow } from "../../services/util";
import { CustomDateSelector } from "../DateSelector";
import { format, isBefore } from "date-fns";
import { isSameDay } from "date-fns/esm";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "500px",
      maxWidth: "500px",
      minHeight: "95%",
      padding: "20px",
    },
    saveButton: {
      width: "100%",
      height: "50px",
      backgroundColor: "#00FF94",
      borderRadius: "5px",
      padding: "6px 8px",
      boxShadow: boxShadow,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
    cancelButton: {
      width: "100%",
      height: "50px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "6px 8px",
      boxShadow: boxShadow,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
    title: {
      fontSize: "24px",
      color: "background: #000000",
      fontWeight: 700,
      marginBottom: "20px",
    },
    subtitle: {
      fontSize: "14px",
      color: "#5C5C5C",
      fontWeight: 700,
    },
    value: {
      fontSize: "16px",
      color: "#000000",
      fontWeight: 700,
      margin: "6px 0px 18px 0px",
      width: "100%",
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    dropDownBox: {
      width: "50%",
    },
  });

function ExtendExpirationDialog(props: any) {
  const { theme, classes, setOpen, purchase, getPartners } = props;

  const [extendedDate, setExtendedDate] = useState<any>(new Date(purchase.expiresAt));
  const extendedDateFormat = format(new Date(extendedDate), "MM/dd/yyyy");
  const expiredDateFormat = format(new Date(purchase.expiresAt), "MM/dd/yyyy");
  const today = new Date();
  const isPastDate = isSameDay(new Date(purchase.expiresAt), today)
    || isBefore(new Date(purchase.expiresAt), today);

  async function extendExpiration() {
    try {
      await getData(
        `purchases/${purchase.purchaseId}/extend-expiration`,
        "PUT",
        props.currentUser,
        {},
        {
          extendedDate: extendedDate,
          userId: purchase.userId,
        },
        true
      );
      getPartners();
    } catch (error) {
      console.error(error);
    }
    setOpen(false);
  }
  
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Card className={classes.root} elevation={0}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Box
            style={{ height: "300px", width: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography className={classes.title} style={{ margin: '0 0 0 0' }}>
              Extend purchase expiration
            </Typography>
            <Typography className={classes.label} style={{
              fontSize: 18,
              paddingTop: 7,
            }}>
              Easy identifier: {purchase.easyIdentifier}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems={"start"}
              justifyContent={'space-around'}
            >
              <Box>
                <Typography className={classes.heading}>
                  {isPastDate ? 'EXPIRED ON' : 'EXPIRES AT'}
                </Typography>
                <Typography className={classes.label} style={{
                  fontSize: 18,
                  paddingTop: 7,
                }}>
                  {expiredDateFormat}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.heading}>
                  EXTEND TO
                </Typography>
                <CustomDateSelector
                  value={extendedDate}
                  onChange={(date) => setExtendedDate(date)}
                  TextFieldComponent={(props) => (
                    <TextField {...{ ...props, variant: "outlined" }} />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignSelf="center"
              justifyContent="end"
              width="100%"
            >
              <Button
                className={classes.saveButton}
                disabled={extendedDateFormat === expiredDateFormat}
                onClick={extendExpiration}
              >
                EXTEND EXPIRATION
              </Button>
              <Box padding={theme.spacing(0.1)}></Box>
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                CANCEL
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(ExtendExpirationDialog));
