import React from "react";
import Config from "src/config";
import { ConfigKey } from "src/config/config.enums";
import { useMediaQuery, useTheme } from "@material-ui/core";

function TopBannerLinks(props: any) {
  const { color = "#FFFFFF" } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const openInCurrentTab = (url: string | undefined) => {
    const newWindow = window.open(url, "_self", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const linkInfo = [
    { name: "Contact", url: "https://www.mutual.app/contact" },
    { name: "FAQ", url: "https://www.mutual.app/faq" },
    { name: "Terms", url: "https://www.mutual.app/terms" },
    { name: "Privacy", url: "https://www.mutual.app/privacy" },
    { name: "Blog", url: "https://blog.mutual.app/" },
    { name: "Partner", url: `https://${Config.getValue(ConfigKey.APP_HOSTED_URL)}` },
  ];

  const tapBannerBaseStyle = {
    width: "100%",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
  };

  const linkBaseStyle = {
    margin: "1vw",
  };

  const navLinkStyle = {
    color: color,
    fontSize: xs ? 14 : 18,
    cursor: 'pointer'
  };

  const partnerLinkStyle = {
    color: "#00FF94",
    fontSize: xs ? 14 : 18,
    cursor: 'pointer'
  };

  return (
    <div style={tapBannerBaseStyle}>
      {linkInfo.map((link) => {
        return (
          <div style={linkBaseStyle} key={link.name}>
            <a
              onClick={() => {
                openInCurrentTab(link.url);
              }}
              style={
                link.name === "Partner" ? partnerLinkStyle : navLinkStyle
              }
            >
              {link.name}
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default TopBannerLinks;
