import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import Config from "../../config";
import { ConfigKey } from "../../config/config.enums";
import * as ROUTES from "../../constants/routes";
import AccountPage from "../../pages/AccountPage";
import BusinessInfoPage from "../../pages/BusinessInfoPage";
import ExperiencesPage from "../../pages/ExperiencesPage";
import PayoutPage from "../../pages/PayoutPage";
import EmployeePage from "../../pages/EmployeePage";
import { PAYOUTMETHOD } from "../../constants/routes";
import { loadStripe } from "@stripe/stripe-js";
import TransactionsPage from "../../pages/TransactionsPage";
import exampleTransactions from "../../assets/json/exampleTransactions.js";
import exampleMetrics from "../../assets/json/exampleMetrics.js";
import getData from "../../services/getData";
import { getPartnerId } from "src/services/auth";
import AdminToolsPage from "src/pages/AdminToolsPage";
import { Option } from "react-dropdown";
// import { v4 as uuidv4 } from 'uuid';
// import { subDays } from 'date-fns'

function Content(props: any) {
  const {
    currentUser,
    setToken,
    partnerId,
    adminId,
    setPartnerId,
    selectedLocation,
    setLocation,
    experiences,
    updateSelectedLocation,
    reloadExperiences,
    payoutMethods,
    updatePayoutMethods,
    partner,
    setPartner,
    isSignUp,
    transactions,
    setTransactions,
    metrics,
    setMetrics,
    cards,
    getPaymentMethods,
    updateLocation,
    ownerInfoError,
    savePartnerInfo,
    editInfo,
    locationInfoError,
    setLocationInfoError,
    locationSelected,
    setLocationSelected,
    createOrUpdateLocation,
    isAdmin,
  } = props; // user

  const [stripe, setStripe] = useState<any>();
  const [experienceCategories, setExperienceCategories] = useState<Option[]>(
    []
  );

  const getExperienceCategories = async () => {
    if (
      props.currentUser !== undefined &&
      (await getPartnerId(props.currentUser)) !== ""
    ) {
      try {
        const response = await getData(
          `experiences/categories`,
          "GET",
          props.currentUser,
          {},
          undefined,
          true
        );
        const categories = response.map(
          (category: { id: string; name: string }) => {
            return { value: category.id, label: category.name };
          }
        );
        setExperienceCategories(categories);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    loadStripe(Config.getValue(ConfigKey.STRIPE_KEY)).then((_stripe) => {
      if (_stripe !== null) {
        setStripe(_stripe);
      }
    });
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (isAdmin) {
      getExperienceCategories();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (isSignUp && partner.signUpState.doneSignUp === false) {
      setTransactions(exampleTransactions.transactions);
      setMetrics(exampleMetrics);
    } else if (
      exampleMetrics.sales === metrics.sales &&
      exampleMetrics.revenue === metrics.revenue
    ) {
      setMetrics({
        sales: 0,
        revenue: 0,
        salesTax: 0,
        views: 0,
        clicks: 0,
        marketingSpend: 0,
      });
      setTransactions([]);
    }
  }, [
    isSignUp,
    metrics.revenue,
    metrics.sales,
    partner.signUpState.doneSignUp,
    setMetrics,
    setTransactions,
  ]);

  // useEffect(() => {
  //   if (isSignUp) {
  //     const randomIntInRange = (min: number, max: number) => {
  //       return Math.floor(Math.random() * (max - min + 1) + min);
  //     }
  //     let numSales = 0;
  //     let revenue = 0;
  //     let salesTax = 0;
  //     let views = 0;
  //     let clicks = 0;
  //     let marketingSpend = 0;
  //     const _transactions = [];
  //     for (let j = 0; j < 60; j++) {
  //       let numTransactions = randomIntInRange(0, 8);
  //       for (let i = 0; i < numTransactions; i++) {
  //         let dateCursor = new Date();
  //         dateCursor = subDays(dateCursor, j);
  //         let hour = randomIntInRange(14, 23);
  //         let minute = randomIntInRange(0, 60);
  //         dateCursor.setHours(hour);
  //         dateCursor.setMinutes(minute);
  //         const transaction = { purchaseId: uuidv4(), used: { createdAt: dateCursor }, experience: { title: 'Bowling for two' }, salesTax: 10 * 100 * .08, paymentAmount: 10 * 100 };
  //         numSales++;
  //         revenue += 10 * 100;
  //         salesTax += 10 * 100 * .08;
  //         views += 571;
  //         clicks += 142;
  //         marketingSpend += numTransactions * 23;
  //         _transactions.push(transaction);
  //       }
  //     }
  //     _transactions.sort((a: any, b: any) => b.used.createdAt - a.used.createdAt);
  //     console.log(JSON.stringify(_transactions));
  //     console.log(JSON.stringify({ sales: numSales, revenue: revenue, salesTax: salesTax, views: views, clicks: clicks, marketingSpend: marketingSpend }))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSignUp]);

  const history = useHistory();
  const [reloadInProgress] = useState(false); // setReloadInProgress

  useEffect(() => {
    if (reloadInProgress === true) {
      const state = {
        currentUser: currentUser,
        partnerId: partnerId,
        selectedLocation: selectedLocation,
        partner: partner,
        payoutMethods: payoutMethods,
        updateSelectedLocation: null,
        updatePayoutMethods: null,
      };
      history.push(`/home${PAYOUTMETHOD}`, state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadInProgress]);

  const [pendingBoosts, setPendingBoosts] = useState<any>([]);
  const [liveBoosts, setLiveBoosts] = useState<any>([]);

  useEffect(() => {
    if (currentUser !== undefined && currentUser.length > 1) {
      getBoosts("pending", setPendingBoosts);
      getBoosts("approved", setLiveBoosts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  async function getBoosts(filter: string, setBoosts: Function) {
    if (
      props.currentUser !== undefined &&
      (await getPartnerId(props.currentUser)) !== ""
    ) {
      try {
        const response = await getData(
          `boosts?filter=${filter}`,
          "GET",
          props.currentUser,
          {}
        );
        setBoosts(response);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const updateBoosts = () => {
    getBoosts("pending", setPendingBoosts);
    getBoosts("approved", setLiveBoosts);
  };

  return (
    <React.Fragment>
      <Switch>
        <Route>
          <Route
            exact
            path={`/home${ROUTES.BUSINESSINFO}`}
            render={() => (
              <BusinessInfoPage
                history={props.history}
                currentUser={currentUser}
                partnerId={partnerId}
                selectedLocation={selectedLocation}
                updateLocationManager={updateLocation}
                signUpState={partner.signUpState}
                locationInfoError={locationInfoError}
                setLocationInfoError={setLocationInfoError}
                setLocation={setLocation}
                locationSelected={locationSelected}
                setLocationSelected={setLocationSelected}
                updateLocation={createOrUpdateLocation}
              />
            )}
          />
          <Route
            exact
            path={`/home${ROUTES.ADMINTOOLS}`}
            render={() => (
              <AdminToolsPage
                currentUser={currentUser}
                setToken={setToken}
                partnerId={partnerId}
                setPartnerId={setPartnerId}
                selectedLocation={selectedLocation}
                experiences={experiences}
                reloadExperiences={reloadExperiences}
                stripe={stripe}
                pendingBoosts={pendingBoosts}
                liveBoosts={liveBoosts}
                updateBoosts={updateBoosts}
                isSignUp={isSignUp}
                cards={cards}
                getPaymentMethods={getPaymentMethods}
                getExperiences={reloadExperiences}
                revenuePercent={partner.revenuePercent}
                isAdmin={isAdmin}
                experienceCategories={experienceCategories}
              />
            )}
          />

          {selectedLocation !== undefined && (
            <Route
              exact
              path={`/home${ROUTES.EXPERIENCES}`}
              render={() => (
                <ExperiencesPage
                  currentUser={currentUser}
                  partnerId={partnerId}
                  adminId={adminId}
                  selectedLocation={selectedLocation}
                  experiences={experiences}
                  reloadExperiences={reloadExperiences}
                  stripe={stripe}
                  pendingBoosts={pendingBoosts}
                  liveBoosts={liveBoosts}
                  updateBoosts={updateBoosts}
                  isSignUp={isSignUp}
                  cards={cards}
                  getPaymentMethods={getPaymentMethods}
                  revenuePercent={partner.revenuePercent}
                  updateSelectedLocation={updateSelectedLocation}
                  isAdmin={isAdmin}
                  experienceCategories={experienceCategories}
                />
              )}
            />
          )}

          {selectedLocation !== undefined && (
            <Route
              exact
              path={`/home${ROUTES.PAYOUTMETHOD}`}
              render={() => (
                <PayoutPage
                  currentUser={currentUser}
                  partnerId={partnerId}
                  selectedLocation={selectedLocation}
                  partner={partner}
                  payoutMethods={payoutMethods}
                  updateSelectedLocation={updateSelectedLocation}
                  updatePayoutMethods={updatePayoutMethods}
                />
              )}
            />
          )}

          {selectedLocation !== undefined && (
            <Route
              exact
              path={`/home${ROUTES.TRANSACTIONS}`}
              render={() => (
                <TransactionsPage
                  currentUser={currentUser}
                  partnerId={partnerId}
                  selectedLocation={selectedLocation}
                  transactions={transactions}
                  metrics={metrics}
                  isSignUp={isSignUp}
                />
              )}
            />
          )}

          {selectedLocation !== undefined && (
            <Route
              exact
              path={`/home${ROUTES.EMPLOYEETRAINING}`}
              render={() => (
                <EmployeePage
                  currentUser={currentUser}
                  partnerId={partnerId}
                  selectedLocation={selectedLocation}
                />
              )}
            />
          )}

          <Route
            exact
            path={`/home${ROUTES.ACCOUNT}`}
            render={() => (
              <AccountPage
                key={"accountPage"}
                currentUser={currentUser}
                partnerId={partnerId}
                partner={partner}
                setPartner={setPartner}
                ownerInfoError={ownerInfoError}
                savePartnerInfo={savePartnerInfo}
                editInfo={editInfo}
              />
            )}
          />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Content;
