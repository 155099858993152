import React, { useState } from "react";
import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Box,
  useMediaQuery,
  Typography,
  Divider,
} from "@material-ui/core";
import {
  createObjectBasedOnFirst,
  formatAsPhoneNumber,
  partnerBase,
} from "../services/util";
import EditableAvatar from "../components/EditableAvatar/EditableAvatar";
import InfoHeader from "../components/InfoHeader/InfoHeader";
import GenericFormInput from "../components/GenericFormInput/GenericFormInput";
import getData from "../services/getData";
import Config from "../config";
import { ConfigKey } from "../config/config.enums";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "95%",
      maxWidth: "450px",
      boxShadow: "unset"
    },
    removeTopBorderRadius: {
      borderRadius: "0px 0px 8px 8px"
    },
    removeBottomBorderRadius: {
      borderRadius: "8px 8px 0px 0px"
    },
    spacing: {
      paddingTop: "1rem",
    },
    spacingLarge: {
      paddingRight: "1rem",
    },
    headerText: {
      fontWeight: 900,
      fontSize: "30px",
      lineHeight: "36px",
    },
    headerDivider: {
      marginTop: theme.spacing(2),
      backgroundColor: "#D6D6D6",
    },
    contactHeader: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      color: "#5C5C5C",
      paddingBottom: theme.spacing(1),
    },
    contact: {
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "18px",
      color: "#5C5C5C",
      paddingRight: theme.spacing(0.5),
    },
    contactLink: {
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "18px",
      color: "#29ABE3",
      textDecoration: "none",
    },
  });

function AccountPage(props: any) {
  const {
    classes,
    partner,
    setPartner,
    partnerId,
    currentUser,
    ownerInfoError,
    savePartnerInfo,
    editInfo,
  } = props;

  const [uploadingImage, setUploadingImage] = useState(false);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));

  const handleInputChange = (name: string, value: string) => {
    if (name === "number") {
      value = formatAsPhoneNumber(value);
    }
    setPartner({ ...partner, [name]: value });
  };

  const uploadImage = async (files: any) => {
    if (files.length > 0) {
      setUploadingImage(true);
      let form = new FormData();
      form.append("photo", files[0]);
      const apiProtocol = Config.getValue(ConfigKey.API_PROTOCOL);
      const apiRoot = Config.getValue(ConfigKey.API_ROOT);
      const apiRoute = Config.getValue(ConfigKey.API_ROUTE);
      const res = await fetch(
        `${apiProtocol}://${apiRoot}${apiRoute}/partners/${partnerId}/profile-image`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            authtoken: currentUser,
          }),
          body: form,
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        setPartner({ ...partner, profileImage: response.profileImage });
      }
      setUploadingImage(false);
    }
  };

  const formFields = [
    {
      label: "Your Full Name",
      name: "fullName",
      type: "text",
      errorMsg: "Enter your full name",
      multiline: false,
    },
    {
      label: "Email:",
      name: "email",
      type: "text",
      errorMsg: "Please enter a correct email",
      multiline: false,
    },
    {
      label: "Phone Number:",
      name: "number",
      type: "text",
      errorMsg: "Please enter a complete phone number",
      multiline: false,
    },
    {
      label: "Emergency Payout Address:",
      name: "address",
      type: "text",
      errorMsg: "Please enter a complete address",
      multiline: true,
    },
  ];

  const ownerInfoFields = formFields.map((formField, i) => {
    return (
      <div key={`${formField.name}_wrapper`}>
        <GenericFormInput
          infoError={ownerInfoError}
          formField={formField}
          editMode={editInfo}
          object={partner}
          handleInputChange={handleInputChange}
          detectFormErrors={() => { }}
          key={i}
          onBlur={() => { }}
        ></GenericFormInput>
      </div>
    );
  });
  return (
    <Box display="flex" flexDirection="column" alignItems={!xs && !sm ? "start" : "center"} width={1}>
      { !xs && !sm && (<Box display="flex" flexDirection="column" width={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column">
            <Typography className={classes.headerText}>
              Add Your Contact Information
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.headerDivider}></Divider>
      </Box>)}
      <Card className={!xs && !sm ? classes.root : `${classes.root} ${classes.removeBottomBorderRadius}`}>
        <CardContent>
          <InfoHeader
            saveInfo={savePartnerInfo}
            editInfo={editInfo}
            infoError={ownerInfoError}
            header={"Your Contact Information"}
            showActions={!(partner.signUpState.activePage === 1 &&
              partner.signUpState.page === 1)}
            style={{ fontSize: '20px' }}
            isSubheader={true}
          ></InfoHeader>
          {!xs && !sm && (<EditableAvatar
            uploadImage={uploadImage}
            url={partner?.profileImage?.url}
            uploadingImage={uploadingImage}
          ></EditableAvatar>)}
          <Box display="flex" flexDirection="column" paddingTop={"1rem"}>
            <form>{ownerInfoFields}</form>
          </Box>
        </CardContent>
        {(xs || sm) && (<EditableAvatar
          uploadImage={uploadImage}
          url={partner?.profileImage?.url}
          uploadingImage={uploadingImage}
        ></EditableAvatar>)}
      </Card>
      <Card className={!xs && !sm ? classes.root : `${classes.root} ${classes.removeTopBorderRadius}`}>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.contactHeader}>
              Need Help?
            </Typography>
            <Box display="flex" flexDirection="row">
              <Typography className={classes.contact}>
                Call us anytime at:
              </Typography>
              <a href="tel:+1(801) 472-8537" className={classes.contactLink}>
                (801) 472-8537
              </a>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography className={classes.contact}>Email us at: </Typography>
              <a href="mailto:watson@mutual.app" className={classes.contactLink}>
                watson@mutual.app
              </a>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <div className={md ? classes.spacing : classes.spacingLarge}></div>
    </Box>
  );
}

export default withStyles(styles)(AccountPage);
