import {
  Box,
  Button,
  createStyles,
  Dialog,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { LabeledCustomSwitch } from "./CustomSwitch";
import Dropdown, { Option } from "react-dropdown";
import { FixedOrPercent } from "./FixedOrPercent";
import { useEffect } from "react";
import getData from "src/services/getData";
import { isNil } from "lodash";

const headerColor = "rgba(30, 16, 41, 1)";
const labelTextColor = "rgba(92, 92, 92, 1)";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    saveButton: {
      height: 40,
      backgroundColor: "#00FF94",
      color: "#1E1029",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: 0.7,
      borderRadius: "5px",
      marginBottom: "5px",
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "7px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "7px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    dropDownBox: {
      height: "400px",
      width: "auto",
      margin: 10,
      maxWidth: 250,
    },
    errorText: {
      color: '#f77067',
      fontSize: 13,
    }
  })
);

const row = {
  display: "flex",
  flexDirection: "row",
};

const column = {
  display: "flex",
  flexDirection: "column",
};
const headerBlock = {
  bgcolor: headerColor,
  height: 60,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  // wrap is needed for alignContent to work correctly
  flexWrap: "wrap",
};
const mainHeaderText = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "white",
};

const sectionHeaderText = {
  fontWeight: 700,
  fontSize: "22px",
  lineHeight: "26px",
  paddingBottom: "10px",
  paddingTop: "20px",
};
const labelText = {
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "16px",
  color: labelTextColor,
  paddingBottom: "5px",
};
const bodyBlock = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  ...column,
};

interface IGlobalSettingsProps {
  onTapClose: Function;
  currentUser: string;
}
/// the popup that allows a user to edit global settings
/// it is sugggested to use showGlobalSettingsDialog() instead of this directly
function GlobalSettings(props: IGlobalSettingsProps) {
  const classes = styles();

  const dropDownOptions = ["Percentage", "Fixed Amount"];
  const [allowReferralsToggle, setAllowReferralsToggle] =
    useState<boolean>(true);
  const [creditType, setCreditType] = useState<string>("Fixed Amount");
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>("Fixed Amount");
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string | null>();

  useEffect(() => {
    fetchGlobalSettings();
  }, []);

  async function updateGlobalSettings() {
    try {
      const response = await getData(
        'promo-codes/global-settings',
        'POST',
        props.currentUser,
        {
          referralAllowed: allowReferralsToggle,
          credit: {
            type: getProfitType(creditType),
            amount: creditAmount,
          },
          discount: {
            type: getProfitType(discountType),
            amount: discountAmount,
          },
        },
        undefined,
        true
      );
      if (response.status > 200) {
        setErrorMsg('Failed to update the global settings');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchGlobalSettings() {
    try {
      const data = await getData(
        'promo-codes/global-settings',
        'GET',
        props.currentUser,
        {},
        undefined,
        true
      );
      setGlobalSettings(data);
    } catch (error) {
      console.error(error);
    }
  }

  function setGlobalSettings(data: any) {
    setAllowReferralsToggle(data.referralAllowed);
    setCreditType(getProfitLabel(data.credit.type));
    setCreditAmount(data.credit.amount);
    setDiscountType(getProfitLabel(data.discount.type));
    setDiscountAmount(data.discount.amount);
  }

  function getProfitType(type: string) {
    return type === 'Percentage' ? 1 : 0;
  }

  function getProfitLabel(value: number) {
    return value === 1 ? 'Percentage' : 'Fixed Amount';
  }

  function createSaveButton() {
    return (
      <Button
        variant="contained"
        disableElevation
        className={classes.saveButton}
        onClick={() => {
          updateGlobalSettings();
          props.onTapClose();
        }}
      >
        SAVE CHANGES
      </Button>
    );
  }
  function referallProgramSection() {
    return (
      <>
        <Typography style={sectionHeaderText}>Referall Program</Typography>
        <Box sx={{ ...row, paddingBottom: "10px" }}>
          <Box sx={{ ...column, paddingRight: 15 }}>
            <Typography style={labelText}>ALLOW REFERALLS</Typography>
            <LabeledCustomSwitch
              activeLabel={"ALLOW"}
              inActiveLabel={"ALLOW"}
              isActive={allowReferralsToggle}
              onTap={(
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                setAllowReferralsToggle(checked);
              }}
            />
            {allowReferralsToggle && (
              <>
                <Box sx={{ ...row, paddingBottom: "10px", paddingTop: "10px" }}>
                  <Box sx={{ ...column, paddingRight: 15 }}>
                    <Typography style={labelText}>DISCOUNT TYPE</Typography>
                    <Dropdown
                      controlClassName={classes.dropdownSelector}
                      placeholderClassName={classes.dropDownOption}
                      menuClassName={classes.dropdown}
                      className={classes.dropDownOption}
                      options={dropDownOptions}
                      value={discountType}
                      onChange={(newValue: Option) => {
                        setDiscountType(newValue.value);
                        setDiscountAmount(0);
                      }}
                    />
                  </Box>
                  <Box sx={{ ...column }}>
                    <Typography style={labelText}>DISCOUNT AMOUNT</Typography>
                    <FixedOrPercent
                      key={"fixedPercentDiscount"}
                      isFixed={discountType === "Fixed Amount"}
                      creditAmount={discountAmount}
                      onChange={(newVal) => {
                        setDiscountAmount(newVal);
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ ...row, paddingBottom: "10px", paddingTop: "10px" }}>
                  <Box sx={{ ...column, paddingRight: 15 }}>
                    <Typography style={labelText}>CREDIT TYPE</Typography>
                    <Dropdown
                      controlClassName={classes.dropdownSelector}
                      placeholderClassName={classes.dropDownOption}
                      menuClassName={classes.dropdown}
                      className={classes.dropDownOption}
                      options={dropDownOptions}
                      value={creditType}
                      onChange={(newValue: Option) => {
                        setCreditType(newValue.value);
                        setCreditAmount(0);
                      }}
                    />
                  </Box>
                  <Box sx={{ ...column }}>
                    <Typography style={labelText}>CREDIT AMOUNT</Typography>
                    <FixedOrPercent
                      key={"fixedPercentCredit"}
                      isFixed={creditType === "Fixed Amount"}
                      creditAmount={creditAmount}
                      onChange={(newVal) => {
                        setCreditAmount(newVal);
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </>
    );
  }
  return (
    <Box sx={column}>
      <Box sx={headerBlock}>
        <Typography style={mainHeaderText}>Global Settings</Typography>
      </Box>
      <Box sx={bodyBlock}>
        {referallProgramSection()}
        {!isNil(errorMsg) &&
          <Typography
            align="center"
            className={classes.errorText}
          >{errorMsg}</Typography>
        }
        {createSaveButton()}
      </Box>
    </Box>
  );
}

/// conditionally shows the global settings dialog
export function showGlobalSettingsDialog(
  open: boolean,
  onClose: Function,
  currentUser: any) {
  return (
    <Dialog
      // fullWidth stretches to the max width (currently defaults to sm)
      fullWidth={true}
      open={open}
      onClose={() => onClose()}
      scroll={"body"}
      disableBackdropClick={false}
    >
      <GlobalSettings
        onTapClose={onClose}
        currentUser={currentUser} />
    </Dialog>
  );
}
