import {
  Box,
  createStyles,
  Switch,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ChangeEvent } from "react";

export interface ISwitchProps {
  activeLabel: string;
  inActiveLabel: string;
  isActive: boolean;

  onTap: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}
const labelTextColor = "rgba(92, 92, 92, 1)";
const row = {
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
};
const switchText = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
  color: labelTextColor,
};

export function LabeledCustomSwitch(props: ISwitchProps) {
  return (
    <Box sx={row}>
      <Box sx={{ paddingRight: "10px" }}>
        <CustomSwitch onChange={props.onTap} checked={props.isActive} />
      </Box>
      <Typography style={switchText}>
        {props.isActive ? props.activeLabel : props.inActiveLabel}
      </Typography>
    </Box>
  );
}

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 50,
      height: 30,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 3,
      color: "#FFFFFF",
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#00FF94",
          borderColor: "#5C5C5C",
        },
      },
    },
    thumb: {
      width: 24,
      height: 24,
      boxShadow: "none",
    },
    track: {
      border: `1px solid #5C5C5C`,
      borderRadius: 24,
      opacity: 1,
      backgroundColor: "#CDBDD8",
    },
    checked: {},
  })
)(Switch);
