import { withStyles } from "@material-ui/core/styles";
import MaterialChip from '@material-ui/core/Chip';

const Chip = (props: any) => {
	const StyledChip = withStyles({
		root: {
			backgroundColor: props.color,
			color: 'white',
			fontWeight: 500,
			fontSize: 13,
			margin: "5px",
		},
		outlined: {
			color: props.color,
			border: `1px solid ${props.color}`,
			backgroundColor: 'transparent',
			fontWeight: 500,
			fontSize: 13,
			margin: "5px",
		}
	})(MaterialChip);

	return <StyledChip {...props} />;
};

export default Chip;