import {
  Box,
  createStyles,
  Theme,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    imgFile: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#FAFAFA",
      borderRadius: "10px",
      border: "1px solid transparent",
      width: "100px",
      height: "100px",
    },
    fileUpload: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#FAFAFA",
      borderRadius: "10px",
      width: "100px",
      height: "100px",
    },
    addPhotoText: {
      fontSize: "14px",
      lineHeight: "17px",
      textAlign: "center",
      color: "rgba(186, 186, 186, 0.4)",
    },
    fileUrl: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      borderRadius: "10px",
    },
    stack: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    stackItem: {
      boxSizing: "border-box",
      width: "100px",
      display: "flex",
      flexFlow: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    },
  });

function PhotoUpload(props: any) {
  const baseImageCount = 4;
  const [imageCount, setImageCount] = useState(4);

  const {
    theme,
    classes,
    height,
    width,
    callback,
    images,
    imageSetUpdated,
    setFormSubmitError,
  } = props;
  const [imageSet, setImageSet] = useState<any>();

  useEffect(() => {
    if (images !== undefined && images.length > 0) {
      setImageSet(images);
    }
  }, [images]);

  useEffect(() => {
    if (imageSetUpdated) {
      imageSetUpdated(imageSet);
    }
  }, [imageSet, imageSetUpdated]);

  const fileRef0 = useRef<any>(null);
  const fileRef1 = useRef<any>(null);
  const fileRef2 = useRef<any>(null);
  const fileRef3 = useRef<any>(null);
  const [hovered, setHovered] = useState(new Array(imageCount).fill(false));
  const [validFiles, setValidFiles] = useState<any>([]);

  useEffect(() => {
    setImageCount(baseImageCount - validFiles.length);
    callback(validFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validFiles]);

  const preventDefault = (e: any) => {
    e.preventDefault();
  };

  const dragOver = (e: any) => {
    preventDefault(e);
  };

  const dragEnter = (e: any) => {
    preventDefault(e);
  };

  const dragLeave = (e: any) => {
    preventDefault(e);
  };

  const fileDrop = (e: any) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = (e: any) => {
    const i = parseInt(e.target.name);
    const fileRef =
      i === 0 ? fileRef0 : i === 1 ? fileRef1 : i === 2 ? fileRef2 : fileRef3;
    if (fileRef.current.files.length) {
      handleFiles(fileRef.current.files);
    }
  };

  const fileInputClicked = (e: any, i: number) => {
    const fileRef =
      i === 0 ? fileRef0 : i === 1 ? fileRef1 : i === 2 ? fileRef2 : fileRef3;
    fileRef.current.click();
  };

  const handleFiles = (files: any) => {
    let addedImages = 0;
    for (let i = 0; i < files.length; i++) {
      if (
        validateFile(files[i]) &&
        validFiles.length + addedImages < baseImageCount
      ) {
        addedImages++;
        files[i].objectUrl = URL.createObjectURL(files[i]);
        setValidFiles((prevArray: any) => [...prevArray, files[i]]);
      }
    }
  };

  const validateFile = (file: any) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
    if (validTypes.indexOf(file.type) === -1) {
      setFormSubmitError({
        field: "image",
        msg: "Images other than jpeg, jpg, png, and gif were ignored",
      });
      return false;
    }
    if (file.size / 1000000 > 10) {
      setFormSubmitError({
        field: "image",
        msg: "Images larger than 10 MB were ignored",
      });
      return false;
    }
    return true;
  };

  const updateHover = (value: boolean, index: number) => {
    let hoveredCopy = new Array(imageCount).fill(false);
    hoveredCopy[index] = value;
    setHovered(hoveredCopy);
  };

  const removeImage = (index: number) => {
    let validFilesCopy = [...validFiles];
    validFilesCopy.splice(index, 1);
    setValidFiles(validFilesCopy);
  };

  const removeImageFromImages = (index: number) => {
    let imageSetCopy = [...imageSet];
    imageSetCopy.splice(index, 1);
    setImageSet(imageSetCopy);
  };

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      {imageSet !== undefined &&
        imageSet.map((img: any, i: number) => (
          <Box key={i} className={classes.stack}>
            <a href={img.url} target="_blank" rel="noopener noreferrer">
              <Box
                marginY={theme.spacing(0.1)}
                marginRight={i === baseImageCount - 1 ? 0 : theme.spacing(0.1)}
                marginLeft={
                  i === 0 || imageSet.length === 0 ? 0 : theme.spacing(0.1)
                }
                height={height}
                width={width}
                className={clsx(classes.stackItem, classes.imgFile)}
              >
                <img
                  src={img.url}
                  alt="Experience"
                  className={classes.fileUrl}
                />
              </Box>
            </a>
            <Box
              position="absolute"
              style={{
                marginLeft:
                  i === 0 || imageSet.length === 0 ? width * 0.7 : width * 0.75,
                marginTop: height * 0.1,
              }}
            >
              <CancelTwoToneIcon
                onClick={() => removeImageFromImages(i)}
                htmlColor={"#999"}
                style={{ margin: 0, cursor: "pointer" }}
              ></CancelTwoToneIcon>
            </Box>
          </Box>
        ))}
      {validFiles !== undefined &&
        validFiles.map((file: any, i: number) => (
          <Box key={i} className={classes.stack}>
            <a href={file.objectUrl} target="_blank" rel="noopener noreferrer">
              <Box
                marginY={theme.spacing(0.1)}
                marginRight={
                  i + (imageSet === undefined ? 0 : imageSet.length) ===
                  baseImageCount - 1
                    ? 0
                    : theme.spacing(0.1)
                }
                marginLeft={
                  i + (imageSet === undefined ? 0 : imageSet.length) === 0
                    ? 0
                    : theme.spacing(0.1)
                }
                height={height}
                width={width}
                className={clsx(classes.stackItem, classes.imgFile)}
              >
                <img
                  src={file.objectUrl}
                  alt="Experience"
                  className={classes.fileUrl}
                />
              </Box>
            </a>
            <Box
              position="absolute"
              style={{
                marginLeft:
                  i + (imageSet === undefined ? 0 : imageSet.length) === 0
                    ? width * 0.7
                    : width * 0.75,
                marginTop: height * 0.1,
              }}
            >
              <CancelTwoToneIcon
                onClick={() => removeImage(i)}
                htmlColor={"#999"}
                style={{ margin: 0, cursor: "pointer" }}
              ></CancelTwoToneIcon>
            </Box>
          </Box>
        ))}
      {[
        ...Array(
          Math.max(
            0,
            imageCount - (imageSet === undefined ? 0 : imageSet.length)
          )
        ),
      ].map((e, i) => (
        <Box
          key={i}
          marginY={theme.spacing(0.1)}
          marginRight={
            i +
              validFiles.length +
              (imageSet === undefined ? 0 : imageSet.length) ===
            baseImageCount - 1
              ? 0
              : theme.spacing(0.1)
          }
          marginLeft={
            i +
              validFiles.length +
              (imageSet === undefined ? 0 : imageSet.length) ===
            0
              ? 0
              : theme.spacing(0.1)
          }
          height={height}
          width={width}
          className={classes.fileUpload}
          style={{
            border: hovered[i]
              ? "1px dashed rgba(186, 186, 186, 0.8)"
              : "1px dashed rgba(186, 186, 186, 0.4)",
          }}
          onMouseEnter={() => updateHover(true, i)}
          onMouseLeave={() => updateHover(false, i)}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={(e) => fileInputClicked(e, i)}
        >
          <Typography
            className={classes.addPhotoText}
            style={{
              color: hovered[i]
                ? "rgba(186, 186, 186, 0.8)"
                : "rgba(186, 186, 186, 0.4)",
            }}
          >
            Add Photo
          </Typography>
          <input
            id={i.toString()}
            name={i.toString()}
            onChange={filesSelected}
            accept=".jpg,.jpeg,.png,.gif"
            ref={
              i === 0
                ? fileRef0
                : i === 1
                ? fileRef1
                : i === 2
                ? fileRef2
                : fileRef3
            }
            style={{ display: "none" }}
            type="file"
            multiple
          />
        </Box>
      ))}
    </Box>
  );
}

export default withTheme(withStyles(styles)(PhotoUpload));
