import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  authenticate,
  authenticateGoogle,
  authenticateFacebook,
  createAccountEmail,
} from "../../services/auth";
import ToniteLogo from "../../assets/tonite-logo.svg";
import FacebookLogo from "../../assets/facebookLogo.svg";
import GoogleLogo from "../../assets/googleLogo.svg";
import TopBannerLinks from "../TopBannerLinks/TopBannerLinks";
import { boxShadow } from "../../services/util";

function LoginForm(props: any) {
  //eslint-disable-next-line
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loginError, setLoginError] = useState("");

  const signUp = async () => {
    const response = await createAccountEmail(email, password);
    if (response === true) {
      props.history.push("/home/location info");
    } else if (typeof response === "string") {
      setLoginError(response);
    }
  };

  const goToTerms = () => {
    props.history.push("/terms");
  };

  const login = async () => {
    const response = await authenticate(email, password);
    if (response === true) {
      props.history.push("/home/location info");
    } else if (typeof response === "string") {
      setLoginError(response);
    }
  };

  const authWithGoogle = async () => {
    const response = await authenticateGoogle();
    if (response === true) {
      props.history.push("/home/location info");
    } else if (typeof response === "string") {
      setLoginError(response);
    }
  };

  const authWithFacebook = async () => {
    const response = await authenticateFacebook();
    if (response === true) {
      props.history.push("/home/location info");
    } else if (typeof response === "string") {
      setLoginError(response);
    }
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    checkFields("checked", event.target.checked);
  };

  const checkFields = (field: string, _checked?: boolean) => {
    let disabled = false;
    if (!regexEmail.test(email)) {
      if (field === "email") {
        setEmailError(true);
      }
      disabled = true;
    }
    if (emailError === true) {
      setEmailError(false);
    }

    if (password.length < 6) {
      if (field === "password") {
        setPasswordError(true);
      }
      disabled = true;
    }
    if (passwordError === true) {
      setPasswordError(false);
    }
    if (isSignUp && (field === "checked" ? !_checked : !checked)) {
      disabled = true;
    }
    setDisabled(disabled);
  };

  useEffect(() => {
    checkFields("");
    if (isSignUp && !checked) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, isSignUp, password]);

  const isDisabled3rdParty = () => {
    return isSignUp && !checked;
  };

  const resetFormValidation = () => {
    if (emailError === true) {
      setEmailError(false);
    }
    if (passwordError === true) {
      setPasswordError(false);
    }
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const loginFormBaseStyle = {
    position: "absolute" as "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const loginFormStyle = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-evenly",
    flex: 5,
    borderRadius: "20px",
    padding: "1rem",
    minWidth: xs ? "75vw" : sm ? "65vw" : md ? "40vw" : lg ? "30vw" : "20vw",
    margin: "0px 1rem",
    marginTop: "5vw",
  };

  const formStyle = {
    display: "flex",
    flexFlow: "column",
    paddingBottom: "1rem",
  };

  const headerStyle = {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const titleStyle = {
    fontWeight: "bold" as "bold",
    fontSize: "24px",
    lineHeight: "29px",
  };

  const subTitleStyle = {
    fontWeight: "bold" as "bold",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.05em",
    textTransform: "uppercase" as "uppercase",
    color: "#4DC9E4",
    cursor: "pointer",
  };

  const agreementStyle = {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    fontWeight: "lighter" as "lighter",
    fontSize: "15px",
    lineHeight: "18px",
    paddingBottom: "1.5rem",
  };

  const agreementLinkStyle = {
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0.05em",
    color: "#4DC9E4",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const dividerWrapperStyle = {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1.5vw 0",
  };

  const dividerStyle = {
    width: "42.5%",
    backgroundColor: "#000",
    height: "1px",
  };

  const thirdStyle = {
    fontSize: "13px",
    lineHeight: "16px",
  };

  const thirdPartyButtonsStyle = {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    width: "100%",
    paddingBottom: "2vw",
  };

  const facebookButtonStyle = {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "#3B5998",
    boxShadow: boxShadow,
    borderRadius: "5px",
    width: "45%",
    height: "3rem",
    filter: isDisabled3rdParty() ? "grayscale(80%)" : "unset",
    cursor: "pointer",
  };

  const googleButtonStyle = {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: boxShadow,
    borderRadius: "5px",
    width: "45%",
    height: "3rem",
    filter: isDisabled3rdParty() ? "grayscale(80%)" : "unset",
    cursor: "pointer",
  };

  const thirdPartyStyle = {
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.05em",
    textTransform: "uppercase" as "uppercase",
    color: "#FFFFFF",
  };

  const googleTextStyle = {
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.05em",
    textTransform: "uppercase" as "uppercase",
    color: "#1E1029",
  };

  const logoStyle = {
    width: "0.75rem",
    userDrag: "none",
    WebkitUserDrag: "none",
  };

  const googleLogoStyle = {
    width: "1.5rem",
    userDrag: "none",
    WebkitUserDrag: "none",
  };

  const toniteLogoStyle = {
    marginTop: "2vw",
    userDrag: "none",
    WebkitUserDrag: "none",
    width: xs ? "50vw" : sm ? "37vw" : md ? "25vw" : lg ? "25vw" : "12vw",
  };

  const spacerStyle = {
    flex: 3,
  };

  const errorText = {
    color: "#FE2A2A",
    maxWidth: "30vw",
  };

  const forgotPassword = {
    fontSize: "15px",
    lineHeight: "18px",
    color: "#4DC9E4",
    fontWeight: 400,
  };

  const smallLoginButtonsStyle = {
    display: "grid",
    gridRowGap: "1.5vw",
  };

  return (
    <div style={loginFormBaseStyle}>
      <TopBannerLinks />
      <img
        src={ToniteLogo}
        alt="Tonite logo"
        style={toniteLogoStyle}
      />
      <div style={loginFormStyle} className="loginForm">
        <div style={headerStyle}>
          <h1 style={titleStyle}>{isSignUp ? "Sign Up" : "Sign In"}</h1>
          <h2
            style={subTitleStyle}
            onClick={() => {
              setIsSignUp(!isSignUp);
              resetFormValidation();
            }}
          >
            {isSignUp ? "Sign In" : "Sign Up"}
          </h2>
        </div>
        <form style={formStyle}>
          <FormControl>
            <InputLabel htmlFor="email" shrink>
              Email
            </InputLabel>
            <Input
              id="email"
              type="email"
              onInput={(e: any) => {
                setEmail(e != null ? e.target.value : "");
                if (
                  e.target.value !== "" &&
                  e.inputType === undefined &&
                  e.data === undefined &&
                  e.dataTransfer === undefined &&
                  e.isComposing === undefined
                ) {
                  checkFields("");
                }
              }}
              onBlur={() => checkFields("email")}
            />
            <FormHelperText id="emailError" style={errorText}>
              {emailError ? "Email must be a valid email" : " "}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="password" shrink>
              Password
            </InputLabel>
            <Input
              id="password"
              type="password"
              autoComplete="on"
              onInput={(e: any) => {
                setPassword(e != null ? e.target.value : "");
                if (
                  e.target.value !== "" &&
                  e.inputType === undefined &&
                  e.data === undefined &&
                  e.dataTransfer === undefined &&
                  e.isComposing === undefined
                ) {
                  checkFields("");
                }
              }}
              onBlur={() => checkFields("password")}
            />
            <FormHelperText id="passwordError" style={errorText}>
              {passwordError ? "Password must be more then 6 characters" : " "}
            </FormHelperText>
          </FormControl>
        </form>
        {isSignUp && (
          <div style={agreementStyle}>
            <Checkbox
              checked={checked}
              onChange={(event) => handleChange(event)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <h2>
              I agree to Tonite’s{" "}
              <Link onClick={goToTerms} style={agreementLinkStyle}>
                Terms & Conditions
              </Link>
            </h2>
          </div>
        )}
        {loginError.length > 0 && (
          <FormControl>
            <FormHelperText id="login-error" style={errorText}>
              {loginError}
            </FormHelperText>
          </FormControl>
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={() => (isSignUp ? signUp() : login())}
        >
          {isSignUp ? "SIGN UP" : "SIGN IN"}
        </Button>
        <div style={dividerWrapperStyle}>
          <hr style={dividerStyle} />
          <h3 style={thirdStyle}>OR</h3>
          <hr style={dividerStyle} />
        </div>
        {!xs && !sm ? (
          <div style={thirdPartyButtonsStyle}>
            {/* Hiding Facebook until we decide to use it */}
            {false && (
              <div
                style={facebookButtonStyle}
                onClick={() => {
                  if (!isDisabled3rdParty()) authWithFacebook();
                }}
              >
                <img src={FacebookLogo} alt="Facebook logo" style={logoStyle} />
                <h1 style={thirdPartyStyle}>Facebook</h1>
              </div>
            )}
            <div
              style={googleButtonStyle}
              onClick={() => {
                if (!isDisabled3rdParty()) authWithGoogle();
              }}
            >
              <img src={GoogleLogo} alt="Google logo" style={googleLogoStyle} />
              <h1 style={googleTextStyle}>Google</h1>
            </div>
          </div>
        ) : (
          // Small screen Facebook & Google buttons
          <div style={smallLoginButtonsStyle}>
            {/* Hiding Facebook until we decide to use it */}
            {false && (
              <Button
                variant="contained"
                color="secondary"
                disabled={!checked}
                onClick={() => {
                  if (!isDisabled3rdParty()) authWithFacebook();
                }}
              >
                {"SIGN UP WITH FACEBOOK"}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              disabled={!checked}
              onClick={() => {
                if (!isDisabled3rdParty()) authWithGoogle();
              }}
            >
              {"SIGN UP WITH GOOGLE"}
            </Button>
          </div>
        )}
        {!isSignUp && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            paddingBottom=".75vw"
          >
            <Button onClick={() => props.setResetPassword(true)}>
              <Typography style={forgotPassword}>Forgot Password?</Typography>
            </Button>
          </Box>
        )}
      </div>
      <div style={spacerStyle}></div>
    </div>
  );
}

export default LoginForm;
