import fetch, { Headers } from 'node-fetch';
import { isUserAdmin, resetFirebaseAuth } from "./auth";

import Config from "../config";
import { ConfigKey } from "../config/config.enums";
import { isNil } from 'lodash';

export default async function getData(uri: string, method: string, token: string, body: object, params?: object, useAdmin?: boolean): Promise<any> {
    try {
        const apiProtocol = Config.getValue(ConfigKey.API_PROTOCOL);
        const apiRoot = Config.getValue(ConfigKey.API_ROOT);
        const apiRoute = useAdmin !== undefined && useAdmin && !isNil(token) && isUserAdmin(token) ? Config.getValue(ConfigKey.API_ADMIN_ROUTE) : Config.getValue(ConfigKey.API_ROUTE);
        let url = `${apiProtocol}://${apiRoot}${apiRoute}/${uri}`;
        if (!isNil(params) && params !== {}) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + getQueryString(params);
        }
        const res = await fetch(
            url,
            {
                method: method,
                headers: new Headers({
                    Accept: "application/json",
                    authtoken: token,
                    "Content-Type": "application/json",
                }),
                ...(Object.keys(body).length > 0 && { body: JSON.stringify(body) })
            }
        );
        if (res.status === 200) {
            const response = await res.json();
            return response;
        } if (res.status === 205) {
            const _token = await resetFirebaseAuth();
            if (_token !== undefined) {
                return getData(url, method, _token, body, params);
            }
        } else {
            throw res;
        }
    } catch (error) {
        return error;
    }
}

function getQueryString(params: any) {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}
