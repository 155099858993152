import {
    Box,
    Card,
    CardActions,
    CardMedia,
    createStyles,
    Dialog,
    Divider,
    IconButton,
    Popover,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
    Button,
} from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    RightChevronIcon,
    EditIcon,
    StopIcon,
    BoxBoxIcon,
    PersonIcon
} from "../components/Icons";
import ExperienceForm from "../components/ExperienceForm/ExperienceForm";
import BoostForm from "../components/BoostForm/BoostForm";
import AssignExperience from "../components/AssignExperience/AssignExperience";
import { boxShadow, createObjectBasedOnFirst, experienceBase, ExperiencesButton } from "../services/util";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PopupState, { bindTrigger, bindPopover, Props } from "material-ui-popup-state";
import { isNil } from "lodash";
import getData from "../services/getData";
import Config from "../config";
import { ConfigKey } from "../config/config.enums";
import { loadStripe } from "@stripe/stripe-js";

const styles = (theme: Theme) =>
    createStyles({
        headerText: {
            [theme.breakpoints.down("sm")]: {
                fontSize: "24px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "30px",
            },
            fontWeight: 900,
            fontSize: "30px",
            lineHeight: "36px",
        },
        locationText: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "21.6px",
            color: "#5C5C5C",
        },
        subHeaderText: {
            marginTop: theme.spacing(1),
            fontSize: "18px",
            lineHeight: "22px",
            marginBottom: theme.spacing(1),
        },
        headerDivider: {
            marginTop: theme.spacing(2),
            backgroundColor: "#D6D6D6",
        },
        buttonText: {
            paddingLeft: "4px",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "16.8px",
            letterSpacing: "5%",
            textTransform: "uppercase",
            color: "#FFFFFF",
        },
        modal: {
            position: "absolute",
            top: "10%",
            left: "10%",
            overflow: "scroll",
            height: "100%",
            display: "block",
        },
        experienceCard: {
            width: "300px",
            height: "285px",
            boxShadow: boxShadow,
        },
        hoverButton: {
            backgroundColor: "rgba(255,255,255,1)",
            "&:hover": {
                backgroundColor: "rgba(0,255,148,0.4)",
            },
        },
        button: {
            width: "100%",
            height: "50px",
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            padding: "6px 8px",
            boxShadow: boxShadow,
            textTransform: "uppercase",
            fontSize: "14px",
            lineHeight: "17px",
            fontWeight: 700,
            letterSpacing: "0.05em",
        },
        paper: {
            width: "100%",
            height: "100%",
        },
        black: {
            color: '#1E1029'
        },
        gray: {
            color: '#5C5C5C'
        },
        white: {
            color: '#FFFFFF'
        },
        green: {
            color: '#00FF94',
        }
    });

function ExperiencesPage(props: any) {
    const {
        classes,
        cards,
        selectedLocation,
        experiences,
        reloadExperiences,
        pendingBoosts,
        liveBoosts,
        revenuePercent = 0.1,
        updateSelectedLocation,
        updateBoosts,
        adminId,
        isAdmin,
        experienceCategories,
    } = props;

    const [selectedExperience, setSelectedExperience] = useState<any>();
    const [boost, setBoost] = useState(false);
    const [open, setOpen] = useState(false);
    const [openBoost, setOpenBoost] = useState(false);
    const [experienceToAssign, setExperienceToAssign] = useState<any>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [formType, setFormType] = useState("");
    const [experienceBoost, setExperienceBoost] = useState<any>();
    const [isDeactivate, setIsDeactivate] = useState<boolean>(false);
    const stripePromise = loadStripe(Config.getValue(ConfigKey.STRIPE_KEY));

    const openExperience = (popupState: Partial<Props>, exp: any, index: number) => {
        popupState?.parentPopupState?.close();
        setSelectedExperience(exp);
        setBoost(false);
        setOpen(true);
    };

    const openCreateBoost = (popupState: Partial<Props>, exp: any) => {
        popupState?.parentPopupState?.close();
        setFormType("submit-approval");
        setSelectedExperience(exp);
        setOpenBoost(true);
    };

    const openEditBoost = (popupState: Partial<Props>, exp: any, boost: any) => {
        popupState.parentPopupState?.close();
        setFormType("edit");
        setExperienceBoost(boost);
        setSelectedExperience(exp);
        setOpenBoost(true);
    };

    const openAssignExperience = (popupState: Partial<Props>, exp: any, index: number) => {
        popupState?.parentPopupState?.close();
        setExperienceToAssign(exp);
        setBoost(false);
        setOpen(true);
    };

    const openApproveBoost = async (popupState: Partial<Props>, exp: any, index: number) => {
        popupState?.parentPopupState?.close();
        setFormType("approve-reject");
        setExperienceBoost(
            pendingBoosts.find(
                (boost: any) => boost.experienceId === exp.experienceId
            )
        );
        setSelectedExperience(exp);
        setOpenBoost(true);
    };

    function openDeactivateBoost(popupState: Partial<Props>, exp: any) {
        popupState?.parentPopupState?.close();
        setExperienceBoost(
            pendingBoosts.find(
                (boost: any) => boost.experienceId === exp.experienceId
            )
        );
        setSelectedExperience(exp);
        setOpenConfirm(true);
    }

    function openDeactivateExperience(popupState: Partial<Props>, exp: any) {
        let buildExp = JSON.parse(JSON.stringify(experienceBase));
        buildExp = createObjectBasedOnFirst(buildExp, exp);
        popupState?.parentPopupState?.close();
        setIsDeactivate(true);
        setSelectedExperience(buildExp);
        setOpenConfirm(true);
    }

    const disableExperience = async (exp: any) => {
        const updatedExp = {
            approved: false,
            live: false,
            title: exp.title,
            why: exp.why,
            price: exp.price,
            discountedPrice: exp.discountedPrice,
            staffInstructions: exp.staffInstructions,
            type: exp.type,
            occurance: exp.occurance,
            seasons: exp.seasons,
            includedItems: exp.includedItems,
            addOns: exp.addOns,
            images: exp.images,
            categories: exp.categories,
            hours: exp.hours,
            locationId: exp.locationId,
            partnerId: exp.partnerId,
            experienceId: exp.experienceId,
            limitPerUser: exp.limitPerUser,
            highlights: exp.highlights,
            approvedStamp: exp.approvedStamp,
            redemptionDetails: exp.redemptionDetails,
            hideDiscountedPrice: exp.hideDiscountedPrice,
            order: exp.order,
            priorityValue: exp.priorityValue,
        }
        try {
            await getData(
                `experiences/${exp.experienceId}`,
                "PATCH",
                props.currentUser,
                updatedExp,
                undefined,
                true,
            );
            reloadExperiences();
            setOpenConfirm(false);
        } catch (error) {
            console.error(error);
        }
    };

    const buildExperience = (
        exp: any,
        classes: any,
        index: number,
        openConfirm: boolean,
        setOpenConfirm: any,
        openEditBoost: any,
        experienceBoost: any,
        setExperienceBoost: any
    ) => {
        let selectedBoost = pendingBoosts.find((boost: any) => {
            return boost.experienceId === exp.experienceId;
        });
        const liveBoost = !isNil(selectedBoost);
        if (!liveBoost) {
            selectedBoost = liveBoosts.find((boost: any) => {
                return boost.experienceId === exp.experienceId;
            });
        }
        const url =
            exp.images && exp.images.length > 0
                ? exp.images[0].url
                : "https://mutual-experiences.s3.us-east-2.amazonaws.com/a4be5297-842f-4726-812f-5df483f953f2-1ea64540-143d-492f-852a-1220aeeac020.jpeg";

        return (
            <PopupState key={index} variant="popover" popupId="exp-more-popover">
                {(popupState) => (
                    <Box key={index} pl={2}>
                        <Card
                            className={classes.experienceCard}
                            style={{ marginRight: "30px", marginTop: "15px" }}
                        >
                            <CardMedia
                                className={classes.media}
                                children={
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        style={{ height: "200px", padding: "10px 15px" }}
                                    >
                                        <Typography
                                            style={{
                                                maxHeight: "150px",
                                                overflow: "hidden",
                                                fontWeight: 700,
                                                fontSize: "30px",
                                                lineHeight: "30px",
                                                color: classes.white,
                                                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.23)",
                                            }}
                                        >
                                            {exp.title}
                                        </Typography>
                                        <Typography
                                            style={{
                                                paddingTop: "1rem",
                                                fontWeight: 700,
                                                fontSize: "12px",
                                                lineHeight: "12px",
                                                color: classes.green,
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {exp.live ? "Active" : "Inactive"}
                                        </Typography>
                                    </Box>
                                }
                                style={{
                                    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 28.86%, rgba(0, 0, 0, 0.74) 100%), url(${url})`,
                                }}
                            />
                            <CardActions disableSpacing>
                                <Box display="flex" flexDirection="column" width={1}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "16px",
                                                lineHeight: "16px",
                                                color: classes.black,
                                            }}
                                        >
                                            {exp.live
                                                ? !isNil(exp.editedExperience) &&
                                                    exp.editedExperience.live === false
                                                    ? "Pending Deactivation"
                                                    : "Live"
                                                : exp.approved
                                                    ? "Pending Approval"
                                                    : "Work in Progress"}
                                        </Typography>

                                        <div>
                                            <IconButton {...bindTrigger(popupState)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                            >
                                                <Box width={"200px"} paddingY={"10px"} margin={0}>
                                                    <Option
                                                        classes={classes}
                                                        label="Edit"
                                                        onClick={() =>
                                                            openExperience(popupState, exp, index)
                                                        }
                                                    >
                                                        <EditIcon fill={classes.gray} />
                                                    </Option>
                                                    {isAdmin && (
                                                        <Option
                                                            classes={classes}
                                                            label="Assign to partner"
                                                            onClick={() =>
                                                                openAssignExperience(popupState, exp, index)
                                                            }
                                                        >
                                                            <PersonIcon fill={classes.gray} />
                                                        </Option>
                                                    )}
                                                    {isNil(selectedBoost) && (
                                                        <Option
                                                            classes={classes}
                                                            label="Create Boost"
                                                            onClick={() => openCreateBoost(popupState, exp)}
                                                        >
                                                            <BoxBoxIcon fill={classes.gray} />
                                                        </Option>
                                                    )}
                                                    {isAdmin &&
                                                        !isNil(
                                                            pendingBoosts.find(
                                                                (boost: any) =>
                                                                    boost.experienceId === exp.experienceId
                                                            )
                                                        ) && (
                                                            <Option
                                                                classes={classes}
                                                                label="Approve Boost"
                                                                onClick={() =>
                                                                    openApproveBoost(popupState, exp, index)
                                                                }
                                                            >
                                                                <EditIcon fill={classes.gray} />
                                                            </Option>
                                                        )}
                                                    {isAdmin && !isNil(exp.activeBoost) && exp.live && (
                                                        <Option
                                                            classes={classes}
                                                            label="Deactivate boost"
                                                            onClick={() =>
                                                                openDeactivateBoost(popupState, exp)
                                                            }
                                                        >
                                                            <StopIcon fill={classes.gray} />
                                                        </Option>
                                                    )}
                                                    {isAdmin && exp.live && (
                                                        <Option
                                                            classes={classes}
                                                            label="Deactivate experience"
                                                            onClick={() =>
                                                                openDeactivateExperience(popupState, exp)
                                                            }
                                                        >
                                                            <StopIcon fill={"#5C5C5C"} />
                                                        </Option>
                                                    )}
                                                </Box>
                                            </Popover>
                                        </div>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Typography
                                                style={{
                                                    paddingLeft: "0.5rem",
                                                    fontWeight: 700,
                                                    fontSize: "16px",
                                                    lineHeight: "19.2px",
                                                    color: classes.black,
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {exp.live ? "Active" : "Inactive"}
                                            </Typography>
                                        </Box>
                                        {!isNil(adminId) && (
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                            >
                                                {!isNil(selectedBoost) ? (
                                                    <Button
                                                        onClick={() => {
                                                            openEditBoost(popupState, exp, selectedBoost);
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                paddingRight: "0.5rem",
                                                                fontWeight: 700,
                                                                fontSize: "17px",
                                                                lineHeight: "19.2px",
                                                                color: classes.black,
                                                            }}
                                                        >
                                                            {"Boost"}
                                                        </Typography>
                                                        <RightChevronIcon fill={classes.black} />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            openEditBoost(popupState, exp, selectedBoost);
                                                        }}>
                                                        <Typography
                                                            style={{
                                                                paddingRight: "0.5rem",
                                                                fontWeight: 700,
                                                                fontSize: "17px",
                                                                lineHeight: "19.2px",
                                                            }}
                                                        >
                                                            {"Boost"}
                                                        </Typography>
                                                        <RightChevronIcon fill={classes.black} />
                                                    </Button>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </CardActions>
                        </Card>
                    </Box>
                )}
            </PopupState>
        );
    };

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    async function deactivateBoost(exp: any) {
        const boostOrderId = exp.activeBoost.boostOrderId;
        const experienceId = exp.experienceId;
        if (!isNil(boostOrderId) && !isNil(experienceId))
            try {
                await getData(
                    `boosts/deactivate/${boostOrderId}/${experienceId}`,
                    "PATCH",
                    props.currentUser,
                    {},
                    undefined,
                    true
                );
            } catch (error) {
                console.error(error);
            }
        reloadExperiences();
        updateBoosts();
        setOpenConfirm(false);
    }

    return (
        <Card style={{ border: "none", boxShadow: "none" }}>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                    <Box
                        pt={2}
                        pl={2}
                        display={!xs && !sm ? "flex" : "block"}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box display="flex" flexDirection="column">
                            <Box
                                pb={4}
                                display="flex"
                                flexDirection="column"
                                alignItems="baseline"
                            >
                                <Typography className={classes.headerText}>
                                    Experiences
                                </Typography>
                                <Typography className={classes.locationText}>
                                    {selectedLocation.name}
                                </Typography>
                            </Box>
                            <Typography className={classes.subHeaderText}>
                                Create new date experiences for Mutual users
                            </Typography>
                        </Box>
                        <ExperiencesButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setSelectedExperience(undefined);
                                setOpen(true);
                            }}
                        >
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <AddIcon htmlColor={classes.white} />
                                <Typography className={classes.buttonText}>
                                    New Experience
                                </Typography>
                            </Box>
                        </ExperiencesButton>
                    </Box>
                    {!xs && !sm && <Divider className={classes.headerDivider}></Divider>}
                </Box>
                {experiences && (
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        style={{ margin: "15px 0" }}
                    >
                        {experiences.map((exp: any, index: number) => {
                            return buildExperience(
                                exp,
                                classes,
                                index,
                                openConfirm,
                                setOpenConfirm,
                                openEditBoost,
                                experienceBoost,
                                setExperienceBoost
                            );
                        })}
                    </Box>
                )}
                <Dialog
                    open={open}
                    onClose={(event, reason) => {
                        setOpen(false);
                        setSelectedExperience(undefined);
                        setExperienceToAssign(undefined);
                        setBoost(false);
                    }}
                    scroll={"body"}
                >
                    {experienceToAssign ? (
                        <AssignExperience
                            currentUser={props.currentUser}
                            setExperienceToAssign={setExperienceToAssign}
                            experienceToAssign={experienceToAssign}
                            selectedLocation={selectedLocation}
                            revenuePercent={revenuePercent}
                            reloadExperiences={reloadExperiences}
                            setSelectedExperienceBoost={() => { }}
                            setOpenAssign={(open: boolean) => {
                                setSelectedExperience(undefined);
                                setExperienceToAssign(undefined);
                                setBoost(false);
                                setOpen(open);
                            }}
                            updateSelectedLocation={updateSelectedLocation}
                            experiences={experiences}
                        ></AssignExperience>
                    ) : (
                        <ExperienceForm
                            currentUser={props.currentUser}
                            setSelectedExperience={setSelectedExperience}
                            selectedExperience={selectedExperience}
                            selectedLocation={selectedLocation}
                            revenuePercent={revenuePercent}
                            reloadExperiences={reloadExperiences}
                            setSelectedExperienceBoost={() => { }}
                            setOpen={(open: boolean) => {
                                setSelectedExperience(undefined);
                                setBoost(false);
                                setOpen(open);
                            }}
                            isApproval={false}
                            isAdmin={isAdmin}
                            experienceCategories={experienceCategories}
                        />
                    )}
                </Dialog>

                <Dialog
                    open={openBoost}
                    onClose={(event, reason) => {
                        setOpen(false);
                        setSelectedExperience(undefined);
                        setExperienceToAssign(undefined);
                        setBoost(false);
                    }}
                    scroll={"body"}
                >
                    <BoostForm
                        theme={theme}
                        cards={cards}
                        updatePaymentMethods={() => { }}
                        stripe={stripePromise}
                        experienceId={selectedExperience?.experienceId}
                        selectedBoost={experienceBoost}
                        updateBoosts={updateBoosts}
                        liveBoosts={liveBoosts}
                        selectedExperienceBoost={() => { }}
                        setSelectedExperienceBoost={() => { }}
                        reloadExperiences={reloadExperiences}
                        setOpen={setOpenBoost}
                        formType={formType}
                        currentUser={props.currentUser}
                        getExperiencesSeekingApproval={() => { }}
                        boostIsLive={experienceBoost?.approved === true ? true : false}
                        isAdmin={isAdmin}
                    />
                </Dialog>
                <Dialog
                    open={openConfirm}
                    onClose={(event, reason) => {
                        // setExperienceIdToAssign(undefined)
                        setIsDeactivate(false);
                        setBoost(false);
                    }}
                    scroll={"body"}
                >
                    <Box p={4} flexDirection="column" justifyContent="center">
                        <Typography style={{ textAlign: "center" }}>
                            {`Are you sure you want to deactivate this ${isDeactivate ? 'experience' : 'boost'}?`}
                        </Typography>
                        <Box padding={theme.spacing(0.2)}></Box>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Button
                                style={{
                                    width: "100%",
                                    height: "50px",
                                    backgroundColor: classes.white,
                                    borderRadius: "5px",
                                    padding: "6px 8px",
                                    boxShadow: boxShadow,
                                    textTransform: "uppercase",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    fontWeight: 700,
                                    letterSpacing: "0.05em",
                                }}
                                onClick={() => {
                                    if (isDeactivate) {
                                        disableExperience(selectedExperience);
                                    } else {
                                        deactivateBoost(selectedExperience);
                                    }
                                }}
                            >
                                DEACTIVATE
                            </Button>
                            <Box padding={theme.spacing(0.1)}></Box>
                            <Button
                                style={{
                                    width: "100%",
                                    height: "50px",
                                    backgroundColor: classes.white,
                                    borderRadius: "5px",
                                    padding: "6px 8px",
                                    boxShadow: boxShadow,
                                    textTransform: "uppercase",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    fontWeight: 700,
                                    letterSpacing: "0.05em",
                                }}
                                onClick={() => setOpenConfirm(false)}
                            >
                                CANCEL
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        </Card>
    );
}

export default withStyles(styles)(ExperiencesPage);

function Option(props: any) {
    const { classes, onClick, label } = props;
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingY={"4px"}
            className={classes.hoverButton}
            onClick={() => {
                onClick();
            }}
            style={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21.6px",
                width: "100%",
                margin: "5px",
                cursor: "pointer",
            }}
        >
            <Box display="flex" flexDirection="row" justifyContent="center" flex={1}>
                {props.children}
            </Box>
            <Box display="flex" flexDirection="row" flex={4}>
                <Typography
                    style={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "21.6px",
                        color: classes.gray,
                    }}
                >
                    {label}
                </Typography>
            </Box>
        </Box>
    );
}
