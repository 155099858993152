import {
  Box,
  Button,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { formatTime, parseTime } from "../../services/util";
import { isNil } from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    location: {
      width: "100%",
    },
    root: {
      width: "95%",
    },
    fullWidthGrid: {
      display: "flex",
      flex: 1,
    },
    title: {
      fontSize: "24px",
      lineHeight: "29px",
    },
    pos: {
      marginBottom: 12,
    },
    divider: {
      width: "105%",
      marginLeft: "0.5%",
      backgroundColor: "#BABABA",
    },
    editButton: {
      fontStyle: "italic",
      fontSize: "14px",
      lineHeight: "16.8px",
      color: "#2ABC12",
      fontWeight: 400,
    },
    saveButton: {
      color: "#13A3D5",
    },
    editBannerText: {
      color: "#FFFFFF",
      textAlign: "center",
    },
    editBanner: {
      backgroundColor: "#100F0F",
      opacity: 0.75,
      bottom: 0,
    },
    label: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 700,
      color: "#5C5C5C",
    },
    formInput: {
      paddingBottom: "1rem",
    },
    "MuiInput-input": {
      padding: 0,
    },
    ownerField: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 400,
      color: "#5C5C5C",
    },
    copyOwnerField: {
      color: "#13A3D5",
    },
    spacing: {
      paddingTop: "1rem",
    },
    spacingLarge: {
      paddingRight: "1rem",
    },
  });

export const DateSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: "#FFFFFF",
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#00FF94",
          borderColor: "#5C5C5C",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid #5C5C5C`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#CDBDD8",
    },
    checked: {},
  })
)(Switch);

function StoreHours(props: any) {
  const { classes, locationSelected, periods, updateHours, title } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let toggle = true;
    if (!event.target.checked) {
      const availableDays = Object.entries(openHours).filter((item: any) => {
        return item[1].available;
      });
      if (availableDays.length < 2) {
        toggle = false;
      }
    }
    if (toggle) {
      setOpenHours({
        ...openHours,
        [event.target.name]: {
          ...openHours[event.target.name],
          available: event.target.checked,
          hours: [
            {
              open: {
                hour: 8,
                minute: 0,
                text: formatTime({ hour: 8, minute: 0 }),
              },
              close: {
                hour: 21,
                minute: 0,
                text: formatTime({ hour: 21, minute: 0 }),
              },
            },
          ],
        },
      });
    }
  };

  const week = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const [openHours, setOpenHours] = useState<any>();
  const daysOfWeek = [
    { date: "monday", day: 1 },
    { date: "tuesday", day: 2 },
    { date: "wednesday", day: 3 },
    { date: "thursday", day: 4 },
    { date: "friday", day: 5 },
    { date: "saturday", day: 6 },
    { date: "sunday", day: 0 },
  ];
  if (openHours === undefined) {
    const _openHours: any = {};
    daysOfWeek.forEach((day) => {
      _openHours[day.date] = {
        available: true,
        hours: [
          {
            open: {
              hour: 8,
              minute: 0,
              text: formatTime({ hour: 8, minute: 0 }),
            },
            close: {
              hour: 21,
              minute: 0,
              text: formatTime({ hour: 21, minute: 0 }),
            },
          },
        ],
      };
    });
    setOpenHours(_openHours);
  }

  useEffect(() => {
    if (!isNil(periods)) {
      let days = [...week];
      periods.forEach((period: any) => {
        const openHour = parseInt(period.open.time.substring(0, 2));
        const openMinute = parseInt(period.open.time.substring(2, 4));
        const closeHour = parseInt(period.close.time.substring(0, 2));
        const closeMinute = parseInt(period.close.time.substring(2, 4));
        const _dayOfWeek = week[period.open.day];
        const multiple = !days.includes(_dayOfWeek);
        days = days.filter((item) => item !== _dayOfWeek);
        if (!multiple) {
          openHours[_dayOfWeek] = {
            available: true,
            hours: [
              {
                open: {
                  hour: openHour,
                  minute: openMinute,
                  text: formatTime({ hour: openHour, minute: openMinute }),
                },
                close: {
                  hour: closeHour,
                  minute: closeMinute,
                  text: formatTime({ hour: closeHour, minute: closeMinute }),
                },
              },
            ],
          };
        } else {
          openHours[_dayOfWeek].hours.push({
            open: {
              hour: openHour,
              minute: openMinute,
              text: formatTime({ hour: openHour, minute: openMinute }),
            },
            close: {
              hour: closeHour,
              minute: closeMinute,
              text: formatTime({ hour: closeHour, minute: closeMinute }),
            },
          });
        }
      });
      days.forEach((missingDay) => {
        openHours[missingDay] = { available: false };
      });
      setOpenHours({ ...openHours });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods]);

  const updateTimeWithDate = (
    date: Date,
    dayOfWeek: string,
    type: string,
    hourIndex: number
  ): boolean => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const otherTime =
      openHours[dayOfWeek].hours[hourIndex][
      type === "close" ? "open" : "close"
      ];
    if (
      (type === "close" &&
        (hours < otherTime.hour ||
          (hours === otherTime.hour && minutes >= otherTime.minute))) ||
      (type === "open" &&
        (hours > otherTime.hour ||
          (hours === otherTime.hour && minutes <= otherTime.minute)))
    ) {
      return false;
    } else {
      openHours[dayOfWeek].hours[hourIndex][type].hour = hours;
      openHours[dayOfWeek].hours[hourIndex][type].minute = minutes;
      openHours[dayOfWeek].hours[hourIndex][type].text = formatTime(
        openHours[dayOfWeek].hours[hourIndex][type]
      );
      setOpenHours({ ...openHours });
      return true;
    }
  };

  const updateTime = (
    text: string,
    dayOfWeek: string,
    type: string,
    hourIndex: number
  ) => {
    let success = false;
    text = text.trim().toLowerCase();
    if (text.length > 0) {
      let now = parseTime(text);
      if (now !== null) {
        success = updateTimeWithDate(now, dayOfWeek, type, hourIndex);
      } else {
        success = false;
      }
      if (!success) {
        openHours[dayOfWeek].hours[hourIndex][type].hour =
          openHours[dayOfWeek].hours[hourIndex][
            type === "close" ? "open" : "close"
          ].hour;
        openHours[dayOfWeek].hours[hourIndex][type].minute =
          openHours[dayOfWeek].hours[hourIndex][
            type === "close" ? "open" : "close"
          ].minute;
        openHours[dayOfWeek].hours[hourIndex][type].text = formatTime(
          openHours[dayOfWeek].hours[hourIndex][type]
        );
        setOpenHours({ ...openHours });
      }
    }
  };

  const toggleHours = (day: string) => {
    if (openHours[day].hours.length === 1) {
      openHours[day].hours.push({
        open: { hour: 8, minute: 0, text: formatTime({ hour: 8, minute: 0 }) },
        close: {
          hour: 21,
          minute: 0,
          text: formatTime({ hour: 21, minute: 0 }),
        },
      });
    } else {
      openHours[day].hours.pop();
    }
    setOpenHours({ ...openHours });
  };

  const updateTimeText = (
    text: string,
    dayOfWeek: string,
    type: string,
    hourIndex: number
  ) => {
    openHours[dayOfWeek].hours[hourIndex][type].text = text;
    setOpenHours({ ...openHours });
  };

  useEffect(() => {
    updateHours(openHours);
  }, [openHours, updateHours]);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box width={1}>
      <InputLabel htmlFor={"hours"} className={classes.label}>
        {title}
      </InputLabel>
      {openHours !== undefined &&
        Object.entries(openHours).map(([dayOfWeek, value]) => {
          const hours: any = value;
          return (
            <Box
              key={dayOfWeek}
              onClick={(e: any) => {
                e.preventDefault();
                return false;
              }}
            >
              <Grid
                component="label"
                container
                alignItems="center"
                onClick={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
              >
                {locationSelected && (
                  <FormGroup>
                    <Grid
                      item
                      style={{ paddingRight: "1rem" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <FormControlLabel
                        control={
                          <DateSwitch
                            checked={hours.available}
                            onChange={handleChange}
                            name={dayOfWeek}
                          />
                        }
                        label={
                          <Grid
                            item
                            xs={3}
                            style={{
                              border: "1px solid transparent",
                              padding: 0,
                            }}
                          >{`${dayOfWeek
                            .charAt(0)
                            .toUpperCase()}${dayOfWeek.slice(1)}:`}</Grid>
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                  </FormGroup>
                )}
                {!hours.available && (
                  <Grid item>{hours.available ? "Open" : "Closed"}</Grid>
                )}
                {!locationSelected && hours.hours !== undefined && (
                  <Grid item xs={6}>
                    <Typography style={{ lineHeight: "unset" }}>
                      {`${hours.hours[0].open.text} - ${hours.hours[0].close.text}` +
                        (hours.hours.length > 1
                          ? `, ${hours.hours[1].open.text} - ${hours.hours[1].close.text}`
                          : "")}
                    </Typography>
                  </Grid>
                )}

                {locationSelected &&
                  hours.available &&
                  hours.hours !== undefined && (
                    <Grid
                      item
                      onClick={(e: any) => {
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        onClick={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <Grid
                          item
                          xs={5}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          onClick={(e: any) => {
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            onClick={(e: any) => {
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <Box
                              onClick={(e: any) => {
                                e.preventDefault();
                              }}
                            >
                              <Input
                                inputProps={{ style: { padding: 0 } }}
                                disableUnderline={true}
                                value={hours.hours[0].open.text}
                                name={`${dayOfWeek}-open`}
                                margin="none"
                                onBlur={(e) =>
                                  updateTime(
                                    e.target.value,
                                    dayOfWeek,
                                    "open",
                                    0
                                  )
                                }
                                onChange={(e) =>
                                  updateTimeText(
                                    e.target.value,
                                    dayOfWeek,
                                    "open",
                                    0
                                  )
                                }
                              />
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography style={{ lineHeight: "unset" }}>
                                -
                              </Typography>
                            </Box>
                            <Box
                              onClick={(e: any) => {
                                e.preventDefault();
                              }}
                            >
                              <Input
                                inputProps={{ style: { padding: 0 } }}
                                disableUnderline={true}
                                value={hours.hours[0].close.text}
                                name={`${dayOfWeek}-close`}
                                margin="none"
                                onBlur={(e) =>
                                  updateTime(
                                    e.target.value,
                                    dayOfWeek,
                                    "close",
                                    0
                                  )
                                }
                                onChange={(e) =>
                                  updateTimeText(
                                    e.target.value,
                                    dayOfWeek,
                                    "close",
                                    0
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                        {hours.hours.length > 1 ? (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography style={{ lineHeight: "unset" }}>
                              {", "}
                            </Typography>
                          </Box>
                        ) : (
                          <Button
                            style={{
                              color: "#29ABE3",
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            size="small"
                            onClick={() => toggleHours(dayOfWeek)}
                          >
                            Add Hours
                          </Button>
                        )}
                        {hours.hours.length === 2 && (
                          <Grid
                            item
                            xs={6}
                            sm={5}
                            md={4}
                            lg={4}
                            xl={4}
                            style={{ display: "flex", flexDirection: "row" }}
                            onClick={(e: any) => {
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              onClick={(e: any) => {
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Box
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Input
                                  inputProps={{ style: { padding: 0 } }}
                                  disableUnderline={true}
                                  value={hours.hours[1].open.text}
                                  name={`${dayOfWeek}-open`}
                                  margin="none"
                                  onBlur={(e) =>
                                    updateTime(
                                      e.target.value,
                                      dayOfWeek,
                                      "open",
                                      1
                                    )
                                  }
                                  onChange={(e) =>
                                    updateTimeText(
                                      e.target.value,
                                      dayOfWeek,
                                      "open",
                                      1
                                    )
                                  }
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Typography style={{ lineHeight: "unset" }}>
                                  -
                                </Typography>
                              </Box>
                              <Box
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Input
                                  inputProps={{ style: { padding: 0 } }}
                                  disableUnderline={true}
                                  value={hours.hours[1].close.text}
                                  name={`${dayOfWeek}-close`}
                                  margin="none"
                                  onBlur={(e) =>
                                    updateTime(
                                      e.target.value,
                                      dayOfWeek,
                                      "close",
                                      1
                                    )
                                  }
                                  onChange={(e) =>
                                    updateTimeText(
                                      e.target.value,
                                      dayOfWeek,
                                      "close",
                                      1
                                    )
                                  }
                                />
                              </Box>
                            </Box>
                            <Grid item>
                              <IconButton
                                color="inherit"
                                onClick={() => toggleHours(dayOfWeek)}
                                style={{ padding: 0 }}
                              >
                                <CloseIcon
                                  style={{ height: ".75em", width: ".75em" }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )
        })}
    </Box>
  );
}

export default withStyles(styles)(StoreHours);
