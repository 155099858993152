import {
  createStyles,
  withStyles,
  Theme,
  Box,
  Typography,
  Tabs,
  AppBar,
  Tab,
  useTheme,
  useMediaQuery,
  Card,
} from "@material-ui/core";
import { auth } from "firebase/app";
import React, { useEffect, useState } from "react";
import CategoryList from "src/components/AdminTools/CategoryList";
import ExperiencesPanel from "src/components/AdminTools/ExperiencesPanel";
import PartnersList from "src/components/AdminTools/PartnersList";
import PromoCodeList from "src/components/AdminTools/PromoCodeList";
import TransactionsList from "src/components/AdminTools/TransactionsList";
import { getPartnerId } from "src/services/auth";
import getData from "src/services/getData";
import { delay } from "src/services/util";

const styles = (theme: Theme) =>
  createStyles({
    headerText: {
      color: "black",
      fontWeight: 900,
      fontSize: "30px",
      lineHeight: "36px",
    },
    experienceCard: {
      width: "300px",
      height: "285px",
      boxShadow:
        "0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802",
    },
    card: {
      [theme.breakpoints.up("md")]: {
        background: "transparent",
        boxShadow: "none !important",
      },
      [theme.breakpoints.down("sm")]: {
        background: "white",
        boxShadow: "none !important",
      },
    },
  });

enum TabIndex {
  experiences = 0,
  partners = 1,
  transactions = 2,
  categories = 3,
  promoCodes = 4,
}

function AdminToolsPage(props: any) {
  const {
    classes,
    currentUser,
    setToken,
    partnerId,
    setPartnerId,
    reloadExperiences,
    cards,
    isAdmin,
    updateBoosts,
    liveBoosts,
    experienceCategories,
  } = props;

  const [index, setIndex] = useState(0);
  const [currentToken, setCurrentToken] = useState(currentUser);

  const [isLoading, setIsLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [editedExperiences, setEditedExperiences] = useState([]);
  const [pendingBoosts, setPendingBoosts] = useState([]);
  const [pendingBoostsWithPartnerId, setPendingBoostsWithPartnerId] = useState(
    []
  );
  const [actionItems, setActionItems] = useState([]);

  const handleTabChange = (event: any, newIndex: any) => {
    setIndex(newIndex);
  };

  function allyProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  async function getExperiencesSeekingApproval() {
    setExperiences([]);
    setEditedExperiences([]);
    setPendingBoosts([]);
    setPendingBoostsWithPartnerId([]);
    setIsLoading(true);
    try {
      const response = await getData(
        "action-items",
        "GET",
        props.currentUser,
        {},
        {
          type: "approval",
        },
        true
      );
      setExperiences(response.experiences);
      setEditedExperiences(response.editedExperiences);
      setIsLoading(false);
      setPendingBoosts(
        response.pendingBoosts.map((boost: any) => boost.boosts)
      );
      setPendingBoostsWithPartnerId(response.pendingBoosts);
      setActionItems(
        response.experiences ?? [] + response.editedExperiences ?? []
      );
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    getExperiencesSeekingApproval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function changePartner(partnerId: string, quickEdit: boolean) {
    try {
      await getData(
        `partners/${partnerId}/customClaim`,
        "PATCH",
        currentUser,
        {},
        {},
        true
      );
      let token: string | undefined;
      let attempts: number = 0;
      while (attempts < 5) {
        token = await auth().currentUser?.getIdToken(true);
        let tokenPartnerId = await getPartnerId(token);
        if (tokenPartnerId === partnerId) break;
        attempts++;
        await delay(1000);
      }
      if (token) {
        partnerId = await getPartnerId(token);
        if (partnerId === "") {
          partnerId = currentUser.uid;
        }
        setCurrentToken(token);
        if (partnerId !== null && !quickEdit) {
          setToken(token);
          setPartnerId(partnerId);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Card
        className={classes.card}
        style={{ boxShadow: "none", overflow: "visible" }}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              pt={!xs && !sm ? 0 : 2}
              pl={!xs && !sm ? 0 : 2}
            >
              <Box display="flex" flexDirection="column">
                <Typography className={classes.headerText}>
                  Admin Tools
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <AppBar
          position="static"
          style={{
            background: "transparent",
            boxShadow: "none",
            borderBottom: "1px solid #D6D6D6",
          }}
        >
          <Tabs
            value={index}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#1E1029" } }}
          >
            <Tab
              label="Approvals"
              {...allyProps(0)}
              style={
                index === TabIndex.experiences
                  ? {
                      fontWeight: 900,
                      fontSize: "15px",
                      color: "black",
                    }
                  : {
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "black",
                    }
              }
            />
            <Tab
              label="Partner's List"
              {...allyProps(1)}
              style={
                index === TabIndex.partners
                  ? {
                      fontWeight: 900,
                      fontSize: "15px",
                      color: "black",
                    }
                  : {
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "black",
                    }
              }
            />
            <Tab
              label="Transactions"
              {...allyProps(2)}
              style={
                index === TabIndex.transactions
                  ? {
                      fontWeight: 900,
                      fontSize: "15px",
                      color: "black",
                    }
                  : {
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "black",
                    }
              }
            />
            <Tab
              label="Sections"
              {...allyProps(3)}
              style={
                index === TabIndex.categories
                  ? {
                      fontWeight: 900,
                      fontSize: "15px",
                      color: "black",
                    }
                  : {
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "black",
                    }
              }
            />
            <Tab
              label="Promo Codes"
              {...allyProps(4)}
              style={
                index === TabIndex.promoCodes
                  ? {
                      fontWeight: 900,
                      fontSize: "15px",
                      color: "black",
                    }
                  : {
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "black",
                    }
              }
            />
          </Tabs>
        </AppBar>
        <ExperiencesPanel
          value={index}
          index={TabIndex.experiences}
          currentUser={currentToken}
          classes={classes}
          reloadExperiences={reloadExperiences}
          setSelectedExperienceBoost={() => {}}
          changePartner={(id: string) => changePartner(id, true)}
          cards={cards}
          liveBoosts={liveBoosts}
          isAdmin={isAdmin}
          updateBoosts={updateBoosts}
          theme={theme}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          experiences={experiences}
          setExperiences={setExperiences}
          editedExperiences={editedExperiences}
          setEditedExperiences={setEditedExperiences}
          pendingBoosts={pendingBoosts}
          setPendingBoosts={setPendingBoosts}
          actionItems={actionItems}
          setActionItems={setActionItems}
          getExperiencesSeekingApproval={getExperiencesSeekingApproval}
          experienceCategories={experienceCategories}
          partnerId={partnerId}
        ></ExperiencesPanel>
        {index === TabIndex.partners && (
          <PartnersList
            currentUser={currentToken}
            theme={theme}
            changePartner={(id: string) => changePartner(id, false)}
            initialPendingBoosts={pendingBoostsWithPartnerId}
          ></PartnersList>
        )}
        {index === 2 && <TransactionsList currentUser={props.currentUser} />}
        {index === 3 && <CategoryList currentUser={props.currentUser} />}
        {index === 4 && <PromoCodeList currentUser={props.currentUser} />}
      </Card>
    </div>
  );
}

export default withStyles(styles)(AdminToolsPage);
