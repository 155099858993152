import {
  Box,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState, useEffect, useRef, RefObject } from "react";
import { labelText } from "./EditCreatePromoCode";
import getData from "src/services/getData";
import _ from "lodash";
import { formatCurrencyAsDollars } from "src/services/util";

export interface ITargetedExperienceSection {
    currentUser: string;
    targetExperienceId?: string | null;
    targetExperienceTitle?: string | null;
    targetExperienceSet: (exprienceId: string | null, experienceTitle: string | null) => void;
}

export interface IExperienceSuggestions {
    experiences: IExperienceSuggestion[];
    callback(experience: IExperienceSuggestion): void;
    showPopup: boolean;
}

interface IExperienceSuggestionRow {
    experience: IExperienceSuggestion;
    callback(experience: IExperienceSuggestion): void;
}

export interface IExperienceSuggestion {
    experienceId: string;
    title: string;
    partnerName?: string;
    price?: number;
}

const row = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
};

const column = {
    display: "flex",
    flexDirection: "column",
};

const headerText = {
    fontWeight: 700,
    fontSize: "18px",
}

const bodyText = {
    fontWeight: 400,
    fontSize: "15px",
}

const expand = {
    width: 550,
}

export function TargetedExperienceSection(props: ITargetedExperienceSection) {
    const { currentUser } = props;
    const [experiences, setExperiences] = useState<IExperienceSuggestion[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        setSearchString(props.targetExperienceTitle ?? '');
    }, [props.targetExperienceTitle]);

    // Used to listen for clicks outside of current component, in order to
    // close the suggestions popup.  And used to give up-to-date state to
    // that handler
    const stateRef = useRef<string | null>();
    stateRef.current = props.targetExperienceTitle;
    const targetExperienceSuggestionsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleOutsideClick(event: { target: any; }) {
            if (targetExperienceSuggestionsRef.current &&
                    !(targetExperienceSuggestionsRef.current.contains(event.target)))
            {
                setShowPopup(false);
                // only reset the search string if the target experience
                // is not set
                if (!stateRef.current) {
                    setSearchString('');
                }
                return;
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
        // Clean up
        document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [targetExperienceSuggestionsRef]);

    useEffect(() => {
        if (!!props.targetExperienceTitle) return;
        debounceFetchAndSetExperiences();
        return;
    }, [searchString]);

    let fetchExperiencesTimer: NodeJS.Timeout;
    async function debounceFetchAndSetExperiences() {
        clearTimeout(fetchExperiencesTimer);
        fetchExperiencesTimer = setTimeout(fetchAndSetExperiences, 300);
        return;
    }
    async function fetchAndSetExperiences() {
        if (searchString.length <= 0) {
            setExperiences([]);
            return;
        }
        try {
            const response = await getData(
                `experiences/experience-suggestions`,
                "GET",
                currentUser,
                {},
                {
                    searchString: searchString
                },
                true
            );
            setExperiences(response);
        } catch (error) {
            setExperiences([]);
            setSearchString('');
            console.error(error);
        }
        return;
    }
    return (
        <div ref={targetExperienceSuggestionsRef}>
            <Box >
                <Typography style={labelText}>EXPERIENCE</Typography>
                <TextField
                    value={searchString}
                    style={{ position: "relative" }}
                    placeholder="Search experience"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={(event) => {
                        // allow backspacing of current target experience
                        if (!!props.targetExperienceTitle) {
                            props.targetExperienceSet(null, null);
                            setSearchString('');
                        }
                        else {
                            setShowPopup(true);
                            setSearchString(event.target.value!);
                        }
                    }}
                />
                <ExperienceSuggestions
                    experiences={experiences}
                    callback={(experience: IExperienceSuggestion) => {
                        props.targetExperienceSet(experience.experienceId, experience.title);
                        setSearchString(experience.title);
                        setShowPopup(false);
                    }}
                    showPopup={showPopup}
                />
            </Box>
        </div>
    );
}

export function ExperienceSuggestions(props: IExperienceSuggestions) {
    const { experiences, callback, showPopup } = props;
    return showPopup ? (
        <Box
            border={1}
            borderColor={"#D6D6D6"}
            borderRadius={7}
            style={{
                marginTop: 6,
                width: "93.5%",
                overflow: "hidden",
                position: "absolute",
                zIndex: 1
            }}
            sx={{ ...column }}>
            {Array.isArray(experiences) && experiences.length > 0
                ? experiences.map((item: IExperienceSuggestion) =>
                    <ExperienceSuggestionRow
                        key={item.experienceId}
                        experience={item}
                        callback={callback}
                    />
                )
                : (
                    <Box style={{ backgroundColor: '#FFF', paddingTop: 10, paddingBottom: 10 }}>
                        <Typography
                            align={'center'}
                            style={{
                                ...labelText,
                                paddingBottom: 0,
                                color: "#d3d3d3",
                            }}>
                            No results found
                        </Typography>
                    </Box>
                )
            }
        </Box>
    ) : <></>
}

function ExperienceSuggestionRow(props: IExperienceSuggestionRow) {
    const { experience, callback } = props;
    return (
        <Box
            sx={{ ...row, ...expand, padding: 16 }}
            style={{ background: "#FFF" }}>
            <Box sx={column}>
                <Typography style={headerText}>{experience?.title}</Typography>
                <Box sx={row} >
                    <Typography style={{ ...bodyText, marginRight: 8 }}>{experience?.partnerName}</Typography>
                    <Typography style={bodyText}>{formatCurrencyAsDollars(experience?.price ?? 0)}</Typography>
                </Box>
            </Box>
            <Button
                variant="contained"
                disableElevation
                style={{
                    backgroundColor: '#d3d3d3',
                    color: '#000',
                }}
                onClick={() => callback!(experience)}
            >
                Choose
            </Button>
        </Box>
    );
}