import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  Theme,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import getData from "src/services/getData";
import Dropdown from "react-dropdown";
import { useState } from "react";
import CurrencyInput from "../CurrencyInput";
import { boxShadow } from "../../services/util";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "500px",
      maxWidth: "500px",
      minHeight: "95%",
      padding: "20px",
    },
    saveButton: {
      width: "100%",
      height: "50px",
      backgroundColor: "#00FF94",
      borderRadius: "5px",
      padding: "6px 8px",
      boxShadow: boxShadow,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
    cancelButton: {
      width: "100%",
      height: "50px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "6px 8px",
      boxShadow: boxShadow,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
    title: {
      fontSize: "24px",
      color: "background: #000000",
      fontWeight: 700,
      marginBottom: "20px",
    },
    subtitle: {
      fontSize: "14px",
      color: "#5C5C5C",
      fontWeight: 700,
    },
    value: {
      fontSize: "16px",
      color: "#000000",
      fontWeight: 700,
      margin: "6px 0px 18px 0px",
      width: "100%",
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    dropDownBox: {
      width: "50%",
    },
  });

function RefundDialog(props: any) {
  const { theme, classes, setOpen, purchase, getPartners } = props;

  const [refundType, setRefundType] = useState<string>("credit");
  const [refundAmountType, setRefundAmountType] = useState<string>("full");
  const [refundAmount, setRefundAmount] = useState<number>(
    purchase.paymentAmount
  );

  const refundDropdownTypes = [
    { label: "Credit Refund", value: "credit" },
    { label: "Stripe Refund", value: "stripe" },
  ];
  const refundDropdownAmountType = [
    { label: "Full Refund", value: "full" },
    { label: "Partial Refund", value: "partial" },
  ];

  async function issueRefund() {
    try {
      await getData(
        `purchases/${purchase.purchaseId}/refund`,
        "POST",
        props.currentUser,
        {},
        {
          credit: refundType === "credit",
          userId: purchase.userId,
        },
        true
      );
      getPartners();
    } catch (error) {
      console.error(error);
    }
    setOpen(false);
  }

  function changeRefundType(refundType: any) {
    setRefundType(refundType.value);
  }

  function changeRefundAmountType(refundAmountType: any) {
    if (refundAmountType.value === "full") {
      setRefundAmount(purchase.paymentAmount);
    }
    setRefundAmountType(refundAmountType.value);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Card className={classes.root} elevation={0}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Box
            style={{ height: "300px", width: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div></div>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography className={classes.title}>
                Date Experience Refund
              </Typography>
            </Box>
            <div>
              <Box
                display="flex"
                flexDirection="column"
                className={classes.dropDownBox}
              >
                <Typography className={classes.subtitle}>
                  Refund Type
                </Typography>
                <FormControl className={classes.inputField}>
                  <Dropdown
                    controlClassName={classes.dropdownSelector}
                    placeholderClassName={classes.dropDownOption}
                    menuClassName={classes.dropdown}
                    className={classes.dropDownOption}
                    options={refundDropdownTypes}
                    value={refundType}
                    onChange={changeRefundType}
                  />
                </FormControl>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                paddingY={theme.spacing(0.25)}
              >
                <Typography className={classes.subtitle}>
                  Refund Amount
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Box paddingRight={theme.spacing(0.25)}>
                    <Dropdown
                      controlClassName={classes.dropdownSelector}
                      placeholderClassName={classes.dropDownOption}
                      menuClassName={classes.dropdown}
                      className={classes.dropDownOption}
                      options={refundDropdownAmountType}
                      value={refundAmountType}
                      onChange={changeRefundAmountType}
                    />
                  </Box>
                  <CurrencyInput
                    aria-describedby="Refund Amount"
                    onValueChange={(value: any) => {
                      setRefundAmount(value);
                    }}
                    value={refundAmount}
                    index={0}
                    disabled={refundAmountType === "full"}
                    placeholder={"Price"}
                    id="price"
                    max={purchase.paymentAmount}
                    classes={classes}
                  />
                </Box>
              </Box>
            </div>
            <Box
              display="flex"
              flexDirection="column"
              alignSelf="center"
              justifyContent="end"
              width="100%"
            >
              <Button
                className={classes.saveButton}
                onClick={() => {
                  issueRefund();
                }}
              >
                ISSUE REFUND
              </Button>
              <Box padding={theme.spacing(0.1)}></Box>
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                CANCEL
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(RefundDialog));
