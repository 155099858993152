import { Box, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";
import { notNumbersRegex } from "src/services/util";

const lightBorder = "rgba(214, 214, 214, 1)";
interface INumberInputProps {
  defaultValue?: number;
  inputKey: string;
  onChange: (newValue: number) => void;
}

export function NumberInput(props: INumberInputProps) {
  const maxNumbersAllowed = 15;

  /// handles changes when the editing mode is set to Percent
  function handleNumberInput(event: ChangeEvent<HTMLInputElement>) {
    let inputString = event.target.value;
    inputString = inputString.replaceAll(notNumbersRegex, "");
    if (inputString.length > maxNumbersAllowed) {
      inputString = inputString.slice(0, maxNumbersAllowed);
    }
    //double cast to get rid of leading 0
    event.target.value = String(Number(inputString));
    props.onChange(Number(inputString));
  }
  return (
    <Box
      sx={{
        border: `1px solid ${lightBorder}`,
        borderRadius: 7,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <TextField
        key={props.inputKey}
        value={props.defaultValue}
        onChange={handleNumberInput}
        defaultValue={"0"}
      />
    </Box>
  );
}
