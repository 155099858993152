import {
  Box,
  Card,
  CardContent,
  createStyles,
  IconButton,
  Theme,
  Typography,
  withStyles,
  withTheme,
  Dialog,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { Spinner } from "react-activity";
import { isNil } from "lodash";

import getData from "../../services/getData";
import CloseIcon from "@material-ui/icons/Close";
import { boxShadow } from "../../services/util";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: "95%",
      width: "500px",
    },
    profileImage: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      objectFit: "cover",
      background: "#E6E6E6",
    },
    hoverButton: {
      backgroundColor: "rgba(255,255,255,1)",
      "&:hover": {
        backgroundColor: "rgba(0,255,148,0.4)",
      },
    },
    headerText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    confirmDialog: {
      minHeight: "95%",
      width: "350px",
    },
    button: {
      width: "100%",
      height: "50px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "6px 8px",
      boxShadow: boxShadow,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
  });

function AssignExperience(props: any) {
  const {
    theme,
    classes,
    currentUser,
    setOpenAssign,
    experienceToAssign,
    reloadExperiences,
    updateSelectedLocation,
    experiences,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState<any>([]);

  useEffect(() => {
    getPartners();
  }, []);

  async function getPartners() {
    setIsLoading(true);
    try {
      const response = await getData(
        "partners",
        "GET",
        props.currentUser,
        {},
        {},
        true
      );
      setPartners(response);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    setIsLoading(false);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Card className={classes.root} elevation={0}>
        <div style={{ position: "absolute", top: "0.5em", right: "1em" }}>
          <IconButton
            color="inherit"
            onClick={() => setOpenAssign(false)}
            style={{ padding: 0 }}
          >
            <CloseIcon style={{ height: "1em", width: "1em" }} />
          </IconButton>
        </div>
        <CardContent>
          <Box display="flex" justifyContent="center" pt={1} pb={2}>
            <Typography className={classes.headerText}>
              Select a partner
            </Typography>
          </Box>

          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              m={5}
              style={{ padding: "10px 15px" }}
            >
              <Spinner />
            </Box>
          )}

          {!isNil(partners) && partners.length > 0 ? (
            partners.map((partner: any, index: number) => (
              <PartnerListItem
                key={index}
                index={index}
                classes={classes}
                selectedPartner={partners[index]}
                theme={theme}
                experienceToAssign={experienceToAssign}
                currentUser={currentUser}
                setOpenAssign={setOpenAssign}
                reloadExperiences={reloadExperiences}
                updateSelectedLocation={updateSelectedLocation}
                experiences={experiences}
              />
            ))
          ) : (
            <Box display="flex" justifyContent="center">
              {!isLoading && (
                <Typography>There are no partners available</Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

function PartnerListItem(props: any) {
  const {
    index,
    selectedPartner,
    classes,
    theme,
    experienceToAssign,
    currentUser,
    setOpenAssign,
    reloadExperiences,
    updateSelectedLocation,
    experiences,
  } = props;
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  async function assignExperienceToPartner() {
    try {
      await getData(
        `partners/${selectedPartner.partnerId}/assign-experience/${experienceToAssign.experienceId}`,
        "POST",
        currentUser,
        {},
        undefined,
        true
      );
      setOpenConfirm(false);
      setOpenAssign(false);
    } catch (error) {
      console.error(error);
    }
    updateSelectedLocation(
      experiences.length > 1 ? experienceToAssign.locationId : ""
    );
    reloadExperiences();
  }

  return (
    <div>
      <Box
        key={index}
        onClick={() => {
          setOpenConfirm(true);
        }}
      >
        <Card
          style={{
            borderRadius: "0px",
            border: "1px solid #E6E6E6",
            cursor: "pointer",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ padding: "10px 15px", borderRadius: "0px" }}
            className={classes.hoverButton}
          >
            <Box style={{ width: "60px", fontWeight: "bold" }}>
              {selectedPartner.profileImage?.url && (
                <img
                  src={selectedPartner.profileImage.url}
                  className={classes.profileImage}
                  alt=""
                />
              )}
              {!selectedPartner.profileImage?.url && (
                <div className={classes.profileImage} />
              )}
            </Box>
            <Typography style={{ width: "50%", fontWeight: "bold" }}>
              {selectedPartner.fullName ?? "No name available"}
            </Typography>
            <Typography style={{ width: "40%" }}>
              {selectedPartner.businessName ?? "No location name"}
            </Typography>
          </Box>
        </Card>
      </Box>
      <Dialog
        open={openConfirm}
        onClose={() => {}}
        scroll={"body"}
      >
        <Box p={4} flexDirection="column" justifyContent="center">
          <Typography style={{ textAlign: "center" }}>
            You are assigning <b>{experienceToAssign?.title ?? "No title"}</b>{" "}
            to <b>{selectedPartner.fullName ?? "no name available"}</b>
          </Typography>
          <Box padding={theme.spacing(0.2)}></Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Button
              className={classes.button}
              onClick={() => assignExperienceToPartner()}
            >
              CONFIRM
            </Button>
            <Box padding={theme.spacing(0.1)}></Box>
            <Button
              className={classes.button}
              onClick={() => setOpenConfirm(false)}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default withTheme(withStyles(styles)(AssignExperience));
