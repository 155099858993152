import { Box } from "@material-ui/core";

const lightGreen = "rgba(7, 203, 109, 1)";
const greyBorder = "rgba(186, 186, 186, 1)";
const greyFilling = "rgba(229, 229, 229, 1)";

interface ICheckBoxProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export function CustomCheckBox(props: ICheckBoxProps) {
  return (
    <Box
      onClick={() => {
        props.onChange(!props.value);
      }}
    >
      {props.value ? (
        <Box
          sx={{
            height: 15,
            width: 15,
            bgcolor: lightGreen,
            borderRadius: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ height: 5, width: 5, bgcolor: "white" }} />
        </Box>
      ) : (
        <Box
          sx={{
            height: 15,
            width: 15,
            border: `1px solid ${greyBorder}`,
            borderRadius: 3,
            bgcolor: greyFilling,
          }}
        />
      )}
    </Box>
  );
}
