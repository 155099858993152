import {
	Box,
	Button,
	createStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
	withStyles,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { isNil } from "lodash";
import { formatCurrencyAsDollars } from "src/services/util";
import { showGlobalSettingsDialog } from "./PromoCode/GlobalSettings";
import { showPromoDialog } from "./PromoCode/EditCreatePromoCode";
import { Discount, PromoCode } from "./PromoCode/PromoInterfaceEnums";
import getData from "src/services/getData";

const styles = (theme: Theme) =>
	createStyles({
		label: {
			fontSize: "24px",
			lineHeight: "17px",
			color: "#5C5C5C",
			paddingBottom: theme.spacing(1),
			fontFamily: "Lato",
			marginTop: 10,
		},
		horizontalSpace: {
			marginTop: 20,
		},
		verticalSpace: {
			marginLeft: 20,
		},
		verticalIcon: {
			color: "#0B0A0A",
		},
		table: {
			marginTop: 10,
		},
		tableHeader: {
			fontSize: 13,
			marginTop: 30,
			paddingLeft: 20,
			color: "#5C5C5C",
		},
		tableHeadCell: {
			width: "100%",
			marginBottom: 10,
			fontWeight: 700,
		},
		tableRow: {
			fontSize: 14,
			borderRadius: 1,
			border: "1px solid #d3d3d3",
			paddingLeft: 20,
			paddingTop: 15,
			paddingBottom: 15,
			marginBottom: 3,
			marginTop: 3,
			borderEndEndRadius: 10,
			borderStartStartRadius: 10,
			borderEndStartRadius: 10,
			borderStartEndRadius: 10,
			color: "#5C5C5C",
		},
		tableBodyCell: {
			width: "100%",
			fontWeight: 500,
		},
		createNewPromoButton: {
			height: 40,
			backgroundColor: "#2ABC12",
			color: "#FFF",
			fontWeight: "bold",
			fontSize: 14,
			letterSpacing: 0.7,
		},
		globalSettingButton: {
			backgroundColor: "#1E1029",
			height: 40,
			fontSize: 14,
			fontWeight: "bold",
			color: "#FFF",
			marginRight: 10,
		},
		dropdownSelector: {
			boxSizing: "border-box",
			borderRadius: "10px",
		},
		dropdown: {
			boxSizing: "border-box",
			borderRadius: "10px",
			padding: `0px ${theme.spacing(2)}px`,
		},
		dropDownOption: {
			fontSize: "16px",
			lineHeight: "19px",
			color: "#000000",
			fontWeight: "bold",
		},
		dropdownArrow: {
			color: "#29abe2",
			"&:before": {
				color: "#C4C4C4",
			},
			"&:after": {
				color: "#29abe2",
			},
		},
	});

enum PromoPopUpState {
	closed = 0,
	createPromo = 1,
	editPromo = 2,
}

function PromoCodeList(props: any) {
	const { classes } = props;
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [promos, setPromos] = useState<PromoCode[]>([]);
	const [pages, setPages] = useState<PromoCode[][]>([]);
	const [page, setPage] = useState<number>(1);
	const [filter, setFilter] = useState<string>('All');
	const [editablePromoCode, setEditablePromoCode] = useState<PromoCode>();
	const [showCreateEditPromoPopup, setShowCreateEditPromoPopup] =
		useState<PromoPopUpState>(PromoPopUpState.closed);
	const [globalSettingShown, setGlobalSettingShown] = useState<boolean>(false);
	const availableFilters = ["All", "Valid", "Expired"];
	const nPerPage = 7;


	useEffect(() => {
		fetchAllPromoCodes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchAllPromoCodes(filterString?: string) {
		try {
			const response = await getData(
				"promo-codes",
				"GET",
				props.currentUser,
				{},
				undefined,
				true
			);
			setupPages(response, filterString);
		} catch (error) {
			console.error(error);
		}
	}

	const handlePageChange = (event: any, value: any) => {
		setPage(value);
	}

	// Calculate number of pages
	const handlePageCount = () => {
		return Math.ceil(promos.length / nPerPage);
	}

	function setupPages(promoList: PromoCode[], filterString?: string) {
		setPage(handlePageCount() < page ? 1 : page);
		if (!isNil(filterString)) {
			setPage(1); // Reset to the first page when a new filter is selected
			if (filterString === 'Expired') {
				promoList = promoList.filter((entry: PromoCode) => !entry.live);
			} else if (filterString === 'Valid') {
				promoList = promoList.filter((entry: PromoCode) => entry.live);
			}
		}
		const tempPages = [];
		let index = 0;
		while (index <= promoList.length - 1) {
			tempPages.push(promoList.slice(index, index + nPerPage));
			index = index + nPerPage;
		}
		// Using an empty array as a placeholder to align the pages to the Pagination component
		tempPages.splice(0, 0, []);
		setPromos(promoList);
		setPages(tempPages);
	}

	// Display the discount as either type 0: fixed amount or 1: percentage
	const formatDiscount = (discount: Discount | undefined | null): string => {
		if (isNil(discount)) return '';
		return discount.type === 0
			? formatCurrencyAsDollars(discount.amount ?? 0)
			: `${discount.amount}%`;
	};

	const formatExpireDate = (expiredAt: Date | undefined | null): string => {
		return expiredAt ? format(new Date(expiredAt), 'MM/dd/yyyy') : 'N/A'
	}

	const createNewPromo = () => {
		setShowCreateEditPromoPopup(PromoPopUpState.createPromo);
	};

	function createDataCell(promo: PromoCode) {
		return (
			<>
				<Typography
					align="left"
					className={classes.tableBodyCell}
					style={{ color: promo.live ? "#07CB6D" : "#C7C7C7", fontWeight: 700 }}
				>
					{promo.promoCode}
				</Typography>
				<Typography align="left" className={classes.tableBodyCell}>
					{formatExpireDate(promo.expiredAt)}
				</Typography>
				<Typography align="left" className={classes.tableBodyCell} style={{ fontWeight: 500, color: "#0B0A0A" }}>
					{promo.description?.toUpperCase()}
				</Typography>
				<Typography align="center" className={classes.tableBodyCell}>
					{promo.creatorName}
				</Typography>
				<Typography align="center" className={classes.tableBodyCell}>
					{formatDiscount(promo.discount)}
				</Typography>
				<Typography align="center" className={classes.tableBodyCell}>
					{promo.redemptions}
				</Typography>
				<Typography align="center" className={classes.tableBodyCell}>
					{formatCurrencyAsDollars(promo.totalSumDiscounted)}
				</Typography>
			</>
		);
	}

	return (
		<div>
			<Box display="flex" flexDirection="column" justifyContent="center">
				<Box pl={mobile ? 2 : 0} pr={mobile ? 2 : 0} pt={mobile ? 1 : 0}>
					<div className={classes.horizontalSpace} />
					{
						showGlobalSettingsDialog(
							globalSettingShown,
							() => setGlobalSettingShown(false),
							props.currentUser
						)
					}
                    {/* dialog is for editing & creating of promos */
                        showPromoDialog(
                            showCreateEditPromoPopup !== PromoPopUpState.closed,
                            () => setShowCreateEditPromoPopup(PromoPopUpState.closed),
                            props.currentUser,
                            showCreateEditPromoPopup === PromoPopUpState.editPromo
                                ? editablePromoCode
                                : undefined,
							() => {
								// refetch promo codes to load new data
								fetchAllPromoCodes();
							}
                        )
                    }
					<Box
						display="flex"
						flexDirection="row"
						justifyContent={"space-between"}
					>
						<Box
							display="flex"
							flexDirection="row"
							alignContent={"center"}
							style={{ width: "50%" }}
						>
							<Typography className={classes.label}>
								Promotional codes
							</Typography>
							<div className={classes.verticalSpace} />
							<Box sx={{ width: "20%" }}>
								<Dropdown
									controlClassName={classes.dropdownSelector}
									placeholderClassName={classes.dropdownPlaceholder}
									arrowClassName={classes.dropdownArrow}
									menuClassName={classes.dropdown}
									className={classes.dropDownOption}
									options={availableFilters}
									value={filter}
									placeholder="Select one"
									onChange={(selection) => fetchAllPromoCodes(selection.value)}
								/>
							</Box>
						</Box>
						<Box
							style={{ width: "100%" }}
							display="flex"
							flexDirection="row"
							alignContent={"right"}
							justifyContent={"end"}
						>
							<Button
								variant="contained"
								disableElevation
								className={classes.globalSettingButton}
								onClick={() => setGlobalSettingShown(true)}
							>
								GLOBAL SETTINGS
							</Button>
							<Button
								variant="contained"
								disableElevation
								className={classes.createNewPromoButton}
								onClick={createNewPromo}
							>
								{"\u{002B}"} CREATE NEW PROMO CODE
							</Button>
						</Box>
					</Box>
				</Box>

				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					className={classes.tableHeader}
				>
					<Typography align="left" className={classes.tableHeadCell}>
						CODE
					</Typography>
					<Typography align="left" className={classes.tableHeadCell}>
						EXPIRES ON
					</Typography>
					<Typography align="left" className={classes.tableHeadCell}>
						CODE DESCRIPTION
					</Typography>
					<Typography align="center" className={classes.tableHeadCell}>
						CREATED BY
					</Typography>
					<Typography align="center" className={classes.tableHeadCell}>
						DISCOUNT
					</Typography>
					<Typography align="center" className={classes.tableHeadCell}>
						REDEMPTIONS
					</Typography>
					<Typography align="center" className={classes.tableHeadCell}>
						DISCOUNTED $
					</Typography>
				</Box>
				{Array.isArray(pages[page]) &&
					pages[page].map((_promoCode: PromoCode, index: number) => {
						return (_promoCode &&
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
								key={index}
								className={classes.tableRow}
								onClick={() => {
									setEditablePromoCode(_promoCode);
									setShowCreateEditPromoPopup(PromoPopUpState.editPromo);
								}}
							>
								{createDataCell(_promoCode)}
							</Box>
						);
					})}
				<Box display="flex" flexDirection="column" alignItems="center" style={{ marginTop: 20 }}>
					<Pagination count={handlePageCount()} page={page} onChange={handlePageChange} />
				</Box>
			</Box>
		</div>
	);
}

export default withStyles(styles)(PromoCodeList);
