import { createTheme, TextField, ThemeProvider } from "@material-ui/core";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};
declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey { }
}

// creates a single DatePicker With the styles we prefer.
// It is suggested to wrap the use of this components with
// <ThemeProvider theme={yourThemeHere}><ThemeProvider/>
export function CustomDateSelector(props: DatePickerProps) {
  return (
    <ThemeProvider theme={datePickerMUITheme}>
      <DatePicker
        disablePast={true}
        disableToolbar
        value={props.value}
        minDate={props.minDate}
        maxDate={props.maxDate}
        minDateMessage={props.minDateMessage}
        maxDateMessage={props.maxDateMessage}
        onChange={props.onChange}
        clearable={props.clearable ?? undefined}
        format="MM/dd/yyyy"
        TextFieldComponent={(textFieldProps) => {
          return (
            <TextField
              {...{
                ...textFieldProps,
                // InputProps vs inputProps
                // https://stackoverflow.com/questions/69870930/inputprops-vs-inputprops-in-mui-textfield
                inputProps: {
                  style: {
                    border: "1px solid rgba(186, 186, 186, 0.6)",
                    borderRadius: "10px",
                    fontSize: "15px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    padding: "8px 10px",
                    color: "#5C5C5C",
                  },
                  ...textFieldProps.inputProps,
                },
                InputProps: {
                  disableUnderline: true,
                  ...textFieldProps.InputProps,
                },
              }}
            />
          );
        }}
      />
    </ThemeProvider>
  );
}

export const datePickerMUITheme = createTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        color: "black",
        "&:hover": {
          backgroundColor: "rgba(0, 255, 148, .5)",
        },
      },
      daySelected: {
        color: "black",
        backgroundColor: "rgba(0, 255, 148, 1)", //light green
        "&:hover": {
          backgroundColor: "rgba(0, 255, 148, .5)",
        },
      },
      // dayDisabled: {
      //   color: "yellow",
      // },
      current: {
        color: "black",
        "&:hover": {
          backgroundColor: "rgba(0, 255, 148, .5)",
        },
      },
    },
  },
});
