import {
  Box,
  Button,
  CardActions,
  createStyles,
  Divider,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "30px",
      },
      fontWeight: 900,
      lineHeight: "29px",
    },
    subHeader: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      },
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: "29px",
    },
    divider: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "105%",
      },
      marginLeft: "0.5%",
      backgroundColor: "#BABABA",
    },
    editButton: {
      fontStyle: "italic",
      fontSize: "14px",
      lineHeight: "16.8px",
      color: "#2ABC12",
      fontWeight: 400,
    },
    saveButton: {
      color: "#13A3D5",
    },
  });

function InfoHeader(props: any) {
  const { classes, editInfo, infoError, header, saveInfo, showActions, isSubheader = false } = props;

  return (
    <Box display="flex" flexDirection="column" paddingBottom={"0.5rem"}>
      <Box
        width={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={!isSubheader ? classes.title : classes.subHeader}>{header}</Typography>
        {showActions && (
          <CardActions>
            <Button
              size="small"
              className={clsx(
                classes.editButton,
                editInfo ? classes.saveButton : ""
              )}
              onClick={() => saveInfo()}
              disabled={
                Object.values(infoError).filter((item) => item === true)
                  .length > 0
              }
            >
              {!editInfo ? "Edit" : "Save"}
            </Button>
          </CardActions>
        )}
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
}

export default withStyles(styles)(InfoHeader);
