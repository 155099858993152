// @ts-nocheck
import qs from "qs";
import React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { getPartnerId } from "../../services/auth";
import getData from "../../services/getData";

interface ProtectedRouteProps extends RouteProps {
  path: string;
  auth: any;
  locationId: string;
  setLocationId: Function;
}

export class ProtectedRoute extends Route<ProtectedRouteProps> {
  async parseProps() {
    if (
      this.props.location?.search !== undefined &&
      this.props.location?.search.length > 0
    ) {
      const params = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      const state = params.state?.toString();
      const stateSplit = state?.split("|||");
      if (stateSplit !== undefined && stateSplit.length === 2) {
        const code = {
          code: params.code,
          partnerId: stateSplit[0],
          locationId: stateSplit[1],
        };
        localStorage.setItem("code", JSON.stringify(code));
      }
    }
    if (this.props.auth !== null) {
      const code = localStorage.getItem("code");
      if (code !== null) {
        localStorage.removeItem("code");
        await this.submitToken(JSON.parse(code), this.props.auth);
      }
    }
  }

  async submitToken(code: any, user: any) {
    const token: string = await user.getIdToken(true);
    let partnerId = await getPartnerId(token);
    if (partnerId !== null && partnerId === code.partnerId) {
      let url = `payout-methods/${code.locationId}?type=stripe`;
      await getData(url, "POST", token, { stripeToken: code.code });
      localStorage.setItem("reload", code.locationId);
    }
  }

  constructor(props: any) {
    super(props);
    this.parseProps();
  }

  render() {
    return (
      <Route
        render={(props: RouteComponentProps) => {
          if (this.props.auth === null) {
            return <Redirect to={ROUTES.LOGIN} />;
          }

          if (this.props.component) {
            return React.createElement(this.props.component, {
              user: this.props.auth,
              locationId: this.props.locationId,
              setLocationId: this.props.setLocationId,
            });
          }

          if (this.props.render) {
            return this.props.render(props);
          }
        }}
      />
    );
  }
}
