
export const firebaseConfig = {
  apiKey: "AIzaSyA_ndn1mba2nGVaeBCEPrQr628rjVI7UcQ",
  authDomain: "mutual-experiences.firebaseapp.com",
  databaseURL: "https://mutual-experiences.firebaseio.com",
  projectId: "mutual-experiences",
  storageBucket: "mutual-experiences.appspot.com",
  messagingSenderId: "1094509152064",
  appId: "1:1094509152064:web:f74cef4642edd732019ca5",
  measurementId: "G-MW846CJZYJ"
};
export const devFirebaseConfig = {
  apiKey: "AIzaSyB6Mth3JwxO54fCIRYIQlGUJyoSb1089VU",
  authDomain: "mutualdates-dev.firebaseapp.com",
  projectId: "mutualdates-dev",
  storageBucket: "mutualdates-dev.appspot.com",
  messagingSenderId: "453083365415",
  appId: "1:453083365415:web:b908fc6c705cdb5f534e02"
};
