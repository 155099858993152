import React, { useEffect } from "react";
import { createStyles, Theme, useTheme, withStyles } from "@material-ui/core/styles";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import {
  BackButton,
  createObjectBasedOnFirst,
  NextButton,
  partnerBase,
} from "../../services/util";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import getData from "../../services/getData";

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      [theme.breakpoints.up("md")]: {
        background: "#FFFFFF",
        border: "1px solid #D9D9D9",
        position: "fixed",
      },
      [theme.breakpoints.down("sm")]: {
        background: "transparent",
        position: "relative",
      },
      padding: theme.spacing(2),
      bottom: 0,
      width: "100%"
    },
    nextButton: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#050505",
      textTransform: "uppercase",
    },
    backButton: {
      [theme.breakpoints.up("md")]: {
        color: "#050505",
      },
      [theme.breakpoints.down("sm")]: {
        color: "white",
      },
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "uppercase",
    },
    leftArrow: {
      [theme.breakpoints.up("md")]: {
        color: "#050505",
      },
      [theme.breakpoints.down("sm")]: {
        color: "white",
      },
    },
  });

function Footer(props: any) {
  const {
    classes,
    partner,
    partnerId,
    token,
    setPartner,
    activeNavPage,
    nextDisabled,
    saveInfo,
  } = props;

  useEffect(() => {
    if (activeNavPage !== undefined) {
      const _tempPartner = JSON.parse(JSON.stringify(partner));
      delete _tempPartner.profileImage;
      _tempPartner.signUpState.activePage = activeNavPage;
      setPageState(_tempPartner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNavPage]);

  const next = async () => {
    if (
      partner.signUpState.activePage === 1 &&
      partner.signUpState.page === 1
    ) {
      await saveInfo();
    } else if (
      partner.signUpState.activePage === 2 &&
      partner.signUpState.page === 2
    ) {
      await saveInfo(false);
    }
    const _tempPartner = JSON.parse(JSON.stringify(partner));
    delete _tempPartner.profileImage;
    if (_tempPartner.signUpState.activePage === 6) {
      _tempPartner.signUpState.doneSignUp = true;
    } else {
      if (
        _tempPartner.signUpState.activePage === _tempPartner.signUpState.page
      ) {
        _tempPartner.signUpState.page += 1;
        _tempPartner.signUpState.activePage = _tempPartner.signUpState.page;
      } else {
        _tempPartner.signUpState.activePage += 1;
      }
    }
    setPageState(_tempPartner);
  };

  const moveBack = () => {
    const _tempPartner = JSON.parse(JSON.stringify(partner));
    delete _tempPartner.profileImage;
    _tempPartner.signUpState.activePage -= 1;
    setPageState(_tempPartner);
  };

  const setPageState = async (tempPartner: any) => {
    try {
      delete tempPartner.profileImage;
      delete tempPartner.revenuePercent;
      const response = await getData(
        `partners/${partnerId}`,
        "PATCH",
        token,
        tempPartner
      );
      const _partner = createObjectBasedOnFirst(partnerBase, response);
      setPartner(_partner);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {
        partner.signUpState.activePage !== 8 || activeNavPage !== 8
          ?
          <footer className={classes.footer}>
            {partner !== undefined && (
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="row" flex={4}>
                  {partner.signUpState.activePage !== 1 && (
                    <BackButton
                      onClick={() => {
                        moveBack();
                      }}
                    >
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <KeyboardArrowLeftIcon className={classes.leftArrow}></KeyboardArrowLeftIcon>
                        <Typography className={classes.backButton}>Back</Typography>
                      </Box>
                    </BackButton>
                  )}
                </Box>
                <Box display="flex" flexDirection="row" flex={4}>
                  <NextButton
                    onClick={() => {
                      next();
                    }}
                    disabled={nextDisabled}
                    style={{
                      backgroundColor: nextDisabled
                        ? "rgba(0,255,148,.5)"
                        : "#00FF94",
                      borderColor: nextDisabled ? "rgba(0,255,148,.5)" : "#00FF94",
                    }}
                  >
                    <Typography className={classes.nextButton}>
                      {partner.signUpState.activePage === 6 ? "Finish" : "Next"}
                    </Typography>
                  </NextButton>
                </Box>
              </Box>
            )}
          </footer>
          : null}
    </>
  );
}

export default withStyles(styles)(Footer);
