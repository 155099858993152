import {
  createStyles,
  Grid,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import throttle from "lodash/throttle";
import React, { useState } from "react";
import getData from "../../services/getData";

interface PlaceType {
  name: string;
  address: string;
  location: LatLng;
  number: string;
  opening_hours: Hours;
}

interface LatLng {
  lat: number;
  lng: number;
}

interface Hours {
  open_now: boolean;
  periods: Period[];
}

interface Period {
  close: TimePeriod;
  open: TimePeriod;
}

interface TimePeriod {
  day: number;
  time: string;
}

const styles = (theme: Theme) => createStyles({});

function LocationSearch(props: any) {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<PlaceType[]>([]);
  const [blockedGeolocation, setBlockedGeolocation] = useState(false);
  const [latLng, setLatLng] = useState<any>();

  const getUsersLocation = () => {
    if (!blockedGeolocation && latLng === undefined) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setBlockedGeolocation(true);
        }
      );
    }
  };

  const getPlaces = React.useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          let url = `places?businessName=${request.input}`;
          if (latLng !== undefined) {
            url += `&lat=${latLng.lat}&lng=${latLng.lng}`;
          }
          const response = await getData(url, "GET", props.currentUser, {});
          callback(response);
        },
        1000
      ),
    [props.currentUser, latLng]
  );

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    getPlaces({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, getPlaces]);

  return (
    <Autocomplete
      id="googlePlacesSearch"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onFocus={() => getUsersLocation()}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        props.updateLocationFields(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            props.hasLocationId
              ? "Search to update"
              : "Search your business name here"
          }
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item xs>
              <span style={{ fontWeight: 700 }}>{option.name}</span>
              <Typography variant="body2" color="textSecondary">
                {option.address}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default withStyles(styles)(LocationSearch);
