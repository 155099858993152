import { Avatar, Box, CircularProgress, createStyles, Theme, Typography, withStyles } from '@material-ui/core';
import React, { useRef } from 'react';
import clsx from 'clsx';

const avatarSize = 6.5;
const styles = (theme: Theme) =>
  createStyles({
    large: {
      width: `${avatarSize}rem`,
      height: `${avatarSize}rem`,
      position: 'unset',
    },
    emptyProfilePhoto: {
      backgroundColor: '#0D0D0D',
    },
    uploadText: {
      fontSize: '12px',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    stack: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    stackItem: {
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
    },
    stackForeground: {
      marginLeft: '-100%',
      width: `${avatarSize}rem`,
      height: `${avatarSize}rem`,
      clipPath: `circle(${avatarSize / 2}rem at center)`,
    },
    editBannerText: {
      color: '#FFFFFF',
      textAlign: 'center'
    },
    editBanner: {
      backgroundColor: '#100F0F',
      opacity: .75,
      bottom: 0,
    },
  });


function EditableAvatar(props: any) {

  const {classes, uploadImage, uploadingImage, url} = props;

  const profileImgInputRef = useRef<any>();

  const selectImage = () => {
    if (profileImgInputRef !== null && profileImgInputRef.current !== null) {
      profileImgInputRef.current.click();
    }
  }

  return (
    <div>
      <input type="file" id="profileImg" style={{ display: 'none' }} accept=".jpg,.jpeg,.png,.gif" ref={profileImgInputRef}
        onChange={(e) => uploadImage(e.target.files)} />
      <Box display='flex' flexDirection='row' justifyContent='center' paddingLeft={uploadingImage || url === null ? 0 : `${avatarSize}rem`}>
        <div className={classes.stack} onClick={() => selectImage()}>
          <div className={clsx(classes.stackItem, classes.stackBackground)}>
            {uploadingImage ?
              <Avatar className={clsx(classes.large)}>
                <CircularProgress />
              </Avatar>
              : url === null ?
                <Avatar alt="Upload Photo" className={clsx(classes.large, classes.emptyProfilePhoto)} style={{cursor: 'pointer'}}>
                  <Box display='flex' flexDirection='column' justifyContent='center'>
                    <Typography align='center' className={classes.uploadText}>Upload</Typography>
                    <Typography align='center' className={classes.uploadText}>Photo</Typography>
                  </Box>
                </Avatar>
                : <Avatar alt="Garrett" src={url} className={classes.large} />}
          </div>
          {(!uploadingImage && url !== null) &&
            <div className={clsx(classes.stackItem, classes.stackForeground)} style={{cursor: 'pointer'}}>
              <div className={classes.editBanner}>
                <Typography className={classes.editBannerText}>edit</Typography>
              </div>
            </div>}
        </div>
      </Box>
    </div>);
}

export default withStyles(styles)(EditableAvatar);
