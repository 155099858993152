import React, { useEffect, useState } from "react"
import LoginForm from "../components/LoginForm";
import { withRouter } from "react-router-dom";
import Background from '../assets/landingBackground.png';
import ResetPassword from "../components/ResetPassword";

function LoginPage(props: any) {

  const [auth] = useState(props.auth)
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (props.auth !== null && props.auth !== undefined) {
      props.history.push('/home/location info');
    }
  }, [props]);

  useEffect(() => {
    if (auth !== null && auth !== undefined) {
      props.history.push('/home/location info');
    }
  }, [auth, props.history])

  var backgroundStyle = {
    background: "url(" + Background + "), #22112F",
    backgroundSize: 'cover',
    opacity: .9,
    width: '100vw',
    height: '100vh',
    minHeight: '100vh',
    position: 'absolute' as 'absolute',
    backgroundBlendMode: 'soft-light, normal',
    mixBlendMode: 'multiply' as 'multiply',
    backgroundPosition: 'center center',
  };

  return (
    <div>
      <div style={backgroundStyle}></div>
      <div>
        {resetPassword ? <ResetPassword setResetPassword={setResetPassword} />: <LoginForm {...props} setResetPassword={setResetPassword}/>}
      </div>
    </div>
  );
}

export default withRouter(LoginPage);
