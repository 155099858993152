import {
  Box,
  Card,
  createStyles,
  Divider,
  FormHelperText,
  InputLabel,
  Theme,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import Config from "../config";
import { ConfigKey } from "../config/config.enums";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import GenericFormInput from "../components/GenericFormInput/GenericFormInput";
import getData from "../services/getData";
import { createObjectBasedOnFirst, PaymentButton } from "../services/util";

const styles = (theme: Theme) =>
  createStyles({
    headerText: {
      [theme.breakpoints.up("md")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      fontWeight: 900,
      lineHeight: "36px",
    },
    locationText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "21.6px",
      color: "#5C5C5C",
    },
    headerDivider: {
      marginTop: theme.spacing(2),
      backgroundColor: "#D6D6D6",
    },
    label: {
      color: "#5C5C5C",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    infoText: {
      color: "#5C5C5C",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "18px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    buttonText: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "16.8px",
      textTransform: "uppercase",
      color: "#050505",
    },
    payoutMethodDropdown: {
      width: "50%",
      minWidth: "225px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    accountText: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "16.8px",
      textTransform: "uppercase",
      color: "#050505",
      paddingBottom: theme.spacing(2),
    },
    card: {
      [theme.breakpoints.up("md")]: {
        boxShadow: "none",
      },
      [theme.breakpoints.down("sm")]: {
        boxShadow:
          "0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802",
      },
    },
  });

function PayoutPage(props: any) {
  const {
    theme,
    classes,
    selectedLocation,
    currentUser,
    partnerId,
    partner,
    payoutMethods,
    updatePayoutMethods,
    updateSelectedLocation,
  } = props;

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    sleep(300).then(() => {
      window.dispatchEvent(new Event("storage"));
    });
  }, []);

  const openStripeConnect = () => {
    const baseUrl = "https://dashboard.stripe.com/express/oauth/authorize";
    const responseType = "code";
    const scope = "read_write";
    const clientId = Config.getValue(ConfigKey.STRIPE_CLIENT_ID);
    const url =
      `${baseUrl}?response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${partnerId}|||${selectedLocation.locationId}` +
      `&stripe_user[email]=${partner.email}&stripe_user[country]=US` +
      `&suggested_capabilities[]=transfers&stripe_user[product_description]=Mutual Date Provider`;
    window.open(url, "_self");
  };

  const editStripeConnect = async (payoutMethodId: string) => {
    try {
      const response = await getData(
        `payout-methods/${payoutMethodId}`,
        "GET",
        currentUser,
        {}
      );
      if (response.loginUrl !== undefined) {
        window.open(response.loginUrl, "_blank");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveCheck = async () => {
    const _address = createObjectBasedOnFirst(address, address);
    delete _address.city;
    delete _address.state;
    let url = `payout-methods/${selectedLocation.locationId}?type=check`;
    try {
      const results = await getData(url, "POST", currentUser, _address);
      if (!_.isNil(results.status)) {
        // TODO: can probably have some feedback before updatePayoutMethods is called.
        setGenericFormError("CANNOT DELIEVER TO THAT ADDRESS.");
      } else {
        await updatePayoutMethods(selectedLocation.locationId);
      }
    } catch (res: unknown) {
      if (res instanceof Error && res.message !== undefined) {
        setGenericFormError(res.message);
      }
    }
  };

  const setPayoutMethod = async (payoutMethodId: string) => {
    const type = payoutMethodId.includes("acct_") ? "stripe" : "check";
    let url = `payout-methods/${selectedLocation.locationId}?type=${type}`;
    await getData(url, "PATCH", currentUser, {
      payoutMethodId: payoutMethodId,
    });
    updateSelectedLocation(selectedLocation.locationId);
  };

  const findAccount = (accountId: string) => {
    let _account: any;
    _account = payoutMethods.lobAccounts?.find((account: any) => {
      return account.id === accountId;
    });
    if (!_.isNil(_account)) {
      return {
        payoutMethodId: _account.id,
        selectedMethod: "check",
      };
    }
    _account = payoutMethods.stripeAccounts?.find((account: any) => {
      return account.id === accountId;
    });
    if (!_.isNil(_account)) {
      return {
        payoutMethodId: _account.id,
        selectedMethod: "stripe",
      };
    }
  };

  let payoutMethodsOptions: any = [
    { value: 0, label: "Add Bank Account" },
    { value: 1, label: "Add Debit Card" },
    { value: 2, label: "Add Check" },
  ];
  const [payoutOptions, setPayoutOptions] = useState(payoutMethodsOptions);
  const [account, setAccount] = useState<any>();
  const [selectedOption, setSelectedOption] = useState(payoutMethodsOptions[0]);
  const [addressIsReal, setAddressIsReal] = useState<boolean>(false);
  const [address, setAddress] = useState<any>({
    company: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    state: "",
  });
  interface PlaceType {
    name: string;
    address: string;
    location: LatLng;
  }
  interface LatLng {
    lat: number;
    lng: number;
  }

  //gets a list of possible real addresses
  const getPlaces = React.useMemo(
    () =>
      _.throttle(
        async (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          let url = `places?businessName=${request.input}`;

          const response = await getData(url, "GET", props.currentUser, {});
          callback(response);
        },
        1000
      ),
    [props.currentUser]
  );

  // whenever the address object changes, verify that the
  // returned place list has atleast one result
  useEffect(() => {
    //TODO: find more efficient way to determine if address is real or not
    getPlaces({ input: address.address }, (results?: PlaceType[]) => {
      if (results && results.length > 0) {
        setAddressIsReal(true);
      } else {
        setAddressIsReal(false);
      }
    });
  }, [address, getPlaces]);

  //whenever address is changed or if we find out that an address is real/fake
  useEffect(() => {
    detectFormErrors();
  }, [addressIsReal, address]);

  const [option, setOption] = useState(
    account === undefined || account.object === "bank_account"
      ? 0
      : account.object === "check"
      ? 2
      : 1
  );

  const setupAllOptions = (selectedValue: any) => {
    let _account: any;
    payoutMethods.stripeAccounts?.forEach((account: any) => {
      payoutMethodsOptions.push({
        value: account.id,
        label:
          (account.external_accounts?.data[0].object === "card"
            ? account.external_accounts?.data[0].brand
            : account.external_accounts?.data[0].bank_name) +
          " - " +
          account.external_accounts?.data[0].last4,
      });
    });
    payoutMethods.lobAccounts?.forEach((account: any) => {
      payoutMethodsOptions.push({
        value: account.id,
        label: account.company + " - " + account.address_line1,
      });
    });
    setPayoutOptions(_.unionBy(payoutMethodsOptions, payoutOptions, "value"));

    const selectedPayoutMethod = _.isNil(selectedValue)
      ? selectedLocation.payoutMethod
      : (selectedValue + "").length > 1
      ? findAccount(selectedValue)
      : undefined;

    if (selectedPayoutMethod !== undefined) {
      if (selectedPayoutMethod.selectedMethod === "check") {
        _account = payoutMethods.lobAccounts?.find((account: any) => {
          return account.id === selectedPayoutMethod.payoutMethodId;
        });
        if (!_.isNil(account)) {
          _account = {
            id: _account.id,
            company: _account.company,
            address: _account.address_line1,
            address2: _account.address_line2 ?? "",
            postalCode: _account.address_zip,
            object: "check",
          };
          setAddress(createObjectBasedOnFirst(address, _account));
        }
      } else if (selectedPayoutMethod.selectedMethod === "stripe") {
        _account = payoutMethods.stripeAccounts?.find((account: any) => {
          return account.id === selectedPayoutMethod.payoutMethodId;
        });
        if (_account !== null && _account !== undefined) {
          _account = {
            id: _account.id,
            bankName: _account.external_accounts.data[0].bank_name,
            last4: _account.external_accounts.data[0].last4,
            object: _account.external_accounts.data[0].object,
            brand: _account.external_accounts.data[0].brand?.toUpperCase(),
            expMonth: _account.external_accounts.data[0].exp_month?.toString(),
            expYear: _account.external_accounts.data[0].exp_year?.toString(),
          };
        }
      }
      if (_account !== undefined) {
        const index = payoutMethodsOptions.findIndex((option: any) => {
          return option.value === _account.id;
        });
        const option = payoutMethodsOptions[index];
        setSelectedOption(option);
        setAccount(_account);
      }
      const _option =
        _account === undefined || _account.object === "bank_account"
          ? 0
          : _account.object === "check"
          ? 2
          : 1;
      setOption(_option);
    } else if (!_.isNil(selectedValue)) {
      setAccount(undefined);
      setOption(selectedValue);
      if (selectedValue === 2) {
        setAddress({
          company: "",
          address: "",
          address2: "",
          postalCode: "",
          city: "",
          state: "",
        });
      }
    }
  };

  useEffect(() => {
    setupAllOptions(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutMethods, selectedLocation]);

  const changePayoutMethod = (_payoutMethod: any) => {
    setSelectedOption(_payoutMethod);
    setupAllOptions(_payoutMethod.value);
  };

  let formFields = [
    {
      label: "Payee Name (Usually the business name)",
      name: "company",
      type: "text",
      errorMsg: "Enter companies name",
      multiline: false,
    },
    {
      label: "Street Address",
      name: "address",
      type: "text",
      errorMsg: "Please enter a correct street address",
      multiline: false,
    },
    {
      label: "Address Line 2 (Apt/Suite)",
      name: "address2",
      type: "text",
      errorMsg: "Please enter missing apt/suite",
      multiline: false,
    },
    {
      label: "City",
      name: "city",
      type: "text",
      errorMsg: "",
      multiline: false,
    },
    {
      label: "State",
      name: "state",
      type: "text",
      errorMsg: "",
      multiline: false,
    },
    {
      label: "Zip Code",
      name: "postalCode",
      type: "text",
      errorMsg: "Please enter a valid zip code",
      multiline: false,
    },
  ];

  const [addressFormError, setAddressFormError] = useState<any>({
    company: false,
    address: false,
    address2: false,
    postalCode: false,
    city: false,
    state: false,
  });
  const [canSaveAddress, setCanSaveAddress] = useState(false);
  const [genericFormError, setGenericFormError] = useState("");

  const handleInputChange = (name: string, value: string) => {
    setAddress({ ...address, [name]: value });
  };

  const detectFormErrors = () => {
    const errorState: { [key: string]: boolean } = {
      name: false,
      address: false,
      address2: false,
      postalCode: false,
      city: false,
      state: false,
    };
    if (address.company.length === 0) {
      errorState.company = true;
    } else if (address.address.length <= 0 || !addressIsReal) {
      errorState.address = true;
    } else if (address.city.length <= 0) {
      errorState.city = true;
    } else if (address.state.length <= 0) {
      errorState.state = true;
    } else if (address.postalCode.length < 5) {
      errorState.postalCode = true;
    }
    setAddressFormError(errorState);
    if (!Object.values(errorState).includes(true)) {
      setCanSaveAddress(true);
    } else {
      setCanSaveAddress(false);
    }
    if (genericFormError.length > 0) {
      setGenericFormError("");
    }
  };

  const addressFormFields = formFields.map((formField, i) => {
    if (
      account !== undefined &&
      selectedLocation.payoutMethod !== undefined &&
      selectedLocation.payoutMethod.payoutMethodId &&
      formField.errorMsg === ""
    ) {
      return null;
    } else {
      return (
        <div key={i}>
          <GenericFormInput
            detectFormErrors={() => {}}
            infoError={addressFormError}
            formField={formField}
            editMode={true}
            object={address}
            handleInputChange={handleInputChange}
            key={i}
            onBlur={() => {}}
            disabled={account !== undefined && account.object === "check"}
          ></GenericFormInput>
        </div>
      );
    }
  });

  return (
    <Box display="flex" flexDirection="column">
      <Card className={classes.card} style={{ marginTop: "15px" }}>
        {selectedLocation !== undefined && (
          <Box p={2}>
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="baseline"
                  >
                    <Typography className={classes.headerText}>
                      Payout Method
                    </Typography>
                    <Typography className={classes.locationText}>
                      {selectedLocation.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider className={classes.headerDivider}></Divider>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              width={"50%"}
              minWidth={"450px"}
              paddingTop={theme.spacing(0.25)}
            >
              <InputLabel htmlFor="title" className={classes.label}>
                How would you like to be paid?
              </InputLabel>
              <Dropdown
                className={classes.payoutMethodDropdown}
                options={payoutOptions}
                value={selectedOption}
                onChange={changePayoutMethod}
              />

              <InputLabel htmlFor="title" className={classes.label}>
                {option === 0
                  ? "Enter your banking information:"
                  : option === 1
                  ? "Enter your debit card information:"
                  : "Enter your check information:"}
              </InputLabel>
              <Typography className={classes.infoText}>
                {option === 0
                  ? "Deposits are made automatically 3-5 days after the date is redeemed in the business."
                  : option === 1
                  ? "Input a payout address we can send a check to if we " +
                    "are unable to pay your business through the normal method."
                  : "Checks are sent out on the 20th of each month. Because of delays in mailing, " +
                    "they may not reflect the total amount of transactions that occurred in the period."}
              </Typography>

              {account !== undefined && (option === 0 || option === 1) && (
                <Box>
                  {account.object === "bank_account" && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography className={classes.accountText}>
                        {account.bankName + " - XXX-XXXX-" + account.last4}
                      </Typography>
                    </Box>
                  )}

                  {account.object === "card" && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography className={classes.accountText}>
                        {account.brand + " - XXX-XXXX-" + account.last4}
                      </Typography>
                    </Box>
                  )}

                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    paddingBottom={theme.spacing(0.25)}
                  >
                    <PaymentButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        editStripeConnect(account.id);
                      }}
                    >
                      {option === 0 && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography className={classes.buttonText}>
                            Edit Bank Info
                          </Typography>
                        </Box>
                      )}
                      {option === 1 && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography className={classes.buttonText}>
                            Edit Debit Card
                          </Typography>
                        </Box>
                      )}
                    </PaymentButton>
                  </Box>
                </Box>
              )}
              {option === 2 && (
                <>
                  <Box display="flex" flexDirection="column">
                    {genericFormError.length > 0 && (
                      <FormHelperText error id="genericError">
                        {genericFormError}
                      </FormHelperText>
                    )}
                    <form>{addressFormFields}</form>
                  </Box>
                  {account === undefined && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <PaymentButton
                        variant="contained"
                        color="primary"
                        disabled={!canSaveAddress}
                        onClick={() => {
                          saveCheck();
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography className={classes.buttonText}>
                            Save
                          </Typography>
                        </Box>
                      </PaymentButton>
                    </Box>
                  )}
                </>
              )}

              {(option === 0 || option === 1) && account === undefined && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  paddingBottom={theme.spacing(0.25)}
                >
                  <PaymentButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      openStripeConnect();
                    }}
                  >
                    {option === 0 && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography className={classes.buttonText}>
                          Connect Your Bank Account
                        </Typography>
                      </Box>
                    )}
                    {option === 1 && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography className={classes.buttonText}>
                          Connect Your Debit Card
                        </Typography>
                      </Box>
                    )}
                  </PaymentButton>
                </Box>
              )}

              {account !== undefined &&
                selectedLocation.payoutMethod !== undefined &&
                account.id !== selectedLocation.payoutMethod.payoutMethodId && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <PaymentButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setPayoutMethod(selectedOption.value);
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography className={classes.buttonText}>
                          Update
                        </Typography>
                      </Box>
                    </PaymentButton>
                  </Box>
                )}
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(PayoutPage));
