export const LOGIN = '/';
export const DASHBOARD = '/home';
export const BUSINESSINFO = '/location info';
export const ACCOUNT = '/account';
export const EXPERIENCES = '/experiences';
export const PAYOUTMETHOD = '/payout method';
export const TRANSACTIONS = '/transactions';
export const EMPLOYEETRAINING = '/employee training';
export const FAQ = '/faq';
export const TERMS = '/terms';
export const ADMINTOOLS = '/admin tools';