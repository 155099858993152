import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
} from "react-beautiful-dnd";
import getData from "src/services/getData";

interface categoryType {
  id: string;
  name: string;
  order: number;
}

const dragLineStyle = {
  height: 3,
  width: 40,
  bgcolor: "rgba(151, 151, 151, 1)",
};
const dragLineContainerStyle = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-around",
};
const rowStyle = {
  padding: 20,
  display: "flex",
  flexDirection: "row",
};
const seperatedBorderedStyle = {
  border: "1px solid rgba(214, 214, 214, 1)",
  borderRadius: "10px",
  marginBottom: 5,
};
const itemTextStyle = {
  fontSize: 16,
  fontWeight: 500,
  color: "rgba(11, 10, 10, 1)",
};
const listHeaderStyle = {
  fontSize: 24,
  color: "rgba(92, 92, 92, 1)",
};

function CategoryList(props: { currentUser: string }) {
  const [categories, setCategories] = React.useState([] as categoryType[]);
  // acts like a constructor.
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories.length === 0) return;
    updateCategoryOrderInBackend();
  }, [categories]);

  // updates the category list (backend)
  async function updateCategoryOrderInBackend() {
    let categoriesUpdatedOrderField = categories.map(
      (category: categoryType, index: number) => {
        category.order = index;
        return category;
      }
    );
    try {
      const response = await getData(
        "experiences/reorder-categories",
        "POST",
        props.currentUser,
        categoriesUpdatedOrderField,
        {},
        true
      );
    } catch (error) {
      console.error(error);
    }
  }

  // retrieves a list of experience categories from MongoDB
  async function fetchCategories() {
    const response = await getData(
      "experiences/categories",
      "GET",
      props.currentUser,
      {},
      {},
      true
    );
    setCategories(response);
  }

  // updates the category list (local state) as a result of drag & drop
  function handleOnDragEnd(dragResults: DropResult) {
    if (!dragResults.destination) return;
    const items = Array.from(categories);
    const [reorderedItem] = items.splice(dragResults.source.index, 1);
    items.splice(dragResults.destination.index, 0, reorderedItem);
    setCategories(items);
  }

  // creates the two horizontal lines that will be click area
  // for dragging categories around. Helper to createListOfCategories()
  function createDragLines(provided: DraggableProvided) {
    return (
      <Box
        // line below: specifies the component that will be the click area for dragging
        {...provided.dragHandleProps}
        sx={dragLineContainerStyle}
      >
        <Box sx={dragLineStyle} />
        <Box sx={dragLineStyle} />
      </Box>
    );
  }

  // from categories, creates a list of components that can be
  // dragged into different orders
  function createListOfCategories() {
    return categories.map((item: categoryType, index) => {
      return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(draggableProvided) => (
            <li
              // line below: an individual item that can be dragged
              {...draggableProvided.draggableProps}
              ref={draggableProvided.innerRef}
            >
              <Box sx={{ ...rowStyle, ...seperatedBorderedStyle }}>
                {createDragLines(draggableProvided)}
                <Box
                  sx={{
                    flex: 15,
                    ...itemTextStyle,
                  }}
                >
                  {item.name}
                </Box>
              </Box>
            </li>
          )}
        </Draggable>
      );
    });
  }

  return (
    <>
      <Box style={{
        ...listHeaderStyle,
        marginTop: 20,
        marginBottom: 20,
      }}>
        Category
      </Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="categories">
          {(droppableProvided) => {
            return (
              <ul
                // line below: where draggables can be dropped
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                style={{ listStyleType: "none" }}
              >
                {createListOfCategories()}
                {droppableProvided.placeholder}
              </ul>
            );
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default CategoryList;
