import { Input } from '@material-ui/core';
// https://github.com/larkintuckerllc/react-currency-input
import React, { CSSProperties, FC, useCallback } from 'react';

interface Props {
    classes?: any;
    max?: number;
    onValueChange: (value: number, index: number) => void;
    style?: CSSProperties;
    value: number;
    placeholder: string;
    id: string;
    index: number;
    disabled: boolean;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput: FC<Props> = ({
    classes,
    max = Number.MAX_SAFE_INTEGER,
    onValueChange,
    style = {},
    value,
    placeholder,
    id,
    index,
    disabled = false,
}) => {
    const valueAbsTrunc = Math.trunc(Math.abs(value));
    if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
        throw new Error(`invalid value property`);
    }
    const handleKeyDown = useCallback(
        (e): void => {
            const { key, keyCode } = e;
            if (
                (value === 0 && !VALID_FIRST.test(key)) ||
                (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
            ) {
                return;
            }
            const valueString = value.toString();
            let nextValue: number;
            if (keyCode !== DELETE_KEY_CODE) {
                const nextValueString: string = value === 0 ? key : `${valueString}${key}`;
                nextValue = Number.parseInt(nextValueString, 10);
            } else {
                const nextValueString = valueString.slice(0, -1);
                nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
            }
            if (nextValue > max) {
                return;
            }
            onValueChange(nextValue, index);
        },
        [max, onValueChange, value, index]
    );
    const handleChange = useCallback(() => { }, []);
    const valueDisplay = (value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Input
            disabled={disabled}
            className={classes.dropdown}
            inputMode="numeric"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={style}
            value={valueDisplay}
            id={id}
            placeholder={placeholder}
            disableUnderline
        />
    );
};

export default CurrencyInput;