import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";

import "./ProgressBar.css";

export const ProgressBar = (props: any) => {

    const { width, percent, status } = props;
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        setValue(percent * width);
    }, [percent, width]);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div className={!xs && !sm ? "progressComp" : "progressCompMobile"}>
            <div className="progress-div" style={{ width: width }}>
                <div style={{ width: `${value}px` }} className="progressBar" />
            </div>
            <h1 className="percent-number">{status}</h1>
        </div>
    );
};