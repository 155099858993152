import React, { useEffect, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import Navigator from "../components/Navigator";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  createStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { Box, CssBaseline } from "@material-ui/core";
import Header from "../components/Header";
import Content from "../components/Content";
import { useHistory, withRouter } from "react-router-dom";
import {
  createObjectBasedOnFirst,
  formatAsPercent,
  locationBase,
  partnerBase,
} from "../services/util";
import {
  BusinessIcon,
  FlagIcon,
  LightBulbIcon,
  MoneyIcon,
  PersonIcon,
  SwapIcon,
  KeyIcon,
} from "../components/Icons";
import Footer from "../components/Footer";
import { isNil } from "lodash";
import { BUSINESSINFO } from "../constants/routes";
import getData from "../services/getData";
import { isUserAdmin } from "src/services/auth";
import firebase from "firebase/app";
import "firebase/auth";
import HeaderBanner from "src/components/HeaderBanner";
import jwt_decode from "jwt-decode";

const drawerWidth = 256;

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(0,0,0,0)", // transparent
    },
    secondary: {
      main: "#00FF94",
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  typography: {
    fontFamily: ["Lato", "Helvetica", "Arial", "sans-serif"].join(","),
  },
});

theme = createMuiTheme({
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#1E1029",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiInput: {
      underline: {
        "&&&&:before": {
          borderBottom: "0",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.23)",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#BABABA",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#BABABA",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
      focused: {},
    },
    MuiFormControlLabel: {
      root: {
        width: "unset",
      },
      labelPlacementStart: {
        marginLeft: 0,
      },
      label: {
        minWidth: "100px",
      },
    },
  },
});

const styles = createStyles({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "inset 0 0 0 2000px rgba(34, 17, 47, 0.95)",
      width: "100%",
    },
    minHeight: "100vh",
  },
  // TODO: Background is covering white box too in mobile. Figure out how to use this so the style is the same as the login page
  backgroundStyle: {
    // background: "url(" + Background + "), #22112F",
    // backgroundSize: 'cover',
    // opacity: .9,
    // width: '100vw',
    // height: '100vh',
    // minHeight: '100vh',
    // position: 'absolute' as 'absolute',
    // backgroundBlendMode: 'soft-light, normal',
    // mixBlendMode: 'multiply' as 'multiply',
    // backgroundPosition: 'center center',
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 4),
      background: "#FFFFFF",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2),
      background: "transparent",
    },
    flex: 1,
    paddingBottom: "100px",
  },
});

function DashboardPage(props: any) {
  const {
    classes,
    isSignUp,
    partner,
    setPartner,
    token,
    setToken,
    partnerId,
    adminId,
    setPartnerId,
    locations,
    experiences,
    setExperiences,
    payoutMethods,
    getLocations,
    getExperiences,
    updatePayoutMethods,
    selectedLocation,
    setLocation,
    selectFirstLocation,
    cards,
    getPaymentMethods,
    updateLocation,
    setupPartnerId,
  } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const [activeNavPage, setActiveNavPage] = useState<number>();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [experienceIds, setExperienceIds] = useState([]);
  const [transactions, setTransactions] = useState<any>([]);
  let _transactions = new Set();
  const [metrics, setMetrics] = useState({
    sales: 0,
    revenue: 0,
    salesTax: 0,
    views: 0,
    clicks: 0,
    marketingSpend: 0,
  });
  let _metrics = {
    sales: 0,
    revenue: 0,
    salesTax: 0,
    views: 0,
    clicks: 0,
    marketingSpend: 0,
  };

  const [ownerInfoError, setOwnerInfoError] = useState<any>({
    fullName: false,
    email: false,
    number: false,
    address: false,
  });
  const [editInfo, setEditInfo] = useState(
    isSignUp && partner.fullName === "" ? true : false
  );
  const [locationInfoError, setLocationInfoError] = useState<any>({
    name: false,
    address: false,
    number: false,
    taxRate: false,
    hours: false,
  });
  const [locationSelected, setLocationSelected] = useState(
    selectedLocation !== undefined && selectedLocation.locationId === null
      ? true
      : false
  );

  const lastPage: number = 8;

  useEffect(() => {
    if (locationSelected) {
      const formNames = ["name", "number", "address", "taxRate", "hours", ""];
      for (const name of formNames) {
        let value = selectedLocation[name];
        if (name === "name" || name === "address") {
          if (value.length === 0) {
            locationInfoError[name] = true;
            break;
          } else {
            locationInfoError[name] = false;
          }
        } else if (name === "number") {
          if (value.length !== 14) {
            locationInfoError[name] = true;
            break;
          } else {
            locationInfoError[name] = false;
          }
        } else if (name === "taxRate") {
          if (value.length > 0) {
            value = parseFloat(value);
          }
          if (value.length === 0 || isNaN(value) || value < 0 || value > 100) {
            locationInfoError[name] = true;
            break;
          } else {
            locationInfoError[name] = false;
          }
        } else if (name === "hours") {
          locationInfoError[name] = false;
        }
      }
      setLocationInfoError({ ...locationInfoError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSelected, selectedLocation]); // locationInfoError left off on purpose

  const createOrUpdateLocation = async (isUpdate: boolean) => {
    let _tempLocation = JSON.parse(JSON.stringify(selectedLocation));
    _tempLocation.taxRate = formatAsPercent(_tempLocation.taxRate, false);
    let url = `locations`;
    if (isUpdate) {
      url += `/${selectedLocation.locationId}`;
    } else {
      delete _tempLocation.locationId;
    }
    if (_tempLocation.materials === null) {
      delete _tempLocation.materials;
    }
    if (
      !isNil(_tempLocation.manager) &&
      !isNil(_tempLocation.manager.managerImage) &&
      isNil(_tempLocation.manager.managerImage.url)
    ) {
      delete _tempLocation.manager.managerImage;
    }
    if (
      !isNil(_tempLocation.experienceIds) &&
      _tempLocation.experienceIds.length === 0
    ) {
      delete _tempLocation.experienceIds;
    }
    if (
      _tempLocation.manager === undefined ||
      _tempLocation.manager === null ||
      _tempLocation.manager.name === undefined ||
      _tempLocation.manager.name === null ||
      _tempLocation.manager.name.length === 0
    ) {
      delete _tempLocation.manager;
      if (isUpdate) {
        _tempLocation.manager = { name: " " };
      }
    }
    delete _tempLocation.payoutMethod;
    try {
      const response = await getData(
        url,
        isUpdate ? "PATCH" : "POST",
        token,
        _tempLocation
      );
      const _location = createObjectBasedOnFirst(locationBase, response);
      getLocations(_location.locationId);
      if (locationSelected) {
        setLocationSelected(!locationSelected);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getAnalytics = async (exp: any) => {
      try {
        const response = await getData(
          `experiences/${exp.experienceId}/analytics?grouping=quarter`,
          "GET",
          token,
          {}
        );
        if (!isNil(response)) {
          if (!isNil(response.analytics)) {
            for (let item of response.analytics) {
              _metrics = {
                sales:
                  _metrics.sales +
                  (isNil(item.metrics.unitSales) ? 0 : item.metrics.unitSales),
                revenue:
                  _metrics.revenue +
                  (isNil(item.metrics.revenue) ? 0 : item.metrics.revenue),
                salesTax:
                  _metrics.salesTax +
                  (isNil(item.metrics.salesTax) ? 0 : item.metrics.salesTax),
                views:
                  _metrics.views +
                  (isNil(item.metrics.impressions)
                    ? 0
                    : item.metrics.impressions),
                clicks:
                  _metrics.clicks +
                  (isNil(item.metrics.clicks) ? 0 : item.metrics.clicks),
                marketingSpend:
                  _metrics.marketingSpend +
                  (isNil(item.metrics.marketingCost)
                    ? 0
                    : item.metrics.marketingCost),
              };
              setMetrics(_metrics);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getPurchases = async (exp: any) => {
      if (
        _transactions.size > 0 &&
        _transactions.values().next().value.locationId !== exp.locationId
      ) {
        _transactions = new Set();
        _metrics = {
          sales: 0,
          revenue: 0,
          salesTax: 0,
          views: 0,
          clicks: 0,
          marketingSpend: 0,
        };
      }
      try {
        const response = await getData(
          `experiences/${exp.experienceId}/purchases`,
          "GET",
          token,
          {}
        );
        if (!isNil(response)) {
          for (const item of response) {
            if (!isNil(item.transactions)) {
              for (const _transaction of item.transactions) {
                _transactions.add(_transaction);
              }
            }
          }
          setTransactions(Array.from(_transactions));
        }
      } catch (error) {
        console.error(error);
      }
    };
    const expIds = experiences.map((item: any) => {
      return item.experienceId;
    });
    if (JSON.stringify(expIds) !== JSON.stringify(experienceIds)) {
      setExperienceIds(expIds);
      experiences.forEach((exp: any) => {
        getPurchases(exp);
        getAnalytics(exp);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceIds, experiences, token]);

  //eslint-disable-next-line
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const detectFormErrorsAccount = (name: string) => {
    const value = partner[name];
    const errorState = {
      fullName: false,
      email: false,
      number: false,
      address: false,
    };
    if (name === "fullName" && value.length === 0) {
      errorState[name] = true;
    }
    if (name === "email" && !regexEmail.test(value)) {
      errorState[name] = true;
    }
    if (name === "number" && value.length !== 14) {
      errorState[name] = true;
    }
    if (name === "address" && value.length === 0) {
      errorState[name] = true;
    }
    setOwnerInfoError(errorState);
    return errorState;
  };

  const formFields = [
    {
      name: "fullName",
    },
    {
      name: "email",
    },
    {
      name: "number",
    },
    {
      name: "address",
    },
  ];
  useEffect(() => {
    if (JSON.stringify(partner) !== JSON.stringify(partnerBase)) {
      if (
        partner.signUpState.activePage === 1 &&
        partner.signUpState.page === 1
      ) {
        for (const formItem of formFields) {
          const fieldKey = formItem.name;
          const errorState: any = detectFormErrorsAccount(fieldKey);
          if (errorState[fieldKey]) {
            break;
          }
        }
      }
    }
  }, [partner]);

  const savePartnerInfo = async () => {
    if (editInfo) {
      let hasFormError = false;
      for (const formItem of formFields) {
        const fieldKey = formItem.name;
        const errorState: any = detectFormErrorsAccount(fieldKey);
        if (errorState[fieldKey]) {
          hasFormError = true;
          break;
        }
      }
      if (!hasFormError) {
        const _tempPartner = JSON.parse(JSON.stringify(partner));
        delete _tempPartner.profileImage;
        delete _tempPartner.revenuePercent;
        try {
          const response = await getData(
            `partners/${partnerId}`,
            "PATCH",
            token,
            _tempPartner
          );
          const _partner = createObjectBasedOnFirst(partnerBase, response);
          if (response.partnerId !== partnerId) {
            await setupPartnerId();
          }
          setPartnerId(response.partnerId);
          setPartner(_partner);
          setEditInfo(!editInfo);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      formFields.reverse().forEach((formItem) => {
        detectFormErrorsAccount(formItem.name);
      });
      setEditInfo(!editInfo);
    }
  };

  useEffect(() => {
    const checkNextButtonDisabled = () => {
      let setDisabled = true;
      if (
        partner.signUpState.activePage === 1 &&
        partner.signUpState.page === 1
      ) {
        setDisabled =
          Object.values(ownerInfoError).filter((item) => item === true).length >
          0;
      } else if (
        partner.signUpState.activePage === 2 &&
        partner.signUpState.page === 2
      ) {
        setDisabled =
          Object.values(locationInfoError).filter((item) => item === true)
            .length > 0;
      } else {
        if (partner.signUpState.activePage === 1) {
          if (
            partner.fullName.length > 0 &&
            partner.address.length > 0 &&
            partner.number.length > 10
          ) {
            setDisabled = false;
          }
        } else if (partner.signUpState.activePage === 2) {
          if (!isNil(locations)) {
            if (locations.length > 0) {
              setDisabled = false;
            }
          }
        } else if (partner.signUpState.activePage === 3) {
          if (experiences.length >= 0) {
            setDisabled = false;
          }
        } else if (partner.signUpState.activePage === 4) {
          if (
            payoutMethods !== undefined &&
            payoutMethods.stripeAccounts !== undefined &&
            payoutMethods.lobAccounts !== undefined &&
            (payoutMethods.stripeAccounts.length > 0 ||
              payoutMethods.lobAccounts.length > 0)
          ) {
            setDisabled = false;
          }
        } else if (partner.signUpState.activePage === 5) {
          setDisabled = false;
        } else if (partner.signUpState.activePage === 6) {
          setDisabled = false;
        }
      }
      if (setDisabled !== nextDisabled) {
        setNextDisabled(setDisabled);
      }
    };
    if (isSignUp) {
      checkNextButtonDisabled();
    }
  }, [
    isSignUp,
    nextDisabled,
    partner,
    locations,
    experiences,
    payoutMethods,
    ownerInfoError,
    locationInfoError,
  ]);

  useEffect(() => {
    async function buildCategories() {
      const children = [
        {
          id: "Location Info",
          icon: <BusinessIcon fill="#DECDEB" />,
          iconActive: <BusinessIcon fill="#00FF94" />,
          index: 2,
        },
        {
          id: "Experiences",
          icon: <FlagIcon fill="#DECDEB" />,
          iconActive: <FlagIcon fill="#00FF94" />,
          index: 3,
        },
        {
          id: "Payout Method",
          icon: <MoneyIcon fill="#DECDEB" />,
          iconActive: <MoneyIcon fill="#00FF94" />,
          index: 4,
        },
        {
          id: "Transactions",
          icon: <SwapIcon fill="#DECDEB" />,
          iconActive: <SwapIcon fill="#00FF94" />,
          index: 5,
        },
        {
          id: "Employee Training",
          icon: <LightBulbIcon fill="#DECDEB" />,
          iconActive: <LightBulbIcon fill="#00FF94" />,
          index: 6,
        },
      ];
      if (isSignUp) {
        children.unshift({
          id: "Account",
          icon: <PersonIcon fill="#DECDEB" />,
          iconActive: <PersonIcon fill="#00FF94" />,
          index: 1,
        });
      } else {
        children.push({
          id: "Account",
          icon: <PersonIcon fill="#DECDEB" />,
          iconActive: <PersonIcon fill="#00FF94" />,
          index: 7,
        });
      }
      let categories: any = [
        {
          id: "client_main",
          children: children,
        },
      ];
      setCategories(categories);
      const idToken = await firebase.auth().currentUser?.getIdToken();
      if (idToken !== undefined && (await isUserAdmin(idToken))) {
        const adminCategory = {
          id: "admin_tools",
          children: [
            {
              id: "Admin Tools",
              icon: <KeyIcon fill="#DECDEB" />,
              iconActive: <KeyIcon fill="#00FF94" />,
              index: 8,
            },
          ],
        };
        categories = [...categories, adminCategory];
        setCategories(categories);
      }
    }
    buildCategories();
  }, [isSignUp]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const history = useHistory();

  const changeLocation = (location: any) => {
    if (location.value === null) {
      setupNewLocation();
    } else {
      const matchingLocation = locations.filter(
        (item: { locationId: any }) => item.locationId === location.value
      );
      if (matchingLocation !== undefined && matchingLocation.length > 0) {
        setLocation(
          createObjectBasedOnFirst(locationBase, matchingLocation.pop())
        );
      }
    }
  };

  const setupNewLocation = () => {
    if (
      selectedLocation !== undefined &&
      selectedLocation.locationId !== null
    ) {
      const _location = createObjectBasedOnFirst(locationBase, {});
      history.push(`/home${BUSINESSINFO}`);
      setLocation(createObjectBasedOnFirst(locationBase, _location));
    }
  };

  const setActivePage = (index: number) => {
    setActiveNavPage(index);
  };

  const isAdminUsingPartner = () => {
    if (token !== undefined || !isNil(token)) {
      if (token.length === 0) return false;
      const decoded: any = jwt_decode(token);
      if (decoded !== undefined && decoded.partnerId !== undefined) {
        return decoded.partnerId !== decoded.adminId;
      }
    }
    return false;
  };

  const isAdmin = () => {
    if (token !== undefined || !isNil(token)) {
      if (token.length === 0) return false;
      const decoded: any = jwt_decode(token);
      return !isNil(decoded.adminId);
    }
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="column">
        {isAdminUsingPartner() && (
          <HeaderBanner
            partner={partner}
            setPartnerId={(id: string) => setPartnerId(id)}
            setToken={(token: string) => setToken(token)}
            currentUser={token}
          />
        )}
        <div className={classes.backgroundStyle}></div>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden mdUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                onClose={handleDrawerToggle}
                categories={categories}
                setCategories={setCategories}
                isSignUp={isSignUp}
                partner={partner}
                setActivePage={setActivePage}
                theme={theme}
                locations={locations}
                selectedLocation={selectedLocation}
                changeLocation={changeLocation}
                selectFirstLocation={selectFirstLocation}
                isUsingPartner={isAdminUsingPartner()}
              />
            </Hidden>
            <Hidden smDown implementation="css">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                locations={locations}
                selectedLocation={selectedLocation}
                changeLocation={changeLocation}
                handleDrawerToggle={handleDrawerToggle}
                categories={categories}
                setCategories={setCategories}
                isSignUp={isSignUp}
                partner={partner}
                setActivePage={setActivePage}
                theme={theme}
                selectFirstLocation={selectFirstLocation}
                isUsingPartner={isAdminUsingPartner()}
              />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <Header
              onDrawerToggle={handleDrawerToggle}
              selectedLocation={selectedLocation}
              setupNewLocation={setupNewLocation}
              isSignUp={isSignUp}
              partner={partner}
              locations={locations}
              changeLocation={changeLocation}
              categories={categories}
              setCategories={setCategories}
              setActivePage={setActivePage}
              theme={theme}
              selectFirstLocation={selectFirstLocation}
            />
            <main
              className={classes.main}
              style={{
                paddingTop:
                  history.location.pathname === "/home/location info"
                    ? "8px"
                    : theme.spacing(6),
              }}
            >
              <Content
                key={"contentWidget"}
                {...props}
                currentUser={token}
                setToken={setToken}
                partnerId={partnerId}
                adminId={adminId}
                setPartnerId={setPartnerId}
                selectedLocation={selectedLocation}
                setLocation={setLocation}
                experiences={experiences}
                setExperiences={setExperiences}
                updateSelectedLocation={getLocations}
                reloadExperiences={getExperiences}
                payoutMethods={payoutMethods}
                updatePayoutMethods={updatePayoutMethods}
                partner={partner}
                setPartner={setPartner}
                isSignUp={isSignUp}
                transactions={transactions}
                setTransactions={setTransactions}
                metrics={metrics}
                setMetrics={setMetrics}
                cards={cards}
                getPaymentMethods={getPaymentMethods}
                updateLocation={updateLocation}
                ownerInfoError={ownerInfoError}
                savePartnerInfo={savePartnerInfo}
                editInfo={editInfo}
                locationInfoError={locationInfoError}
                setLocationInfoError={setLocationInfoError}
                locationSelected={locationSelected}
                setLocationSelected={setLocationSelected}
                createOrUpdateLocation={createOrUpdateLocation}
                isAdmin={isAdmin()}
              ></Content>
            </main>
            { /** Hide footer on Admin Tools page */}
            {activeNavPage !== lastPage && isSignUp && (
              <Footer
                partner={partner}
                partnerId={partnerId}
                token={token}
                setPartner={setPartner}
                activeNavPage={activeNavPage}
                nextDisabled={nextDisabled}
                saveInfo={
                  partner.signUpState.activePage === 1 &&
                  partner.signUpState.page === 1
                    ? savePartnerInfo
                    : partner.signUpState.activePage === 2 &&
                      partner.signUpState.page === 2
                    ? createOrUpdateLocation
                    : null
                }
              />
            )}
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default withStyles(styles)(withRouter(DashboardPage));
