import {
    Box,
    Button,
    createStyles,
    Dialog,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { isNil } from 'lodash';
import { CreditType, PromoCode, PromoTypes } from './PromoInterfaceEnums';
import { LabeledCustomSwitch } from './CustomSwitch';
import { ChangeEvent, useState, useEffect } from 'react';
import { CustomDateSelector } from 'src/components/DateSelector';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CustomCheckBox } from './CheckBox';
import Dropdown, { Option } from 'react-dropdown';
import { FixedOrPercent } from './FixedOrPercent';
import { NumberInput } from './NumberInput';
import { v4 as uuidv4 } from 'uuid';
import { addDays } from 'date-fns';
import getData from 'src/services/getData';
import { Discount } from 'src/components/AdminTools/PromoCode/PromoInterfaceEnums';
import { TargetedExperienceSection } from './ExperienceSuggestions';
import { CopyIcon } from '../../../components/Icons/index';

interface PromoCodeWrapper {
    promoCode?: PromoCode;
    onTapClose: Function;
    currentUser: any;
    onUpdateComplete?: Function;
}

const headerColor = 'rgba(30, 16, 41, 1)';
const labelTextColor = 'rgba(92, 92, 92, 1)';
const lightBorder = 'rgba(214, 214, 214, 1)';
const notLettersAndSpaceRegex = /[^a-z ]+/gi;
const notLettersAndNumbersRegex = /[^a-z0-9]+/gi;

const styles = makeStyles((theme: Theme) =>
    createStyles({
        saveButton: {
            height: 40,
            backgroundColor: '#00FF94',
            color: '#1E1029',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: 0.7,
        },
        disabledSaveButton: {
            height: 40,
            backgroundColor: '#404040',
            color: '#1E1029',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: 0.7,
        },
        dropdownSelector: {
            border: '1px solid rgba(186, 186, 186, 0.6)',
            boxSizing: 'border-box',
            borderRadius: '7px',
        },
        dropdown: {
            border: '1px solid rgba(186, 186, 186, 0.6)',
            boxSizing: 'border-box',
            borderRadius: '7px',
            padding: `0px ${theme.spacing(1)}px`,
        },
        dropDownOption: {
            fontSize: '16px',
            lineHeight: '19px',
            color: '#000000',
        },
        dropDownBox: {
            width: 'auto',
            margin: 10,
            maxWidth: 250,
        }
    })
);

// Styles
const row = {
    display: 'flex',
    flexDirection: 'row',
};
const column = {
    display: 'flex',
    flexDirection: 'column',
};
const headerBlock = {
    backgroundColor: headerColor,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    // wrap is needed for alignContent to work correctly
    flexWrap: 'wrap',
};
const mainHeaderText = {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    color: 'white',
};
const sectionHeaderText = {
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '26px',
    paddingBottom: '10px',
    paddingTop: '20px',
};
export const labelText = {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: labelTextColor,
    paddingBottom: '5px',
};
const bodyBlock = {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    ...column,
};
const error = {
    color: '#f77067',
    fontSize: 13,
}

enum dropDownOptions {
    percentage = 'Percentage',
    fixedAmount = 'Fixed Amount',
}

/// the popup that allows a user to edit or create a promo
/// it is suggested to use showPromoDialog() instead of this directly
function CreateEditPromoCode(props: PromoCodeWrapper) {
    const classes = styles();
    const promoCodeType = props.promoCode?.promoType;
    const [editing] = useState<boolean>(!isNil(props.promoCode));
    const [deepLinkMutual, setDeepLinkMutual] = useState<string>('');
    const [deepLinkMutualDates, setDeepLinkMutualDates] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [code, setCode] = useState<string>(getUniquePromoCode());
    const [creatorName, setCreatorName] = useState<string>('');
    const [promoStatusToggle, setPromoStatusToggle] = useState<boolean>(false);
    const [targetExperienceId, setTargetExperienceId] = useState<string | null>();
    const [targetExperienceTitle, setTargetExperienceTitle] = useState<string | null>();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [expirationDate, setExpirationDate] = useState<Date | null>(addDays(new Date(), 1));
    const [discountDropDownVal, setDiscountDropDownVal] = useState<CreditType>(CreditType.flatAmount);
    const [discountAmount, setDiscountAmount] = useState<number>(0);
    const [allowCodeStackingToggle, setAllowCodeStackingToggle] = useState<boolean>(false);
    const [maxRedemptionsPerUser, setMaxRedemptionsPerUser] = useState<number | null>(null);
    const [maxRedemptions, setMaxRedemptions] = useState<number | null>(null);
    const [validPromoCode, setValidPromoCode] = useState<boolean>(false);
    const [limitToFirstPurchaseOnly, setLimitToFirstPurchaseOnly] = useState<boolean>(false);

    const [errorText, setErrorText] = useState<string | null>(null);

    // start / end date checking should only be performed when
    // 1) the user is creating a promo code
    // 2) the user is editing and has changed the start / end date
    const [hasChangedStartDate, setHasChangedStartDate] = useState(false);
    const [hasChangedEndDate, setHasChangedEndDate] = useState(false);

    async function populateFieldsFromPromo() {
        const promo: PromoCode = props.promoCode!;
        setDescription(promo.description ?? '');
        setCode(promo.promoCode);
        setDeepLinkMutual(promo.deepLinkMutual);
        setDeepLinkMutualDates(promo.deepLinkMutualDates);
        setCreatorName(promo.creatorName!);
        setPromoStatusToggle(promo.live);
        setTargetExperienceId(promo.targetedExperienceId);
        if (!!promo.targetedExperienceId) {
            setTargetExperienceTitle(promo.targetedExperienceTitle);
        }
        setStartDate(promo.startAt);
        setExpirationDate(promo.expiredAt);
        setDiscountDropDownVal(promo.discount!.type);
        setDiscountAmount(promo.discount!.amount);
        setAllowCodeStackingToggle(promo.allowStacking);
        setMaxRedemptionsPerUser(promo.maxRedemptionsPerUser);
        setLimitToFirstPurchaseOnly(promo.limitToFirstPurchaseOnly ?? false);
        setMaxRedemptions(promo.maxRedemptions);
    }

    useEffect(() => {
        if (editing) {
            populateFieldsFromPromo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!description && description !== "" &&
            !!code && code !== "" && code.length > 2 &&
            !!creatorName && creatorName !== "") {
            setValidPromoCode(true);
        } else {
            setValidPromoCode(false);
        }
        return;
    }, [
        description,
        code,
        creatorName,
    ]);

    function informationSection() {
        return (
            <>
                <Typography style={{ ...sectionHeaderText }}>Information</Typography>
                {editing
                    ? <Box sx={{ ...column, paddingBottom: 10 }}>
                        <Typography style={labelText}>DEEP LINK</Typography>
                        { deepLinkSection('Mutual App', deepLinkMutual, copyDeepLinkMutual) }
                        { deepLinkSection('Mutual Experience', deepLinkMutualDates, copyDeepLinkMutualDates) }
                    </Box> : <></>
                }
                <Box sx={{ ...column, paddingBottom: '10px' }}>
                    <Typography style={labelText}>DESCRIPTION</Typography>
                    <TextField
                        key={'description'}
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                        placeholder='Describe what this promo code does'
                        variant='outlined'
                        size='small'
                        fullWidth={true}
                    />
                </Box>
                <Box sx={{ ...row, paddingBottom: '10px' }}>
                    <Box sx={{ ...column, paddingRight: 10 }}>
                        <Typography style={labelText}>CODE</Typography>
                        <TextField
                            onChange={(event) => {
                                // Limit promo code to only letters and numbers
                                event.target.value = event.target.value.replaceAll(
                                    notLettersAndNumbersRegex,
                                    ''
                                );
                                // Limit promo code length to 8 and always capitalized
                                setCode(event.target.value.toUpperCase().slice(0, 8));
                            }}
                            value={code}
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                        />
                    </Box>
                    <Box sx={{ ...column, paddingRight: 10 }}>
                        <Typography style={labelText}>CREATED BY</Typography>
                        <TextField
                            onChange={(event) => {
                                event.target.value = event.target.value.replaceAll(
                                    notLettersAndSpaceRegex,
                                    ''
                                );
                                setCreatorName(event.target.value);
                            }}
                            placeholder='Enter name'
                            value={creatorName}
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                        />
                    </Box>
                    <Box sx={{ ...column }}>
                        <Typography style={labelText}>STATUS</Typography>
                        <LabeledCustomSwitch
                            activeLabel={'ACTIVE'}
                            inActiveLabel={'INACTIVE'}
                            isActive={promoStatusToggle}
                            onTap={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                setPromoStatusToggle(checked);
                            }}
                        />
                    </Box>
                </Box>
                <TargetedExperienceSection
                    currentUser={props.currentUser}
                    targetExperienceId={targetExperienceId}
                    targetExperienceTitle={targetExperienceTitle}
                    targetExperienceSet={(experienceId, experienceTitle) => {
                        setTargetExperienceId(experienceId);
                        setTargetExperienceTitle(experienceTitle);
                        return;
                    }}/>
            </>
        );
    }

    function deepLinkSection(sectionTitle: string, deepLink: string, copy: Function) {
        return (
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography style={{ marginRight: 5, width: 160 }}>{sectionTitle + ':'}</Typography>
                <Typography
                    style={{
                        display: '-webkit-box',
                        maxHeight: 18,
                        marginRight: 5,
                        width: '70%',
                        color: '#29ABE3',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>{deepLink}</Typography>
                <div style={{ width: 40 }}>
                    <Button
                        onClick={() => copy()}
                        size='small'>
                        <CopyIcon />
                    </Button>
                </div>
            </Box>
        );
    }

    function getDayAfterStartPromo() {
        let newDate = new Date();
        if (!isNil(startDate)) {
            newDate = new Date(startDate);
        }
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }

    function getDayBeforeEndPromo() {
        let newDate;
        if (isNil(expirationDate)) {
            newDate = new Date();
            var year = newDate.getFullYear();
            var month = newDate.getMonth();
            var day = newDate.getDate();
            newDate = new Date(year + 100, month, day);
        } else {
            newDate = new Date(expirationDate);
            newDate.setDate(newDate.getDate() - 1);
        }
        return newDate;
    }

    function durationSection() {
        return (
            <>
                <Typography style={sectionHeaderText}>Duration</Typography>
                <Box sx={{ ...row, alignItems: 'center' }}>
                    <Box sx={{ ...column, paddingRight: 10, width: 150 }}>
                        <Typography style={labelText}>START DATE</Typography>
                        <Box>
                            <CustomDateSelector
                                value={startDate}
                                maxDate={(editing && !hasChangedStartDate) ? undefined : getDayBeforeEndPromo()}
                                minDateMessage={(editing && !hasChangedStartDate) ? null : undefined }
                                onChange={(date: MaterialUiPickersDate) => {
                                    if (isNil(date)) return;
                                    setHasChangedStartDate(true);
                                    setStartDate(date);
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ ...column, paddingRight: 10, width: 150 }}>
                        <Typography style={labelText}>EXPIRATION DATE</Typography>
                        {!!expirationDate ? (
                            <CustomDateSelector
                                value={expirationDate}
                                minDate={(editing && !hasChangedEndDate) ? undefined : getDayAfterStartPromo()}
                                maxDateMessage={(editing && !hasChangedEndDate) ? null : undefined }
                                onChange={(date: MaterialUiPickersDate) => {
                                    setExpirationDate(date);
                                    setHasChangedEndDate(true);
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    border: `1px solid ${lightBorder}`,
                                    borderRadius: 10,
                                    paddingLeft: 10,
                                    paddingRight: 5,
                                    paddingTop: 1,
                                    paddingBottom: 2,
                                }}
                            >
                                <TextField placeholder='N/A' disabled={true} />
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ ...column }}>
                        <Box sx={{ height: labelText.lineHeight }} />
                        <Box
                            sx={{
                                ...row,
                                paddingBottom: 0,
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ paddingRight: 5 }}>
                                <CustomCheckBox
                                    value={!expirationDate}
                                    onChange={(newVal: boolean) => {
                                        setExpirationDate(newVal ? null : addDays(new Date(), 1));
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography style={{ ...labelText, paddingBottom: 0 }}>
                                    DOESN'T EXPIRE
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }

    function discountSection() {
        return (
            <>
                <Typography style={sectionHeaderText}>Discount</Typography>
                <Box sx={row}>
                    <Box sx={{ ...column, paddingRight: 10 }}>
                        <Typography style={labelText}>TYPE</Typography>
                        <Dropdown
                            controlClassName={classes.dropdownSelector}
                            placeholderClassName={classes.dropDownOption}
                            menuClassName={classes.dropdown}
                            className={classes.dropDownOption}
                            options={[dropDownOptions.percentage, dropDownOptions.fixedAmount]}
                            value={
                                discountDropDownVal === CreditType.flatAmount ? dropDownOptions.fixedAmount : dropDownOptions.percentage
                            }
                            onChange={(newValue: Option) => {
                                if (newValue.value === dropDownOptions.fixedAmount) {
                                    setDiscountDropDownVal(CreditType.flatAmount);
                                }
                                else if (newValue.value === dropDownOptions.percentage) {
                                    setDiscountDropDownVal(CreditType.percentageAmount);
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{ ...column, paddingRight: 10 }}>
                        <Typography style={labelText}>AMOUNT</Typography>
                        <FixedOrPercent
                            key={'fixedPercent'}
                            creditAmount={discountAmount}
                            isFixed={discountDropDownVal === CreditType.flatAmount}
                            onChange={(newVal) => {
                                setDiscountAmount(newVal);
                            }}
                        />
                    </Box>
                    <Box sx={{ ...column }}>
                        <Typography style={labelText}>ALLOW CODE STACKING</Typography>
                        <LabeledCustomSwitch
                            activeLabel={'ALLOW'}
                            inActiveLabel={'ALLOW'}
                            isActive={allowCodeStackingToggle}
                            onTap={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                setAllowCodeStackingToggle(checked);
                            }}
                        />
                    </Box>
                </Box>
            </>
        );
    }

    function redemptionsSection() {
        return (
            <>
                <Typography style={sectionHeaderText}>Redemptions</Typography>
                {promoCodeType !== undefined && promoCodeType === PromoTypes.marketingPromotion &&
                    <Box sx={{ ...row }}>
                        <Box sx={{ ...column, paddingBottom: '10px', paddingRight: '40px' }}>
                            <Typography style={labelText}>FIRST PURCHASE ONLY</Typography>
                            <LabeledCustomSwitch
                                activeLabel={'FIRST ONLY'}
                                inActiveLabel={'INACTIVE'}
                                isActive={limitToFirstPurchaseOnly}
                                onTap={(
                                    _event: ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                ) => {
                                    setLimitToFirstPurchaseOnly(checked);
                                }}
                            />
                        </Box>
                    </Box>
                }
                <Box sx={{ ...row }}>
                    <Box sx={{ ...column, paddingBottom: '10px', paddingRight: '40px' }}>
                        <Typography style={labelText}>PER USER</Typography>
                        <LabeledCustomSwitch
                            activeLabel={'LIMITED'}
                            inActiveLabel={'UNLIMITED'}
                            isActive={!isNil(maxRedemptionsPerUser)}
                            onTap={(
                                _event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                setMaxRedemptionsPerUser(checked ? 1 : null);
                            }}
                        />
                    </Box>
                    {!isNil(maxRedemptionsPerUser) && (
                        <Box sx={{ ...column, paddingBottom: '10px' }}>
                            <Typography style={labelText}>AMOUNT</Typography>
                            <NumberInput
                                defaultValue={maxRedemptionsPerUser}
                                inputKey={'perUserInput'}
                                onChange={(newValue: number) => {
                                    setMaxRedemptionsPerUser(newValue);
                                }}
                            />
                        </Box>
                    )}
                </Box>
                <Box sx={row}>
                    <Box sx={{ ...column, paddingBottom: '10px', paddingRight: '40px' }}>
                        <Typography style={labelText}>MAX USES</Typography>
                        <LabeledCustomSwitch
                            activeLabel={'LIMITED'}
                            inActiveLabel={'UNLIMITED'}
                            isActive={!isNil(maxRedemptions)}
                            onTap={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                            ) => {
                                setMaxRedemptions(checked ? 1000 : null);
                            }}
                        />
                    </Box>
                    {!isNil(maxRedemptions) && (
                        <Box sx={{ ...column, paddingBottom: '10px' }}>
                            <Typography style={labelText}>AMOUNT</Typography>
                            <NumberInput
                                defaultValue={maxRedemptions}
                                inputKey={'maxUsesRedemptionsInput'}
                                onChange={(newValue: number) => {
                                    setMaxRedemptions(newValue);
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </>
        );
    }

    function getUniquePromoCode() {
        return uuidv4().slice(0, 8).toUpperCase();
    }

    async function createNewPromoCode() {
        const discount: Discount = {
            type: discountDropDownVal,
            amount: discountAmount,
        };
        const newPromoDetails: PromoCode = {
            promoCode: code,
            description: description,
            creatorName: creatorName,
            live: promoStatusToggle,
            promoType: PromoTypes.marketingPromotion,
            referrerUserId: null,
            createdAt: new Date(),
            discount: discount,
            startAt: startDate,
            expiredAt: expirationDate,
            maxRedemptions: maxRedemptions,
            maxRedemptionsPerUser: maxRedemptionsPerUser,
            targetedExperienceId: targetExperienceId ?? null,
            targetedExperienceTitle: targetExperienceTitle ?? null,
            allowStacking: allowCodeStackingToggle,
            redemptions: 0,
            totalSumDiscounted: 0,
            creditEarned: null,
            deepLinkMutual: deepLinkMutual,
            deepLinkMutualDates: deepLinkMutualDates,
            limitToFirstPurchaseOnly: limitToFirstPurchaseOnly,
        }

        try {
            await getData(
                "promo-codes",
                "PUT",
                props.currentUser,
                { ...newPromoDetails },
                undefined,
                true
            );
        } catch (e) {
            console.error(e);
        }
    }

    async function updatePromo() {
        const discount: Discount = {
            type: discountDropDownVal,
            amount: discountAmount,
        };
        const newPromoDetails: Partial<PromoCode> = {
            promoCode: code,
            description: description,
            creatorName: creatorName,
            live: promoStatusToggle,
            promoType: PromoTypes.marketingPromotion,
            createdAt: new Date(),
            discount: discount,
            startAt: startDate,
            expiredAt: expirationDate,
            maxRedemptions: maxRedemptions,
            maxRedemptionsPerUser: maxRedemptionsPerUser,
            targetedExperienceId: targetExperienceId,
            targetedExperienceTitle: targetExperienceTitle,
            allowStacking: allowCodeStackingToggle,
            limitToFirstPurchaseOnly: limitToFirstPurchaseOnly,
        }

        try {
            await getData(
                "promo-codes",
                "PATCH",
                props.currentUser,
                { ...newPromoDetails },
                undefined,
                true
            );
            // wait until the backend confirms that the promo code
            // was updated before refetching
            if (!isNil(props.onUpdateComplete)) {
                props.onUpdateComplete();
            }
        } catch (e) {
            console.error(e);
            setErrorText(`${e}`);
        }
    }

    function createSaveButton() {
        return (
            <Button
                variant='contained'
                disableElevation
                className={validPromoCode ? classes.saveButton : classes.disabledSaveButton}
                onClick={() => {
                    if (!validPromoCode) {
                        return;
                    }
                    editing ? updatePromo() : createNewPromoCode();
                    props.onTapClose();
                }}
            >
                {editing ? 'SAVE CHANGES' : 'CREATE PROMO CODE'}
            </Button>
        );
    }

    function copyDeepLinkMutual() {
        navigator.clipboard.writeText(deepLinkMutual);
    }
    function copyDeepLinkMutualDates() {
        navigator.clipboard.writeText(deepLinkMutualDates);
    }

    return (
        <Box sx={column}>
            <Box sx={headerBlock}>
                <Typography style={mainHeaderText}>
                    {editing ? 'Edit promo code' : 'Create promo code'}
                </Typography>
            </Box>
            <Box sx={bodyBlock}>
                {informationSection()}
                {durationSection()}
                {discountSection()}
                {redemptionsSection()}
                {error !== null ? <Typography style={error}></Typography> : <Box/>}
                {createSaveButton()}
            </Box>
        </Box>
    );
}

/// allows you to create or edit an existing promo via popup.
/// edit mode if promoCodeToEdit it is given
export function showPromoDialog(
    open: boolean,
    onClose: Function,
    currentUser: any,
    promoCodeToEdit?: PromoCode,
    onUpdateComplete?: Function,
) {
    return (
        <Dialog
            // fullWidth stretches to the max width (currently defaults to sm)
            fullWidth={true}
            open={open}
            onClose={() => onClose()}
            scroll={'body'}
            disableBackdropClick={false}
        >
            <CreateEditPromoCode
                promoCode={promoCodeToEdit}
                onTapClose={onClose}
                currentUser={currentUser}
                onUpdateComplete={onUpdateComplete}
            ></CreateEditPromoCode>
        </Dialog>
    );
}
