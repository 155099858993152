import {
  Box,
  Card,
  CardContent,
  createStyles,
  createTheme,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  withStyles,
  withTheme,
  Switch
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";
import {
  convertExperienceToEditedExperience,
  createObjectBasedOnFirst,
  experienceBase,
  ExperiencesButton,
  ExperiencesSaveButton,
  ExperiencesSubmitButton,
  formatTimeString,
  locationBase,
} from "../../services/util";
import _ from "lodash";
import PhotoUpload from "../PhotoUpload/PhotoUpload";
import CurrencyInput from "../CurrencyInput";
import getData from "../../services/getData";
import Config from "../../config";
import { ConfigKey } from "../../config/config.enums";
import CloseIcon from "@material-ui/icons/Close";
import StoreHours from "../StoreHours/StoreHours";
import { CustomDateSelector } from "../DateSelector";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};
declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey { }
}
export const DateSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: "#FFFFFF",
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#00FF94",
          borderColor: "#5C5C5C",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid #5C5C5C`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#CDBDD8",
    },
    checked: {},
  })
)(Switch);
const datePickerMUITheme = createTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        color: "black",
        "&:hover": {
          backgroundColor: "rgba(0, 255, 148, .5)",
        },
      },
      daySelected: {
        color: "black",
        backgroundColor: "rgba(0, 255, 148, 1)", //light green
        "&:hover": {
          backgroundColor: "rgba(0, 255, 148, .5)",
        },
      },
      current: {
        color: "black",
        "&:hover": {
          backgroundColor: "rgba(0, 255, 148, .5)",
        },
      },
    },
  },
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "95%",
      maxWidth: "500px",
      minHeight: "95%",
    },
    label: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#5C5C5C",
      paddingBottom: theme.spacing(1),
      flexGrow: 1,
    },
    inputField: {
      width: "100%",
    },
    title: {
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "29px",
    },
    divider: {
      backgroundColor: "#BABABA",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    buttonText: {
      paddingLeft: "4px",
      fontWeight: 700,
      fontSize: "10px",
      lineHeight: "12px",
      letterSpacing: "5%",
      textTransform: "uppercase",
    },
    buttonTextMain: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      color: "#1E1029",
    },
    infoHeader: {
      fontSize: "18px",
      lineHeight: "21.6px",
      fontWeight: 700,
      paddingBottom: theme.spacing(1),
    },
    infoBody: {
      fontSize: "14px",
      lineHeight: "16.8px",
      fontWeight: 400,
      paddingBottom: theme.spacing(0.5),
    },
    inputLabelHeight: {
      [theme.breakpoints.down("xs")]: {
        minHeight: "20px",
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: "42px",
      },
    },
  });

interface State {
  title: string;
  why: string;
  websiteUrl: string;
  staffInstructions: string;
  redemptionDetails: string;
}

function ExperienceForm(props: any) {
  const {
    theme,
    classes,
    currentUser,
    setSelectedExperience,
    selectedExperience,
    selectedLocation,
    revenuePercent,
    reloadExperiences,
    setSelectedExperienceBoost,
    setOpen,
    adminId,
    partnerId,
    isApproval,
    isAdmin,
    experienceCategories,
  } = props;

  if (isAdmin && selectedExperience?.editedExperience !== undefined) {
    // show the experience edits
    convertExperienceToEditedExperience(selectedExperience);
  }

  const [selectedDate, handleDateChange] = useState<any>(new Date());
  const [location, setLocation] = useState<any>(locationBase);
  const [selectedTime] = useState<any>(new Date("2020-01-01T00:00:00.000Z"));
  const [validFiles, setValidFiles] = useState<any>([]);
  const [uploadUrls, setUploadUrls] = useState<any>([]);
  const [canSave, setCanSave] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState<any>({});
  const [imageSet, setImageSet] = useState<any>();
  const [startExperienceDate, setStartExperienceDate] = useState<MaterialUiPickersDate | undefined>();
  const [endExperienceDate, setEndExperienceDate] = useState<MaterialUiPickersDate | undefined>();
  const week = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const columnStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
  };

  function getDayAfterStartExperienceDate() {
    let newDate = new Date();
    if (!_.isNil(startExperienceDate)) {
      newDate = new Date(startExperienceDate);
    }
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  function getDayBeforeEndExperienceDate() {
    let newDate: Date;
    if (_.isNil(endExperienceDate)) {
      newDate = new Date();
      var year = newDate.getFullYear();
      var month = newDate.getMonth();
      var day = newDate.getDate();
      newDate = new Date(year + 100, month, day);
    } else {
      newDate = new Date(endExperienceDate);
      newDate.setDate(newDate.getDate() - 1);
    }
    return newDate;
  }

  function startAndEndDate() {
    return (
      <ThemeProvider theme={datePickerMUITheme}>
        <Box
          sx={{
            ...rowStyle,
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingBottom: 15,
          }}
        >
          <Box sx={{ ...columnStyle, width: 200 }}>
            <Typography className={classes.label}>Start Date</Typography>
            <CustomDateSelector
              maxDate={getDayBeforeEndExperienceDate()}
              value={startExperienceDate ?? null}
              maxDateMessage="Date cannot be after end date"
              minDateMessage="Date cannot be before Today"
              onChange={(date: MaterialUiPickersDate) =>
                updateExperienceStartDate(date)
              }
            />
          </Box>
          <Box sx={{ width: 20 }} />
          <Box sx={{ ...columnStyle, width: 200 }}>
            <Typography className={classes.label}>End Date</Typography>
            <CustomDateSelector
              minDate={getDayAfterStartExperienceDate()}
              value={endExperienceDate ?? null}
              minDateMessage="End date must be after today and after the start date"
              clearable={true}
              onChange={(date: MaterialUiPickersDate) =>
                updateExperienceEndDate(date)

              }
            />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
  const imageSetUpdated = (imageSet: any) => {
    setImageSet(imageSet);
  };
  const validateSave = (_exp: any) => {
    let _canSave = true;
    let error = {};
    if (!(_exp.title.length >= 3 && _exp.title.length <= 50)) {
      _canSave = false;
      error = {
        field: "title",
        msg: "Title must be provided, min. 3 characters",
      };
    } else if ((_exp.priorityValue < 1 || _exp.priorityValue > 10000)) {
      _canSave = false;
      error = {
        field: "priority",
        msg: "Priority must be between 1-10000",
      };
      setSubmitError({ field: "priority", msg: "Priority must be between 1-10000" });
    } else if ((_exp.order < 1 || _exp.order > 10000)) {
      _canSave = false;
      error = {
        field: "order",
        msg: "Featured order must be between 1-10000",
      };
      setSubmitError({ field: "order", msg: "Featured order must be between 1-10000" });
    } else if (_exp.price <= 0) {
      _canSave = false;
      error = { field: "price", msg: "Price must be provided" };
    } else if (
      !(_exp.discountedPrice > 0 && _exp.discountedPrice <= _exp.price)
    ) {
      _canSave = false;
      error = {
        field: "discountedPrice",
        msg: "Discounted price must be provided",
      };
    } else if (
      _exp.includedItems?.filter((i: string) => i.trim().length > 0).length <= 0
    ) {
      _canSave = false;
      error = {
        field: "includedItems",
        msg: "Need to specify what is included",
      };
    }
    if (!(
      validFiles.length ===
      validFiles.filter((file: any) => file.hasOwnProperty("imageUrl"))
        .length)
    ) {
      _canSave = false;
      error = { field: "image", msg: "Waiting for images to upload" };
      setSubmitError({ field: "image", msg: "Waiting for images to upload" });
    }
    if (!(_canSave === canSave && error === formError)) {
      setCanSave(_canSave);
      setFormError(error);
    }

    return _canSave;
  };

  const setSubmitError: Function = (error: { field: string, msg: string }) => {
    if (error !== formSubmitError) {
      setCanSubmit(false);
      setFormError(error);
    }
    return;
  }

  const validateSubmit = (_exp: any) => {
    let error: { field?: string, msg?: string } = {};
    if (_exp.staffInstructions.length <= 0 && _exp.type.value !== 'Ticket') {
      setSubmitError({
        field: "staffInstructions",
        msg: "Redemption instructions must be provided",
      });
      return;
    }
    if (_exp.type.value === 'Ticket' && _exp.redemptionDetails.length <= 0) {
      setSubmitError({
        field: "redemptionDetails",
        msg: "Redemption details must be provided",
      });
      return;
    }
    if (!validateSave(_exp)) {
      return;
    }
    if (
      _exp.includedItems?.filter((i: string) => i.trim().length > 0)
        .length <= 0
    ) {
      setSubmitError(error = {
        field: "includedItems",
        msg: "Need to specify what is included",
      });
      return;
    }
    if (isApproval && _.isEmpty(_exp.categories)) {
      setSubmitError(
        error = {
          field: "category",
          msg: "Need to specify the category",
        });
      return;
    }

    setCanSubmit(true);
    if (error !== formSubmitError) {
      setFormError(error);
    }
    return;
  };

  let quantity: any = [
    { value: 0, label: "Unlimited" },
    { value: 1000, label: "1000" },
    { value: 500, label: "500" },
    { value: 250, label: "250" },
  ];

  useEffect(() => {
    let buildExp = JSON.parse(JSON.stringify(experienceBase));
    if (
      selectedExperience !== undefined &&
      !_.isNil(selectedExperience.experienceId)
    ) {
      let _selectedExperience = JSON.parse(JSON.stringify(selectedExperience));
      if (isAdmin && isApproval && selectedExperience.editedExperience !== undefined) {
        // show the experience edits
        convertExperienceToEditedExperience(_selectedExperience);
      }
      _selectedExperience.price = Math.round(100 * _selectedExperience.price);
      _selectedExperience.discountedPrice = Math.round(
        100 * _selectedExperience.discountedPrice
      );
      _selectedExperience.mutualsCut = Math.round(
        _selectedExperience.discountedPrice * revenuePercent
      );
      if (
        _selectedExperience.quantity !== undefined &&
        _selectedExperience.quantity !== null
      ) {
        _selectedExperience.quantity = quantity.find((item: any) => {
          return item.value === _selectedExperience.quantity;
        });
      }
      if (_.isEmpty(_selectedExperience.addOns)) {
        _selectedExperience.addOns = [{ title: "", price: 0 }];
      }
      buildExp = createObjectBasedOnFirst(buildExp, _selectedExperience);
      setExperience(buildExp);
      setOriginalExperience(buildExp);
      validateSave(buildExp);
      validateSubmit(buildExp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExperience]);

  useEffect(() => {
    const fetchExperienceLocation = async () => {
      try {
        if (selectedLocation === locationBase) {
          const response = await getData(
            `locations/${selectedExperience.locationId}`,
            "GET",
            currentUser,
            {}
          );
          setLocation(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchExperienceLocation();
  }, [selectedLocation, currentUser, selectedExperience?.locationId]);

  const [experience, setExperience] = useState({
    ...JSON.parse(JSON.stringify(experienceBase)),
    hours: JSON.parse(JSON.stringify(location.hours)),
  });

  const [originalExperience, setOriginalExperience] = useState<any>({});
  const [experienceDidChange, setExperienceDidChange] = useState(false);

  useEffect(() => {
    setExperienceDidChange(originalExperience !== experience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience]);

  useEffect(() => {
    const filesNeedUpload = validFiles.filter(
      (file: any) => !file.hasOwnProperty("inProgress")
    );
    if (
      filesNeedUpload.length === uploadUrls.length &&
      filesNeedUpload.length > 0
    ) {
      for (let file of filesNeedUpload) {
        file.inProgress = true;
        const signedUrl = uploadUrls.shift();
        validateSubmit(experience);
        uploadFileToS3(signedUrl, file).then(
          () => {
            file.key = signedUrl.fields.key;
            file.imageUrl = `${Config.getValue(ConfigKey.S3_URL)}/${signedUrl.fields.key
              }`;
            validateSubmit(experience);
          },
          (err: any) => {
            console.error(err);
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadUrls]);

  const uploadFileToS3 = (signedUrl: any, file: any) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(signedUrl.fields).forEach((key) => {
        formData.append(key, signedUrl.fields[key]);
      });
      formData.append("file", file);
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener(
        "progress",
        (event) => {
          file.percent = Math.round((event.loaded / event.total) * 100);
          setValidFiles([...validFiles]);
        },
        false
      );
      xhr.open("POST", signedUrl.url, true);
      xhr.send(formData);
      xhr.onload = function () {
        this.status === 204 ? resolve(true) : reject(this.responseText);
        file.inProgress = false;
      };
    });
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const _exp = { ...experience, [prop]: event.target.value };
      setExperience(_exp);
      validateSave(_exp);
      if (prop === "staffInstructions" || prop === "redemptionDetails") {
        validateSubmit(_exp);
      }
    };

  const updateExperienceDiscountShow = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hideDiscountedPrice = !event.target.checked;
    if (hideDiscountedPrice) {
      setExperience({
        ...experience,
        hideDiscountedPrice,
        discountedPrice: experience.price,
        mutualsCut: Math.round(experience.price * revenuePercent)
      });
    } else {
      setExperience({
        ...experience,
        hideDiscountedPrice,
      });
    }
  };

  const updateExperienceStartDate = (startAt: MaterialUiPickersDate | undefined) => {
    const _exp = {
      ...experience,
      startAt: startAt,
    };
    setStartExperienceDate(startAt);
    setExperience(_exp);
  }

  const updateExperienceEndDate = (endAt: MaterialUiPickersDate | undefined) => {
    const _exp = {
      ...experience,
      endAt: endAt,
    };
    setEndExperienceDate(endAt);
    setExperience(_exp);
  }

  const updateExperienceCategory = (category: Option) => {
    const _exp = {
      ...experience,
      categories: [{ id: category.value, name: category.label }],
    };
    setExperience(_exp);
    validateSubmit(_exp);
  };

  const updateExperienceType = (type: any) => {
    setExperience({
      ...experience,
      type: { key: type.value, value: type.label },
    });
  };

  const updateExperienceQuantity = (_quantity: any) => {
    setExperience({
      ...experience,
      quantity: { value: _quantity.value, label: _quantity.label },
    });
  };

  const updateExperienceLimitPerUser = (_limitPerUser: any) => {
    setExperience({
      ...experience,
      limitPerUser: _limitPerUser.value,
    });
  };

  const updateAddOnTitle = (e: any, i: number) => {
    const value = e.target.value;
    experience.addOns[i].title = value;
    setExperience({ ...experience });
  };

  const addAddOn = () => {
    experience.addOns.push({ title: "", price: 0 });
    setExperience({ ...experience });
  };

  const removeAddOn = (index: number) => {
    experience.addOns.splice(index, 1);
    setExperience({ ...experience });
  };

  const updateIncludedItem = (e: any, i: number) => {
    const value = e.target.value;
    experience.includedItems[i] = value;
    setExperience({ ...experience });
    validateSave({ ...experience });
  };

  const addIncludedItem = () => {
    experience.includedItems.push("");
    setExperience({ ...experience });
    validateSave({ ...experience });
  };

  const removeIncludedItem = (index: number) => {
    experience.includedItems.splice(index, 1);
    setExperience({ ...experience });
    validateSave({ ...experience });
  };

  const imageAdded = (images: any) => {
    const extensions = [];
    const oldImages = validFiles.filter((file: any) =>
      file.hasOwnProperty("inProgress")
    );
    for (const image of images) {
      if (
        oldImages.length === 0 ||
        oldImages.findIndex(
          (file: any) => file.objectUrl === image.objectUrl
        ) === -1
      ) {
        extensions.push(image["type"].split("/").pop());
      }
    }
    if (extensions.length > 0) {
      getImageUploadURLs(extensions);
    }
    setValidFiles(images);
  };

  const submitForApproval = () => {
    saveForLater(true);
  };

  const approveExperience = async () => {
    experience.images = validFiles.map((file: any) => {
      if (file.key !== undefined) {
        return { imageId: file.key.split(".")[0], url: file.imageUrl };
      } else {
        return null;
      }
    });
    if (imageSet !== undefined) {
      experience.images = [...imageSet, ...experience.images];
    }
    const exp = { ...experience };
    exp.addOns = exp.addOns.filter(
      (addOn: any) => addOn.title.trim().length !== 0 && addOn.price > 0
    );
    if (exp.websiteUrl.trim().length === 0) {
      exp.websiteUrl = null;
    }
    delete exp.mutualsCut;
    exp.quantity = exp.quantity?.value ?? 0;
    if (exp.quantity === 0) {
      delete exp.quantity;
    }
    exp.price = exp.price / 100;
    exp.discountedPrice = exp.discountedPrice / 100;
    exp.includedItems = exp.includedItems.filter(
      (i: string) => i.trim().length > 0
    );
    if (!_.isNil(startExperienceDate)) {
      exp.startAt = startExperienceDate;
    }
    if (!_.isNil(endExperienceDate)) {
      exp.endAt = endExperienceDate;
    }
    try {
      if (experienceDidChange) {
        // API doesn't take webisteUrl or mutualsCut when updating experiences
        if (exp.websiteUrl === "" || _.isNil(exp.websiteUrl)) {
          delete exp.websiteUrl;
        }
        delete exp.mutualsCut;
        // updates the experience with any changes
        // unless the user is an admin?  Then we want to update, and then
        // merge the editExperience
        await getData(`experiences/${exp.experienceId}`, "PATCH", currentUser, exp);
      }

      if (isAdmin) {
        // merge the editExperience before approving
        await getData(
          `action-items?action_type=updateExperience&id=${exp.experienceId}`,
          "POST",
          currentUser,
          {
            approve: true,
          },
          undefined,
          true
        );
      }

      await getData(
        `action-items?action_type=experience&id=${exp.experienceId}`,
        "POST",
        currentUser,
        {
          approve: true,
        },
        undefined,
        true
      );
      setOpen(false);

    } catch (error) {
      console.error(error);
    }
    reloadExperiences();
  };
  /// Called when users taps "Edit" on an experience
  const saveForLater = async (setApproved: boolean = false) => {
    experience.images = validFiles.map((file: any) => {
      if (file.key !== undefined) {
        return { imageId: file.key.split(".")[0], url: file.imageUrl };
      } else {
        return null;
      }
    });

    if (imageSet !== undefined) {
      experience.images = [...imageSet, ...experience.images];
    }
    const exp = { ...experience };
    exp.addOns = exp.addOns.filter(
      (addOn: any) => addOn.title.trim().length !== 0 && addOn.price > 0
    );
    if (exp.websiteUrl.trim().length === 0) {
      exp.websiteUrl = null;
    }
    delete exp.mutualsCut;
    exp.quantity = exp.quantity.value;
    if (exp.quantity === 0) {
      delete exp.quantity;
    }
    exp.price = exp.price / 100;
    exp.discountedPrice = exp.discountedPrice / 100;
    let isUpdate = true;
    if (exp.experienceId === null) {
      isUpdate = false;
      delete exp.experienceId;
    }
    if (setApproved) {
      exp.approved = true;
    }
    exp.locationId = location.locationId;
    exp.includedItems = exp.includedItems.filter(
      (i: string) => i.trim().length > 0
    );
    try {
      if (adminId === partnerId) {
        exp.locationId = selectedLocation
          ? selectedLocation.locationId
          : exp.locationId
            ? exp.locationId
            : "test_location";
      }
      let experience;
      if (isUpdate) {
        // update the editExperience
        experience = await getData(
          `experiences/${exp.experienceId}`,
          "PATCH",
          currentUser,
          exp
        );
        // when an admin updates their own experience,
        // it doesn't have to be approved, so just merge
        // the edits in
        if (isAdmin) {
          await getData(
            `action-items?action_type=updateExperience&id=${exp.experienceId}`,
            "POST",
            currentUser,
            {
              approve: true,
            },
            undefined,
            true,
          );
        }
      } else {
        // create the experience
        experience = await getData(
          `experiences`,
          "POST",
          currentUser,
          exp
        );
      }
      if (!isUpdate) {
        experience.isNew = true;
        setSelectedExperienceBoost(experience);
        reloadExperiences();
      } else {
        reloadExperiences();
      }
      setSelectedExperience(undefined);
    } catch (error) {
      console.error(error);
    }
    setOpen(false);
  };

  const updatePrice = (value: any, index: number) => {
    if (value < experience.discountedPrice) {
      experience.discountedPrice = value;
    }
    const _exp = { ...experience, price: value };
    setExperience(_exp);
    validateSave(_exp);
  };

  const updateDiscountedPrice = (value: any) => {
    if (value > experience.price) {
      experience.price = value;
    }
    experience.mutualsCut = Math.round(value * revenuePercent);
    const _exp = { ...experience, discountedPrice: value };
    setExperience(_exp);
    validateSave(_exp);
  };

  const updatePriority = (event: any) => {
    const min = 1;
    const max = 10000;
    const _exp = { ...experience, priorityValue: Math.max(min, Math.min(max, Number(event.target.value.replace(/\D/g, '')))) };
    setExperience(_exp);
    validateSave(_exp);
  };

  const updateOrder = (event: any) => {
    const min = 1;
    const max = 10000;
    const _exp = { ...experience, order: Math.max(min, Math.min(max, Number(event.target.value.replace(/\D/g, '')))) };
    setExperience(_exp);
    validateSave(_exp);
  };

  const update = (value: any, index: number) => {
    experience.addOns[index].price = value;
    setExperience({ ...experience });
  };

  const getImageUploadURLs = async (extensions: Array<string>) => {
    try {
      const response = await getData(
        "experiences/imageUrls",
        "POST",
        currentUser,
        { extensions: extensions }
      );
      if (response !== null && response !== undefined && response.length > 0) {
        setUploadUrls([...uploadUrls.concat(response)]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateHours = (openHours: any) => {
    if (
      experience.hours !== undefined &&
      openHours !== undefined &&
      openHours !== null
    ) {
      const hours: {
        open: { day: number; time: string };
        close: { day: number; time: string };
      }[] = [];
      const date = new Date();
      Object.entries(openHours).forEach(([dayOfWeek, dayInfo]) => {
        if (dayInfo !== undefined) {
          const day: any = dayInfo;
          const dayIndex: number = week.indexOf(dayOfWeek);
          if (day.available) {
            if (day.hours !== undefined) {
              day.hours.forEach(
                (period: {
                  open: { hour: number; minute: number };
                  close: { hour: number; minute: number };
                }) => {
                  hours.push({
                    open: {
                      day: dayIndex,
                      time: formatTimeString(
                        date,
                        period.open.hour,
                        period.open.minute
                      ),
                    },
                    close: {
                      day: dayIndex,
                      time: formatTimeString(
                        date,
                        period.close.hour,
                        period.close.minute
                      ),
                    },
                  });
                }
              );
            }
          }
        }
      });
      hours.sort((a, b) =>
        a.open.day > b.open.day ? 1 : b.open.day > a.open.day ? -1 : 0
      );
      experience.hours = hours;
      setExperience(experience);
    }
  };

  let experienceTypes: any = [
    { value: 0, label: "Food" },
    { value: 1, label: "Attraction" },
    { value: 2, label: "Ticket" },
  ];
  let limitPerUserOptions: any = [
    { value: null, label: "Unlimited" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
  ];
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Card className={classes.root} elevation={0}>
        <div style={{ position: "absolute", top: "0.5em", right: "1em" }}>
          <IconButton
            color="inherit"
            onClick={() => setOpen(false)}
            style={{ padding: 0 }}
          >
            <CloseIcon style={{ height: "1em", width: "1em" }} />
          </IconButton>
        </div>

        <CardContent>
          <form>
            <InputLabel htmlFor="title" className={classes.label}>
              Write a title for your experience:
            </InputLabel>
            {formError.field === "title" && (
              <FormHelperText error>{formError.msg}</FormHelperText>
            )}
            <FormControl className={classes.inputField}>
              <Input
                id="title"
                className={classes.title}
                aria-describedby="Title of the experience"
                disableUnderline
                inputProps={{ maxLength: 50 }}
                onChange={handleChange("title")}
                placeholder="Start your title with a verb"
                value={experience.title}
                multiline={true}
              />
            </FormControl>

            <InputLabel
              htmlFor="why"
              className={classes.label}
            >{`Description (${experience.why.length}/500):`}</InputLabel>
            <FormControl className={classes.inputField}>
              <Input
                id="why"
                aria-describedby="Description of the experience"
                disableUnderline
                multiline
                placeholder={
                  "Explain what the daters will do, mention cool parts, " +
                  "and explain anything that is confusing for first time customers"
                }
                inputProps={{ maxLength: 500 }}
                onChange={handleChange("why")}
                value={experience.why}
              />
            </FormControl>
            <Divider className={classes.divider}></Divider>

            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              paddingBottom={theme.spacing(0.25)}
            >
              <Box
                display="flex"
                flexDirection="column"
                flex="1"
                paddingRight={theme.spacing(0.15)}
              >
                <InputLabel
                  htmlFor="type"
                  className={`${classes.label} ${classes.inputLabelHeight}`}
                >
                  Experience Type:
                </InputLabel>
                <FormControl className={classes.inputField}>
                  <Dropdown
                    controlClassName={classes.dropdownSelector}
                    placeholderClassName={classes.dropDownOption}
                    menuClassName={classes.dropdown}
                    className={classes.dropDownOption}
                    options={experienceTypes}
                    value={experience.type.value}
                    onChange={updateExperienceType}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flex="1"
                paddingLeft={theme.spacing(0.15)}
              >
                <InputLabel
                  htmlFor="quantity"
                  className={`${classes.label} ${classes.inputLabelHeight}`}
                >
                  Number of redemptions:
                </InputLabel>
                <FormControl className={classes.inputField}>
                  <Dropdown
                    controlClassName={classes.dropdownSelector}
                    placeholderClassName={classes.dropDownOption}
                    menuClassName={classes.dropdown}
                    className={classes.dropDownOption}
                    options={quantity}
                    value={experience.quantity?.label}
                    onChange={updateExperienceQuantity}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flex="1"
                paddingLeft={theme.spacing(0.15)}
              >
                <InputLabel
                  htmlFor="type"
                  className={`${classes.label} ${classes.inputLabelHeight}`}
                  style={{ paddingTop: !xs ? "0px" : "10px" }}
                >
                  Limit Per User:
                </InputLabel>
                <FormControl className={classes.inputField}>
                  <Dropdown
                    controlClassName={classes.dropdownSelector}
                    placeholderClassName={classes.dropDownOption}
                    menuClassName={classes.dropdown}
                    className={classes.dropDownOption}
                    options={limitPerUserOptions}
                    value={
                      limitPerUserOptions.find(
                        (item: any) => item.value === experience.limitPerUser
                      ) ?? limitPerUserOptions[0]
                    }
                    onChange={updateExperienceLimitPerUser}
                  />
                </FormControl>
              </Box>
            </Box>
            {isApproval && (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                paddingBottom={theme.spacing(0.25)}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  flex="1"
                  paddingRight={theme.spacing(0.15)}
                >
                  <InputLabel htmlFor="category" className={`${classes.label}`}>
                    Category:
                  </InputLabel>
                  {formError.field === "category" && (
                    <FormHelperText error>{formError.msg}</FormHelperText>
                  )}
                  <FormControl className={classes.inputField}>
                    <Dropdown
                      controlClassName={classes.dropdownSelector}
                      placeholderClassName={classes.dropDownOption}
                      menuClassName={classes.dropdown}
                      className={classes.dropDownOption}
                      options={experienceCategories}
                      value={
                        experience.categories != null &&
                          experience.categories.length > 0
                          ? experience.categories[0].id
                          : null
                      }
                      onChange={updateExperienceCategory}
                    />
                  </FormControl>
                </Box>
              </Box>
            )}

            {isApproval && startAndEndDate()}

            {experience.type.value === 'Ticket' && (<>
              <InputLabel
                htmlFor="redemptionDetails"
                className={classes.label}
              >{`Redemption instructions (${experience.redemptionDetails.length}/250):`}</InputLabel>
              <FormControl className={classes.inputField}>
                <Input
                  id="redemptionDetails"
                  aria-describedby="Description of how the experience will be provided"
                  disableUnderline
                  multiline
                  placeholder={
                    "Explain to the user where or how to redeem their tickets " +
                    "and explain how and when they will receive their tickets"
                  }
                  inputProps={{ maxLength: 250 }}
                  onChange={handleChange("redemptionDetails")}
                  value={experience.redemptionDetails}
                />
              </FormControl>
              <Divider className={classes.divider}></Divider>
            </>)}

            <InputLabel htmlFor="websiteUrl" className={classes.label}>
              Experience link (optional):
            </InputLabel>
            <FormControl className={classes.inputField}>
              <Input
                id="websiteUrl"
                aria-describedby="Website link to the experience"
                disableUnderline
                multiline
                placeholder="Paste a link to a related part of your website here"
                onChange={handleChange("websiteUrl")}
                value={experience.websiteUrl}
              />
            </FormControl>
            <Divider className={classes.divider}></Divider>

            <Box display="flex" flexDirection="column">
              {(formError.field === "price" ||
                formError.field === "discountedPrice") && (
                  <FormHelperText error>{formError.msg}</FormHelperText>
                )}
              <Box
                display="flex"
                flexDirection="row"
                paddingBottom={theme.spacing(0.25)}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  flex="1"
                  paddingRight={theme.spacing(0.15)}
                >
                  <InputLabel htmlFor="price" className={classes.label}>
                    Pre-Discount Price:
                  </InputLabel>
                  <FormControl className={classes.inputField}>
                    <CurrencyInput
                      aria-describedby="Price of the experience"
                      onValueChange={updatePrice}
                      value={experience.price}
                      index={0}
                      disabled={false}
                      placeholder={"Price"}
                      id="price"
                      max={99999999}
                      classes={classes}
                    />
                  </FormControl>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingRight={theme.spacing(0.15)}
                >

                  <InputLabel htmlFor="visibility" className={classes.label}>
                    VISIBILITY
                  </InputLabel>
                  <Box
                    display="flex"
                    flexDirection="row"
                    paddingRight={theme.spacing(0.15)}
                  >
                    <DateSwitch
                      onChange={updateExperienceDiscountShow}
                      checked={!experience.hideDiscountedPrice}
                    />
                    <InputLabel style={{ "paddingLeft": "0.5em" }} htmlFor="show" className={classes.label}>
                      SHOW
                    </InputLabel>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                paddingBottom={theme.spacing(0.25)}
              >

                <Box display="flex"
                  flexDirection="row"

                >
                  {(!experience.hideDiscountedPrice) ? (
                    <Box display="flex"
                      flexDirection="column"
                      flex="1"

                    >
                      <InputLabel
                        htmlFor="discountedPrice"
                        className={classes.label}
                      >
                        Discounted Price:
                      </InputLabel>
                      <FormControl className={classes.inputField}>
                        <CurrencyInput
                          aria-describedby="Discounted price of the experience"
                          onValueChange={updateDiscountedPrice}
                          value={experience.discountedPrice}
                          index={0}
                          disabled={false}
                          placeholder={"Discounted price"}
                          id="discountedPrice"
                          max={99999999}
                          classes={classes}
                        />
                      </FormControl>
                    </Box>
                  ) : (<p></p>)}
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    paddingLeft={theme.spacing(0.05)}
                  >
                    <InputLabel htmlFor="cut" className={classes.label}>
                      Mutual's Cut:
                    </InputLabel>
                    <FormControl className={classes.inputField}>
                      <CurrencyInput
                        aria-describedby="Mutual's cut of the experience"
                        disabled={true}
                        onValueChange={() => { }}
                        value={experience.mutualsCut}
                        index={0}
                        placeholder={"Mutual's Cut"}
                        id="cut"
                        max={99999999}
                        classes={classes}
                      />
                    </FormControl>
                  </Box>
                </Box>

              </Box>
            </Box>
            <Divider className={classes.divider}></Divider>
            {isAdmin && (<>
              <Box
                display="flex"
                flexDirection="column"
                flex="1"
                paddingBottom={theme.spacing(0.15)}
              >
                {formError.field === "priority" && (
                  <FormHelperText error>{formError.msg}</FormHelperText>
                )}
                <InputLabel
                  htmlFor="priority"
                  className={classes.label}
                >
                  CATEGORY PRIORITY
                </InputLabel>
                <FormControl className={classes.inputField}>
                  <Input
                    className={classes.dropdown}
                    inputMode="numeric"
                    aria-describedby="Order of the experiences within categories"
                    value={experience.priorityValue}
                    onChange={updatePriority}
                    disabled={false}
                    placeholder="1-10000"
                    id="priority"
                    classes={classes}
                  />
                </FormControl>
              </Box>
              <Divider className={classes.divider}></Divider>
              <Box
                display="flex"
                flexDirection="column"
                flex="1"
                paddingBottom={theme.spacing(0.15)}
              >
                {formError.field === "priority" && (
                  <FormHelperText error>{formError.msg}</FormHelperText>
                )}
                <InputLabel
                  htmlFor="order"
                  className={classes.label}
                >
                  FEATURED ORDER
                </InputLabel>
                <FormControl className={classes.inputField}>
                  <Input
                    className={classes.dropdown}
                    inputMode="numeric"
                    aria-describedby="Order of the experiences within featured section"
                    value={experience.order}
                    onChange={updateOrder}
                    disabled={false}
                    placeholder="1-10000"
                    id="order"
                    classes={classes}
                  />
                </FormControl>
              </Box>
              <Divider className={classes.divider}></Divider>
            </>)}
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={theme.spacing(0.25)}
            >
              <InputLabel htmlFor="includedItems" className={classes.label}>
                This date includes (List each item this date includes):
              </InputLabel>
              {formError.field === "includedItems" && (
                <FormHelperText error>{formError.msg}</FormHelperText>
              )}
              {experience.includedItems.map((e: any, i: any) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  key={i}
                  paddingBottom={theme.spacing(0.1)}
                  justifyContent="space-between"
                >
                  <Box flex={9}>
                    <FormControl className={classes.inputField}>
                      <Input
                        id="includedItem"
                        value={experience.includedItems[i]}
                        aria-describedby="An item that is included"
                        className={classes.dropdown}
                        disableUnderline
                        placeholder="Ex. Two medium ice creams"
                        onChange={(e) => updateIncludedItem(e, i)}
                      />
                    </FormControl>
                  </Box>
                  {i === experience.includedItems.length - 1 ? (
                    <Box
                      flex={4}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ExperiencesButton
                        variant="contained"
                        color="primary"
                        onClick={addIncludedItem}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <AddIcon fontSize="small" htmlColor="#FFFFFF" />
                          <Typography
                            className={classes.buttonText}
                            style={{ color: "#FFFFFF" }}
                          >
                            Add Item
                          </Typography>
                        </Box>
                      </ExperiencesButton>
                    </Box>
                  ) : (
                    <Box
                      flex={4}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => removeIncludedItem(i)}
                    >
                      <RemoveIcon fontSize="small" />
                      <Typography className={classes.buttonText}>
                        Remove
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Divider className={classes.divider}></Divider>

            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={theme.spacing(0.25)}
            >
              <InputLabel htmlFor="priceAddOn" className={classes.label}>
                Add Ons
              </InputLabel>
              {experience.addOns.map((e: any, i: any) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  key={i}
                  paddingBottom={theme.spacing(0.1)}
                >
                  <Box flex={4}>
                    <FormControl className={classes.inputField}>
                      <CurrencyInput
                        onValueChange={update}
                        index={i}
                        disabled={false}
                        value={experience.addOns[i].price}
                        placeholder={"Price"}
                        id="priceAddOn"
                        max={999999}
                        classes={classes}
                      />
                    </FormControl>
                  </Box>
                  <Box paddingX={theme.spacing(0.15)} flex={8}>
                    <FormControl className={classes.inputField}>
                      <Input
                        id="addOntitle"
                        value={experience.addOns[i].title}
                        aria-describedby="Title of add-on"
                        className={classes.dropdown}
                        disableUnderline
                        placeholder="Add on title"
                        onChange={(e) => updateAddOnTitle(e, i)}
                      />
                    </FormControl>
                  </Box>
                  {i === experience.addOns.length - 1 ? (
                    <Box
                      flex={5}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <ExperiencesButton
                        variant="contained"
                        color="primary"
                        onClick={addAddOn}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <AddIcon fontSize="small" htmlColor="#FFFFFF" />
                          <Typography
                            className={classes.buttonText}
                            style={{ color: "#FFFFFF" }}
                          >
                            Create Add On
                          </Typography>
                        </Box>
                      </ExperiencesButton>
                    </Box>
                  ) : (
                    <Box
                      flex={5}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => removeAddOn(i)}
                    >
                      <RemoveIcon fontSize="small" />
                      <Typography className={classes.buttonText}>
                        Remove
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Divider className={classes.divider}></Divider>

            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={theme.spacing(0.25)}
            >
              <InputLabel htmlFor="photos" className={classes.label}>
                Experience Photos:
              </InputLabel>
              {formSubmitError.field === "image" && (
                <FormHelperText error>{formSubmitError.msg}</FormHelperText>
              )}
              <Box display="flex" flexDirection="row">
                <PhotoUpload
                  width={100}
                  height={100}
                  callback={imageAdded}
                  images={experience.images}
                  imageSetUpdated={imageSetUpdated}
                  setFormSubmitError={setFormSubmitError}
                ></PhotoUpload>
              </Box>
            </Box>
            <Divider className={classes.divider}></Divider>

            {experience.type.value !== 'Ticket' && (<>
              <InputLabel htmlFor="staffInstructions" className={classes.label}>
                Redemption Instructions
              </InputLabel>
              {formSubmitError.field === "staffInstructions" && (
                <FormHelperText error>{formSubmitError.msg}</FormHelperText>
              )}
              <FormControl className={classes.inputField}>
                <Input
                  id="staffInstructions"
                  aria-describedby="Staff instructions for the experience"
                  disableUnderline
                  multiline
                  placeholder="Explain briefly how you'd like your staff to enter this deal into your POS system"
                  onChange={handleChange("staffInstructions")}
                  value={experience.staffInstructions}
                />
              </FormControl>
              <Divider className={classes.divider}></Divider>
            </>)}

            {!isApproval && (
              <StoreHours
                title={"Available Hours"}
                locationSelected={location}
                periods={experience.hours}
                updateHours={updateHours}
              ></StoreHours>
            )}
            {!isApproval && <Divider className={classes.divider}></Divider>}
            {!isAdmin && (
              <Box
                display="flex"
                flexDirection="column"
                paddingBottom={theme.spacing(0.25)}
              >
                <Typography className={classes.infoHeader}>
                  Experience Approval Process
                </Typography>
                <Typography className={classes.infoBody}>
                  {"After you submit this experience for approval," +
                    "you can continue to edit it until it is approved or we contact you with requested changes."}
                </Typography>
                <Typography className={classes.infoBody}>
                  {
                    "We do our best to approve these quickly, generally taking 1-2 business days."
                  }
                </Typography>
              </Box>
            )}
            <Box display="flex" flexDirection="column">
              <ExperiencesSubmitButton
                variant="contained"
                color="secondary"
                onClick={!isApproval ? submitForApproval : approveExperience}
                disabled={!canSubmit}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography className={classes.buttonTextMain}>
                    {isAdmin
                      ? !isApproval
                        ? "Save Changes"
                        : "Approve"
                      : experience.approved
                        ? "Resubmit For Approval"
                        : "Submit for Approval"}
                  </Typography>
                </Box>
              </ExperiencesSubmitButton>
              <Box padding={theme.spacing(0.1)}></Box>
              <ExperiencesSaveButton
                variant="contained"
                color="secondary"
                onClick={() =>
                  !isApproval ? saveForLater(false) : setOpen(false)
                }
                disabled={!canSave}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography className={classes.buttonTextMain}>
                    {!isApproval ? "Save for Later" : "Deny"}
                  </Typography>
                </Box>
              </ExperiencesSaveButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(ExperienceForm));