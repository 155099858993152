import firebase from "firebase/app";
import "firebase/auth";
import jwt_decode from 'jwt-decode';
import { isNil } from "lodash";

export const isUserAdmin = function (token: string | undefined): boolean {
  let decoded: any;
  if (token !== undefined && !isNil(token) && token.length !== 0) {
    decoded = jwt_decode(token);
  }
  if (decoded !== undefined && decoded.admin !== undefined && decoded.admin === true) {
    return true
  }
  return false;
};

export const getPartnerId = async function (token: string | undefined): Promise<string> {
  let decoded: any;
  if (token !== undefined && !isNil(token) && token.length !== 0) {
    decoded = jwt_decode(token);
  }
  if (decoded !== undefined && decoded.partnerId !== undefined) {
    return decoded.partnerId;
  }
  return '';
};

export const getAdminId = async function (token: string | undefined): Promise<string> {
  let decoded: any;
  if (token !== undefined && !isNil(token) && token.length !== 0) {
    decoded = jwt_decode(token);
  }
  if (decoded !== undefined && decoded.adminId !== undefined) {
    return decoded.adminId;
  }
  return '';
};

const handleError = function (err: any, other: string) {
  const code = err.code;
  const message = err.message;
  switch (code) {
    case 'auth/email-already-in-use':
      other = message;
      break;
    case 'auth/account-exists-with-different-credential':
      other = 'An account exists with the same email, sign in using a provider with this email address';
      break;
    case 'auth/credential-already-in-use':
      other = message;
      break;
  }
  return other;
}

export const authenticate = async function (
  email: string,
  password: string
): Promise<boolean | string> {
  let success = false;
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().signInWithEmailAndPassword(email, password);
    success = true;
  } catch (err) {
    return handleError(err, 'There was an error signing in with email/password');
  }
  return success;
};

export const createAccountEmail = async function (
  email: string,
  password: string
): Promise<boolean | string> {
  let success = false;
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().createUserWithEmailAndPassword(email, password);
    success = true;
  } catch (err) {
    return handleError(err, 'There was an error creating your account');

  }
  return success;
};

export const authenticateGoogle = async function (): Promise<boolean | string> {
  let success = false;
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithPopup(provider);
    if (userCredential.user?.uid !== undefined) {
      success = true;
    }
  } catch (err) {
    return handleError(err, 'There was an error signing in with Google');
  }
  return success;
}

export const authenticateFacebook = async function (): Promise<boolean | string> {
  let success = false;
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().useDeviceLanguage();
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithPopup(provider);
    if (userCredential.user?.uid !== undefined) {
      success = true;
    }
  } catch (err) {
    return handleError(err, 'There was an error signing in with Facebook');
  }
  return success;
}

export const sendPasswordResetEmail = async function (email: string): Promise<void> {
  await firebase.auth().sendPasswordResetEmail(email);
}

export const userIsAuthenticated = function () {
  return firebase.auth().currentUser;
};

export const unauthenticate = function () {
  firebase.auth().signOut();
};

export const resetFirebaseAuth = async function () {
  const token = await firebase.auth().currentUser?.getIdToken(true);
  return token;
}
