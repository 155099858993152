import { Box, Typography } from "@material-ui/core";
import { Fragment } from "react";

export function VerticalOption(props: any) {
	const { classes, icon, onClick, label } = props;
	return <Box
		display="flex"
		flexDirection="row"
		alignItems="center"
		paddingY={"4px"}
		className={classes.hoverButton}
		onClick={() => {
			onClick();
		}}
		style={{
			fontWeight: 700,
			fontSize: "18px",
			lineHeight: "21.6px",
			width: "100%",
			margin: "5px",
			cursor: "pointer",
		}}
	>
		<Box
			display="flex"
			flexDirection="row"
			justifyContent="center"
			flex={1}
		>
			{icon ? <>{icon}</> : <Fragment />}
		</Box>
		<Box display="flex" flexDirection="row" flex={4}>
			<Typography
				style={{
					fontWeight: 700,
					fontSize: "18px",
					lineHeight: "21.6px",
					color: "#5C5C5C",
					whiteSpace: "pre-line"
				}}
				display="block"
			>
				{label}
			</Typography>
		</Box>
	</Box>;
}