import React, { useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import TopBannerLinks from "../TopBannerLinks/TopBannerLinks";
import ToniteLogo from "../../assets/tonite-logo.svg";
import { sendPasswordResetEmail } from "../../services/auth";

const styles = (theme: Theme) =>
  createStyles({
    loginFormBaseStyle: {
      position: "absolute" as "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    loginFormStyle: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-evenly",
      flex: 5,
      borderRadius: "20px",
      padding: "1rem",
      marginTop: "3vw",
      maxHeight: "700px",
    },
    titleStyle: {
      fontWeight: "bold" as "bold",
      fontSize: "24px",
      lineHeight: "29px",
    },
    formStyle: {
      display: "flex",
      flexFlow: "column",
      paddingBottom: "1rem",
    },
    inputFieldStyle: {
      padding: "0.5rem 0",
    },
    errorText: {
      color: "#FE2A2A",
      maxWidth: "30vw",
    },
    cancel: {
      fontSize: "15px",
      lineHeight: "18px",
      color: "#4DC9E4",
      fontWeight: 400,
    },
    button: { width: "100%" },
    toniteLogoStyle: {
      marginTop: "2vw",
      userDrag: "none",
      WebkitUserDrag: "none",
    },
    spacerStyle: {
      flex: 3,
    },
  });

function ResetPassword(props: any) {
  const { classes, setResetPassword } = props;

  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (email.length > 0) {
      //eslint-disable-next-line
      const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let disabled = false;
      if (!regexEmail.test(email)) {
        setEmailError(true);
        disabled = true;
      } else if (emailError === true) {
        setEmailError(false);
      }
      setDisabled(disabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const resetPassword = async () => {
    await sendPasswordResetEmail(email);
    setEmail('');

    setResetPassword(false);
  }

  return (
    <Box className={classes.loginFormBaseStyle}>
      <TopBannerLinks />
      <img
        src={ToniteLogo}
        alt="Tonite logo"
        className={classes.toniteLogoStyle}
      />
      <Box
        className={classes.loginFormStyle}
        style={{
          maxHeight: xs
            ? `${(90 * 705) / 1190}vw`
            : sm
              ? `${(65 * 705) / 1190}vw`
              : md
                ? `${(40 * 705) / 1190}vw`
                : lg
                  ? `${(30 * 705) / 1190}vw`
                  : `${(20 * 705) / 1190}vw`,
          minWidth: xs
            ? "90vw"
            : sm
              ? "65vw"
              : md
                ? "40vw"
                : lg
                  ? "30vw"
                  : "20vw",
        }}
      >
        <h1 className={classes.titleStyle}>Reset Password</h1>
        <form className={classes.formStyle}>
          <FormControl className={classes.inputFieldStyle}>
            <InputLabel htmlFor="email" shrink>
              Email
            </InputLabel>
            <Input
              id="email"
              type="email"
              onChange={(event?) =>
                setEmail(event != null ? event.target.value : "")
              }
            />
            {emailError && (
              <FormHelperText id="emailError" className={classes.errorText}>
                Email must be a valid email
              </FormHelperText>
            )}
          </FormControl>

          <Box paddingY={theme.spacing(0.25)} width={"100%"}>
            <Button
              variant="contained"
              color="secondary"
              disabled={disabled}
              onClick={() => resetPassword()}
              className={classes.button}
            >
              Reset Password
            </Button>
          </Box>
          <Box
            width={"100%"}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Button onClick={() => setResetPassword(false)}>
              <Typography className={clsx(classes.cancel, classes.button)}>
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
      <div className={classes.spacerStyle}></div>
    </Box>
  );
}

export default withStyles(styles)(ResetPassword);
