import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Theme,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import { useState } from "react";
import { Spinner } from "react-activity";
import { boxShadow } from "../../services/util";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "350px",
        maxWidth: "350px",
      },
      [theme.breakpoints.up("md")]: {
        width: "500px",
        maxWidth: "500px",
      },
      minHeight: "95%",
    },
    button: {
      width: "100%",
      height: "50px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "6px 8px",
      boxShadow: boxShadow,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
      textAlign: "center",
    },
  });

function SwitchPartnerDialog(props: any) {
  const { theme, classes, partner, changePartner, open, setOpen } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const switchPartner = async () => {
    setLoading(true);
    await changePartner(partner.partnerId);
    setLoading(false);
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Card className={classes.root} elevation={0}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <Box
            style={{ height: "300px", padding: "16px" }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div></div>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.title}>
                Switch view to {partner.fullName ?? partner.email}
              </Typography>
            </Box>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                m={5}
                style={{ padding: "10px 15px" }}
              >
                <Spinner />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignSelf="center"
                justifyContent="end"
                width="100%"
              >
                <Button
                  className={classes.button}
                  onClick={() => switchPartner()}
                >
                  SWITCH VIEW
                </Button>
                <Box padding={theme.spacing(0.1)}></Box>
                <Button
                  className={classes.button}
                  onClick={() => setOpen(false)}
                >
                  CANCEL
                </Button>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(SwitchPartnerDialog));
