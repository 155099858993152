import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import TopBannerLinks from "../components/TopBannerLinks/TopBannerLinks";
import ToniteLogo from "../assets/tonite-logo.svg";
import Background from "../assets/landingBackground.png";
import { withRouter } from "react-router-dom";

function TermsOfService(props: any) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const loginFormBaseStyle = {
    position: "absolute" as "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const loginFormStyle = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-evenly",
    flex: 5,
    borderRadius: "20px",
    padding: "1rem",
    width: xs ? "80vw" : sm ? "75vw" : md ? "70vw" : lg ? "80vw" : "70vw",
    marginTop: "3vw",
  };

  const toniteLogoStyle = {
    maxWidth: "100%",
    marginTop: "2vw",
    userDrag: "none",
    WebkitUserDrag: "none",
  };

  const terms = {
    lastUpdated: "December 29th, 2020",
  };

  const mainTitle = {
    color: "rgb(227, 106, 99)",
    fontSize: "20px",
    fontWeight: 700,
  };

  const mainSubHeader = {
    color: "rgb(227, 106, 99)",
    fontSize: "15px",
    fontWeight: 700,
  };

  const header = {
    color: "rgb(59, 59, 59)",
    fontSize: "15px",
    fontWeight: "bold" as "bold",
    padding: "1rem 0",
  };

  const subheader = {
    color: "rgb(59, 59, 59)",
    fontSize: "15px",
    padding: "0.5rem 0",
  };

  const text = {
    color: "rgb(59, 59, 59)",
    fontSize: "15px",
    fontWeight: 400,
  };

  const spacerStyle = {
    height: "2rem",
  };

  const termBlock = {
    paddingBottom: "1rem",
  };

  const backgroundStyle = {
    background: "url(" + Background + "), #22112F",
    backgroundSize: "cover",
    opacity: 0.9,
    width: "100%",
    height: "100%",
    position: "fixed" as "fixed",
    backgroundBlendMode: "soft-light, normal",
    mixBlendMode: "multiply" as "multiply",
    backgroundPosition: "center center",
  };

  const orderedList = {
    padding: "0.5rem 0",
    marginLeft: "2rem",
    fontWeight: 700,
  };

  return (
    <div>
      <div style={backgroundStyle}></div>
      <div>
        <div style={loginFormBaseStyle}>
          <TopBannerLinks />
          <img
            src={ToniteLogo}
            alt="Tonite logo"
            style={toniteLogoStyle}
          />
          <div style={loginFormStyle}>
            <Grid container>
              <Grid container item xs={1} sm={1}></Grid>
              <Grid container item xs={10} sm={10}>
                <Box display="flex" flexDirection="column">
                  <Typography style={{ ...mainTitle }}>
                    Tonite Partner Terms of Use
                  </Typography>
                  <Typography
                    style={{ ...mainSubHeader }}
                  >{`Last updated ${terms.lastUpdated}`}</Typography>
                  <br />
                  <p style={{ ...text }}>
                    BY ACCEPTING THIS AGREEMENT, BY (1) CLICKING A BOX
                    INDICATING ACCEPTANCE OR (2) EXECUTING AN ORDER FORM THAT
                    REFERENCES THESE TERMS, PARTNER AGREES TO THE TERMS OF THIS
                    AGREEMENT.
                  </p>
                  <br />
                  <p style={{ ...text }}>
                    IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS ACCEPTING ON
                    BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL
                    REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH ENTITY
                    AND ITS AFFILIATES TO THESE TERMS, IN WHICH CASE THE TERM
                    “PARTNER” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF
                    THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES NOT HAVE SUCH
                    AUTHORITY, OR DOES NOT AGREE WITH THESE TERMS AND
                    CONDITIONS, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT
                    AND MAY NOT USE THE SERVICES.
                  </p>
                  <br />
                  <p style={{ ...text }}>
                    SECTION 17 OF THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN
                    HOW CLAIMS THAT THE PARTIES HAVE AGAINST EACH OTHER ARE
                    RESOLVED. INCLUDING WITHOUT LIMITATION A MANDATORY
                    ARBITRATION PROVISION.
                  </p>
                  <br />
                  <div style={{ ...termBlock }}>
                    <p style={{ ...header }}>1. GENERAL.</p>
                    <p style={{ ...text }}>
                      These Tonite Partner Terms and Conditions ("Terms")
                      are hereby accepted and agreed to by the company
                      identified within the Tonite sign-up process
                      (“Partner” or “You"), and constitute a legally binding
                      agreement by and between Partner and Mutual LLC (“Mutual,”
                      “Company,” “we,” or “us”). These Terms may be subject to
                      and/or incorporate the Tonite Order Form (each, an
                      “Order Form,” and all such Order Forms together with these
                      Terms collectively, the “Agreement”). Partner’s access to
                      and use of the Tonite Program (as defined below) is
                      subject to the Agreement and may be modified or updated by
                      Mutual from time to time, effective upon posting an
                      updated version of these Terms on the Mutual website.
                      Partner is responsible for updating contact information
                      and regularly reviewing the Terms for updates and
                      information from Mutual. Continued use of the Tonite
                      Services after any such modifications or updates shall
                      constitute Partner’s consent to such changes. Capitalized
                      terms used but not otherwise defined in the Terms shall
                      have the respective meanings ascribed to such terms in the
                      applicable Order Form.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>2. SERVICES.</p>
                    <p style={{ ...subheader }}>2.1 Items and Services.</p>
                    <p style={{ ...text }}>
                      Mutual and its affiliates make available certain
                      proprietary technology services that facilitate the
                      marketing and sale of orders or vouchers (“Vouchers”) for
                      Items (as defined below) from Partner to Customers (as
                      defined below), including on-demand lead generation,
                      payment processing, marketing, advertising and promotional
                      services, proprietary information services, onboarding,
                      operational and other support services (“Tonite
                      Program”).
                    </p>
                    <p style={{ ...text }}>
                      “Items” are defined as goods, services or experiences (or
                      vouchers therefor) that Partner offers and sells to
                      customers or end users (“Customers”) for personal use
                      and/or consumption. Items includes, without limitation,
                      Restaurant Items (as defined below).
                    </p>
                    <p style={{ ...text }}>
                      “Restaurant Items” are defined as foods and beverages that
                      are typically sold by restaurants in a form intended for
                      immediate consumption on-premises, for take-out, and/or
                      delivery. Mutual shall retain sole discretion whether an
                      Item constitutes a non-Restaurant Item.
                    </p>
                    <p style={{ ...subheader }}>2.2 Partner Technology.</p>
                    <p style={{ ...text }}>
                      In connection with the Tonite Program, Mutual and
                      its affiliates may also make available to Partner a
                      website, mobile application or other technology interface
                      for Partner to access and use the Tonite Program
                      (collectively, the “Tonite Services”), which may
                      include Mutual’s and its affiliates’ proprietary
                      technology platform through which insights and analytics
                      regarding Partner’s performance and history using the
                      Tonite Services are provided, and through which
                      Partner may, among other things, receive, accept and
                      fulfill requests for Vouchers/Items from Customers.
                    </p>
                    <p style={{ ...subheader }}>2.3 Mutual App.</p>
                    <p style={{ ...text }}>
                      Mutual and its affiliates may also make available to
                      Customers its proprietary technology that enables
                      Customers to purchase Vouchers from Partner (“Mutual
                      App”). Mutual is authorized to promote and sell Vouchers
                      on Partner’s behalf, subject to the terms of this
                      Agreement. Each such Voucher will evidence the applicable
                      Item and will be sent to the Customer electronically upon
                      receipt by Mutual of payment therefor. The Customer may
                      then redeem the Voucher for the applicable Item with
                      Partner by presenting such Voucher prior to its expiration
                      date in paper or electronic form at the applicable
                      Location (as defined below). For clarity, Partner is the
                      issuer of Vouchers and the seller of the Items.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>3. MUTUAL’S OBLIGATIONS.</p>
                    <p style={{ ...subheader }}>3.1 Tonite Services.</p>
                    <p style={{ ...text }}>
                      Subject to the terms and conditions of this Agreement,
                      Mutual and its affiliates will make available the
                      applicable Tonite Services to Partner, solely for
                      use by Partner at locations that are owned and operated by
                      Partner (each, a “Location”). Partner shall provide Mutual
                      current and accurate Location information throughout the
                      Term of this Agreement. In connection with the provision
                      of Tonite Services to Partner, Mutual and its
                      affiliates, on behalf of Partner, may respond to
                      complaints by Partner’s customers (“Customers”) about
                      Items sold by Partner via the Mutual App. For the
                      avoidance of doubt, as between Partner and Mutual, Mutual
                      will retain sole and absolute control over the Mutual App
                      (and all elements of the user experience and user
                      interface relating to the Mutual App), including with
                      respect to: (i) the personalization of the Mutual App for
                      Customers; (ii) the prioritization and display of options
                      available to Customers; (iii) the search functionality and
                      results provided to Customers; and (iv) adding, removing
                      or otherwise modifying any feature or functionality made
                      available through the Mutual App to optimize reliability
                      or efficiency on the Mutual App.
                    </p>
                    <p style={{ ...subheader }}>
                      3.2 Technology, Not Delivery, Services.
                    </p>
                    <p style={{ ...text }}>
                      Partner agrees neither Mutual nor its affiliates provide
                      any delivery services. Rather, Mutual provides technology
                      services that enable Partner to connect with Customers who
                      may purchase Items or Vouchers from Partner.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>4. PARTNER’s OBLIGATIONS.</p>
                    <p style={{ ...subheader }}>4.1 Availability of Items.</p>
                    <p style={{ ...text }}>
                      Partner will make Items purchased through the Mutual App
                      redeemable during its normal business hours, honor
                      Vouchers as if they were cash purchases of Items, and
                      ensure the description of each Item and Location (as
                      defined below) is accurate. The expiration date for
                      Vouchers shall be ninety (90) days from the date such
                      Voucher is purchased by a Customer unless Partner clearly
                      and conspicuously states a different expiration date for
                      such Voucher in its listing. Partner will provide Mutual
                      with prior notice of any change in the description of any
                      Item (including its Retail Price) or Location. Partner
                      will train its employees, contractors and other personnel
                      regarding the Tonite Program and how to accept
                      Vouchers presented for redemption. Partner will provide,
                      prepare, handle, store, label and package, as applicable,
                      all Items in accordance with applicable laws and
                      regulations, including without limitation all laws, rules
                      and regulations governing consumer health and safety
                      and/or time or temperature controls required for food
                      safety (“Consumer Safety Standards”). Partner will
                      determine any quality, quantity, duration, portion, size,
                      ingredient or other criteria that apply to Items
                      (“Criteria”), and Partner is responsible for ensuring that
                      all Items meet the applicable Criteria. If Partner fails
                      to prepare or supply Items in accordance with Consumer
                      Safety Standards or if any Item fails to meet the Criteria
                      (each, a “Substandard Item”), Mutual may, in its sole
                      discretion, remove such Item from the Mutual App. Items
                      that contain (or may contain) an endangered species may
                      not be made available for purchase through, and will be
                      removed from, the Mutual App. Partner represents and
                      warrants that all information for Items, including without
                      limitation, descriptions of activities, nutrition
                      information, calorie count or allergen information, that
                      is made available through the Mutual App is, and at all
                      times will remain, accurate. In addition, Partner will
                      ensure that the contents of its listings, including each
                      Item’s Criteria and any notifications about ingredients,
                      nutritional information, allergen information, etc., are
                      accurate and comply with all applicable laws and
                      regulations.
                    </p>
                    <p style={{ ...subheader }}>4.2 Item Responsibility.</p>
                    <p style={{ ...text }}>
                      Partner acknowledges and agrees that Mutual does not take
                      title to or responsibility for any Item at any time.
                      Notwithstanding, Partner shall be responsible for any
                      reimbursement costs related to Customer refunds for
                      substandard Items or other related issues within Partner’s
                      control (including any costs associated with returns of
                      any such substandard Items or otherwise unsatisfactory
                      Item(s), if applicable)), including by way of example,
                      missing or incomplete Items, Items not cooked thoroughly,
                      and Items not prepared or delivered in accordance with
                      Partner’s internal standards and specifications. Mutual
                      may, in its sole discretion, deduct reimbursement costs
                      from the payment Mutual remits to Partner in accordance
                      with this Section 4. To the extent required by applicable
                      law, Items are sold to Customers under Partner’s retail
                      and/or food license privileges. Partner agrees to direct
                      Customers and to instruct Partner’s employees, contractors
                      and other personnel to direct Customers to Mutual for any
                      requests for refunds; provided, that once the Item Revenue
                      for any Item/Voucher has been remitted by Mutual to
                      Partner, Mutual will no longer issue refunds for such
                      Item/Voucher. Partner agrees that Customers may cancel any
                      Voucher prior to redemption for a full refund via the
                      Mutual App, and Mutual may, at its option, issue refunds
                      to Customers in the form of credits to be used for other
                      purchases through the Mutual App.
                    </p>
                    <p style={{ ...subheader }}>4.3 Use Restrictions.</p>
                    <p style={{ ...text }}>
                      In connection with the access to and use of the Tonite
                      Services and Mutual App, Partner will not (and will
                      not allow any third party to): (i) reverse engineer or
                      attempt to discover any source code or underlying ideas or
                      algorithms used to provide the Tonite Services and
                      Mutual App (except to the extent applicable law prohibits
                      reverse engineering restrictions); (ii) provide, lease,
                      lend, disclose, or otherwise use or allow others to use,
                      in each case, for the direct benefit of any third party,
                      the Tonite Services and Mutual App (except as
                      otherwise authorized by Mutual); or (iii) possess or use,
                      or allow the transfer, transmission, export, or re-export
                      of any software or portion thereof in violation of any
                      export control laws or regulations administered by the
                      U.S. Commerce Department, U.S. Treasury Department's
                      Office of Foreign Assets Control, or any other government
                      agency. Partner will not (and will not allow any third
                      party to) use the Tonite Services and Mutual App or
                      any other transactional, operational, performance or other
                      data or information that is related to the sale of Items
                      to Customers through the Mutual App to directly or
                      indirectly compete with Mutual or its affiliates or the
                      Tonite Services.
                    </p>
                    <p style={{ ...subheader }}>4.4 Item Restrictions.</p>
                    <p style={{ ...text }}>
                      The following restricted Items may not be featured or sold
                      via the Mutual App: people, illegal items, dangerous items
                      (like weapons, explosives, flammables, etc.), alcohol,
                      stolen goods, endangered species or items containing
                      endangered species or any items that Partner does not have
                      permission to offer. Mutual may remove from—or otherwise
                      limit your ability to post to—a Partner’s Tonite
                      account any Items Mutual deems prohibited or
                      inappropriate.
                    </p>
                    <p style={{ ...subheader }}>4.5 Gratuities.</p>
                    <p style={{ ...text }}>
                      Unless otherwise selected by Partner, Partner agrees to
                      allow Customers to provide gratuities through the Mutual
                      App. Mutual shall remit to Partner the full value of any
                      gratuities provided by Customers. It is the sole
                      responsibility of the Partner to comply with all
                      applicable laws (including tax, gratuity, social security
                      and employment laws where applicable) regarding the
                      distribution of any gratuities.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>5. FEES AND TAXES.</p>
                    <p style={{ ...subheader }}>5.1 Fees; Calculation.</p>
                    <p style={{ ...text }}>
                      For each Item (or Voucher therefor) sold by Partner via
                      the Mutual App, Partner will pay Mutual as follows: the
                      Retail Price (as defined below) of all Items that Partner
                      sells via the Mutual App and that are redeemed by the
                      Customer (excluding any Sales Tax collected on Partner’s
                      behalf) multiplied by the applicable fee percentage set
                      forth below or in the Order Form pursuant to which each
                      such Item is sold (“Fee”). The Fee does not include any
                      applicable taxes. Mutual will remit to Partner the total
                      Retail Price collected for all Vouchers for Items that
                      Partner sells via the Mutual App (including any Sales
                      Taxes collected on its behalf) following redemption of
                      such Vouchers less: (a) the applicable retained Fee; (b)
                      any refunds given to Customers; and (c) third party
                      transaction costs, including without limitation, credit
                      card processing fees (such final remitted amount being
                      “Item Revenue”). All Item Revenue that is duly owed to
                      Partner (i.e., for Vouchers that have been redeemed) will
                      be remitted within fourteen (14) business days of the
                      redemption of the Item, or if the payment method is via
                      check, by the 20th of the month following such redemption.
                    </p>
                    <p style={{ ...text }}>
                      Unless otherwise agreed to by the parties or modified by
                      requirement of applicable laws or regulations, the Fee
                      shall be equal to percent set forth in the applicable
                      Order Form for each Item/Voucher sold via the Mutual App
                      and redeemed by a Customer. For clarity, in the event any
                      Voucher or Item is not redeemed by a Customer within the
                      time period specified for such Voucher/Item at the time
                      such Customer purchased the Voucher for such Item, or in
                      the absence of any specified time period, 90 days from the
                      date of purchase of such Voucher, Mutual will have no
                      obligation to remit Item Revenue related to such Item to
                      Partner unless the Voucher was a reservation for a
                      specified date and time.
                    </p>
                    <p style={{ ...text }}>
                      If required by applicable law or regulation, Mutual may
                      adjust the Fee. Such adjustment may apply only to certain
                      Items. All Fees under this Agreement will be paid in U.S.
                      Dollars. Mutual or its affiliates will deduct the Fee from
                      the payment Mutual collects on Partner’s behalf, as
                      detailed in Section 5.3 below. Mutual reserves the right
                      to suspend Partner’s ability to make Items available for
                      purchase by Customers through the Mutual App if Partner’s
                      account is in arrears. Except as may be expressly agreed
                      in this Agreement, each party will be responsible for its
                      expenses and costs during its performance under this
                      Agreement.
                    </p>
                    <p style={{ ...subheader }}>5.2 Retail Prices; Taxes.</p>
                    <p style={{ ...text }}>
                      Tonite Services connect you with Customers who wish
                      to purchase your Items. You are the “merchant”,
                      “retailer”, or “seller” of all Vouchers and Items to be
                      made available for sale via the Mutual App. As such, you
                      are responsible for determining and setting the retail
                      price for each Item (the “Retail Price”), and you are
                      ultimately responsible for the collection and remittance
                      of all applicable Sales Taxes, where required under
                      applicable law. The term “Sales Tax” includes any sales,
                      sellers use, transaction privilege, privilege, general
                      excise, gross receipts, and similar transaction taxes, as
                      well as any bottle, bag, plastic, or other similar fees.
                      For the sake of clarity, the Retail Price for each Item
                      excludes separately stated Sales Taxes.
                    </p>
                    <p style={{ ...text }}>
                      Partner hereby authorizes Mutual to collect applicable
                      Sales Taxes on Partner's behalf based on information
                      provided by Partner through the Tonite Services. The
                      Tonite Services’ functionality may be based on
                      interpretations of federal, state, and local laws and
                      regulations and information provided by taxing
                      authorities. Partner’s use of the Tonite Services,
                      including any communications with Mutual, in no way
                      constitutes the provision of legal or tax advice.
                    </p>
                    <p style={{ ...text }}>
                      Partner shall promptly notify Mutual if it believes any
                      charges (or lack of charges) for Sales Taxes were
                      erroneous or inaccurate. If Sales Taxes charged by Partner
                      are not in accordance with (or in violation of) any law or
                      regulation, Mutual expressly reserves the right to, upon
                      prior notice to Partner, remove affected Items from the
                      Mutual App and/or deactivate Partner from the Mutual App.
                    </p>
                    <p style={{ ...text }}>
                      Certain legislation commonly known as “marketplace
                      facilitator” laws (“Marketplace Facilitator Laws”) may
                      require Mutual to collect and remit Sales Taxes directly
                      to the taxing authority. In jurisdictions with Marketplace
                      Facilitator laws in effect that are applicable to Mutual
                      due to this Agreement (each a “Marketplace Facilitator
                      Jurisdiction” beginning the effective date of such
                      legislation), Mutual may determine, as of a date specified
                      by Mutual (“Switchover Date(s)”), the amount of applicable
                      Sales Tax which Mutual will collect and remit to the
                      taxing authority based on Item descriptions and Additional
                      Information provided by Partner. For the avoidance of
                      doubt, for each Marketplace Facilitator Jurisdiction: (i)
                      Mutual will continue to collect Sales Taxes on behalf of,
                      and remit such amounts to, Partner until the applicable
                      Switchover Date and (ii) beginning on the applicable
                      Switchover Date, any covered Sales Taxes will be collected
                      by Mutual and remitted to the applicable tax authority on
                      Mutual’s own account, and not on behalf of Partner.
                    </p>
                    <p style={{ ...subheader }}>5.3 Pricing</p>
                    <p style={{ ...text }}>
                      Notwithstanding anything to the contrary in this Section
                      5, Partner may not make any Item available to Customers
                      through the Mutual App at a price that is higher than the
                      price that Partner charges in-store for similar Items.
                      Partner agrees that you will not make an Item available
                      under this Agreement at a price higher than the amount
                      Partner is charging for similar Items through any
                      comparable platform for food delivery services.
                    </p>
                    <p style={{ ...subheader }}>
                      5.4 Appointment of Limited Payment Collection Agent.
                    </p>
                    <p style={{ ...text }}>
                      Partner is solely responsible for providing Mutual with,
                      and maintaining, accurate bank account information.
                      Partner hereby appoints Mutual and its affiliates, as the
                      case may be, as Partner’s limited payment collection agent
                      solely for the purpose of: (i) accepting payment of the
                      Retail Price of Items sold by Partner via the Mutual App
                      plus any applicable Sales Tax collected on Partner’s
                      behalf, via the payment processing functionality
                      incorporated in the Mutual App, and (ii) remitting Item
                      Revenue to Partner. Further, Partner agrees that payment
                      collected on its behalf by Mutual or its affiliates will
                      be considered the same as payment made directly to
                      Partner. Partner agrees that if Partner does not receive
                      payment from Mutual or its affiliates, Partner’s only
                      recourse will be against Mutual and its affiliates. Mutual
                      and its affiliates may, from time to time, request
                      information from Partner to confirm Partner’s identity as
                      may be necessary under any applicable compliance
                      obligations before remitting any amounts to Partner and
                      may refuse to process amounts owed to Partner if there
                      exists a legal or regulatory risk or potential breach of
                      law or regulation associated with such remittance to
                      Partner. In the event Partner’s bank account information
                      is not accurate, Mutual shall be entitled to send one or
                      more checks representing any Item Revenue then due to
                      Partner to Partner’s most recent address on file with
                      Mutual, and Mutual shall thereafter have no further
                      obligation related to such Item Revenue. Partner agrees
                      that Mutual and its affiliates may describe or otherwise
                      reflect the terms of this Section, and any related
                      portions of the applicable Order Form, in any terms of
                      use, receipts, disclosures, or notices that may be deemed
                      necessary or prudent. If reasonable, Mutual may adjust the
                      remittance of Item Revenue collected on Partner’s behalf
                      for reasons including failure to fulfill an Item as
                      purchased or making a correction on an Item. Partner may
                      identify any disagreements in connection with such
                      adjustments through Partner’s Tonite Program
                      account. Mutual and its affiliates reserve the right to
                      collect any amounts in connection with such adjustments
                      via a deduction from the remittance of Item Revenue
                      collected on Partner’s behalf, by debiting the payment
                      method or Partner’s bank account on record, or otherwise
                      seeking reimbursement from Partner by any lawful
                      collection methods available. Partner authorizes Mutual
                      and its affiliates to use any or all of the above methods
                      to seek such adjustments and reimbursements. In more
                      serious situations, such as fraud (including any charges
                      for Items that Customers did not place) or Customer
                      complaints, Mutual and its affiliates reserve the right to
                      cancel a payment entirely. By agreeing to these terms,
                      Partner gives Mutual and its affiliates express consent to
                      adjust payments collected on Partner’s behalf as set forth
                      in this Section.
                    </p>
                    <p style={{ ...subheader }}>5.5 Additional Information.</p>
                    <p style={{ ...text }}>
                      Mutual may, from time to time, require Partner to provide
                      certain additional information (“Additional Information”)
                      pertaining to particular Items or particular sales of
                      Items for the proper determination, calculation,
                      collection, and remittance of Sales Taxes, or to comply
                      with other applicable laws or regulations. Additional
                      Information may include, but is not limited to: Universal
                      Product Codes (“UPCs”), Global Trade Item Numbers
                      (“GTINs”), Stock Keeping Units (“SKUs”), ingredients,
                      temperature, container, weight, volume, quantities,
                      serving/portion size, nutritional facts, inclusion of
                      utensils, method of preparation (e.g., sliced), identity
                      of preparer, whether the item is “ready-to-eat”, or
                      intended use. Partner is solely responsible for providing
                      requested Additional Information to Mutual in a timely
                      manner. If Partner fails to timely provide Additional
                      Information in response to notification and request by
                      Mutual, Mutual expressly reserves the right to temporarily
                      remove affected Items from the Mutual App until such
                      Additional Information is received.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>6. REPORTING.</p>
                    <p style={{ ...text }}>
                      Mutual may provide Partner aggregate information regarding
                      the number of Items sold to and/or redeemed by Customers
                      via the Mutual App. Mutual will also provide reasonable
                      information regarding any refunds given to Customers,
                      including the date of the transaction, the Item purchased
                      and the reason for the refund, subject to the restrictions
                      and requirements of applicable privacy laws and Mutual’s
                      then-current Privacy Policy. To the extent applicable,
                      Partner agrees that Mutual may share Partner’s
                      transactional data regarding purchased Items with
                      Partner’s parent company or franchisor. Partner shall
                      comply with all applicable data privacy and security laws
                      in its use, handling, storage and processing of any
                      information or data received from Mutual pursuant to this
                      Agreement.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>
                      7. INTELLECTUAL PROPERTY; MARKETING AND PROMOTIONAL
                      ACTIVITIES.
                    </p>
                    <p style={{ ...subheader }}>7.1 Marks.</p>
                    <p style={{ ...text }}>
                      Subject to the terms of this Agreement, and unless Partner
                      opts out pursuant to an applicable Order Form, each party
                      hereby grants to the other party (and, in the case of
                      Mutual, to its affiliates) a limited, non-exclusive and
                      non-transferable license during the Term to use such
                      party’s respective Marks in the territory, on a
                      royalty-free basis, solely in connection with the
                      activities related to this Agreement or any other
                      activities relating to the Tonite Program. For
                      purposes of this Agreement, the term “Marks” will mean the
                      trademarks, service marks, trade names, copyrights, logos,
                      slogans, content, media, materials, identifying symbols
                      and indicia of the applicable party. All uses of a party’s
                      Marks by the other party will be in the form and format
                      specified or approved by the owner of such marks. Other
                      than as specifically set forth in this Agreement, neither
                      party will use the other party’s Marks without the prior,
                      express, written consent of the other party (by email is
                      sufficient). For the avoidance of doubt, however, any use
                      or display of Partner’s Marks by Mutual or its affiliates
                      in connection with making Items available through the
                      Mutual App in the ordinary course of business, including
                      without limitation, displaying Partner’s Marks and profile
                      photo on the confirmation screen for Customers in the
                      Mutual App, will not require any such prior, express,
                      written consent. Partner further agrees that any use or
                      display of Mutual’s Marks will conform to any written
                      guidelines provided by Mutual or otherwise made available
                      to Partner. All goodwill related to the use of a party’s
                      Marks by the other party will inure to the benefit of the
                      owner of such Marks. Except as expressly set forth herein,
                      neither party will be deemed to grant the other party any
                      license or rights under any intellectual property or other
                      proprietary rights. All rights not granted are expressly
                      reserved. Without limiting anything in the Agreement,
                      Partner represents and warrants that Partner’s Marks do
                      not infringe, misappropriate, or otherwise violate any
                      third party’s intellectual property or other proprietary
                      rights. Partner agrees that Mutual or its affiliates may
                      remove Partner’s Marks from the Mutual App if Mutual or
                      its affiliates receive notice or otherwise reasonably
                      believe that such Partner’s Marks may infringe,
                      misappropriate, or otherwise violate any intellectual
                      property or other proprietary rights.
                    </p>
                    <p style={{ ...subheader }}>7.2 No Development.</p>
                    <p style={{ ...text }}>
                      EACH PARTY ACKNOWLEDGES AND AGREES THAT THERE SHALL BE NO
                      DEVELOPMENT OF TECHNOLOGY, CONTENT, MEDIA OR OTHER
                      INTELLECTUAL PROPERTY BY EITHER PARTY FOR THE OTHER PARTY
                      PURSUANT TO THIS AGREEMENT. Any development activities
                      relating to any technology, content, media or other
                      intellectual property must be the subject of a separate
                      written agreement between Mutual and Partner prior to the
                      commencement of any such activities.
                    </p>
                    <p style={{ ...subheader }}>7.3 Marketing.</p>
                    <p style={{ ...text }}>
                      Mutual and its affiliates may showcase the availability of
                      Partner’s Items via the Mutual App through various
                      promotional activities (e.g., through social media
                      channels, websites, advertisements, or blogs). Partner and
                      its affiliates may showcase the availability of Partner’s
                      Items via the Mutual App through various promotional
                      activities, including posting marketing or promotional
                      materials regarding Partner’s participation in the Tonite
                      on-site at each Location and/or online (e.g.,
                      through social media channels, websites, advertisements,
                      or blogs). Mutual (or a party designated by Mutual acting
                      on Mutual’s behalf) may take video and still images for
                      marketing and other efforts related to the Mutual App
                      (“Mutual Photographs”). Partner agrees that Mutual
                      Photographs (including all intellectual property rights
                      therein) are and will remain the sole and exclusive
                      property of Mutual or its affiliates. Additionally,
                      Partner may provide videos, still image or other materials
                      to Mutual or its affiliates (“Partner Marketing
                      Materials”) for use in connection with the display of
                      Partner’s Items on the Mutual App or the marketing and
                      promotion of the Tonite Program and the availability
                      of Partner’s Items via the Mutual App. Partner hereby
                      grants Mutual and its affiliates a non-exclusive,
                      perpetual, fully paid-up and royalty free license to use
                      and display such Partner Marketing Materials in connection
                      with Partner’s Items and other promotional activities
                      relating to the Tonite Program. Without limiting
                      anything in the Agreement, Partner represents and warrants
                      that the Partner Marketing Materials do not infringe,
                      misappropriate, or otherwise violate any third party’s
                      intellectual property or other proprietary rights. To the
                      extent that the Partner Marketing Materials contain any
                      third party materials, Partner is solely responsible for
                      and will secure any and all rights, licenses, consents and
                      permissions necessary for Mutual to be able to use the
                      Partner Marketing Materials in accordance with this
                      Section. Partner agrees that Mutual or its affiliates may
                      remove Partner Marketing Materials from the Mutual App if
                      Mutual or its affiliates receive notice or otherwise
                      reasonably believe that such Partner Marketing Materials
                      may infringe, misappropriate, or otherwise violate any
                      intellectual property or other proprietary rights.
                    </p>
                    <p style={{ ...subheader }}>
                      7.4 Boosted Experience; Promotions.
                    </p>
                    <p style={{ ...text }}>
                      “Boosted Experience(s)” means campaigns in which Partner
                      pays Mutual a mutually agreed upon (in writing) amount per
                      impression for specified Items through the Mutual App.
                      When a Boosted Experience campaign has been accepted by
                      Mutual, Mutual agrees to provide enhanced promotional
                      placement for such Items in the Mutual App until the
                      Boosted Experience achieves the purchased number of
                      impressions or otherwise expires in accordance with its
                      terms.
                    </p>
                    <p style={{ ...text }}>
                      “Promotion(s)” means short-term offers that are available
                      through the Mutual App to stimulate Customer demand. When
                      a Promotion is successfully applied to a purchase, Partner
                      authorizes Mutual to charge Customers for the
                      post-Promotional value of an Item (not including taxes and
                      applicable fees). Subject to Mutual App functionality,
                      Mutual may, at its sole discretion, provide enhanced
                      promotional placement or other visual treatment for a
                      Promotion.
                    </p>
                    <ol type="a" style={{ ...orderedList }}>
                      <li style={{ ...text }}>
                        Boosted Experience(s). Subject to any other guidelines
                        or eligibility criteria for Boosted Experiences that
                        Mutual may make available from time to time, Mutual
                        hereby authorizes Partner to order Boosted Experiences.
                        Mutual is authorized to charge Partner’s payment card or
                        account on file with Mutual for the campaign fees for
                        any such Boosted Experience, or deduct such campaign
                        fees from any amounts due and payable to Partner, or if
                        none, Mutual may send Partner an invoice for such
                        campaign fees, which invoice will be due and payable
                        within thirty (30) days following the date of such
                        invoice. Once ordered by Partner, a Boosted Experience
                        campaign may be terminated early only with Mutual’s
                        written consent (by email sufficient). Notwithstanding
                        anything to the contrary in this Agreement, Mutual may
                        suspend or terminate any Boosted Experience campaign if
                        Partner is delinquent in its payment obligations or
                        otherwise in breach of this Agreement.
                      </li>
                      <li style={{ ...text }}>
                        Promotion(s). From time to time, Mutual may, in its sole
                        discretion, elect to run a Promotion funded fully by
                        Mutual unless Partner agrees to fund any portion
                        thereof. For each such Promotion, Mutual shall provide
                        Partner with a description of the Promotion and any
                        other details regarding the Promotion that Mutual deems
                        relevant, and Mutual shall issue an adjustment to
                        Partner’s payout (which shall also be reflected in any
                        payout details report) to account for the amount of the
                        Promotion that Mutual funds, such that the Partner shall
                        receive the same amount in their Item Revenue for each
                        Voucher purchased pursuant to a Promotion as if a
                        Mutual-funded portion of the Promotion was not applied
                        to such purchase.
                      </li>
                      <li style={{ ...text }}>
                        Parties’ Obligations. The parties’ obligations for each
                        Boosted Experience or Promotion, as applicable, will
                        include the following, but may be expanded upon by the
                        mutual written agreement of the parties.
                      </li>
                      <ol type="i" style={{ ...orderedList }}>
                        <li style={{ ...text }}>
                          Partner’s Obligations. Partner will: (A) honor and
                          fulfill the terms of each Item purchased by Customers
                          through the Mutual App in connection with a Boosted
                          Experience or Promotion; (B) be responsible for the
                          fees associated with the Promotion up to the amount
                          Partner has agreed to fund for such Promotion, if any;
                          and (C) upon reasonable request, supply Mutual with
                          marketing materials, including but not limited to,
                          photographs, graphics, audio, video, and copy, which
                          Mutual may opt to use in its sole discretion, without
                          payment of any license or other fees and which do not
                          violate the rights of any third party. Notwithstanding
                          anything to the contrary in this Agreement, Partner
                          acknowledges and agrees that Partner will not be able
                          to terminate the Agreement while a Boosted Experience
                          or Promotion is live.
                        </li>
                        <li style={{ ...text }}>
                          Mutual’s Obligations. Mutual will (A) honor and
                          fulfill the terms of Promotions offered by Mutual
                          (solely or jointly with Partner) to Customers who have
                          successfully completed their purchase through the
                          Mutual App during such Promotion; (B) be responsible
                          for the fees associated with the Promotion less the
                          amount, if any, that Partner has agreed to fund; (C)
                          provide enhanced promotional placement in the Mutual
                          App for Items that are part of a Boosted Experience
                          campaign until the Boosted Experience achieves the
                          purchased number of impressions or the campaign
                          otherwise expires in accordance with its terms; and
                          (D) use good faith efforts to provide Partner with
                          reasonable information regarding Promotions and
                          Boosted Experiences, which may include, without
                          limitation, the number of Items sold in connection
                          with Promotions and the number of impressions recorded
                          for Boosted Experiences.
                        </li>
                      </ol>
                      <li style={{ ...text }}>
                        Fee on Promotion Purchases. Notwithstanding anything to
                        the contrary in this Agreement, if a Customer
                        successfully applies a Promotion to a purchase through
                        the Mutual App, the Fee therefor shall be calculated
                        based on the total Retail Value of the purchase minus
                        the Partner-funded portion of such Promotion, if any,
                        applied to that purchase.
                      </li>
                      <li style={{ ...text }}>
                        Out of Mutual App Marketing. Unless otherwise agreed by
                        Mutual, Partner may not market or otherwise advertise a
                        Promotion or Boosted Experience outside the Mutual App.
                        If Mutual authorizes Partner to market a Promotion or
                        Boosted Experience out of the Mutual App, all such
                        marketing materials will be subject to Mutual’s prior
                        review and written approval, which shall not be
                        unreasonably withheld.
                      </li>
                    </ol>
                    <p style={{ ...subheader }}>7.5 Publicity.</p>
                    <p style={{ ...text }}>
                      Except as may be expressly set forth in this Agreement or
                      otherwise agreed by the parties in writing, neither party
                      may issue a press release or otherwise refer to the other
                      party in any manner with respect to this Agreement or
                      otherwise, without the prior written consent of such other
                      party.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>
                      8. PROPRIETARY INFORMATION; PERSONAL DATA; FEEDBACK.
                    </p>
                    <p style={{ ...subheader }}>8.1 Definition.</p>
                    <p style={{ ...text }}>
                      “Proprietary Information” means any confidential,
                      proprietary or other non-public information disclosed by
                      or on behalf of one party (“Discloser”) to the other
                      (“Recipient”), whether disclosed verbally, in writing, or
                      by inspection of tangible objects, and includes
                      transactional, operational, performance and other data or
                      information that is related to the sale of Partner’s Items
                      to Customers through the Mutual App and the terms and
                      conditions of this Agreement. Proprietary Information will
                      not include information that: (i) was previously known to
                      the Recipient without an obligation of confidentiality;
                      (ii) was acquired by the Recipient without any obligation
                      of confidentiality from a third party with the right to
                      make such disclosure; (iii) is or becomes publicly
                      available through no fault of the Recipient; or (iv) is
                      independently developed by Recipient without use of or
                      reference to Discloser’s Proprietary Information. Each
                      Recipient agrees that it will not disclose to any third
                      parties other than Representatives, or use in any way
                      other than as necessary to perform this Agreement, the
                      Discloser’s Proprietary Information. Each Recipient will
                      ensure that Proprietary Information will be made available
                      to only Recipient’s affiliates and Recipient’s and
                      Recipient’s affiliates officers, directors, employees and
                      agents who have a need to know such Proprietary
                      Information and who, prior to any disclosure of such
                      Proprietary Information, are bound by written obligations
                      of confidentiality with respect to such Proprietary
                      Information that are no less stringent than those set
                      forth in this Agreement (each, a “Representative”).
                      Recipient will cause its Representatives to comply with
                      the terms of this Agreement and will be solely responsible
                      for any breach of this Agreement by any of its
                      Representatives. Each Recipient will not, and will not
                      authorize others to, remove or deface any notice of
                      copyright, trademark, logo, legend, or other notices of
                      ownership from any originals or copies of the Discloser’s
                      Proprietary Information. The foregoing prohibition on use
                      and disclosure of Proprietary Information will not apply
                      to the extent: (i) the Discloser has authorized such use
                      or disclosure (and Partner hereby authorizes Mutual and
                      its Affiliates to disclose the terms of this Agreement to
                      Partner’s franchisees and/or franchisor as applicable in
                      connection with executing contracts that reference this
                      Agreement) and (ii) a Recipient is required to disclose
                      certain Proprietary Information of the Discloser as a
                      matter of law or by order of a court, provided that the
                      Recipient gives the Discloser prior written notice of such
                      obligation to disclose to the extent legally permissible
                      and reasonably assists in obtaining a protective order
                      prior to making such disclosure. Upon expiration or
                      termination of this Agreement and as requested by
                      Discloser, each Recipient will deliver to the Discloser
                      (or destroy at the Discloser’s election) any and all
                      materials or documents containing the Discloser’s
                      Proprietary Information, together with all copies thereof
                      in whatever form.
                    </p>
                    <p style={{ ...subheader }}>8.2 Privacy.</p>
                    <p style={{ ...text }}>
                      Partner agrees to use, disclose, store, retain or
                      otherwise process Personal Data solely for the purpose of
                      providing Items under this Agreement. Partner will
                      maintain the accuracy and integrity of any Personal Data
                      provided by Mutual and in Partner’s possession, custody or
                      control. Partner agrees to retain Personal Data provided
                      to Partner by Mutual solely by using the software and
                      tools provided by Mutual. “Personal Data” means any
                      information obtained in connection with this Agreement (i)
                      relating to an identified or identifiable natural person;
                      (ii) that can reasonably be used to identify or
                      authenticate an individual, including name, contact
                      information, precise location information, persistent
                      identifiers, and (iii) any information that may otherwise
                      be considered “personal data” or “personal information”
                      under the applicable law.
                    </p>
                    <p style={{ ...subheader }}>8.3 Passwords.</p>
                    <p style={{ ...text }}>
                      Partner is responsible for maintaining the integrity of
                      information related to Partner’s access and use of the
                      Tonite Services, including any password, login or
                      key information. Partner represents and warrants that
                      Partner will not share such information with any third
                      party.
                    </p>
                    <p style={{ ...subheader }}>
                      8.4 Data Re-Identification Restriction.
                    </p>
                    <p style={{ ...text }}>
                      Without limiting any other provision of this Agreement,
                      including any provision in this Section 8, Partner will
                      not merge any of the data collected or otherwise obtained
                      in connection with this Agreement, including any Personal
                      Data, with other data collected from any source or
                      otherwise use any of the data collected or otherwise
                      obtained in connection with this Agreement, including any
                      Personal Data, for the purpose of re-identification,
                      targeted marketing, or any other similar purpose.
                    </p>
                    <p style={{ ...subheader }}>8.5 Feedback.</p>
                    <p style={{ ...text }}>
                      Partner may, but is not obligated to, submit ideas,
                      suggestions, and/or proposals to Mutual regarding Mutual’s
                      and its affiliate’s products and services (“Feedback”).
                      Mutual has no obligation to Partner (including without
                      limitation any obligation of confidentiality) with respect
                      to such Feedback. Partner represents and warrants that
                      Partner has all rights necessary to submit the Feedback.
                      Partner hereby grants to Mutual a perpetual, irrevocable,
                      worldwide, non-exclusive, fully paid-up, royalty-free, and
                      fully sublicensable right and license to use, store, copy,
                      distribute, publicly perform, communicate to the public,
                      host, stream, publicly display, modify and create
                      derivative works of, and otherwise exploit in any manner,
                      any and all Feedback.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>9. RATINGS.</p>
                    <p style={{ ...text }}>
                      Partner acknowledges and agrees that, after receiving
                      Item(s), a Customer may be prompted by the Mutual App to
                      provide a rating of such Item(s) and, at such Customer’s
                      option, to provide comments or feedback related to the
                      Customer’s experience with Partner and the relevant
                      Item(s) on the Mutual App (“Customer Feedback”). Mutual
                      and its affiliates reserve the right to use, share, and
                      display Customer Feedback in any manner in connection with
                      the business of Mutual and its affiliates without
                      attribution to or approval of Partner. Partner
                      acknowledges that Mutual and its affiliates are
                      distributors (without any obligation to verify) and not
                      publishers of Customer Feedback, provided that Mutual and
                      its affiliates reserve the right to edit or remove
                      comments in the event that such comments include
                      obscenities or other objectionable content, include an
                      individual’s name or other Personal Data, violate any
                      privacy or other applicable laws, or Mutual’s or its
                      affiliates’ content policies or Community Standards (as
                      defined below).
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>
                      10. REPRESENTATIONS AND WARRANTIES; DISCLAIMER.
                    </p>
                    <p style={{ ...subheader }}>
                      10.1 Representations and Warranties.
                    </p>
                    <p style={{ ...text }}>
                      Each party hereby represents and warrants that: (i) it has
                      full power and authority to enter into this Agreement and
                      perform its obligations hereunder; (ii) it is duly
                      organized, validly existing and in good standing under the
                      laws of the jurisdiction of its origin; (iii) it has not
                      entered into, and during the Term will not enter into, any
                      agreement that would prevent it from complying with or
                      performing under this Agreement; (iv) it will comply with
                      all applicable laws and regulations (including without
                      limitation all applicable consumer protection, data
                      protection and privacy laws and, in the case of Partner,
                      all applicable Consumer Safety Standards) in the
                      performance of this Agreement and any activities
                      (including without limitation, Partner’s provision of
                      goods or services to Customers) hereunder; and (v) the
                      Marks used or provided by one party to the other pursuant
                      to this Agreement shall not infringe or otherwise violate
                      the intellectual property rights, rights of publicity, or
                      other proprietary rights of any third party. In addition,
                      Partner further represents and warrants that to the extent
                      Partner has franchisees who participate in any activities
                      under this Agreement, Partner will ensure that such
                      franchisees will comply with, and be subject to, the
                      applicable provisions of this Agreement when participating
                      in such activities.
                    </p>
                    <p style={{ ...subheader }}>10.2 DISCLAIMER.</p>
                    <p style={{ ...text }}>
                      EXCEPT AS SET FORTH HEREIN, MUTUAL MAKES NO
                      REPRESENTATIONS, AND HEREBY EXPRESSLY DISCLAIMS ALL
                      WARRANTIES, EXPRESS OR IMPLIED, REGARDING ITS SERVICES OR
                      PRODUCTS OR ANY PORTION THEREOF, INCLUDING ANY IMPLIED
                      WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE AND IMPLIED
                      WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF
                      PERFORMANCE. NEITHER MUTUAL, ITS SUBSIDIARIES AFFILIATES
                      NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, MERCHANTS,
                      THIRD-PARTY CONTENT PROVIDERS OR LICENSORS, OR ANY OF
                      THEIR OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS, WARRANT
                      THAT USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR FREE;
                      NOR DO THEY MAKE ANY WARRANTY AS TO (I) THE RESULTS THAT
                      MAY BE OBTAINED FROM USE OF THE TONITE SERVICES OR
                      MUTUAL APP, OR (II) THE ACCURACY, COMPLETENESS,
                      RELIABILITY OR CONTENT OF ANY INFORMATION (INCLUDING, BUT
                      NOT LIMITED TO, PRODUCT DESCRIPTIONS), SERVICE, PRODUCTS
                      OR VOUCHERS PROVIDED THROUGH THE TONITE SERVICES OR
                      MUTUAL APP. THE TONITE SERVICES, THE MUTUAL APP AND
                      ALL CONTENT, STATEMENTS (AS DEFINED BELOW) AND OTHER
                      INFORMATION CONTAINED THEREON, AND PRODUCTS AND SERVICES
                      ACCESSIBLE OR AVAILABLE THROUGH THE TONITE SERVICES
                      OR MUTUAL APP ARE MADE ACCESSIBLE OR AVAILABLE TO PARTNER
                      ON AN “AS IS” AND “AS AVAILABLE” BASIS. MUTUAL HEREBY
                      DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES AND
                      CONDITIONS, WHETHER EXPRESS OR IMPLIED, AS TO THE
                      OPERATION OF THE TONITE SERVICES, THE MUTUAL APP, OR
                      THE CONTENT, STATEMENTS OR OTHER INFORMATION CONTAINED
                      THEREON, OR THE PRODUCTS OR SERVICES ACCESSIBLE OR
                      AVAILABLE THROUGH THE TONITE SERVICES OR MUTUAL APP,
                      INCLUDING, BUT NOT LIMITED TO, THOSE OF TITLE
                      NONINFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A
                      PARTICULAR PURPOSE.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>11. INDEMNITY.</p>
                    <p style={{ ...subheader }}>11.1 Indemnified Claims.</p>
                    <p style={{ ...text }}>
                      Partner will indemnify, defend and hold harmless Mutual,
                      its affiliates and respective directors, officers,
                      employees and agents (each, an “Indemnified Party”) from
                      and against any and all claims, damages, liabilities,
                      causes of action, and losses (including reasonable
                      attorney’s fees) (collectively, “Losses”) with respect to
                      any third party claim arising out of or related to: (i)
                      the negligence or willful misconduct of the Partner or its
                      employees or agents in their performance of this
                      Agreement; (ii) any claims that, if true, would be a
                      breach of any of Partner’s representations, warranties or
                      covenants in this Agreement; (iii) any loss, injury or
                      damage that is or may be sustained by any Customer arising
                      from or in connection with Partner’s participation in the
                      Tonite Program or a Customer’s use of Hotel
                      facilities, services or products, including (but not
                      limited to) loss of life, personal injury or property
                      damage; or (iv) any claims that the Marks provided by
                      Partner infringe a third party’s intellectual property
                      rights, to the extent the Indemnified Party used such
                      Marks in accordance with the manner approved by the
                      Indemnifying Party. In addition, you will indemnify,
                      defend and hold harmless the Indemnified Parties from and
                      against any and all Losses with respect to any third party
                      claim arising out of or related to: (A) Partner’s
                      violation or alleged violation of any applicable retail
                      food or other health and safety code, rule or regulation;
                      (B) Sales Tax applicable to sales of Restaurant Items in
                      non-Marketplace Facilitator Jurisdictions; (C) Partner’s
                      failure to provide accurate and complete descriptions or
                      Additional Information for Restaurant Items in Marketplace
                      Facilitator Jurisdictions; or (D) Sales Tax applicable to
                      all sales of non-Restaurant Items, except in the case of
                      each of (A)-(D) above, to the extent such harm was
                      directly caused by the gross negligence or willful
                      misconduct of Mutual or its employees or agents.
                    </p>
                    <p style={{ ...subheader }}>11.2 Procedure.</p>
                    <p style={{ ...text }}>
                      We will provide you prompt written notice of any potential
                      claim subject to indemnification hereunder. You will
                      assume the defense of the claim through counsel you
                      designate, however, such counsel must be reasonably
                      acceptable to the Indemnified Party. You will not settle
                      or compromise any claim, or consent to the entry of any
                      judgment, without written consent of the Indemnified
                      Party, which will not be unreasonably withheld. The
                      Indemnified Party will reasonably cooperate with the
                      Indemnifying Party in the defense of a claim, at
                      Indemnifying Party’s expense.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>12. LIMITS OF LIABILITY.</p>
                    <p style={{ ...text }}>
                      EXCEPT FOR LIABILITY ARISING FROM A PARTY’S GROSS
                      NEGLIGENCE, WILLFUL MISCONDUCT, INDEMNIFICATION
                      OBLIGATIONS OR A BREACH OF CONFIDENTIALITY OBLIGATIONS:
                      (A) IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY CLAIM
                      FOR ANY INDIRECT, WILLFUL, PUNITIVE, INCIDENTAL,
                      EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES, FOR LOSS OF
                      BUSINESS PROFITS, OR DAMAGES FOR LOSS OF BUSINESS OF
                      PARTNER OR ANY THIRD PARTY ARISING OUT OF THIS AGREEMENT,
                      OR LOSS OR INACCURACY OF DATA OF ANY KIND, WHETHER BASED
                      ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, EVEN IF SUCH
                      PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES;
                      AND (B) EACH PARTY’S TOTAL CUMULATIVE LIABILITY OF EACH
                      AND EVERY KIND UNDER THIS AGREEMENT WILL NOT EXCEED THE
                      AMOUNTS PAID TO MUTUAL PURSUANT TO THIS AGREEMENT IN THE
                      TWELVE (12) MONTHS PRIOR TO THE EVENTS GIVING RISE TO SUCH
                      LIABILITY. THE FOREGOING LIMITATION OF LIABILITY AND
                      EXCLUSION OF CERTAIN DAMAGES WILL APPLY REGARDLESS OF THE
                      SUCCESS OR EFFECTIVENESS OF OTHER REMEDIES.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>13. INSURANCE.</p>
                    <p style={{ ...text }}>
                      During the Term and for one (1) year thereafter, Partner
                      will maintain Commercial General Liability and, if
                      required by law, Worker’s Compensation insurance. The
                      Commercial General Liability insurance policy limits will
                      be One Million Dollars ($1,000,000) combined single limit
                      per occurrence for bodily injury, death and property
                      damage liability, and Two Million Dollars ($2,000,000) in
                      aggregate. All policies will be written by reputable
                      insurance companies with a Best’s policyholder rating of
                      not less than A-. Upon Mutual’s request, Partner will
                      provide evidence of the insurance required herein. In no
                      event will the limits of any policy be considered as
                      limiting the liability of a party under this Agreement.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>14. SUPPLEMENTAL TERMS.</p>
                    <p style={{ ...text }}>
                      Partner will comply with the applicable then-current
                      Community Standards, currently available at
                      https://www.mutual.app/standards (“Community Standards”).
                      In addition, the Tonite Services or the Mutual App
                      may contain or require use of third-party software that
                      requires notices or additional terms and conditions
                      (collectively, with the Community Standards, “Supplemental
                      Terms”). Such required third-party software notices or
                      additional terms and conditions shall be provided to
                      Partner from time to time and are incorporated by
                      reference into this Agreement. Supplemental Terms will
                      prevail over the Agreement in the event of a conflict.
                      Mutual will use good faith efforts to provide Partner with
                      written notice of any material updates to the Supplemental
                      Terms, and, if Partner does not agree to comply with the
                      terms of any such update, Partner may, as its sole and
                      exclusive remedy, terminate this Agreement and cease use
                      of the Tonite Services and Mutual App. By continuing
                      to use the Tonite Services and Mutual App, Partner
                      will be deemed to accept the Supplemental Terms. Provider
                      makes no warranties and accepts no liability with respect
                      to third party software. Notwithstanding anything to the
                      contrary herein, the terms and conditions of Mutual’s
                      then-current Privacy Policy, currently available at:
                      https://mutual.app/privacy, will apply to Mutual’s
                      collection, use and processing of Personal Data.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>15. TERM AND TERMINATION.</p>
                    <p style={{ ...text }}>
                      This Agreement will commence on the Effective Date and,
                      unless earlier terminated as provided below, will continue
                      for a period of one (1) year from the Effective Date
                      (“Initial Term”) and will automatically renew for
                      successive one (1) year periods (each, a “Renewal Term”
                      and together with the Initial Term, the “Term”). Either
                      party may terminate this Agreement in the event of a
                      material breach by the other party with two (2) days’
                      prior written notice thereof by the non-breaching party.
                      Either party may terminate this Agreement at any time
                      without cause by giving fourteen (14) days’ prior written
                      notice of termination to the other party, with the
                      exception being that should either party attempt to
                      terminate this Agreement during an active Promotion period
                      or Boosted Experience campaign, such termination will not
                      take effect until such Promotion period or Boosted
                      Experience campaign has ended. Notwithstanding the
                      foregoing, the termination or expiration of this Agreement
                      will not relieve either party of its obligations to
                      fulfill any Voucher that has been purchased by Customers
                      in accordance with its terms. In addition, Mutual may
                      suspend or otherwise terminate this Agreement on written
                      notice in the event of a Brand Matter. A “Brand Matter”
                      means an event involving Partner that, in Mutual’s
                      reasonable judgment, causes it or its affiliates to have
                      significant concern for the reputation of its respective
                      Marks or brand, including matters related to the alleged
                      violation of any applicable retail food or other health or
                      safety code. All payment obligations and Sections 1, 7.1,
                      8-13, this last sentence of 15, 16-17 and 19 will survive
                      the expiration or termination of this Agreement.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>16. NOTICE.</p>
                    <p style={{ ...text }}>
                      Any and all notices permitted or required to be given
                      hereunder will be sent to the address listed below, or
                      such other address as may be provided, and deemed duly
                      given: (a) upon actual delivery, if delivery is by hand;
                      or (b) one (1) day after being sent by overnight courier,
                      charges prepaid; or (c) by electronic mail to the
                      designated recipient. Notices to Mutual should be provided
                      to Mutual LLC, Attn: Mutual LLC, 1881 W Traverse Pkwy Ste
                      E #541, Lehi, UT 84043. Notices to Partner should be
                      provided to the address provided by Partner in an Order
                      Form or otherwise pursuant to this Section.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>
                      17. DISPUTE RESOLUTION AND ARBITRATION.
                    </p>
                    <p style={{ ...subheader }}>
                      17.1 Mandatory Binding Arbitration.
                    </p>
                    <p style={{ ...text }}>
                      Except as otherwise set forth in this Section 17, the
                      exclusive means of resolving any dispute or claim arising
                      out of or relating to these Terms (including any alleged
                      breach thereof) or the Service shall be binding
                      arbitration administered by the American Arbitration
                      Association (“AAA”)(this “Arbitration Agreement”). You may
                      not under any circumstances commence or maintain against
                      Mutual any class action, class arbitration, or other
                      representative action or proceeding.
                    </p>
                    <p style={{ ...subheader }}>
                      17.2 Notice Requirement and Informal Dispute Resolution.
                    </p>
                    <p style={{ ...text }}>
                      Before either party may seek arbitration, the party must
                      first send to the other party a written Notice of Dispute
                      (“Notice”) describing the nature and basis of the claim or
                      dispute, and the requested relief. Any Notice for Mutual
                      should be sent via email to Mutual at: support@mutual.app
                      . After the Notice is received, you and Mutual may attempt
                      to resolve the claim or dispute informally. If you and
                      Mutual do not resolve the claim or dispute within 30 days
                      after the Notice is received, either party may begin an
                      arbitration proceeding. The amount of any settlement offer
                      made by any party may not be disclosed to the arbitrator
                      until after the arbitrator has determined the amount of
                      the award, if any, to which either party is entitled.
                    </p>
                    <p style={{ ...subheader }}>17.3 Arbitration Rules.</p>
                    <p style={{ ...text }}>
                      Arbitration shall be conducted in accordance with the
                      AAA’s Consumer Arbitration Rules. The AAA’s Consumer
                      Arbitration Rules shall govern all aspects of this
                      arbitration, including but not limited to the method of
                      initiating and/or demanding arbitration, except to the
                      extent such rules are in conflict with these Terms. The
                      AAA Consumer Arbitration Rules are available online at
                      www.adr.org or by calling the AAA at 1-800-778-7879. The
                      arbitration shall be conducted by a single, neutral
                      arbitrator who is a lawyer with at least 15 years of
                      experience handling contractual disputes. The arbitrator
                      shall be selected by mutual agreement of the parties, or,
                      if the parties cannot agree, then by striking from a list
                      of arbitrators supplied by AAA. The place of the
                      arbitration shall be Salt Lake City, Utah. Any claims or
                      disputes where the total amount of the award sought is
                      less than $10,000.00 USD may be resolved through binding
                      non-appearance-based arbitration, at the option of the
                      party seeking relief. For claims or disputes where the
                      total amount of the award sought is $10,000 USD or more,
                      or if injunctive relief is sought, the right to a hearing
                      may be demanded by either party. Any judgment on the award
                      rendered by the arbitrator may be entered in any court of
                      competent jurisdiction. The cost of the arbitration
                      including the arbitrator and administrative fees shall be
                      borne equally by each party, and each party shall be
                      responsible for its own expenses and attorneys’ fees and
                      costs.
                    </p>
                    <p style={{ ...subheader }}>
                      17.4 Non-appearance Based Arbitration.
                    </p>
                    <p style={{ ...text }}>
                      If non-appearance arbitration is elected, the arbitration
                      shall be conducted by telephone, online and/or based
                      solely on written submissions; the specific manner shall
                      be chosen by the party initiating the arbitration. The
                      arbitration shall not involve any personal appearance by
                      the parties or witnesses unless otherwise mutually agreed
                      by the parties.
                    </p>
                    <p style={{ ...subheader }}>17.5 Time Limits.</p>
                    <p style={{ ...text }}>
                      If you or Mutual pursue arbitration, the arbitration
                      action must be initiated and/or demanded within the
                      statute of limitations (i.e., the legal deadline for
                      filing a claim) and within any deadline imposed under the
                      AAA Consumer Arbitration Rules for the pertinent claim.
                    </p>
                    <p style={{ ...subheader }}>
                      17.6 Authority of Arbitrator.
                    </p>
                    <p style={{ ...text }}>
                      If arbitration is initiated, the arbitrator will decide
                      the rights and liabilities, if any, of you and Mutual, and
                      the dispute will not be consolidated with any other
                      matters or joined with any other cases or parties. The
                      arbitrator shall have the authority to grant motions
                      dispositive of all or part of any claim. The arbitrator
                      shall have the authority to award monetary damages and to
                      grant any non-monetary remedy or relief available to an
                      individual under applicable law, the AAA’s Consumer
                      Arbitration Rules, and these Terms. The arbitrator shall
                      issue a written award and statement of decision describing
                      the essential findings and conclusions on which the award
                      is based, including the calculation of any damages
                      awarded. The arbitrator has the same authority to award
                      relief on an individual basis that a judge in a court of
                      law would have. The award of the arbitrator is final and
                      binding upon you and Mutual.
                    </p>
                    <p style={{ ...subheader }}>17.7 Waiver of Jury Trial.</p>
                    <p style={{ ...text }}>
                      THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND
                      STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT
                      OF A JUDGE OR A JURY, instead electing that all claims and
                      disputes shall be resolved by arbitration under this
                      Arbitration Agreement. Arbitration procedures are
                      typically more limited, more efficient and less costly
                      than rules applicable in court and are subject to very
                      limited review by a court. In the event any litigation
                      should arise between you and Mutual in any state or
                      federal court in a suit to vacate or enforce an
                      arbitration award, to avoid arbitration, or otherwise, YOU
                      AND MUTUAL WAIVE ALL RIGHTS TO A JURY TRIAL, instead
                      electing that the dispute be resolved by a judge. You
                      agree that any such litigation shall only be heard in a
                      state or federal court in Salt Lake City, Utah.
                    </p>
                    <p style={{ ...subheader }}>
                      17.8 Waiver of Class or Consolidated Actions.
                    </p>
                    <p style={{ ...text }}>
                      ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
                      ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON
                      AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS
                      OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
                      LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
                      CUSTOMER OR USER.
                    </p>
                    <p style={{ ...subheader }}>17.9 Confidentiality.</p>
                    <p style={{ ...text }}>
                      All aspects of the arbitration proceeding, including but
                      not limited to the award of the arbitrator and compliance
                      therewith, shall be strictly confidential. The parties
                      agree to maintain confidentiality unless otherwise
                      required by law. This Section shall not prevent a party
                      from submitting to a court of law any information
                      necessary to enforce these Terms, to enforce an
                      arbitration award, or to seek injunctive or equitable
                      relief.
                    </p>
                    <p style={{ ...subheader }}>
                      17.10 Emergency Equitable Relief.
                    </p>
                    <p style={{ ...text }}>
                      Notwithstanding the foregoing, either party may seek
                      emergency equitable relief before a state or federal court
                      in order to maintain the status quo pending arbitration. A
                      request for interim measures shall not be deemed a waiver
                      of any other rights or obligations under this Arbitration
                      Agreement.
                    </p>
                    <p style={{ ...subheader }}>
                      17.11 Claims Not Subject to Arbitration.
                    </p>
                    <p style={{ ...text }}>
                      Notwithstanding the foregoing, claims of defamation,
                      violation of the Computer Fraud and Abuse Act, and
                      infringement or misappropriation of the other party’s
                      intellectual property rights shall not be subject to this
                      Arbitration Agreement.
                    </p>
                    <p style={{ ...subheader }}>17.12</p>
                    <p style={{ ...text }}>
                      Any claim or dispute between you and Mutual that arises in
                      whole or in part from your use of the Service or in
                      connection with the Privacy Policy will be governed by the
                      laws of the State of Utah, without any regard to any
                      conflicts of law provisions. In any circumstances where
                      the Arbitration Agreement permits the parties to litigate
                      in court, the parties hereby agree to submit to the
                      personal jurisdiction of the courts located in Salt Lake
                      City, Utah, for such purpose, and waive any objection
                      based on inconvenient forum. The United Nations Convention
                      on Contracts for the International Sale of Goods does not
                      apply to these Terms.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>18. NON-DISCRIMINATION.</p>
                    <p style={{ ...text }}>
                      Partner will not, in its use of the Tonite Services
                      or the Mutual App under this Agreement, discriminate
                      against any customer, employee, contractor or other person
                      or individual on the basis of race, color, gender,
                      pregnancy, marital status, familial status, sexual
                      orientation, gender identity or expression, religion,
                      ancestry, national origin, disability, or age except that
                      programs may target beneficial services for specific
                      participant groups, as agreed upon between Mutual and
                      Partner. Partner acknowledges and agrees that upon
                      Mutual’s receipt of evidence of Partner’s discrimination
                      under any of these categories, Mutual will have the right
                      to immediately terminate this Agreement following notice
                      to Partner.
                    </p>
                  </div>
                  <div>
                    <p style={{ ...header }}>19. ADDITIONAL TERMS.</p>
                    <p style={{ ...text }}>
                      The territory of this Agreement is the United States
                      (“Territory”), and all payments issued under this
                      Agreement must be in U.S. dollars. Partner agrees to
                      receive calls, SMS messages and other communications,
                      including those made available by autodialer, sent by or
                      on behalf of Mutual or its affiliates. In this Agreement,
                      “including” means “including, without limitation,” and
                      examples are illustrative and not the sole examples of a
                      particular concept. The failure of either party to
                      enforce, at any time or for any period of time, the
                      provisions hereof, or the failure of either party to
                      exercise any option herein, will not be construed as a
                      waiver of such provision or option and will in no way
                      affect that party’s right to enforce such provisions or
                      exercise such option. This Agreement may not be assigned,
                      transferred, delegated or subcontracted, in whole or in
                      part, by a party without the prior written consent of the
                      other party, provided that each party may assign this
                      Agreement, upon written notice to the other party, (a) to
                      an affiliate of such party, or (b) in connection with the
                      sale of all or substantially all of such party’s equity,
                      business or assets to which this Agreement relates;
                      provided that in the event of any such transfer by
                      Partner, Partner explicitly consents that any such
                      transferee will have access to and control of all Partner
                      accounts related to such transfer, including its accounts
                      with Mutual, access to historical reporting information
                      about Items related to such transfer, and other account
                      data relating to such transfer. In the event of a change
                      of ownership involving Partner’s Location(s), the parties
                      will need to execute a Change of Ownership form and
                      Partner acknowledges and agrees that the Location will not
                      be able to accept or process any Customer purchases on the
                      Mutual App until the Change of Ownership is executed.
                      Subject to the foregoing, this Agreement will be binding
                      upon and will inure to the benefit of each party hereto
                      and its respective successors and assigns. Any purported
                      assignment, transfer, delegation or subcontract in
                      violation of this Section will be null and void. In the
                      event any provision of this Agreement is determined to be
                      invalid or unenforceable by ruling of an arbitrator or a
                      court of competent jurisdiction, the remainder of this
                      Agreement (and each of the remaining terms and conditions
                      contained herein) will remain in full force and effect.
                      Any delay in or failure by either party in the performance
                      of this Agreement will be excused if and to the extent
                      such delay or failure is caused by occurrences beyond the
                      control of the affected party including decrees or
                      restraints of Government, acts of God, strikes, work
                      stoppage or other labor disturbances, war or sabotage
                      (each being a “Force Majeure Event”). The affected party
                      will promptly notify the other party upon becoming aware
                      that any Force Majeure has occurred or is likely to occur
                      and will use commercially reasonable efforts to minimize
                      any resulting delay in or interference with the
                      performance of its obligations under this Agreement.
                      Nothing in this Agreement will be deemed to create any
                      joint venture, joint enterprise, or agency relationship
                      among the parties (except as otherwise expressly set forth
                      above), and no party will have the right to enter into
                      contracts on behalf of, to legally bind, to incur debt on
                      behalf of, or to otherwise incur any liability or
                      obligation on behalf of, the other party hereto, in the
                      absence of a separate writing, executed by an authorized
                      representative of the other party. Each party will be
                      solely responsible for its employees and contractors used
                      in connection with such party’s performance obligations
                      under this Agreement. This Agreement contains the full and
                      complete understanding and agreement between the parties
                      relating to the subject matter hereof and supersedes all
                      prior and contemporary understandings and agreements,
                      whether oral or written, relating such subject matter
                      hereof. This Agreement may be executed in one or more
                      counterparts and by exchange of electronically signed
                      counterparts transmitted by pdf format, each of which will
                      be deemed an original and all of which, when taken
                      together, will constitute one and the same original
                      instrument.
                    </p>
                  </div>
                  <p style={{ ...text }}>
                    If you have any questions or suggestions about our Terms and
                    Conditions, do not hesitate to contact us at
                    support@mutual.app.
                  </p>
                </Box>
              </Grid>
              <Grid container item xs={1} sm={2}></Grid>
            </Grid>
            <div style={spacerStyle}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(TermsOfService);
