import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  createStyles,
  Dialog,
  IconButton,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withTheme,
  withStyles,
} from "@material-ui/core";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import getData from "src/services/getData";
import { RightChevronIcon } from "../Icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DateSwitch } from "../StoreHours/StoreHours";
import { isNil } from "lodash";
import ExperienceForm from "../ExperienceForm/ExperienceForm";
import BoostForm from "../BoostForm/BoostForm";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../config";
import { ConfigKey } from "../../config/config.enums";
import { Spinner } from "react-activity";
import Dropdown from "react-dropdown";
import { boxShadow } from "../../services/util";
import "./paginate.css";

const styles = (theme: Theme) =>
  createStyles({
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    dropDownBox: {
      width: "auto",
      margin: 10,
      maxWidth: 250,
    },
  });

function ExperiencesPanel(props: any) {
  const {
    value,
    index,
    classes,
    reloadExperiences,
    revenuePercent = 0.2,
    changePartner,
    cards,
    updateBoosts,
    currentUser,
    liveBoosts,
    theme,
    isLoading,
    experiences,
    setExperiences,
    editedExperiences,
    pendingBoosts,
    actionItems,
    getExperiencesSeekingApproval,
    isAdmin,
    experienceCategories,
    partnerId,
  } = props;

  const [selectedExperience, setSelectedExperience] = useState<any>();
  const [isApproval, setIsApproval] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const [boost, setBoost] = useState(false);
  const [formType, setFormType] = useState("");
  const [filter, setFilter] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBoost, setSelectedBoost] = useState<any>();
  const [currentPartnerId] = useState(partnerId);
  const [currentRevenuePercent, setCurrentRevenuePercent] = useState(revenuePercent);

  useEffect(() => {
    const updateRevenuePercent = async () => {
      const partnerData = await getData(
        `partners/admin/${currentPartnerId}`,
        "GET",
        currentUser,
        {},
        {},
        true
      );
      if (Math.sign(partnerData?.revenuePercent) >= 0 && partnerData.revenuePercent <= 1) {
        setCurrentRevenuePercent(partnerData.revenuePercent);
      }
    }
    updateRevenuePercent();
  }, [currentPartnerId, currentUser])

  const [currentlyShownActionItems, setCurrentlyShownActionItems] = useState(
    []
  );
  const getFilteredActionItems = (filter: string) => {
    switch (filter) {
      case "boost":
        return actionItems.filter((item: any) =>
          pendingBoosts
            .map((b: any) => b.experienceId)
            .includes(item.experienceId)
        );
      case "edited":
        return editedExperiences;
      case "approval":
        return experiences;
      default:
        return actionItems;
    }
  };
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;
  let [filteredActionItems, setFilteredActionItems] = useState(getFilteredActionItems(filter));

  useEffect(() => {
    let filteredItems = [];
    if (!isNil(actionItems) && filteredActionItems.length === 0) {
      filteredItems = getFilteredActionItems(filter);
      if (filteredItems > 0) {
        setFilteredActionItems(filteredItems);
      }
    }
    if (filteredItems.length > 0) {
      filteredActionItems = filteredItems;
    }

    const endOffset = itemOffset + itemsPerPage;
    setCurrentlyShownActionItems(
      filteredActionItems.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(filteredActionItems.length / itemsPerPage));
  }, [actionItems, filteredActionItems]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentlyShownActionItems(
      filteredActionItems.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(filteredActionItems.length / itemsPerPage));
  }, [itemOffset, filteredActionItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = filteredActionItems.length === 0 ? 0 :
      (event.selected * itemsPerPage) % filteredActionItems.length;

    setItemOffset(newOffset);
  };

  const filterDropdownOptions = [
    { label: "All", value: "" },
    { label: "Boosts", value: "boost" },
    { label: "Edited", value: "edited" },
    { label: "Approvals", value: "approval" },
  ];

  const stripePromise = loadStripe(Config.getValue(ConfigKey.STRIPE_KEY));

  const handleFilterChange = (option: any) => {
    if (option.value !== filter) {
      setFilter(option.value);
      setItemOffset(0);
      setFilteredActionItems(getFilteredActionItems(option.value));
    }
  };

  const openExperience = async (
    popupState: any,
    exp: any,
    index: number,
    isApproval: boolean
  ) => {
    setIsApproval(isApproval);
    popupState.close();
    await changePartner(exp.partnerId);
    setSelectedExperience(exp);
    setBoost(false);
    setOpen(true);
  };

  const openBoost = async (popupState: any, exp: any, index: number) => {
    popupState.close();
    setBoost(true);
    await changePartner(exp.partnerId);
    setFormType("approve-reject");
    setOpen(true);
  };

  const openEditBoost = async (exp: any, index: number) => {
    setSelectedExperience(exp);
    setBoost(true);
    await changePartner(exp.partnerId);
    setFormType("edit");
    setOpen(true);
  };

  const openApproveBoost = async (exp: any, index: number) => {
    setBoost(true);
    // await changePartner(exp.partnerId);
    setSelectedExperience(exp);
    setFormType("approve-reject");
    setOpen(true);
  };

  function openDeactivateBoost(popupState: any, exp: any, currentBoost: any) {
    setSelectedExperience(exp);
    setSelectedBoost(currentBoost);
    popupState.close();
    setOpenConfirm(true);
  }

  async function deactivateBoost() {
    const boostOrderId = selectedExperience.activeBoost.boostOrderId;
    const experienceId = selectedExperience.experienceId;
    if (!isNil(boostOrderId) && !isNil(experienceId)) {
      try {
        await getData(
          `boosts/deactivate/${boostOrderId}/${experienceId}`,
          "PATCH",
          currentUser,
          {},
          undefined,
          true
        );
        reloadExperiences();
        getExperiencesSeekingApproval();
        updateBoosts();
        setOpenConfirm(false);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function displayPaginatedActionItems() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        paddingTop="15px"
        paddingLeft="25px"
      >
        <ReactPaginate
          containerClassName={"pagination"}
          activeClassName={"paginationActive"}
          activeLinkClassName={"paginationActive"}
          onPageChange={handlePageClick}
          pageCount={pageCount}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          style={{ flexWrap: "wrap" }}
        >
          {currentlyShownActionItems.map((item: any, i: any) => (
            <div key={i}>
              {!isNil(item) && isNil(item.boostOrderId) && (
                <Experience
                  key={i}
                  index={index}
                  classes={classes}
                  actionItem={item}
                  openExperience={openExperience}
                  setExperiences={setExperiences}
                  experiences={experiences}
                  currentUser={currentUser}
                  pendingBoosts={pendingBoosts}
                  openBoost={openBoost}
                  openEditBoost={openEditBoost}
                  setSelectedBoost={setSelectedBoost}
                  openApproveBoost={openApproveBoost}
                  openDeactivateBoost={openDeactivateBoost}
                  liveBoosts={liveBoosts}
                />
              )}
            </div>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box
        display="flex"
        flexDirection="column"
        className={classes.dropDownBox}
      >
        <Dropdown
          controlClassName={classes.dropdownSelector}
          placeholderClassName={classes.dropDownOption}
          menuClassName={classes.dropdown}
          className={classes.dropDownOption}
          options={filterDropdownOptions}
          value={filter}
          onChange={handleFilterChange}
        />
      </Box>
      {value === index && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          style={{ flexWrap: "wrap" }}
        >
          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              m={5}
              width="100%"
              style={{ padding: "10px 15px" }}
            >
              <Spinner />
            </Box>
          )}
          {actionItems && displayPaginatedActionItems()}
        </Box>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setSelectedExperience(undefined);
          setBoost(false);
        }}
        scroll={"body"}
      >
        {boost ? (
          <BoostForm
            cards={cards}
            updatePaymentMethods={() => { }}
            stripe={stripePromise}
            experienceId={selectedExperience.experienceId}
            selectedBoost={selectedBoost}
            updateBoosts={updateBoosts}
            liveBoosts={liveBoosts}
            pendingBoosts={pendingBoosts}
            selectedExperienceBoost={() => { }}
            setSelectedExperienceBoost={() => { }}
            reloadExperiences={reloadExperiences}
            setOpen={setOpen}
            currentUser={currentUser}
            openBoost={openBoost}
            formType={formType}
            getExperiencesSeekingApproval={getExperiencesSeekingApproval}
            boostIsLive={!!selectedBoost?.approved}
            isAdmin={isAdmin}
          />
        ) : (
          <ExperienceForm
            currentUser={currentUser}
            setSelectedExperience={setSelectedExperience}
            selectedExperience={selectedExperience}
            revenuePercent={currentRevenuePercent}
            reloadExperiences={getExperiencesSeekingApproval}
            setSelectedExperienceBoost={() => { }}
            setOpen={setOpen}
            isApproval={isApproval}
            isAdmin={isAdmin}
            experienceCategories={experienceCategories}
          ></ExperienceForm>
        )}
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={() => {
          setBoost(false);
        }}
        scroll={"body"}
      >
        <Box p={4} flexDirection="column" justifyContent="center">
          <Typography style={{ textAlign: "center" }}>
            Are you sure you want to deactivate this boost?
          </Typography>
          <Box padding={theme.spacing(0.2)}></Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Button
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                padding: "6px 8px",
                boxShadow: boxShadow,
                textTransform: "uppercase",
                fontSize: "14px",
                lineHeight: "17px",
                fontWeight: 700,
                letterSpacing: "0.05em",
              }}
              onClick={() => deactivateBoost()}
            >
              DEACTIVATE
            </Button>
            <Box padding={theme.spacing(0.1)}></Box>
            <Button
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                padding: "6px 8px",
                boxShadow: boxShadow,
                textTransform: "uppercase",
                fontSize: "14px",
                lineHeight: "17px",
                fontWeight: 700,
                letterSpacing: "0.05em",
              }}
              onClick={() => setOpenConfirm(false)}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

function ExperienceOption(props: any) {
  const { classes, onClick, label } = props;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingY={"4px"}
      className={classes.hoverButton}
      onClick={() => {
        onClick();
      }}
      style={{
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "21.6px",
        width: "100%",
        margin: "5px",
        cursor: "pointer",
      }}
    >
      <Box display="flex" flexDirection="row" flex={4}>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "21.6px",
            color: "#5C5C5C",
            whiteSpace: "pre-line",
            marginLeft: "15px",
          }}
          display="block"
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

function Experience(props: any) {
  const {
    index,
    currentUser,
    classes,
    actionItem,
    openExperience,
    setExperiences,
    openEditBoost,
    openApproveBoost,
    experiences,
    liveBoosts,
    pendingBoosts,
    openDeactivateBoost,
    setSelectedBoost,
  } = props;
  const [isLive, setIsLive] = useState(actionItem.live);

  let currentBoost = pendingBoosts?.find((boost: any) => {
    return boost.experienceId === actionItem.experienceId;
  });
  const liveBoost = !isNil(currentBoost);
  if (!liveBoost) {
    currentBoost = liveBoosts.find((boost: any) => {
      return boost.experienceId === actionItem.experienceId;
    });
  }

  async function handleChange() {
    if (isNil(actionItem?.live)) {
      actionItem.live = true;
    } else {
      actionItem.live = !actionItem.live;
    }
    try {
      await getData(
        `action-items`,
        "POST",
        currentUser,
        {
          approve: actionItem.live,
        },
        {
          id: actionItem.experienceId,
          action_type: "experience",
        },
        true
      );
    } catch (error) {
      console.error(error);
    }
    const objIndex = experiences.findIndex(
      (obj: any) => obj._id === actionItem._id
    );
    experiences[objIndex].live = actionItem.live;
    setExperiences(experiences);
    setIsLive(actionItem.live);
    // TODO: Update live in DB
  }

  function actionItemTitle() {
    let title = "";
    if (actionItem?.live) {
      if (Boolean(actionItem.editedExperience?.live) === false) {
        title = "Pending Deactivation";
      } else {
        title = "Live";
      }
    } else if (!!actionItem?.approved) {
      title = "Pending Approval";
    } else {
      title = "Work in Progress";
    }
    return title;
  }

  const url =
    actionItem.images && actionItem.images.length > 0
      ? actionItem.images[0].url
      : "https://mutual-experiences.s3.us-east-2.amazonaws.com/a4be5297-842f-4726-812f-5df483f953f2-1ea64540-143d-492f-852a-1220aeeac020.jpeg";

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const experiencePendingBoost = pendingBoosts.find(
    (x: any) => x.experienceId === actionItem.experienceId
  );

  return (
    <Box key={index} style={{ marginTop: !xs && !sm ? "49px" : "10px" }}>
      <Card
        className={classes.experienceCard}
        style={{
          marginRight: !xs && sm ? "30px" : "0px",
          marginLeft: "20px",
          marginTop: "15px",
          width: "300px",
          height: "286px",
        }}
      >
        <CardMedia
          className={classes.media}
          children={
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              style={{ height: "200px", padding: "10px 15px" }}
            >
              <Typography
                style={{
                  maxHeight: "150px",
                  overflow: "hidden",
                  fontWeight: 700,
                  fontSize: "30px",
                  lineHeight: "30px",
                  color: "#FFFFFF",
                  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.23)",
                }}
              >
                {actionItem.title}
              </Typography>
              <Typography
                style={{
                  paddingTop: "1rem",
                  fontWeight: 700,
                  fontSize: "12px",
                  lineHeight: "12px",
                  color: "#00FF94",
                  textTransform: "uppercase",
                }}
              >
                {actionItem.live ? "Active" : "Inactive"}
              </Typography>
            </Box>
          }
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 28.86%, rgba(0, 0, 0, 0.74) 100%), url(${url})`,
          }}
        />
        <CardActions disableSpacing>
          <Box display="flex" flexDirection="column" width={1}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: "#1E1029",
                }}
              >
                {actionItemTitle()}
              </Typography>
              <PopupState variant="popover" popupId="exp-more-popover">
                {(popupState) => (
                  <div>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box width={"200px"} paddingY={"10px"} margin={0}>
                        <ExperienceOption
                          classes={classes}
                          onClick={() =>
                            openExperience(popupState, actionItem, index, false)
                          }
                          label="Edit"
                        />
                        <ExperienceOption
                          classes={classes}
                          onClick={() =>
                            openExperience(popupState, actionItem, index, true)
                          }
                          label={`Approve\nExperience`}
                        />
                        {!isNil(experiencePendingBoost) && (
                          <ExperienceOption
                            classes={classes}
                            onClick={() => {
                              setSelectedBoost(currentBoost);
                              openApproveBoost(actionItem, index);
                            }}
                            label={`Approve Boost`}
                          />
                        )}
                        {actionItem?.activeBoost && (
                          <ExperienceOption
                            classes={classes}
                            onClick={() =>
                              openDeactivateBoost(
                                popupState,
                                actionItem,
                                currentBoost
                              )
                            }
                            label="Deactivate Boost"
                          />
                        )}
                      </Box>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <DateSwitch checked={isLive} onChange={handleChange} />
                <Typography
                  style={{
                    paddingLeft: "0.5rem",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19.2px",
                    color: "#1E1029",
                    textTransform: "uppercase",
                  }}
                >
                  {actionItem.live ? "Active" : "Inactive"}
                </Typography>
              </Box>
              <PopupState variant="popover" popupId="exp-more-popover">
                {(popupState) => (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {!isNil(currentBoost) ? (
                      <Button
                        onClick={() => {
                          setSelectedBoost(currentBoost);
                          openEditBoost(actionItem, index);
                        }}
                      >
                        <Typography
                          style={{
                            paddingRight: "0.5rem",
                            fontWeight: 700,
                            fontSize: "17px",
                            lineHeight: "19.2px",
                            color: "#1E1029",
                          }}
                        >
                          {"Boost"}
                        </Typography>
                        <RightChevronIcon fill="#1E1029" />
                      </Button>
                    ) : (
                      <Button disabled>
                        <Typography
                          style={{
                            paddingRight: "0.5rem",
                            fontWeight: 700,
                            fontSize: "17px",
                            lineHeight: "19.2px",
                          }}
                        >
                          {"Boost"}
                        </Typography>
                        <RightChevronIcon fill="#D6D6D6" />
                      </Button>
                    )}
                  </Box>
                )}
              </PopupState>
            </Box>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}

export default withTheme(withStyles(styles)(ExperiencesPanel));
