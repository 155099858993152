import { Box, TextField, Typography } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { formatCurrencyAsDollars, notNumbersRegex  } from 'src/services/util';

const lightBorder = "rgba(214, 214, 214, 1)";
interface IFixedOrPercentProps {
  isFixed: boolean;
  onChange: (newValue: number) => void;
  creditAmount: number;
}

//input field that matches a monetary input style or a percentage input style
export function FixedOrPercent(props: IFixedOrPercentProps) {
  const maxNumbersAllowedFixed = 8;
  const maxNumbersAllowedPercent = 2;

  /// handles changes when the editing mode is set to fixed
  function handleFixedChange(event: ChangeEvent<HTMLInputElement>) {
    let inputString = event.target.value;
    inputString = inputString.replaceAll(notNumbersRegex, "");
    if (inputString.length > maxNumbersAllowedFixed) {
      inputString = inputString.slice(0, maxNumbersAllowedFixed);
    }
    event.target.value = (Number(inputString) / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    props.onChange(Number(inputString.replaceAll(notNumbersRegex, "")));
  }

  /// handles changes when the editing mode is set to Percent
  function handlePercentChange(event: ChangeEvent<HTMLInputElement>) {
    let inputString = event.target.value;
    inputString = inputString.replaceAll(notNumbersRegex, "");
    if (inputString.length > maxNumbersAllowedPercent) {
      inputString = inputString.slice(0, maxNumbersAllowedPercent);
    }
    //double cast to get rid of leading 0
    event.target.value = String(Number(inputString));
    props.onChange(Number(inputString));
  }

  return (
    <Box
      sx={{
        border: `1px solid ${lightBorder}`,
        borderRadius: 7,
        paddingLeft: 5,
        paddingRight: 5,
        width: 130,
      }}
    >
      {props.isFixed ? (
        <TextField
          key={"fixed"}
          onChange={handleFixedChange}
          value={formatCurrencyAsDollars(props.creditAmount)}
          defaultValue={"$0.00"}
        />
      ) : (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <TextField
            key={"percent"}
            onChange={handlePercentChange}
            value={props.creditAmount}
            defaultValue={"0"}
          />
          <Typography>%</Typography>
        </Box>
      )}
    </Box>
  );
}
