import {
  Box,
  createStyles,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  withStyles,
  withTheme,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { DateSwitch } from "../StoreHours/StoreHours";

const styles = (theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "16.8px",
      color: "#5C5C5C",
      paddingBottom: theme.spacing(1),
    },
    dropdownSelector: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
    },
    dropdown: {
      border: "1px solid rgba(186, 186, 186, 0.6)",
      boxSizing: "border-box",
      borderRadius: "10px",
      padding: `0px ${theme.spacing(1)}px`,
      width: "100%",
    },
    dropDownOption: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
      width: "100%",
    },
    cssFocused: {
      "&:hover": {
        borderColor: "#FFFFFF",
      },
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "#FFFFFF",
      },
      "&:hover $notchedOutline": {
        borderColor: "#FFFFFF",
      },
    },
    notchedOutline: {
      borderColor: "#FFFFFF",
      "&:hover": {
        borderColor: "#FFFFFF",
      },
    },
  });

function DateSelectorWithSwitch(props: any) {
  const {
    theme,
    classes,
    date,
    dateMinMax,
    handleChange,
    dropdownOptions,
    selectedDate,
    handleDateChange,
    name,
    label,
    disabled = false,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      paddingRight={theme.spacing(0.1)}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        paddingRight={theme.spacing(0.1)}
      >
        <Typography className={classes.label}>{label}</Typography>
        <DateSwitch
          checked={date.available}
          onChange={handleChange}
          name={name}
          disabled={disabled}
        />
      </Box>
      {date.available ? (
        <Dropdown
          controlClassName={classes.dropdownSelector}
          placeholderClassName={classes.dropDownOption}
          menuClassName={classes.dropdown}
          className={classes.dropDownOption}
          disabled={disabled}
          options={dropdownOptions}
          value={dropdownOptions[0]}
        />
      ) : (
        <DatePicker
          autoOk
          value={selectedDate}
          onChange={(event) => {
            handleDateChange(name, event);
          }}
          disabled={disabled}
          disablePast={true}
          minDate={dateMinMax.startDate}
          maxDate={dateMinMax.endDate}
          minDateMessage="Date cannot be before start date"
          maxDateMessage="Date cannot be after end date"
          TextFieldComponent={(textFieldProps: TextFieldProps) => (
            <TextField
              {...{
                ...textFieldProps,
                variant: "outlined",
                InputProps: {
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  margin: "dense",
                  style: {
                    border: "1px solid rgba(186, 186, 186, 0.6)",
                    borderRadius: "10px",
                  },
                  ...textFieldProps.InputProps,
                },
                inputProps: {
                  style: {
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#000000",
                    padding: "8px 10px",
                  },
                  ...textFieldProps.inputProps,
                },
              }}
              helperText={undefined}
              margin="none"
            />
          )}
        />
      )}
    </Box>
  );
}

export default withTheme(withStyles(styles)(DateSelectorWithSwitch));
