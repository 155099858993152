
export enum PromoTypes {
	userReferral = 0,
	marketingPromotion = 1
}
export enum CreditType {
	flatAmount = 0,
	percentageAmount = 1
}

export interface Discount {
	type: CreditType;
	amount: number;
}

export interface PromoCode {
	promoCode: string;
	deepLinkMutual: string;
	deepLinkMutualDates: string;
	description: string | null;
	creatorName: string | null;
	live: boolean;
	promoType: PromoTypes;
	referrerUserId: string | null;
	createdAt: Date;
	discount: Discount | null;
	startAt: Date | null;
	expiredAt: Date | null;
	maxRedemptions: number | null;
	maxRedemptionsPerUser: number | null;
	targetedExperienceId: string | null;
	targetedExperienceTitle: string | null;
	redemptions: number;
	totalSumDiscounted: number;
	creditEarned: number | null;
	allowStacking: boolean;
	limitToFirstPurchaseOnly: boolean | null;
}