import _ from 'lodash';

import { ConfigKey } from './config.enums';
import rawConf from './config.json';

export default class Config {
	private static _conf = new Map<ConfigKey, string>();
	
	public static getValue(key: ConfigKey): string {
		let val: string | undefined;

		if (!this._conf.has(key)) {
			this.resetConfig();
			if (!this._conf.has(key)) {
				throw new Error('Unable to load config!');
			}
		}

		val = this._conf.get(key);
		return String(val);
	}


	private static resetConfig(): void {
		const config = this.readConfig();
		this.buildConfMap(config);
	}

	private static readConfig(): any {
		try {
			return rawConf;
		} catch (error) {
			throw error;
		}
	}

	private static buildConfMap(obj: any): void {
		const keys = _.keys(obj);
		_.forEach(keys, (key) => this._conf.set(key as ConfigKey, obj[key]));
	}
}